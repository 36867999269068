import MediaContextProvider from "./context/MediaContext";
import HelpModalContextProvider from "./context/HelpModalContext";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ToastContainer } from "react-toastify";
import { PropsWithChildren } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Provider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}>
      <MediaContextProvider>
        <ToastContainer />
        <HelpModalContextProvider>{children}</HelpModalContextProvider>
      </MediaContextProvider>
    </APIProvider>
    </GoogleReCaptchaProvider>
  );
};

export default Provider;
