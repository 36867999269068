import { Switch } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomSwitch = ({
  control,
  name,
  defaultChecked = false,
  label,
  disabled
}: {
  control: any;
  name: string;
  defaultChecked?: boolean;
  label?: string;
  disabled?:boolean;
}) => {
  return (
    <>
      {label && <label className="label">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Switch
            data-testid="Switch"
              defaultChecked={defaultChecked}
              value={value}
              onChange={(event, val) => {
                return onChange(val);
              }}
              disabled={disabled}
              checked={value}
            />
          );
        }}
      />
    </>
  );
};
export default CustomSwitch;
