import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getSessionLoading } from "../../components/profileMenu/selectors";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";

const LoggedOutWrapper = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useSelector((state: any) => state.authGuard);
  const sessionLoading = useSelector((state) => getSessionLoading(state));

  if (auth && sessionLoading) {
    return <GlobalLoader></GlobalLoader>;
  } else if (auth) {
    return <>{<Navigate to="/property-search" />}</>;
  } else {
    return <>{children}</>;
  }
};

export default LoggedOutWrapper;
