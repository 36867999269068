import { useEffect } from "react";
import styles from "./Policy.module.scss";
import { scrollToSection } from "./utils";
import { Helmet } from "react-helmet-async";

const TermsOfUse = () => {
  useEffect(() => {
    const handleAnchorClick = (event: any) => {
      const anchor = event.target.closest("a");

      if (anchor && anchor.getAttribute("href")) {
        const href = anchor.getAttribute("href");
        if (href.startsWith("#")) {
          event.preventDefault();
          const id = href.slice(1);
          scrollToSection(id);
        } else if (
          href.startsWith("http://") ||
          href.startsWith("https://") ||
          href.startsWith("//")
        ) {
          return;
        }
      }
    };

    document.addEventListener("click", handleAnchorClick);

    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <Helmet>
        <title>Terms of Use - OttoLeads</title>
      </Helmet>

      <div className={`${styles.policy}`}>
        <div className={`${styles.policy__header}`}>
          <h3>Last updated March 25, 2024</h3>
          <h1>Terms of Use</h1>
        </div>
        <div className={`${styles.policy__wrapper}`}>
          <div className={`${styles.policy__text}`}>
            <p>
              We are OttoLeads LLC (<b>“Company,” “we,” “us,” “our”</b>), a
              company registered in Wyoming, United States at 30 N Gould St, Ste
              R, Sheridan, WY 82801.
            </p>

            <p>
              We operate the website{" "}
              <a
                href="https://www.ottoleads.ai/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                https://www.ottoleads.ai{" "}
              </a>
              (the <b>”Site”</b>), as well as any other related content,
              functionality, products, and services offered on or through our
              Site that refer or link to these legal terms (the{" "}
              <b>”Legal Terms”</b> or the “Agreement”) (collectively, the{" "}
              <b>”Services”</b>).
            </p>

            <p>
              You can contact us by email at{" "}
              <a
                href="mailto:support@ottoleads.ai"
                target="_blank"
                rel="noreferrer"
              >
                support@ottoleads.ai
              </a>{" "}
              or by mail to 30 N Gould St, Ste R, Sheridan, WY 82801.
            </p>

            <p>
              These Legal Terms constitute a legally binding agreement made
              between you, whether as a guest or a registered subscriber and
              whether personally or on behalf of an entity (<b>“you”</b>), and
              OttoLeads LLC, concerning your access to and use of the Services.
              You agree that by accessing the Services, you have read,
              understood, and agreed to be bound by all of these Legal Terms. IF
              YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
              EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
              DISCONTINUE USE IMMEDIATELY.
            </p>

            <p>
              Supplemental terms and conditions or documents that may be posted
              on the Services from time to time are hereby expressly
              incorporated herein by reference. We reserve the right, in our
              sole discretion, to make changes or modifications to these Legal
              Terms at any time and for any reason. We will alert you about any
              changes by updating the ”Last updated” date of these Legal Terms,
              and you waive any right to receive specific notice of each such
              change. It is your responsibility to periodically review these
              Legal Terms to stay informed of updates. You will be subject to,
              and will be deemed to have been made aware of and to have
              accepted, the changes in any revised Legal Terms by your continued
              use of the Services after the date such revised Legal Terms are
              posted.
            </p>

            <p>
              The Services are intended for users who are 18 years of age or
              older and reside in the United States or any of its territories or
              possessions. By using this Site, you represent and warrant that
              you are of legal age to form a binding contract with the Company
              and meet all of the foregoing eligibility requirements. If you do
              not meet all of these requirements, you must not access or use the
              Site.
            </p>
            <p>
              We recommend that you print a copy of these Legal Terms for your
              records. This Agreement contains significant limitations on our
              liability as well as restrictions on your legal rights—read them
              thoroughly. It is your obligation to read, understand and agree to
              these terms before you proceed to use any of our Services.
            </p>

            <div className={`${styles.policy__toc}`}>
              <h2 id="toc">TABLE OF CONTENTS</h2>

              <ol>
                <li data-list-text="1.">
                  <a href="#ip"> INTELLECTUAL PROPERTY RIGHTS</a>
                </li>
                <li data-list-text="2.">
                  <a href="#userreps"> USER REPRESENTATIONS AND WARRANTIES</a>
                </li>
                <li data-list-text="3.">
                  <a href="#userreg"> USER REGISTRATION</a>
                </li>
                <li data-list-text="4.">
                  <a href="#purchases"> PURCHASES AND PAYMENT</a>
                </li>
                <li data-list-text="5.">
                  <a href="#free"> FREE TRIAL</a>
                </li>
                <li data-list-text="6.">
                  <a href="#cancel"> CANCELLATION</a>
                </li>
                <li data-list-text="7.">
                  <a href="#prohibited"> PROHIBITED ACTIVITIES</a>
                </li>
                <li data-list-text="8.">
                  <a href="#thirdparty"> THIRD-PARTY WEBSITES AND CONTENT</a>
                </li>
                <li data-list-text="9.">
                  <a href="#servicesmanage"> SERVICES MANAGEMENT</a>
                </li>
                <li data-list-text="10.">
                  <a href="#privacypolicy"> PRIVACY POLICY</a>
                </li>
                <li data-list-text="11.">
                  <a href="#copyinfr"> COPYRIGHT INFRINGEMENTS</a>
                </li>
                <li data-list-text="12.">
                  <a href="#termtermination"> TERM AND TERMINATION</a>
                </li>
                <li data-list-text="13.">
                  <a href="#modification"> MODIFICATIONS AND INTERRUPTIONS</a>
                </li>
                <li data-list-text="14.">
                  <a href="#govtlaw"> GOVERNING LAW</a>
                </li>
                <li data-list-text="15.">
                  <a href="#dispute"> DISPUTE RESOLUTION</a>
                </li>
                <li data-list-text="16.">
                  <a href="#corrections"> CORRECTIONS</a>
                </li>
                <li data-list-text="17.">
                  <a href="#disclaimer"> DISCLAIMER</a>
                </li>
                <li data-list-text="18.">
                  <a href="#limitations"> LIMITATIONS OF LIABILITY</a>
                </li>
                <li data-list-text="19.">
                  <a href="#indemnification"> INDEMNIFICATION</a>
                </li>
                <li data-list-text="20.">
                  <a href="#userdata"> USER DATA</a>
                </li>
                <li data-list-text="21.">
                  <a href="#ects">
                    {" "}
                    ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </a>
                </li>
                <li data-list-text="22.">
                  <a href="#california"> CALIFORNIA USERS AND RESIDENTS</a>
                </li>
                <li data-list-text="23.">
                  <a href="#misc"> MISCELLANEOUS</a>
                </li>
                <li data-list-text="24.">
                  <a href="#contact"> CONTACT US</a>
                </li>
              </ol>
              <div className={`${styles.policy__tocInfo}`}>
                <ol>
                  <li id="ip" data-list-text="1.">
                    <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
                    <h3>Our Intellectual Property</h3>
                    <p>
                      We are the owner or the licensee of all intellectual
                      property rights in our Services, including all source
                      code, databases, functionality, software, contents,
                      features, including the website designs, selection,
                      arrangement, audio, video, text, displays, images,
                      photographs, and graphics on the Site and included in the
                      Services (collectively, the <b>”Content”</b>), as well as
                      the trademarks, service marks, and logos contained therein
                      (the <b>”Marks”</b>).
                    </p>

                    <p>
                      Our Content and Marks are protected by copyright and
                      trademark laws (and various other intellectual property
                      rights and unfair competition laws) and treaties in the
                      United States and around the world.
                    </p>

                    <p>
                      The Content and Marks are provided in or through the
                      Services ”AS IS” for your personal, non-commercial use or
                      internal business purpose only.
                    </p>

                    <h3>Your use of our Services</h3>

                    <p>
                      Subject to your compliance with these Legal Terms,
                      including the "
                      <a href="#prohibited">PROHIBITED ACTIVITIES</a>" section
                      below, we grant you a non-exclusive, non-transferable,
                      non-assignable revocable license to do the following
                      during the term of the Agreement:
                    </p>

                    <ul>
                      <li>access and use the Services; and</li>
                      <li>
                        download or print a copy of any portion of the Content
                        to which you have properly gained access.
                      </li>
                    </ul>
                    <p>
                      solely for your personal, non-commercial use or internal
                      business purpose.
                    </p>

                    <p>
                      Except as set out in this section or elsewhere in our
                      Legal Terms, no part of the Services and no Content or
                      Marks may be copied, reproduced, aggregated, republished,
                      uploaded, posted, publicly displayed, encoded, translated,
                      transmitted, distributed, sold, licensed, or otherwise
                      exploited for any commercial purpose whatsoever, without
                      our express prior written permission.
                    </p>

                    <p>
                      If you wish to make any use of the Services, Content, or
                      Marks other than as set out in this section or elsewhere
                      in our Legal Terms, please address your request to{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      .
                    </p>

                    <p>
                      If we grant you the permission to post, reproduce, or
                      publicly display any part of our Services or Content, you
                      must identify us as the owners or licensors of the
                      Services, Content, or Marks and ensure that any copyright
                      or proprietary notice appears or is visible on posting,
                      reproducing, or displaying our Content.
                    </p>

                    <p>
                      The Services may be accessed by you through your
                      employees, agents, or representatives that are authorized
                      by you. You shall ensure that only authorized individuals
                      shall have access to the Services. You shall promptly
                      inform the Company of any unauthorized access to the
                      Services or the loss or theft of your access credentials.
                      You are responsible for all access and use of the Services
                      using your access credentials regardless of whether such
                      access and use was authorized by you. You shall be
                      responsible for, and liable to the Company for, any
                      security breaches by you or your employees, agents, or
                      representatives.
                    </p>

                    <p>
                      We reserve all rights not expressly granted to you in and
                      to the Services, Content, and Marks.
                    </p>

                    <p>
                      Any breach of these Intellectual Property Rights will
                      constitute a material breach of our Legal Terms and your
                      right to use our Services will terminate immediately.
                    </p>

                    <h3>Your submissions and contributions</h3>
                    <p>
                      Please review this section and the "
                      <a href="#prohibited">PROHIBITED ACTIVITIES</a>" section
                      carefully prior to using our Services to understand the
                      (a) rights you give us and (b) obligations you have when
                      you post or upload any content through the Services.
                    </p>
                    <p>
                      <b>Submissions:</b> By directly sending us any question,
                      comment, suggestion, idea, feedback, or other information
                      about the Services (<b>“Submissions”</b>), you agree to
                      assign to us all intellectual property rights in such
                      Submission. You agree that we shall own this Submission
                      and be entitled to its unrestricted use and dissemination
                      for any lawful purpose, commercial or otherwise, without
                      acknowledgment or compensation to you.
                    </p>

                    <p>
                      <b>Contributions:</b> The Services may invite you to chat,
                      contribute to, or participate in blogs, message boards,
                      online forums, and other functionality during which you
                      may create, submit, post, display, transmit, publish,
                      distribute, or broadcast content and materials to us or
                      through the Services, including but not limited to text,
                      writings, video, audio, photographs, music, graphics,
                      comments, reviews, rating suggestions, personal
                      information, or other material (<b>“Contributions”</b>).
                      Any Submission that is publicly posted shall also be
                      treated as a Contribution.
                    </p>
                    <p>
                      You understand that Contributions may be viewable by other
                      users of the Services and possibly through third-party
                      websites.
                    </p>
                    <p>
                      <b>
                        When you post Contributions, you grant us a license
                        (including use of your name, trademarks, and logos):
                      </b>{" "}
                      By posting any Contributions, you grant us, and you
                      represent and warrant that you the right to grant to us,
                      an unrestricted, unlimited, irrevocable, perpetual,
                      non-exclusive, transferable, royalty-free, fully-paid,
                      worldwide right, and license to: use, copy, reproduce,
                      distribute, sell, resell, publish, broadcast, retitle,
                      store, publicly perform, publicly display, reformat,
                      translate, excerpt (in whole or in part), and exploit your
                      Contributions (including, without limitation, your image,
                      name, and voice) for any purpose, commercial, advertising,
                      or otherwise, to prepare derivative works of, or
                      incorporate into other works, your Contributions, and to
                      sublicense the licenses granted in this section. Our use
                      and distribution may occur in any media formats and
                      through any media channels.
                    </p>

                    <p>
                      This license includes our use of your name, company name,
                      and franchise name, as applicable, and any of the
                      trademarks, service marks, trade names, logos, and
                      personal and commercial images you provide.
                    </p>

                    <p>
                      <b>You are responsible for what you post or upload:</b> By
                      sending us Submissions and/or posting Contributions
                      through any part of the Services or making Contributions
                      accessible through the Services by linking your account
                      through the Services to any of your social networking
                      accounts, you:
                    </p>

                    <ul>
                      <li>
                        confirm that you have read and agree with our "
                        <a href="#prohibited">PROHIBITED ACTIVITIES</a>" and
                        will not post, send, publish, upload, or transmit
                        through the Services any Submission nor post any
                        Contribution that is illegal, harassing, hateful,
                        harmful, defamatory, obscene, bullying, abusive,
                        discriminatory, threatening to any person or group,
                        sexually explicit, false, inaccurate, deceitful, or
                        misleading as determined by us;
                      </li>
                      <li>
                        warrant that any such Submission and/or Contributions
                        are original to you or that you have the necessary
                        rights and licenses to submit such Submissions and/or
                        Contributions and that you have full authority to grant
                        us the above-mentioned rights in relation to your
                        Submissions and/or Contributions; and
                      </li>
                      <li>
                        warrant and represent that your Submissions and/or
                        Contributions do not constitute the confidential
                        information or personally identifiable information of a
                        third party.
                      </li>
                    </ul>

                    <p>
                      <b>
                        You retain ownership and responsibility for what you
                        post or upload:
                      </b>{" "}
                      We do not assert any ownership over your Contributions.
                      You retain full ownership of all of your Contributions and
                      any intellectual property rights or other proprietary
                      rights associated with your Contributions subject to the
                      above granted license. We are not liable for any
                      statements or representations in your Contributions. You
                      are solely responsible for your Submissions and/or
                      Contributions and you expressly agree to reimburse us for
                      any and all losses that we may suffer because of your
                      breach of (a) this section, (b) any third party’s
                      intellectual property rights, or (c) applicable law.
                    </p>
                    <p>
                      <b>We may remove or edit your Content:</b> Although we
                      have no obligation to monitor any Contributions, we shall
                      have the right to remove or edit any Contributions at any
                      time without notice if in our reasonable opinion we
                      consider such Contributions harmful or in breach of these
                      Legal Terms. If we remove or edit any such Contributions,
                      we may also suspend or disable your account and report you
                      to the authorities.
                    </p>

                    <h3>Copyright infringement</h3>

                    <p>
                      We respect the intellectual property rights of others. If
                      you believe that any material available on or through the
                      Services infringes upon any copyright you own or control,
                      please immediately refer to the "
                      <a href="#copyinfr">COPYRIGHT INFRINGEMENTS</a>" section
                      below.
                    </p>
                  </li>
                  <li data-list-text="2." id="userreps">
                    <h2>USER REPRESENTATIONS AND WARRANTIES</h2>

                    <p>
                      By using the Services, you represent and warrant that: (1)
                      all registration information you submit will be true,
                      accurate, current, and complete; (2) you will maintain the
                      accuracy of such information and promptly update such
                      registration information as necessary; (3) you have the
                      legal capacity and you agree to comply with these Legal
                      Terms; (4) you are not a minor in the jurisdiction in
                      which you reside; (5) you will not access the Services
                      through automated or non-human means, whether through a
                      bot, script or otherwise; (6) you will not use the
                      Services for any illegal or unauthorized purpose; (7) your
                      use of the Services will not violate any applicable law or
                      regulation; (8) you will not use the Services for any
                      marketing or telemarketing except in strict compliance
                      with all governing laws; (9) You will not violate the
                      publicity or privacy rights of another individual in using
                      the Services; (10) You will obtain all required consents
                      of any person with whom you communicate using the Services
                      or data derived from the Services; (11) You will honor all
                      opt-outs and consent revocations received from any person,
                      and you are solely responsible for doing so; (12) You will
                      not mislead recipients of any call, text, email or other
                      message as to your identity, including without limitation
                      by creating a false caller ID (e.g., ID spoofing), forging
                      addresses or headers, or fraudulently undertaking other
                      technical measures to misrepresent the origin or identity
                      of the sender or caller;
                    </p>
                    <p>
                      Without limiting the foregoing or any other provision
                      herein, in the event that you use automated calling to
                      communicate with persons whose information is obtained
                      through or using the Services, you agree to comply with
                      all applicable provisions of the Telephone Consumer
                      Protection Act (“TCPA”) and all other applicable federal
                      and state laws and regulations regarding unsolicited
                      telemarketing calls and text messages, including, but not
                      limited to:
                    </p>

                    <ul>
                      <li>
                        Obtaining prior express written consent from called
                        parties before using the Services to make calls or send
                        text messages to their mobile numbers;
                      </li>
                      <li>
                        Maintaining accurate and up-to-date Do Not Call (‘DNC”)
                        lists and honoring all opt-out requests promptly;
                      </li>
                      <li>
                        Identifying yourself clearly and accurately in all
                        communications made using the Services; and
                      </li>
                      <li>
                        Not using the Services to make calls or send messages to
                        any list of numbers that has not been properly verified
                        for compliance with the TCPA.
                      </li>
                    </ul>
                    <p>
                      If you provide any information that is untrue, inaccurate,
                      not current, or incomplete, we have the right to suspend
                      or terminate your account and refuse any and all current
                      or future use of the Services (or any portion thereof).
                    </p>
                  </li>
                  <li data-list-text="3." id="userreg">
                    <h2>USER REGISTRATION</h2>

                    <p>
                      You may be required to register to use the Services. You
                      agree to keep your password confidential and will be
                      responsible for all use of your account and password. We
                      reserve the right to remove, reclaim, or change a username
                      you select if we determine, in our sole discretion, that
                      such username is inappropriate, obscene, or otherwise
                      objectionable.
                    </p>
                  </li>
                  <li data-list-text="4." id="purchases">
                    <h2>PURCHASES AND PAYMENT</h2>

                    <p>We accept the following forms of payment:</p>

                    <ul>
                      <li>Visa</li>
                      <li>Mastercard</li>
                      <li>American Express</li>
                      <li>Discover</li>
                      <li>PayPal</li>
                      <li>Klarna</li>
                    </ul>

                    <p>
                      When you enter your payment method, we might place a
                      pre-authorization charge to verify its validity. The
                      pre-authorization is a temporary charge that will be
                      automatically reversed once your payment method is
                      verified. Your account will not be charged until the
                      pre-authorization is successful. If the pre-authorization
                      fails, your agreement or trial will not become effective,
                      and we will not provide any services to you.
                    </p>

                    <p>
                      You agree to provide current, complete, and accurate
                      purchase and account information for all purchases made
                      via the Services. You further agree to promptly update
                      account and payment information, including email address,
                      payment method, and payment card expiration date, so that
                      we can complete your transactions and contact you as
                      needed.
                    </p>
                    <p>
                      Sales tax will be added to the price of purchases as
                      deemed required by us. We may change prices at any time.
                      All payments shall be in US dollars.
                    </p>

                    <p>
                      You agree to pay all charges at the prices then in effect
                      for your purchases and any applicable shipping fees, and
                      you authorize us to charge your chosen payment provider
                      for any such amounts upon placing your order. If your
                      order is subject to recurring charges, then you consent to
                      our charging your payment method on a recurring basis
                      without requiring your prior approval for each recurring
                      charge, until such time as you cancel the applicable
                      order. We reserve the right to correct any errors or
                      mistakes in pricing, even if we have already requested or
                      received payment.
                    </p>

                    <p>
                      We reserve the right to refuse any order placed through
                      the Services. We may, in our sole discretion, limit or
                      cancel quantities purchased per person, per household, or
                      per order. These restrictions may include orders placed by
                      or under the same customer account, the same payment
                      method, and/or orders that use the same billing or
                      shipping address. We reserve the right to limit or
                      prohibit orders that, in our sole judgment, appear to be
                      placed by dealers, resellers, or distributors.
                    </p>

                    <p>
                      The prices for the services or products we offer will be
                      displayed on our Site. You agree to pay the fees in
                      advance on a monthly basis. In case of usage-based fees or
                      other charges that cannot be determined in advance, we
                      will bill you in arrears, and you agree to pay within 30
                      days of receiving the invoice. If you do not pay any
                      amount due, we may take legal action to collect the
                      payment. You will be responsible for all costs incurred in
                      collecting any overdue amounts, including legal fees and
                      costs.
                    </p>

                    <p>
                      We may provide different plans with various features, and
                      we will charge you based on the plan you choose. The fees
                      we charge do not include taxes, fees, or any other costs
                      imposed by the government to support a regulatory program.
                      You are responsible for paying all taxes and fees
                      associated with the services.
                    </p>

                    <p>
                      We may modify our subscription fees periodically. Any
                      changes to the subscription fees will not impact your
                      current billing cycle. The new fees will be effective from
                      your first billing date after we make the change. If you
                      disagree with the new fees, you may terminate your
                      subscription.
                    </p>
                  </li>
                  <li data-list-text="5." id="free">
                    <h2>FREE TRIAL</h2>

                    <p>
                      We offer a 3-day free trial to new users who register with
                      the Services. The account will be charged according to the
                      user’s chosen subscription at the end of the free trial.
                    </p>

                    <p>
                      These Legal Terms govern our provision of Services to you
                      and your use of Services during the trial period, except
                      for any written terms of the trial. We reserve the right
                      to cancel or modify the terms of any trial at any time,
                      and we will not be liable for any damages arising from
                      such cancellation or modification.
                    </p>
                    <p>
                      During the trial period, you must provide credit or debit
                      card information unless otherwise stated in writing. Upon
                      completion of the trial, we will charge the card on file.
                      If you sign up for a free trial, it will automatically
                      convert to a paid subscription at the end of the trial
                      period. To avoid being charged, you must cancel your
                      subscription before the end of the trial period. If you do
                      not cancel before the end of the trial period, you will be
                      charged for the subscription, and the charges are
                      non-refundable.
                    </p>

                    <p>
                      Once your trial converts to a paid subscription, you will
                      be automatically charged monthly subscription fees and any
                      other applicable charges for the Services, in accordance
                      with these Legal Terms. You will continue to be charged
                      until you cancel your subscription.
                    </p>
                  </li>
                  <li data-list-text="6." id="cancel">
                    <h2>CANCELLATION</h2>

                    <p>
                      All purchases are non-refundable. You can cancel your
                      subscription at any time by logging into your account.
                      Your cancellation will take effect immediately.
                    </p>
                    <p>
                      After you cancel, we may continue to collect all amounts
                      due for the remainder of the then-current term.
                    </p>

                    <p>
                      If you are unsatisfied with our Services, please email us
                      at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      .
                    </p>
                  </li>
                  <li data-list-text="7." id="prohibited">
                    <h2>PROHIBITED ACTIVITIES</h2>

                    <p>
                      You may not access or use the Services for any purpose
                      other than that for which we make the Services available.
                      The Services may not be used in connection with any
                      commercial endeavors except those that are specifically
                      endorsed or approved by us.
                    </p>
                    <p>As a user of the Services, you agree not to:</p>

                    <ul>
                      <li>
                        Use the Services to make calls or messages to any
                        emergency numbers, hospitals, or other restricted
                        numbers.
                      </li>
                      <li>
                        Use the Services to make calls or messages that are
                        harassing, abusive, or threatening.
                      </li>
                      <li>
                        Use the Services to make calls or messages that violate
                        any person’s privacy rights.
                      </li>
                      <li>
                        Use the Services to make calls or messages that promote
                        illegal goods or services.
                      </li>
                      <li>
                        Systematically retrieve data or other content from the
                        Services to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.
                      </li>
                      <li>
                        Trick, defraud, or mislead us and other users,
                        especially in any attempt to learn sensitive account
                        information such as user passwords.
                      </li>
                      <li>
                        Circumvent, disable, or otherwise interfere with
                        security-related features of the Services, including
                        features that prevent or restrict the use or copying of
                        any Content or enforce limitations on the use of the
                        Services and/or the Content contained therein.
                      </li>
                      <li>
                        Disparage, tarnish, or otherwise harm, in our opinion,
                        us and/or the Services.
                      </li>
                      <li>
                        Use any information obtained from the Services in order
                        to harass, abuse, or harm another person.
                      </li>
                      <li>
                        Make improper use of our support services or submit
                        false reports of abuse or misconduct.
                      </li>
                      <li>
                        Use the Services in a manner inconsistent with any
                        applicable laws or regulations.
                      </li>
                      <li>
                        Engage in unauthorized framing of or linking to the
                        Services.
                      </li>
                      <li>
                        Upload or transmit (or attempt to upload or to transmit)
                        viruses, trojan horses, or
                      </li>
                      <li>
                        {" "}
                        other material, including excessive use of capital
                        letters and spamming (continuous posting of repetitive
                        text), that interferes with any party’s uninterrupted
                        use and enjoyment of the Services or modifies, impairs,
                        disrupts, alters, or interferes with the use, features,
                        functions, operation, or maintenance of the Services.
                      </li>
                      <li>
                        Engage in any automated use of the system, such as using
                        scripts to send comments or messages, or using any data
                        mining, robots, or similar data gathering and extraction
                        tools.
                      </li>
                      <li>
                        Delete the copyright or other proprietary rights notice
                        from any Content.
                      </li>
                      <li>
                        Attempt to impersonate another user or person or use the
                        username of another user.
                      </li>
                      <li>
                        Upload or transmit (or attempt to upload or to transmit)
                        any material that acts as a passive or active
                        information collection or transmission mechanism,
                        including without limitation, clear graphics interchange
                        formats (“gifs”), 1×1 pixels, web bugs, cookies, or
                        other similar devices (sometimes referred to as
                        ”spyware” or “passive collection mechanisms” or “pcms”).
                      </li>
                      <li>
                        Interfere with, disrupt, or create an undue burden on
                        the Services or the networks or services connected to
                        the Services.
                      </li>
                      <li>
                        Harass, annoy, intimidate, or threaten any of our
                        employees or agents engaged in providing any portion of
                        the Services to you.
                      </li>
                      <li>
                        Attempt to bypass any measures of the Services designed
                        to prevent or restrict access to the Services, or any
                        portion of the Services.
                      </li>
                      <li>
                        Copy or adapt the Services’ software, including but not
                        limited to Flash, PHP, HTML, JavaScript, or other code.
                      </li>
                      <li>
                        Except as permitted by applicable law, decipher,
                        decompile, disassemble, or reverse engineer any of the
                        software comprising or in any way making up a part of
                        the Services.
                      </li>
                      <li>
                        Except as may be the result of standard search engine or
                        Internet browser usage, use, launch, develop, or
                        distribute any automated system, including without
                        limitation, any spider, robot, cheat utility, scraper,
                        or offline reader that accesses the Services, or use or
                        launch any unauthorized script or other software.
                      </li>
                      <li>
                        Use a buying agent or purchasing agent to make purchases
                        on the Services.
                      </li>
                      <li>
                        Make any unauthorized use of the Services, including
                        collecting usernames and/or email addresses of users by
                        electronic or other means for the purpose of sending
                        unsolicited email, or creating user accounts by
                        automated means or under false pretenses.
                      </li>
                      <li>
                        Use the Services in such a way to interfere with our
                        relationship with our service providers, including but
                        not limited to Twilio
                      </li>
                      <li>
                        {" "}
                        Use the Services as part of any effort to compete with
                        us or otherwise use the
                      </li>
                      <li>
                        Services and/or the Content for any revenue-generating
                        endeavor or commercial enterprise.
                      </li>
                      <li>
                        Use the Services to advertise or offer to sell goods and
                        services.
                      </li>
                      <li>Sell or otherwise transfer your profile.</li>
                    </ul>
                  </li>
                  <li data-list-text="8." id="thirdparty">
                    <h2>THIRD-PARTY WEBSITES AND CONTENT</h2>

                    <p>
                      The Services may contain (or you may be sent via the Site)
                      links to other websites (<b>“Third-Party Websites”</b>) as
                      well as articles, photographs, text, graphics, pictures,
                      designs, music, sound, video, information, applications,
                      software, and other content or items belonging to or
                      originating from third parties (
                      <b>“Third-Party Content”</b>). Such Third-Party Websites
                      and Third-Party Content are not investigated, monitored,
                      or checked for accuracy, appropriateness, or completeness
                      by us, and we are not responsible for any Third-Party
                      Websites accessed through the Services or any Third-Party
                      Content posted on, available through, or installed from
                      the Services, including the content, accuracy,
                      offensiveness, opinions, reliability, privacy practices,
                      or other policies of or contained in the Third-Party
                      Websites or the Third-Party Content. Inclusion of, linking
                      to, or permitting the use or installation of any
                      Third-Party Websites or any Third-Party Content does not
                      imply approval or endorsement thereof by us.
                    </p>

                    <p>
                      If you decide to leave the Services and access the
                      Third-Party Websites or to use or install any Third-Party
                      Content, you do so at your own risk, and you should be
                      aware these Legal Terms no longer govern. You should
                      review the applicable terms and policies, including
                      privacy and data gathering practices, of any website to
                      which you navigate from the Services or relating to any
                      applications you use or install from the Services. Any
                      purchases you make through Third-Party Websites will be
                      through other websites and from other companies, and we
                      take no responsibility whatsoever in relation to such
                      purchases which are exclusively between you and the
                      applicable third party.
                    </p>

                    <p>
                      You agree and acknowledge that we do not endorse the
                      products or services offered on Third-Party Websites and
                      you shall hold us blameless from any harm caused by your
                      purchase of such products or services. Additionally, you
                      shall hold us blameless from any losses sustained by you
                      or harm caused to you relating to or resulting in any way
                      from any Third-Party Content or any contact with
                      Third-Party Websites.
                    </p>
                  </li>
                  <li data-list-text="9." id="servicesmanage">
                    <h2>SERVICES MANAGEMENT</h2>

                    <p>
                      We reserve the right, but not the obligation, to: (1)
                      monitor the Services for violations of these Legal Terms;
                      (2) take appropriate legal action against anyone who, in
                      our sole discretion, violates the law or these Legal
                      Terms, including without limitation, reporting such user
                      to law enforcement authorities; (3) in our sole discretion
                      and without limitation, refuse, restrict access to, limit
                      the availability of, or disable (to the extent
                      technologically feasible) any of your Contributions or any
                      portion thereof; (4) in our sole discretion and without
                      limitation, notice, or liability, to remove from the
                      Services or otherwise disable all files and content that
                      are excessive in size or are in any way burdensome to our
                      systems; and (5) otherwise manage the Services in a manner
                      designed to protect our rights and property and to
                      facilitate the proper functioning of the Services.
                    </p>
                  </li>
                  <li data-list-text="10." id="privacypolicy">
                    <h2>PRIVACY POLICY</h2>
                    <p>
                      We care about data privacy and security. Please review our
                      Privacy Policy:{" "}
                      <a
                        href="https://www.ottoleads.ai/policies/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.ottoleads.ai/policies/privacy-policy
                      </a>{" "}
                      By using the Services, you agree to be bound by our
                      Privacy Policy, which is incorporated into these Legal
                      Terms. Please be advised the Services are hosted in the
                      United States. If you access the Services from any other
                      region of the world with laws or other requirements
                      governing personal data collection, use, or disclosure
                      that differ from applicable laws in the United States,
                      then through your continued use of the Services, you are
                      transferring your data to the United States, and you
                      expressly consent to have your data transferred to and
                      processed in the United States. We make no representation
                      that the Services are governed by or operated in
                      accordance with the laws of any other foreign
                      jurisdiction, including but not limited to, data
                      protection laws.
                    </p>
                  </li>
                  <li data-list-text="11." id="copyinfr">
                    <h2>COPYRIGHT INFRINGEMENTS</h2>

                    <p>
                      We respect the intellectual property rights of others. If
                      you believe that any material available on or through the
                      Services infringes upon any copyright you own or control,
                      please immediately notify us using the contact information
                      provided below (a ”Notification”). A copy of your
                      Notification will be sent to the person who posted or
                      stored the material addressed in the Notification. Please
                      be advised that pursuant to applicable law you may be held
                      liable for damages if you make material misrepresentations
                      in a Notification. Thus, if you are not sure that material
                      located on or linked to by the Services infringes your
                      copyright, you should consider first contacting an
                      attorney.
                    </p>
                  </li>
                  <li data-list-text="12." id="termtermination">
                    <h2>TERM AND TERMINATION</h2>

                    <p>
                      These Legal Terms shall remain in full force and effect
                      while you use the Services. WITHOUT LIMITING ANY OTHER
                      PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO,
                      IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                      DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                      CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                      NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                      REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                      LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                      TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                      DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT
                      YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                      DISCRETION.
                    </p>

                    <p>
                      If we terminate or suspend your account for any reason,
                      you are prohibited from registering and creating a new
                      account under your name, a fake or borrowed name, or the
                      name of any third party, even if you may be acting on
                      behalf of the third party. In addition to terminating or
                      suspending your account, we reserve the right to take
                      appropriate legal action, including without limitation
                      pursuing civil, criminal, and injunctive redress.
                    </p>
                  </li>
                  <li data-list-text="13." id="modification">
                    <h2>MODIFICATIONS AND INTERRUPTIONS</h2>

                    <p>
                      We reserve the right to change, modify, or remove the
                      contents of the Services at any time or for any reason at
                      our sole discretion without notice. However, we have no
                      obligation to update any information on our Services. We
                      will not be liable to you or any third party for any
                      modification, price change, suspension, or discontinuance
                      of the Services.
                    </p>

                    <p>
                      We cannot guarantee the Services will be available at all
                      times. We may experience hardware, software, or other
                      problems or need to perform maintenance related to the
                      Services, resulting in interruptions, delays, or errors.
                      We reserve the right to change, revise, update, suspend,
                      discontinue, or otherwise modify the Services at any time
                      or for any reason without notice to you. You agree that we
                      have no liability whatsoever for any loss, damage, or
                      inconvenience caused by your inability to access or use
                      the Services during any downtime or discontinuance of the
                      Services. Nothing in these Legal Terms will be construed
                      to obligate us to maintain and support the Services or to
                      supply any corrections, updates, or releases in connection
                      therewith.
                    </p>
                  </li>
                  <li data-list-text="14." id="govtlaw">
                    <h2>GOVERNING LAW</h2>

                    <p>
                      These Legal Terms and your use of the Services are
                      governed by and construed in accordance with the laws of
                      the State of Wyoming applicable to agreements made and to
                      be entirely performed within the State of Wyoming, without
                      regard to its conflict of law principles.
                    </p>
                  </li>
                  <li data-list-text="15." id="dispute">
                    <h2>DISPUTE RESOLUTION</h2>

                    <p>
                      Any legal action of whatever nature brought by either you
                      or us (collectively, the ”Parties” and individually, a
                      “Party”) shall be commenced or prosecuted in the state and
                      federal courts located in Sheridan, Wyoming, and the
                      Parties hereby consent to, and waive all defenses of lack
                      of personal jurisdiction and forum non conveniens with
                      respect to venue and jurisdiction in such state and
                      federal courts. Application of the United Nations
                      Convention on Contracts for the International Sale of
                      Goods and the Uniform Computer Information Transaction Act
                      (UCITA) are excluded from these Legal Terms. In no event
                      shall any claim, action, or proceeding brought by either
                      Party related in any way to the Services be commenced more
                      than one (1) years after the cause of action arose.
                    </p>

                    <p>
                      BY AGREEING TO THIS AGREEMENT, EACH PARTY IRREVOCABLY
                      WAIVES ANY RIGHT IT MAY HAVE TO JOIN CLAIMS OR DISPUTES
                      WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION, CLASS
                      ARBITRATION, OR SIMILAR PROCEDURAL DEVICE.
                    </p>
                  </li>
                  <li data-list-text="16." id="corrections">
                    <h2>CORRECTIONS</h2>

                    <p>
                      There may be information on the Services that contains
                      typographical errors, inaccuracies, or omissions,
                      including descriptions, pricing, availability, and various
                      other information. We reserve the right to correct any
                      errors, inaccuracies, or omissions and to change or update
                      the information on the Services at any time, without prior
                      notice.
                    </p>
                  </li>
                  <li data-list-text="17." id="disclaimer">
                    <h2>DISCLAIMER</h2>

                    <p>
                      THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                      BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
                      YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                      DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                      WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                      LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                      MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY
                      OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE CONTENT OF
                      ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES
                      AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                      (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                      MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                      NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                      OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
                      OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                      AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                      SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                      LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES
                      BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
                      ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                      POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
                      SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                      RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                      OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                      HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                      FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
                      NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                      MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                      PROVIDERS OF PRODUCTS OR SERVICES. WE EXPRESSLY DISCLAIM
                      ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE
                      SERVICES’ ABILITY TO ENSURE YOUR COMPLIANCE WITH ANY LAWS
                      OR REGULATIONS MENTIONED IN THIS AGREEMENT. THIS INCLUDES,
                      BUT IS NOT LIMITED TO, THE TCPA, TITLE III OF THE OMNIBUS
                      CRIME CONTROL AND SAFE STREETS ACT (WIRETAP ACT), THE
                      COMPUTER FRAUD AND ABUSE ACT (CFAA), THE FEDERAL TRADE
                      COMMISSION TELEMARKETING SALES RULE (TSR), THE CONTROLLING
                      THE ASSAULT OF NON-SOLICITED PORNOGRAPHY AND MARKETING ACT
                      OF 2003 (CAN-SPAM ACT), ANY APPLICABLE STATE OR FEDERAL DO
                      NOT CALL (DNC) LAWS, STATE PRIVACY LAWS, ALL UNFAIR AND
                      DECEPTIVE PRACTICES ACTS, OR ANY INDUSTRY-SPECIFIC
                      REGULATIONS GOVERNING YOUR ACTIVITIES USING THE SERVICES.
                      WE ARE NOT LEGAL ADVISORS AND CANNOT PROVIDE LEGAL ADVICE.
                      YOU ARE SOLELY RESPONSIBLE FOR ENSURING YOUR COMPLIANCE
                      WITH ALL APPLICABLE LAWS AND REGULATIONS. AS WITH THE
                      PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
                      ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
                      EXERCISE CAUTION WHERE APPROPRIATE.
                    </p>
                  </li>
                  <li data-list-text="18." id="limitations">
                    <h2>LIMITATIONS OF LIABILITY</h2>

                    <p>
                      IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                      BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                      INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                      OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
                      LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF
                      THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO
                      THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
                      ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                      ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE
                      AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
                      PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING . CERTAIN US
                      STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS
                      ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                      CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
                      OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
                      YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </p>
                  </li>
                  <li data-list-text="19." id="indemnification">
                    <h2>INDEMNIFICATION</h2>

                    <p>
                      You agree to defend, indemnify, and hold us harmless,
                      including our subsidiaries, affiliates, and all of our
                      respective officers, directors, agents, partners,
                      employees, successors, and assigns from and against any
                      loss, damage, liability, claim, demand, governmental
                      investigation, action, and proceeding, real or threatened,
                      and all losses, judgments, awards, settlements, damages,
                      fines, injuries, penalties, and costs, including
                      reasonable attorneys’ fees and expenses, arising out of:
                      (1) your Contributions; (2) use of the Services; (3)
                      breach of these Legal Terms; (4) any breach of your
                      representations and warranties set forth in these Legal
                      Terms; (5) your violation of the rights of a third party,
                      including but not limited to intellectual property rights;
                      (6) any overt harmful act toward any other user of the
                      Services with whom you connected via the Services; and (7)
                      any violations of applicable laws and regulations
                      including the TCPA, Wiretap Act, the CFAA, the TSR, the
                      CAN-SPAM Act, any applicable state or federal Do Not Call
                      (DNC) laws, state privacy laws, all Unfair and Deceptive
                      Practices Acts, or any industry-specific regulations
                      governing your activities using the Services.
                      Notwithstanding the foregoing, we reserve the right, at
                      your expense, to assume the exclusive defense and control
                      of any matter for which you are required to indemnify us,
                      and you agree to cooperate, at your expense, with our
                      defense of such claims. We will use reasonable efforts to
                      notify you of any such claim, action, or proceeding which
                      is subject to this indemnification upon becoming aware of
                      it. The foregoing indemnification obligations represent
                      the sole indemnification protections intended and the
                      Parties waive all right to any other indemnification
                      protections provided by common law, statute, or otherwise.
                      This provision shall survive the termination of the
                      Agreement.
                    </p>
                  </li>
                  <li data-list-text="20." id="userdata">
                    <h2>USER DATA</h2>

                    <p>
                      We will maintain certain data that you transmit to the
                      Services for the purpose of managing the performance of
                      the Services, as well as data relating to your use of the
                      Services.
                    </p>
                    <p>
                      Although we perform regular routine backups of data, you
                      are solely responsible for all data that you transmit or
                      that relates to any activity you have undertaken using the
                      Services. You agree that we shall have no liability to you
                      for any loss or corruption of any such data, and you
                      hereby waive any right of action against us arising from
                      any such loss or corruption of such data.
                    </p>
                  </li>
                  <li data-list-text="21." id="ects">
                    <h2>
                      ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                    </h2>

                    <p>
                      Visiting the Services, sending us emails, and completing
                      online forms constitute electronic communications. You
                      consent to receive electronic communications, and you
                      agree that all agreements, notices, disclosures, and other
                      communications we provide to you electronically, via email
                      and on the Services, satisfy any legal requirement that
                      such communication be in writing. YOU HEREBY AGREE TO THE
                      USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                      RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
                      AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
                      OR VIA THE SERVICES. You hereby waive any rights or
                      requirements under any statutes, regulations, rules,
                      ordinances, or other laws in any jurisdiction which
                      require an original signature or delivery or retention of
                      non-electronic records, or to payments or the granting of
                      credits by any means other than electronic means.
                    </p>
                  </li>
                  <li data-list-text="22." id="california">
                    <h2>CALIFORNIA USERS AND RESIDENTS</h2>

                    <p>
                      If any complaint with us is not satisfactorily resolved,
                      you can contact the Complaint Assistance Unit of the
                      Division of Consumer Services of the California Department
                      of Consumer Affairs in writing at 1625 North Market Blvd.,
                      Suite N 112, Sacramento, California 95834 or by telephone
                      at (800) 952-5210 or (916) 445-1254.
                    </p>
                  </li>
                  <li data-list-text="23." id="misc">
                    <h2>MISCELLANEOUS</h2>

                    <p>
                      These Legal Terms and any policies or operating rules
                      posted by us on the Services or in respect to the Services
                      constitute the entire agreement and understanding between
                      you and us. Our failure to exercise or enforce any right
                      or provision of these Legal Terms shall not operate as a
                      waiver of such right or provision. These Legal Terms
                      operate to the fullest extent permissible by law. We may
                      assign any or all of our rights and obligations to others
                      at any time. You, on the other hand, may not assign this
                      Agreement or any of the rights granted hereunder without
                      the prior written consent of the Company. We shall not be
                      responsible or liable for any loss, damage, delay, or
                      failure to act caused by any cause beyond our reasonable
                      control. If any provision or part of a provision of these
                      Legal Terms is determined to be unlawful, void, or
                      unenforceable, that provision or part of the provision is
                      deemed severable from these Legal Terms and does not
                      affect the validity and enforceability of any remaining
                      provisions. There is no joint venture, partnership,
                      employment or agency relationship created between you and
                      us as a result of these Legal Terms or use of the
                      Services. You agree that these Legal Terms will not be
                      construed against us by virtue of having drafted them. You
                      hereby waive any and all defenses you may have based on
                      the electronic form of these Legal Terms and the lack of
                      signing by the parties hereto to execute these Legal
                      Terms.
                    </p>

                    <p>
                      <b>No third party beneficiaries.</b> The parties to this
                      Agreement are you and the Company. In no event will any
                      parent, subsidiary or affiliate of the Company have any
                      obligation or liability to you, under the Agreement or
                      otherwise.
                    </p>
                  </li>
                  <li data-list-text="24." id="contact">
                    <h2>CONTACT US</h2>

                    <p>
                      Any notice required or permitted hereunder shall be deemed
                      properly made when delivered by email, hand delivery or by
                      certified or registered mail (return receipt requested),
                      if to you, to the address provided to us by you when you
                      create your account; and, if to us, to the following:
                    </p>

                    <p>
                      OttoLeads LLC <br />
                      30 N Gould St, Ste R Sheridan, WY 82801
                      <br />
                      United States
                    </p>
                    <p>
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
