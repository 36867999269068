import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import AddCard from "./AddCard";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { billingSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch } from "react-redux";
import { fetchCardList } from "./sagaActions";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { addPaymentProps } from "./interface";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../Routes";

const AddPaymentModal = ({ handleClose, open ,replacePaymentMethod}: addPaymentProps) => {
  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(billingSchema),
  });
  const dispatch = useDispatch();
  const [confirmCardLoading, setConfirmCardLoading] = useState(false);

  const onSubmit = async (result: any) => {
    if (result?.setupIntent?.payment_method) {
      try {
        setConfirmCardLoading(true);
        dispatch(fetchCardList({}));
        dispatch(fetchProfileInfo({}));
        setConfirmCardLoading(false);
        handleClose();
        displaySuccessMessage("Payment method added successfully!");
      } catch (error) {
        displayErrorMessage(error);
        setConfirmCardLoading(false);
      }
    }
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  useEffect(() => {
    if (!open) {
      methods.reset({  nameOnCard: "" });
    }
  }, [open]);
  
  return (
    <>
      <Dialog
        className="stepsDialog paymentModal rounded-xl"
        onClose={handleClose}
        fullWidth={true}
        open={open}
      >
        <div>
          <div
            className={`flex justifySpaceBetween alignCenter paymentModalHeading`}
          >
            <h2>Add New Payment Method</h2>
            <span
              role="link"
              className="closeDialog"
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </span>
          </div>
        </div>

        <FormProvider {...methods}>
        <Elements stripe={stripePromise}  >
          <AddCard
            buttonConfig={{ label: "Add New Payment Method" }}
            onSubmit={onSubmit}
            displayErrorMessage={displayErrorMessage}
            confirmCardLoading={confirmCardLoading}
            setConfirmCardLoading={setConfirmCardLoading}
            data-testid="AddNewPaymentMethod"
            replacePaymentMethod={replacePaymentMethod}
          ></AddCard>
</Elements>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default AddPaymentModal;
