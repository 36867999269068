import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { getCardList } from "./selectors";
import styles from "./Billings.module.scss";
import { CardBrands } from "./enum";
import Amex from "../../assets/icons/amex";
import Discover from "../../assets/icons/discover";
import Visa from "../../assets/icons/visa";
import Master from "../../assets/icons/master";
import { detachPaymentMethod, updatePaymentMethod } from "./billingService";
import { fetchCardList, fetchSubscriptions } from "./sagaActions";
import { useState } from "react";
import Loader from "../../atoms/Loader";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import Stripe from "../../assets/icons/stripe";
import EftposAustralia from "../../assets/icons/eftposAustralia";
import ChinaUnionPay from "../../assets/icons/chinaUnionPay";
import JCB from "../../assets/icons/jcb";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Button from "../../atoms/Button";
import { CardListProps } from "./interface";
import { PermissionListEnum } from "../auth/enum";
import { Tooltip } from "@mui/material";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

const CardList = ({ displaySuccessMessage=()=>{}, displayErrorMessage=()=>{} }: CardListProps) => {
  const dispatch=useDispatch();
  const cardList = useSelector((state) => getCardList(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const getCardIcon = (brand: any) => {
    switch (brand) {
      case CardBrands.Amex: {
        return <Amex />;
      }
      case CardBrands.Mastercard: {
        return <Master />;
      }
      case CardBrands.Discover: {
        return <Discover />;
      }
      case CardBrands.Visa: {
        return <Visa />;
      }
      case CardBrands.JCB: {
        return <JCB />;
      }
      case CardBrands.Unionpay: {
        return <ChinaUnionPay />;
      }
      case CardBrands.EftposAustralia: {
        return <EftposAustralia />;
      }
      default: {
        return <Stripe />;
      }
    }
  };

  const deletePaymentMethod = async () => {
    if (!deleteCardId) {
      return;
    }
    try {
      setIsDeleting(true);
      await detachPaymentMethod({ paymentMethodId: deleteCardId });
      displaySuccessMessage("Card deleted successfully!");
      dispatch(fetchCardList({}));
      setIsDeleting(false);
      setShowDeleteModal(false);
    } catch (error) {
      displayErrorMessage(error);
      setIsDeleting(false);
    }
  };

  const setPaymentMethodAsDefault = async (id: string, index: number) => {
    try {
      setLoadingIndex(index);
      await updatePaymentMethod({ paymentMethod: id });
      dispatch(fetchCardList({}));
      setLoadingIndex(-1);
      displaySuccessMessage("Default payment method updated successfully!");
      dispatch(fetchProfileInfo({}));
      dispatch(fetchSubscriptions({}));
    } catch (error) {
      setLoadingIndex(-1);
      displayErrorMessage(error);
    }
  };

  return (
    <>
      <div className={styles.billing__cardsWrap}>
        {cardList?.map((card: any, index: number) => {
          return (
            <div className={`dflex alignStart ${styles.cards}`} key={card?.id}>
              {loadingIndex === index && (
                <div className={`${styles.loaderCards}`}>
                  <Loader></Loader>
                </div>
              )}
              <figure>{getCardIcon(card?.card?.brand)}</figure>
              <figcaption>
                <h6>
                  {card?.card?.brand?.charAt(0).toUpperCase() +
                    card?.card?.brand?.slice(1)}{" "}
                  ending in {card?.card?.last4}
                </h6>
                <p>
                  Expiry {card?.card?.exp_month}/{card?.card?.exp_year}
                </p>
                {profileInfo?.userRoleName !==
                PermissionListEnum.ORGANISATION_OWNER ? (
                    <Tooltip
                      title={ROLE_PERMISSION_STATUS.NoAccess}
                      placement="top"
                    >
                      <div className={`dflex ${styles.cards__link}`}>
                        <span
                          role="link"
                        >
                          Set as default
                        </span>

                        <span role="link">Delete</span>
                      </div>
                    </Tooltip>
             
                ) : (
                  profileInfo?.organisation?.paymentMethodId !== card?.id && (
                    <div className={`dflex ${styles.cards__link}`}>
                      <span
                        role="link"
                        onClick={() =>
                          setPaymentMethodAsDefault(card?.id, index)
                        }
                      >
                        Set as default
                      </span>

                      <span
                        role="link"
                        onClick={() => {
                          setDeleteCardId(card?.id);
                          setShowDeleteModal(true);
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  )
                )}
              </figcaption>
            </div>
          );
        })}
      </div>
      <ConfirmationModal
        isOpen={showDeleteModal}
        setIsOpen={(value: any) => {
          if (!isDeleting) {
            setShowDeleteModal(value);
            setDeleteCardId(undefined);
          }
        }}
        buttonComponent={
          <Button
            label="Yes, Delete"
            className="red xl full "
            action={deletePaymentMethod}
            disabled={isDeleting}
            clicked={isDeleting}
          />
        }
      >
        <h2>Delete Card</h2>
        <p>Are you sure you want to delete this card?</p>
      </ConfirmationModal>
    </>
  );
};
export default CardList;
