import { initialState } from "./reducer";

const getStateData = (state: any) => state["profileInfo"] || initialState;

export const getProfileInfo = (state: any) => {
  const reducer = getStateData(state);
  return reducer.profileInfo;
};

export const getProfileInfoLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.profileInfoLoading;
};

export const getSessionLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.sessionLoading;
};
