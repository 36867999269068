import { getAPI, postAPI } from "../../apis/apis";

export const createLead = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/lead`,
      headers: {},
      data: payload,
    });
  };

  export const fetchMinimalData = async () => {
    return getAPI({
      url: process.env.REACT_APP_BASE_URL + "/campaign/minimal-data",
      headers: {},
    });
  };

  export const addressVerify = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/address/verify`,
      headers: {},
      data: payload,
    });
  };