import { useSelector } from "react-redux";
import { getInvoicesIsLoading } from "./selectors";
import CustomTable from "../../molecules/table/CustomTable";
import Loader from "../../atoms/Loader";
import { INVOICE_COLUMN } from "./constant";
import SearchReflation from "../../assets/icons/searchReflation";
import { InvoiceTableProps } from "./interface";

const InvoiceTable = ({tableData,hasMore,handleNextPage}:InvoiceTableProps) => {
  const invoicesIsLoading = useSelector((state) => getInvoicesIsLoading(state));
  return (
    <>
      {invoicesIsLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <CustomTable
          rows={tableData}
          columns={INVOICE_COLUMN}
          hasMore={hasMore}
          tableStriped
          currentPageIndex={1}
          emptyTableMetaData={{
            icon:<SearchReflation />,
            title:"No results found",
            description:"We couldn’t find any results that match your search or filtering criteria."
          }}
          handleNextPage={handleNextPage}
        ></CustomTable>
      )}
    </>
  );
};
export default InvoiceTable;
