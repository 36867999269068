


const CornerUpRight = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M3.33203 16.6668V15.5002C3.33203 12.6999 3.33203 11.2998 3.877 10.2302C4.35637 9.2894 5.12127 8.5245 6.06208 8.04513C7.13164 7.50016 8.53177 7.50016 11.332 7.50016H16.6654M16.6654 7.50016L12.4987 11.6668M16.6654 7.50016L12.4987 3.3335"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default CornerUpRight;