const Delete = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M14.6667 7.49967L9.66667 12.4997M9.66667 7.49967L14.6667 12.4997M2.76667 10.7997L6.36667 15.5997C6.66 15.9908 6.80667 16.1863 6.99254 16.3274C7.15717 16.4523 7.3436 16.5455 7.54231 16.6023C7.76667 16.6663 8.01111 16.6663 8.5 16.6663H14.8333C16.2335 16.6663 16.9335 16.6663 17.4683 16.3939C17.9387 16.1542 18.3212 15.7717 18.5609 15.3013C18.8333 14.7665 18.8333 14.0665 18.8333 12.6663V7.33301C18.8333 5.93288 18.8333 5.23281 18.5609 4.69803C18.3212 4.22763 17.9387 3.84517 17.4683 3.60549C16.9335 3.33301 16.2335 3.33301 14.8333 3.33301H8.5C8.01111 3.33301 7.76667 3.33301 7.54231 3.39708C7.3436 3.45384 7.15717 3.54705 6.99254 3.67197C6.80667 3.81301 6.66 4.00856 6.36667 4.39967L2.76667 9.19967C2.55151 9.48656 2.44392 9.63 2.40245 9.78753C2.36585 9.92659 2.36585 10.0728 2.40245 10.2118C2.44392 10.3694 2.55151 10.5128 2.76667 10.7997Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Delete;