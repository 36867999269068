import React, { useEffect } from "react";
import MaskedInput from "../../atoms/MaskedInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import { campaignCallBackNoSchema } from "./validation";
import Phone from "../../assets/icons/phone";
import Close from "../../assets/icons/cross";

const CampaignCallBackNo = ({
  showCallBackNo,
  setShowCallBackNo,
  handleCallBackPhoneModal,
}: any) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(campaignCallBackNoSchema),
    defaultValues: {
      callbackPhoneNo: showCallBackNo,
    },
  });

  const onSubmit = async (data: any) => {
    setShowCallBackNo(data.callbackPhoneNo);
    handleCallBackPhoneModal();
  };

  useEffect(() => {
    reset({"callbackPhoneNo": showCallBackNo});
  }, [showCallBackNo]);

  return (
    <div className="dialogWrapper">
      <span
        role="link"
        onClick={() => handleCallBackPhoneModal()}
        className="closeDialog"
      >
        <Close />
      </span>
      <div className={`dialogWrapper__icon`} data-testid="headerIcon">
        <Phone />
      </div>
      <div className="dialogWrapper__info">
        <form>
          <MaskedInput
            placeholder="Enter the phone number"
            label="Callback phone number*"
            name="callbackPhoneNo"
            register={register}
            errors={errors}
            maskChar={""}
            mask={"(999) 999-9999"}
          ></MaskedInput>

          <div className="actionsSm">
            <Button
              type="submit"
              label="Save"
              className="primary animated full"
              action={handleSubmit(onSubmit)}
            ></Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CampaignCallBackNo;
