export enum CreateListSteps{
    SkipTrace='skipTrace',
    SkipTraceSuccess='skipTraceSuccess',
}

export enum SkipRedoMonthOptions{
    TWO_WEEKS = '2_WEEKS',
    FOUR_MONTHS = '4_MONTHS',
    TWO_MONTHS = '2_MONTHS',
}

export const SKIP_REDO_MONTH_OPTIONS = [
    { value: '2_WEEKS', label: 'in the past 2 weeks' },
    { value: '2_MONTHS', label: "in the past 2 months" },
    { value: '4_MONTHS', label: "in the past 4 months" },
    { value: 'ANY_TIME', label: "at any time" },
  ];