export enum MailTypeEnum {
    CARD_DECLINES = 'card_declines',
    SUBSCRIPTION_RENEWED = 'subscription_renewed',
    SUBSCRIPTION_CANCELED = 'subscription_canceled',
    SUBSCRIPTION_CHANGED_DIFFERENT_PLAN = 'subscription_changed_to_different_plan',
    ACCOUNT_PERMANENTLY_SUSPENDED = 'account_permanently_suspended',
    PAYMENT_METHOD_UPDATED = 'payment_method_updated',
    PASSWORD_CHANGED = 'password_changed',
    EMAIL_SUCCESSFULLY_CHANGED = 'email_successfully_changed',
    WELCOME_EMAIL_ON_SIGNUP = 'welcome_email_on_signup',
    CAMPAIGN_BEGINS = 'campaign_begins',
    CAMPAIGN_COMPLETE = 'campaign_complete',
  }