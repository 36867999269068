
const Send = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M8.75182 9.99982H4.16849M4.09794 10.2427L2.15217 16.055C1.99931 16.5116 1.92288 16.7399 1.97773 16.8805C2.02536 17.0026 2.12767 17.0952 2.2539 17.1304C2.39927 17.171 2.61882 17.0722 3.05793 16.8746L16.9842 10.6078C17.4128 10.4149 17.6271 10.3185 17.6933 10.1845C17.7508 10.0681 17.7508 9.93155 17.6933 9.81516C17.6271 9.68119 17.4128 9.58475 16.9842 9.39188L3.05308 3.12293C2.61529 2.92593 2.3964 2.82742 2.25118 2.86783C2.12506 2.90292 2.02277 2.99524 1.97497 3.11711C1.91993 3.25744 1.99555 3.48526 2.14678 3.94088L4.09848 9.8211C4.12446 9.89935 4.13744 9.93848 4.14257 9.9785C4.14712 10.014 4.14707 10.05 4.14243 10.0855C4.1372 10.1255 4.12412 10.1646 4.09794 10.2427Z"

                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default Send;
