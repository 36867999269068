import { createAction } from "@reduxjs/toolkit";

export const triggerNotification = createAction(
  "triggerNotification",
  function prepare({ message, type }) {
    return {
      payload: {
        message,
        type,
      },
    };
  }
);

export const notificationAction = createAction(
  "notification",
  function prepare(data) {
    return { payload: data };
  }
);

export const readNotificationAction = createAction(
  "readNotification",
  function prepare(data) {
    return { payload: data };
  }
);

export const readAllNotificationAction = createAction(
  "readAllNotification",
  function prepare(data) {
    return { payload: data };
  }
);

export const setNotificationAction = createAction(
  "setNotification",
  function prepare(data) {
    return { payload: data };
  }
);
