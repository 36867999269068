import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userRoleList: [] as any[],
  pendingUserList:[] as any[],
  pendingUserListLoading:false,
  activeUserList: [] as any[],
  activeUserListLoading:false
};

const myTeamSlice = createSlice({
  name: "myTeam",
  initialState,
  reducers: {
    setUserRoleList: (state, { payload: { data, total } }) => {
      return {
        ...state,
        userRoleList: data ?? [],
      };
    },setActiveUserList: (state, { payload }) => {
      return {
        ...state,
        activeUserList: payload,
      };
      
    },
    setActiveUserListLoading: (state, { payload }) => {
      return {
        ...state,
        activeUserListLoading: payload,
      };
    },setPendingUserList: (state, { payload }) => {
      return {
        ...state,
        pendingUserList: payload,
      };
      
    },
    setPendingUserListLoading: (state, { payload }) => {
      return {
        ...state,
        pendingUserListLoading: payload,
      };
    },
  },
});

export const { setUserRoleList ,setActiveUserList,setActiveUserListLoading,setPendingUserList,setPendingUserListLoading} = myTeamSlice.actions;

export default myTeamSlice.reducer;
