import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { postAPI } from "../../apis/apis";
import { generateSignedUrl, uploadFile } from "../../apis/fileUploadApis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
function* saveLeadList({ payload }: any) {
  yield put(slice.setListUploading(true));
  try {
    yield postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/upload-lead-list`,
      data: payload,
      headers: {},
    });
    yield all([
      put(slice.setListUploading(false)),
      put(slice.toggleImportFileModal(false)),
      put(slice.setNewListUploaded(true)),
      put(addToast({
        id: new Date().getTime(),
        message: `${payload?.name} list is being processed. We'll notify you when completed`,
        type: ToastType.info,
      }))
    ]);
  } catch (error: any) {
    console.log("error",error)
    yield all([
      put(slice.setListUploading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message??'something went wrong',
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* uploadLeadList({ payload }: any) {
  yield put(slice.setIsFileUploading(true));
  try {
    const signedUrl: string = yield generateSignedUrl({
      key: payload.key,
      method: payload.method,
      content: payload.content,
    });
    yield uploadFile(payload.file, signedUrl);
    yield all([
      put(slice.setLeadListCSV({ file: payload.file, key: payload.key })),
      put(slice.setIsFileUploading(false)),
    ]);
  } catch (error:any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.message ?? "something went wrong",
          type: ToastType.error,
        })
      ),
      put(slice.setIsFileUploading(false)),
    ]); 
  }
}

function* uploadListSaga() {
  yield takeLatest(sagaActions.saveLeadList, saveLeadList);
  yield takeLatest(sagaActions.uploadLeadList, uploadLeadList);
}

export default uploadListSaga;
