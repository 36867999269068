import { yupResolver } from "@hookform/resolvers/yup";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { checkUserInDb } from "../../apis/user";
import Message from "../../assets/icons/message";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import MaskedInput from "../../atoms/MaskedInput";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import userpool from "../../userpool";
import { PermissionListEnum } from "../auth/enum";
import VerifyOtp from "../verifyOtp/VerifyOtp";
import styles from "./Details.module.scss";
import { botTextToSpeech, updateUserProfile } from "./settingService";
import { profileDetailsSchema } from "./validation";

export const Details = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(profileDetailsSchema),
  });
  const dispatch = useDispatch();
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  const [openVerifyOtp, setOpenVerifyOtp] = useState<boolean>(false);
  const [laoding, setLoading] = useState(false);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [audioLoading, setAudioLoading] = useState(false);
  const [staticText, setStaticText] = useState("");

  const onSubmit = async (data: any) => {
    if (watch("email") !== profileInfo?.email) {
      setLoading(true);
      try {
        const res = await checkUserInDb(watch("email"));
        if (res?.data) {
          displayErrorMessage({ message: "This email address already taken!" });
          return;
        } else {
          await callUpdateProfile({
            name: watch("name"),
            organisationName: watch("companyName"),
            email: watch("email"),
            callbackPhone: data?.callbackPhone,
          });
        }
      } catch (err) {
        console.log(err);
        return;
      } finally {
        setLoading(false);
      }
      // const cognitoUser = userpool.getCurrentUser();
      // await new Promise((res) => cognitoUser?.getSession(res));
      // const attribute: any = new CognitoUserAttribute({
      //   Name: "email",
      //   Value: watch("email"),
      // });
      // const attributeList = [attribute];
      // cognitoUser?.updateAttributes(attributeList, async (err, result) => {
      //   setLoading(false);
      //   if (err) {
      //     displayErrorMessage(err);
      //     return;
      //   }
      //   setOpenVerifyOtp(true);
      // });
    } else {
      await callUpdateProfile({
        name: watch("name"),
        organisationName: watch("companyName"),
        callbackPhone: data?.callbackPhone,
      });
    }
  };

  const verifyAttribute = async (otp: string) => {
    const cognitoUser = userpool.getCurrentUser();
    setOtpVerificationLoading(true);
    await new Promise((res) => cognitoUser?.getSession(res));
    cognitoUser?.verifyAttribute("email", otp, {
      onSuccess: async () => {
        setOtpVerificationLoading(false);
        setOpenVerifyOtp(false);
        await callUpdateProfile({
          name: watch("name"),
          email: watch("email"),
          organisationName: watch("companyName"),
        });
      },
      onFailure: (err) => {
        setOtpVerificationLoading(false);
        displayErrorMessage(err);
      },
    });
  };

  const callUpdateProfile = async (data: any) => {
    try {
      setLoading(true);
      await updateUserProfile(data);
      displaySuccessMessage("User profile updated successfully");
      dispatch(fetchProfileInfo({}));
      setLoading(false);
    } catch (error) {
      displayErrorMessage(error);
      setLoading(false);
    }
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  const handleInputChange = (event: any) => {
    setStaticText(event.target.value);
  };

  const generateSpeech = async () => {
    setAudioUrl("");
    setAudioLoading(true);
    try {
      const response = await botTextToSpeech({
        text: staticText,
        voice_settings: {
          stability: 0.55,
          similarity_boost: 0.5,
        },
      });
      const uint8Array = new Uint8Array(response?.data?.data);
      const audioBlob = new Blob([uint8Array], { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
    } catch (error) {
      console.error("Error generating speech:", error);
    } finally {
      setAudioLoading(false);
    }
  };

  useEffect(() => {
    setValue(
      "name",
      `${profileInfo?.nameFirst ?? ""} ${profileInfo?.nameLast ?? ""}`
    );
    setValue("companyName", profileInfo?.organisation?.organisationName);
    setValue("email", profileInfo?.email);
    setValue(
      "callbackPhone",
      `${profileInfo?.organisation?.callbackPhone ?? ""}`
    );
    setStaticText(`Hi I am Jerry, your AI calling assistant. 
Thank you for taking the time to speak with me today. I'll pass this information over to my partner, 
and they will be calling you back soon to discuss the next steps. In the meantime,
 if you have any questions, you can call ${
   profileInfo?.organisation?.organisationName
 } at ${
      profileInfo?.organisation?.callbackPhone
        ? profileInfo?.organisation?.callbackPhone
        : ""
    }.
  Again, the number to reach us at is ${
    profileInfo?.organisation?.callbackPhone
      ? profileInfo?.organisation?.callbackPhone
      : ""
  }. Have a great day, and bye now!`);
  }, [profileInfo]);

  useEffect(() => {
    dispatch(fetchProfileInfo({}));
  }, []);

  return (
    <div className={`${styles.details}`}>
      <div className={`${styles.details__header}`}>
        <h3> Personal Info</h3>
        <p>Update your personal details here.</p>
      </div>
      <div className={`${styles.details__form}`}>
        <form>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              label="Name*"
              register={register}
              name="name"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              prefix={<Message />}
              label="Email address*"
              register={register}
              name="email"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="text"
              label="Company name*"
              register={register}
              name="companyName"
              errors={errors}
              readOnly={
                profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER
              }
              hint="This is the name we use to represent your business when calling prospects."
            ></Input>
          </div>

          <div className={`${styles.input}`}>
            <MaskedInput
              placeholder="Enter the phone number"
              label="Callback phone number"
              name="callbackPhone"
              register={register}
              errors={errors}
              maskChar={""}
              mask={"(999) 999-9999"}
              disabled={
                profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER
              }
              hint="This number connects prospects directly to your business when they return your call."
            ></MaskedInput>
          </div>
          {/* <div className={`${styles.input}`}>
            <div className="inputGroup">
              <span className="label">
                {profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER ? (
                  <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess}>
                    <div>
                      <Button
                        className="primary animated"
                        label={"BOT Voice"}
                        disabled={
                          profileInfo?.userRoleName ===
                          PermissionListEnum.ORGANISATION_VIEWER
                        }
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    className="primary animated"
                    label={"BOT Voice"}
                    action={generateSpeech}
                    disabled={audioLoading}
                  />
                )}
              </span>
              <span className="inputGroup__input">
                <div className="mb-15">
                  <textarea value={staticText} onChange={handleInputChange} />
                </div>
                {audioUrl && true && (
                  <audio controls>
                    <source src={audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                )}
              </span>
            </div>
          </div> */}

          <div className={`dflex justifyEnd ${styles.button}`}>
            {/* <Button label="Cancel" className="outline" action={handleSubmit(onSubmit)}></Button> */}

            <Button
              type="submit"
              label="Save"
              className="primary"
              action={handleSubmit(onSubmit)}
              clicked={laoding}
              disabled={laoding}
            ></Button>
          </div>
        </form>
      </div>
      {openVerifyOtp && (
        <VerifyOtp
          open={openVerifyOtp}
          setOpen={setOpenVerifyOtp}
          onSubmit={verifyAttribute}
          userDetails={{ email: watch("email") }}
          otpVerificationLoading={otpVerificationLoading}
        ></VerifyOtp>
      )}
    </div>
  );
};
