
const TargetIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_12832_15088)">
                    <path d="M18.3346 10.0003H15.0013M5.0013 10.0003H1.66797M10.0013 5.00033V1.66699M10.0013 18.3337V15.0003M16.668 10.0003C16.668 13.6822 13.6832 16.667 10.0013 16.667C6.3194 16.667 3.33464 13.6822 3.33464 10.0003C3.33464 6.31843 6.3194 3.33366 10.0013 3.33366C13.6832 3.33366 16.668 6.31843 16.668 10.0003Z"
                        stroke="currentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_12832_15088">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    );
};

export default TargetIcon;
