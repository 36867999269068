import { ProductPriceIntervalType } from "./enum";

export const getProductByInterval = (annual: boolean, product:any) => {
  let selected = {};
  if (annual) {
    selected = product?.price?.find(
      (price: any) => price?.interval === ProductPriceIntervalType.YEAR
    );
  } else {
    selected = product?.price?.find(
      (price: any) => price?.interval === ProductPriceIntervalType.MONTH
    );
  }
  return selected
};