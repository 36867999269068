import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaskedInput from "../../atoms/MaskedInput";
import { useForm } from "react-hook-form";
import { callBackNoSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button";
import Close from "../../assets/icons/cross";
import styles from "./CreateCampaign.module.scss";
import Phone from "../../assets/icons/phone";
import { updateUserProfile } from "../settingdetails/settingService";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { setProfileInfo } from "../../components/profileMenu/reducer";
import Input from "../../atoms/Input";

const CallBackNo = ({ open, closeModal }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(callBackNoSchema),
  });
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [loading, setLoading] = useState(false);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
     await updateUserProfile(data);
      handleShowToast(
        data.companyName === profileInfo?.organisation?.organisationName
    ? 'Callback phone number added successfully!'
    : 'Company name and callback phone number added successfully!',
    ToastType.success
      );
      dispatch(
        setProfileInfo({ ...profileInfo,
          organisation: {
            ...profileInfo.organisation,
            callbackPhone: data.callbackPhone,
          }
         })
      );
      setLoading(false);
      closeModal();
    } catch (error) {
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("organisationName", profileInfo?.organisation?.organisationName);
  }, [profileInfo]);

  return (
    <Dialog
      className="stepsDialog contactInfoModal"
      onClose={closeModal}
      open={open}
    >
      <span role="link" onClick={() => closeModal()} className="closeDialog">
        <Close />
      </span>
      <div className="dialogWrapper">
        <div className={`${styles.createCampaign}`}>
          <div
            className={`headerIcon ${styles.headerIcon}`}
            data-testid="headerIcon"
          >
            <Phone />
          </div>
          <div className={`${styles.createCampaign__header}`}>
            <Input
              type="text"
              label="Company name*"
              register={register}
              name="organisationName"
              errors={errors}
              hint="This is the name we use to represent your business when calling prospects."
            ></Input>
            <MaskedInput
              placeholder="Enter the phone number"
              label="Callback phone number*"
              name="callbackPhone"
              register={register}
              errors={errors}
              maskChar={""}
              mask={"(999) 999-9999"}
              hint="This number connects prospects directly to your business when they return your call."
            ></MaskedInput>
          </div>
          <div>
            <Button
              type="submit"
              label="Save"
              className="primary animated full"
              action={handleSubmit(onSubmit)}
              clicked={loading}
              disabled={loading}
            ></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CallBackNo;
