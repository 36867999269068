
import { useState } from "react";
import HomeIcon from "../../../assets/icons/homeIcon";
import Button from "../../../atoms/Button";
import Pill from "../../../atoms/Pill";
import EarlyAccessSignup from "../../../organisms/earlyAccess/EarlyAccessSignup";
import { bestLeadSec } from "../HomeConstants";
import styles from "./BestLeadSec.module.scss";
import Register from "../../../organisms/auth/Register";

const BestLeadSec = () => {
  const [openEarlyAccessModal, setOpenEarlyAccessModal] =
    useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  return (
    <div className={`${styles.lead}`}>
      <div className={`dflex justifyCenter alignCenter ${styles.lead__inner}`}>
        <div className={` ${styles.lead__left}`}>
          <h2
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-offset="50"
            data-aos-anchor-placement="bottom-bottom"
          >
            {bestLeadSec.title}
          </h2>
          <p
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-offset="50"
            data-aos-duration="1000"
            data-aos-anchor-placement="bottom-bottom"
          >
            {bestLeadSec.description}
          </p>
        </div>
        <div className={` ${styles.lead__right}`}>
          <div
            className={`${styles.leadCard}`}
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            <div className={`flex  alignCenter ${styles.leadCard__header}`}>
              <figure>
                <HomeIcon />
              </figure>
              <figcaption>
                <h4>{bestLeadSec.propertyAddress}</h4>
                <p>{bestLeadSec.propertyFeatures}</p>
              </figcaption>
            </div>
            <div className={`flex ${styles.leadCard__info}`}>
              <h3>
                Call {bestLeadSec.propertyOwnerName} <small>Today</small>
              </h3>
              <p>{bestLeadSec.phoneNumber}</p>
              <Pill className="calling" label="New Lead" />
            </div>
            <div className={` ${styles.leadCard__summery}`}>
              <h3>Your AI summary</h3>
              <p>{bestLeadSec.aiSummary}</p>
            </div>
            <div className={` ${styles.leadCard__button}`}>
              <Button className="full gradient" label="Start your Free Trial" action={() => setOpenEarlyAccessModal(true)} />
            </div>
          </div>
        </div>
      </div>
      {openEarlyAccessModal ? (
        // <EarlyAccessSignup
        //   open={openEarlyAccessModal}
        //   setOpen={setOpenEarlyAccessModal}
        // ></EarlyAccessSignup>
        <Register
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  );
};

export default BestLeadSec;
