import { postAPI } from "../../apis/apis";

export const sendWebhook = async (payload: any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/users/add-webhook`,
      headers: {},
      data: payload,
    });
  };

  export const reSendWebhook = async (id: any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/users/resend-webhook/${id}`,
      headers: {},
    });
  };

  export const deleteWebhook = async () => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/users/remove-webhook`,
      headers: {},
    });
  };

  export const sendTestWebhook = async () => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/otto-webhook-test`,
      headers: {},
  
    });
  };