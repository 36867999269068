import Mitch from "../../assets/images/Mitch.jpg";
import Roberta from "../../assets/images/Roberta.jpg";
import Terrell from "../../assets/images/Terrell.jpg";
import customerIcon from "../../assets/images/customer1.svg";
import customerIcon2 from "../../assets/images/customerIcon2.svg";
import customerIcon3 from "../../assets/images/customerIcon3.svg";
import Grant from "../../assets/images/grant.jpg";
import { BLACK_FRIDAY_SALE } from "../../constants/constants";

export const waveformInfo = {
  WAVE1: {
    id: "first",
    infoTitle: "Your AI summary",
    playBtnText: "Otto secures a competitive offer",
    audio1: "https://d8eetllu6sdl7.cloudfront.net/Call%20Sample%201.wav",
    infoDesc: `The owner, an older woman, is considering selling due to the difficulty of 
        maintaining the property as she ages. The property is in good condition overall, with the 
        bathrooms needing updates and minor repairs needed for the sunroom ceiling. The kitchen was 
        last updated around 2001. The owner mentioned she would consider selling the property for 
        $325,000 but could go as low as $320,000. She is open to selling within 30 days and 
        confirmed the property has 3 bedrooms and 3 bathrooms.`,
  },
  WAVE2: {
    id: "second",
    infoTitle: "Your AI summary",
    playBtnText: "Otto speaks with a motivated seller",
    audio2: "https://d8eetllu6sdl7.cloudfront.net/Call+Sample+3_FINAL.wav",
    infoDesc: `The woman mentioned that she and her husband are considering selling the property to downsize since their children have moved out. The house, which has 4 bedrooms and 3 bathrooms, needs some maintenance and updating but no major repairs. The woman stated that they would be looking to list the property at around $340,000, which is close to their bottom line. She indicated that they could have the property ready to sell in about a month.`,
  },
  WAVE3: {
    infoTitle: "Your AI summary",
    id: "third",
    audio3: "https://d8eetllu6sdl7.cloudfront.net/Call+Sample+2_FINAL.wav",
    playBtnText: "Otto identifies a seller in distress",
    infoDesc: `Kirby, who bought the property a year ago, is considering selling due to job loss and falling behind on mortgage payments. He aims to sell quickly to resolve his financial situation. The property is in good condition, having been remodeled a year ago, and Kirby needs $175,000 to pay off the mortgage. The property has three bedrooms and two bathrooms.`,
  },
};

export const bestLeadSec = {
  title: "Intelligent leads.",
  description: (
    <>
      Our smart lead analysis engine extracts the following info straight from
      the conversation.
      <br />
      <br />
      - situation
      <br />
      - motivation
      <br />
      - closing timeline
      <br />
      - asking price
      <br />
      - flexibility on price
      <br />
      - repairs needed
      <br />
      <br />
      🎉 Gone are the days of low quality lead summaries from human employees
      that leave you scratching your head.
    </>
  ),
  propertyAddress: "372 Chuck Rd, Austin, TX, 29382",
  propertyFeatures: "3 bed | 2 bath | 1,765 sqft | 1976 year built",
  propertyOwnerName: "Ronald Richards",
  phoneNumber: "(208) 555-0112",
  aiSummary:
    "Ronald expressed openness to selling, mentioning he's currently busy with a new furnace installation at his current residence. Ronald has moved in with his significant other in Hellertown and is not living at the property anymore, so he wants to get rid of it. The property needs a bit of work but is generally in good shape. The last updates to the kitchen and bathroom were about five years ago. Ronald indicated a potential selling price of around $160,000 but mentioned it could possibly go lower.",
};

export const happyCustomerSec = {
  title: (
    <>
      Our <span>happy</span> customers
    </>
  ),
  description:
    "We're confident in the unparalleled power of our conversational AI and we're witnessing it redefine the success blueprint for investors just like you. Don’t just take our word for it. Take a look at some of our satisfied customers, dive into their journeys, hear their stories, and discover how OttoLeads has unlocked their highest potential for success.",
  customers: [
    {
      id: 1,
      name: "Terrell",
      title: "Wholesaler",
      headshotImage: customerIcon2,
      cardImage: Terrell,
      video: "https://d8eetllu6sdl7.cloudfront.net/terrell.mov",
    },
    {
      id: 2,
      name: "Mitch",
      title: "Investor",
      headshotImage: customerIcon,
      cardImage: Mitch,
      video:
        "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Customer%20Reviews_Mitch_2024-05-14.mov",
    },
    {
      id: 3,
      name: "Roberta",
      title: "Wholesaler",
      headshotImage: customerIcon3,
      cardImage: Roberta,
      video:
        "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Customer%20Reviews_Roberta_2024-05-15.mov",
    },
    {
      id: 4,
      name: "Grant",
      title: "Wholesaler",
      headshotImage: customerIcon3,
      cardImage: Grant,
      video:
        "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Customer%20Reviews_Grant_2024-05-15.mov",
    },
  ],
};

export const homeFaqSec = {
  questions: [
    {
      question: "General Access vs AI Calling. What's the difference?",
      answer: (
        <p>
          General access includes most core platform features. You can search
          for properties, view property profiles, build lists, skip trace,
          upload lists, and use our smart CRM features.
          <br />
          <br />
          On the other hand, AI calling allows you to create a calling campaign
          to intelligently call your lists and generate leads, all without you
          or any of your employees having to touch a phone.
        </p>
      ),
    },
    {
      question: "How long do campaigns take to complete?",
      answer: (
        <>
          <p>Campaigns take 3 days to complete.</p>
          <p>
            Our system is capable of running an entire campaign in less than an
            hour; however, we've found that spreading the calls out over three
            days increases the likelihood of reaching prospects with their
            varying schedules.
          </p>
          <p>
            Campaigns are broken up into 3 days of calling: on the first day, we
            call in the morning, on the second day we call in the afternoon, and
            on the third day, we call in the evening.
          </p>
        </>
      ),
    },
    {
      question: "Human vs OttoLeads. What's the difference?",
      answer: (
        <>
          <p>
            We're confident that you'll <u>never</u> want to hire a human caller
            again after switching to OttoLeads.
          </p>
          <p>
            What one full time employee or virtual assistant (VA) can get done
            in a month OttoLeads can do in just three days.
          </p>
          <p>
            OttoLeads is just like a call center in a box, eliminating the
            hassle of training, managing employes, dealing with turnover, and a
            whole lot more.
          </p>
        </>
      ),
    },
    {
      question: "How many leads should I expect?",
      answer: (
        <>
          <p>
            Just like with a human caller, <u>your results will vary</u>{" "}
            depending on your market, the filters you use when pulling your
            data, and your list size.
          </p>
          <p>
            And just like with a human lead-generator, you'll need to lock up
            the leads we generate for you.
          </p>
        </>
      ),
    },
    {
      question: "Do I have to buy phone numbers?",
      answer: (
        <>
          <p>
            We handle buying phone numbers and managing their reputation. Phone
            numbers are included with all AI Calling packages and come at{" "}
            <u>no extra cost to you</u>.
          </p>
        </>
      ),
    },
  ],
};

// const aosCommonAnimation = {
//   "data-aos-delay": "50",
//   "data-aos-duration": "1000",
// };

// export const aosAnimations = {
//   common: aosCommonAnimation,
//   fadeUp: {
//     ...aosCommonAnimation,
//     "data-aos": "fade-up",
//   },
// };

export const pricingSec = {
  prices: [
    {
      title: "General Access",
      price: BLACK_FRIDAY_SALE ? 49 : 98,
      benefits: [
        "Advanced property search with over 50 expertly-crafted filters",
        "Ability to skip trace lists or bring your own skipped property data",
        "Built-in wholesale and amortization calculators",
        "Custom pre-configured CRM to manage your leads and follow ups",
        "Extremely easy to use and navigate the platform",
      ],
    },
    {
      title: "AI Calling (Add-On)",
      price: BLACK_FRIDAY_SALE ? 1346.25 : 1795,
      benefits: [
        "Up to 30,000 calls from a list of 5,000 property records (5k credits)",
        "Expertly-trained intelligent AI conversations with prospects",
        "Detailed KPIs and stats on your campaign performance",
        "Smart lead summary generated by our AI lead analysis engine",
        "Calls optimized for high answer rates and probability of reaching prospects",
        "No need to hire, train, or manage employees",
      ],
    },
  ],
};
