import { Dialog } from "@mui/material";
import style from "./CancelSubscription.module.scss";
import Close from "../../assets/icons/close";
import Alert from "../../assets/icons/alert";
import Button from "../../atoms/Button";
import InfoOutlineSolid from "../../assets/icons/infoOutlineSolid";
import AlertTriangle from "../../assets/icons/alertTriangle";
import { ProductType } from "./enum";
import { cancelSubscription } from "./billingService";
import { fetchSubscriptions } from "./sagaActions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { CancelSubscriptionProps } from "./interface";

const CancelSubscription = ({
  handleClose,
  open,
  subscription,
  displayErrorMessage,
  displaySuccessMessage,
  closeManageSubscription
}: CancelSubscriptionProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleCancelNow = async () => {
    try {
      setLoading(true);
      await cancelSubscription({
        subscriptionId: subscription?.stripeSubscriptionId,
        subsProductType: subscription?.productType,
      });

      setTimeout(() => {
        setLoading(false);
        displaySuccessMessage("Subscription cancelled!");
        if(subscription?.productType===ProductType.GENERAL_ACCESS){
          closeManageSubscription&&closeManageSubscription();
        }
        dispatch(fetchSubscriptions({}));
        dispatch(fetchProfileInfo({}));
        handleClose();
      }, 3000);
    } catch (error) {
      displayErrorMessage(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog welcomeDialog cancelSubscription`}
      >
        {ProductType.GENERAL_ACCESS === subscription?.productType ? (
          <div className={style.cancel}>
            <div className={`${style.cancel__header} flex justifySpaceBetween`}>
              <InfoOutlineSolid />
              <span role="link" className="closeDialog" onClick={()=>!loading&&handleClose()}>
                <Close />
              </span>
            </div>
            <h4>Are you sure you want to do this?</h4>

            <div className={`${style.cancel__item} flex alignCenter`}>
              <AlertTriangle />
              <aside>
                <strong>All of your data will be permanently lost.</strong>
                <p>This includes leads, lists, campaigns, and more.</p>
              </aside>
            </div>

            <div className={`${style.cancel__item} flex alignCenter`}>
              <AlertTriangle />
              <aside>
                <strong>
                  All of your add-on subscriptions will be ended immediately.
                </strong>
                <p>This includes any active AI calling subscription.</p>
              </aside>
            </div>

            <div className={style.cancel__text}>
              <p>
                Your cancellation is effective <strong>immediately</strong> and
                this action cannot be undone.
              </p>
            </div>
            <div className={`${style.cancel__button} flex`}>
              <Button
                label="Keep My Plan"
                className="button primary xl animated"
                action={handleClose}
              />
              <Button
                label="End My Subscription Now"
                className="outline xl outline-red"
                action={handleCancelNow}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
        ) : (
          <div className={style.cancel}>
            <div className={`${style.cancel__header} flex justifySpaceBetween`}>
              <Alert />
              <span role="link" className="closeDialog" onClick={()=>!loading&&handleClose()}>
                <Close />
              </span>
            </div>
            <h4>Are you sure? You will <u>lose</u> all your AI Calling credits!</h4>
            <div className={style.cancel__text}>
              <p>
                Canceling your subscription before it renews will result in all
                of your remaining credits being{" "}
                <strong>permanently deleted</strong> and you lose access to
                starting new campaigns.
              </p>
              <p>
                Your cancellation is effective <strong>immediately</strong>.
              </p>
            </div>
            <div className={`${style.cancel__button} flex`}>
              <Button
                label="Keep My Plan"
                action={handleClose}
                className="button primary xl animated"
              />
              <Button
                label="Cancel Now"
                className="outline xl outline-red"
                action={handleCancelNow}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
export default CancelSubscription;
