import { Dialog } from "@mui/material";
import Zap from "../../assets/icons/zap";
import Button from "../../atoms/Button";
import styles from "./AddAnotherAttempt.module.scss";
import Close from "../../assets/icons/cross";
import Rocket from "../../assets/icons/rocket";
import ClockForward from "../../assets/icons/clockForward";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyCountByCampaign } from "../../pages/detail/selector";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { addAttempt } from "./campaignDetailsService";
import { useState } from "react";
import { setPropertyCountByCampaign } from "../../pages/detail/reducer";
import InfoCircle from "../../assets/icons/infoCircle";
import { AddAnotherAttemptProps } from "./interface";

const AddAnotherAttempt = ({
  open,
  handleClose,
  campaignId,
  displaySuccessMessage,
  displayErrorMessage,
  opUpgradeNow,
}: AddAnotherAttemptProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const propertyCountByCampaign = useSelector((state) =>
    getPropertyCountByCampaign(state)
  );
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const onStartYourCampaign = async () => {
    try {
      setLoading(true);
      await addAttempt(campaignId.id);
      setLoading(false);
      handleClose();
      displaySuccessMessage("Attempt added successfully");
      dispatch(setPropertyCountByCampaign({}));
    } catch (error) {
      setLoading(false);
      displayErrorMessage(error);
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} className={`stepsDialog`}>
        <div className="dialogWrapper">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>

          <div className={`${styles.attempts}`}>
            <div className={`headerIcon ${styles.headerIcon}`}>
              <span className={`${styles.flag}`}>
                <ClockForward />
              </span>
            </div>
            <div className={`${styles.attempts__header}`}>
              <h3>Add Another Attempt</h3>
              <p> Additional calling attempts could produce more leads</p>
            </div>
            {profileInfo?.organisation?.availableCredit <
              propertyCountByCampaign.requiredCredit && (
              <div
                className={`flex justifySpaceBetween alignCenter ${styles.creditsUpgrade}`}
              >
                <div className="flex alignCenter">
                  <span className={`animated-svg `}>
                    <InfoCircle />
                  </span>
                  <p>
                    <strong>Insufficient AI calling credits</strong>
                    <br />
                    You only have {
                      profileInfo?.organisation?.availableCredit
                    }{" "}
                    credits remaining.
                  </p>
                </div>
                <div>
                  <Button
                    label="Upgrade now"
                    prefix={<Zap />}
                    className="primary animated"
                    action={opUpgradeNow}
                  />
                </div>
              </div>
            )}
            <div className={`${styles.attemptsText}`}>
              This attempt will cost{" "}
              <strong>{propertyCountByCampaign.requiredCredit}</strong> credits.
            </div>

            <div className={`${styles.attempts__action}`}>
              <Button
                prefix={<Rocket />}
                className="primary sl full animated"
                label={"Start your Attempt"}
                action={onStartYourCampaign}
                disabled={loading}
                clicked={loading}
              ></Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddAnotherAttempt;
