import { createAction } from "@reduxjs/toolkit";

export const searchAddress = createAction("SEARCH_ADDRESS", function prepare(data) {
  return { payload: data };
});

export const searchProperty = createAction("SEARCH_PROPERTY", function prepare(data) {
  return { payload: data };
});

export const sendEmailAction = createAction(
  "sendEmailNotification",
  (data) => ({ payload: data })
);