import { initialState } from "./reducer";

const getStateData = (state: any) => state["earlyAccess"] || initialState;

export const registeredUser = (state: any) => {
  const reducer = getStateData(state);
  return reducer.registeredUser;
};

export const currentStep = (state: any) => {
  const reducer = getStateData(state);

  return reducer.currentStep;
};

export const isLoading = (state: any) => {
  const reducer = getStateData(state);

  return reducer.isLoading;
};


