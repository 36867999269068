import { useFormContext } from "react-hook-form";
import SearchReflation from "../../../assets/icons/searchReflation";
import DropDown from "../../../atoms/DropDown";
import Input from "../../../atoms/Input";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import {
  CLASSIFICATIONS_OPTIONS,
  HOME_FEATURES_OPTIONS,
  PROPERTY_TYPE_OPTION,
  SQUARE_FEET_OPTIONS,
} from "../constants";

const PropertyCharacteristics = ({
  onInputBlur,
}: {
  onInputBlur: Function;
}) => {
  const {
    formState: { errors },
    control,
    watch,
    register,
  }: any = useFormContext();

  const transformValue = (value: any[]) => {
    if (value.includes("ANY")) {
      if (!watch("propertyType")?.includes("ANY")) {
        return ["ANY"];
      } else {
        return value.filter((type: any) => type !== "ANY");
      }
    }
    return value;
  };

  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <DropDown
            options={CLASSIFICATIONS_OPTIONS}
            label="Classifications"
            name={"propertyCharacteristicsFilter.classifications"}
            control={control}
            errors={
              errors?.["propertyCharacteristicsFilter"]?.["classifications"]
            }
            placeholder="Any"
            clearOnBlur={true}
          ></DropDown>
        </div>

        <div className={`colRow__col`}>
          <p>Property Type</p>

          <MultiSelectDropDown
            options={PROPERTY_TYPE_OPTION}
            smallSize
            label="Property Type"
            name="propertyCharacteristicsFilter.propertyType"
            displayLabel={`Property Type`}
            control={control}
            transformValue={transformValue}
            displayLength={
              watch("propertyCharacteristicsFilter.propertyType")?.filter(
                (type: any) => type !== "ANY"
              ).length
            }
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
            disabled={
              watch("propertyCharacteristicsFilter.classifications") !==
              "RESIDENTIAL"
            }
          ></MultiSelectDropDown>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Bedrooms</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.minBedrooms"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minBedrooms"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.maxBedrooms"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxBedrooms"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Bathrooms</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.minBathrooms"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minBathrooms"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.maxBathrooms"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxBathrooms"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>

      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Year Built</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.yearBuiltMin"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["yearBuiltMin"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.yearBuiltMax"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["yearBuiltMax"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Square Feet</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <DropDown
                options={[
                  // { value: null, label: "No Min" },
                  ...(watch("propertyCharacteristicsFilter.maxSquareFeet")
                    ? SQUARE_FEET_OPTIONS.filter(
                        (option) =>
                          option.value <
                          watch("propertyCharacteristicsFilter.maxSquareFeet")
                      )
                    : SQUARE_FEET_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.minSquareFeet"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minSquareFeet"]
                }
                placeholder="No Min"
                clearOnBlur={true}
              ></DropDown>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <DropDown
                options={[
                  // { value: null, label: "No Max" },
                  ...(watch("propertyCharacteristicsFilter.minSquareFeet")
                    ? SQUARE_FEET_OPTIONS.filter(
                        (option) =>
                          option.value >
                          watch("propertyCharacteristicsFilter.minSquareFeet")
                      )
                    : SQUARE_FEET_OPTIONS),
                ]}
                label=""
                control={control}
                name="propertyCharacteristicsFilter.maxSquareFeet"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxSquareFeet"]
                }
                placeholder="No Max"
                clearOnBlur={true}
              ></DropDown>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Lot Size</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.minLotSize"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["minLotSize"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.maxLotSize"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["maxLotSize"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>

        <div className={`colRow__col`}>
          <p>Stories</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.storiesMin"
                placeholder="No Min"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["storiesMin"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="propertyCharacteristicsFilter.storiesMax"
                placeholder="No Max"
                errors={
                  errors?.["propertyCharacteristicsFilter"]?.["storiesMax"]
                }
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>

      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Home Features</p>
          <MultiSelectDropDown
            options={HOME_FEATURES_OPTIONS}
            label="Any"
            name={"propertyCharacteristicsFilter.homeFeatures"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
      </div>
    </>
  );
};
export default PropertyCharacteristics;
