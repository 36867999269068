
const Visa = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="32" viewBox="0 0 46 32" fill="none">
                <rect  width="45" height="31"  fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.3321 21.1441H11.5858L9.52638 13.0563C9.42863 12.6843 9.22108 12.3554 8.91579 12.2003C8.15389 11.8108 7.31432 11.5007 6.39844 11.3444V11.033H10.8225C11.4331 11.033 11.8911 11.5007 11.9674 12.044L13.0359 17.878L15.7809 11.033H18.4509L14.3321 21.1441ZM19.9774 21.1441H17.3837L19.5195 11.033H22.1131L19.9774 21.1441ZM25.4687 13.8341C25.545 13.2895 26.003 12.9781 26.5372 12.9781C27.3768 12.8999 28.2914 13.0563 29.0546 13.4445L29.5125 11.2676C28.7493 10.9562 27.9097 10.7998 27.1478 10.7998C24.6305 10.7998 22.7987 12.2003 22.7987 14.1441C22.7987 15.6228 24.0962 16.3993 25.0121 16.867C26.003 17.3334 26.3846 17.6448 26.3083 18.1112C26.3083 18.8108 25.545 19.1222 24.7831 19.1222C23.8672 19.1222 22.9514 18.889 22.1131 18.4994L21.6552 20.6777C22.5711 21.066 23.5619 21.2223 24.4778 21.2223C27.3005 21.2992 29.0546 19.9 29.0546 17.7998C29.0546 15.1551 25.4687 15.0001 25.4687 13.8341ZM38.1318 21.1441L36.0724 11.033H33.8603C33.4024 11.033 32.9444 11.3444 32.7918 11.8108L28.9783 21.1441H31.6483L32.1812 19.6668H35.4618L35.7671 21.1441H38.1318ZM34.2419 13.7559L35.0038 17.5666H32.8681L34.2419 13.7559Z" fill="#172B85" />
                <rect  width="45" height="31"   />
            </svg>
        </>
    );
};

export default Visa;


