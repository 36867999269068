import { createAction } from "@reduxjs/toolkit";

export const fetchUserRoleList = createAction(
  "user-RoleList",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchPendingUsersList = createAction(
  "PENDING_USER_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchActiveUsersList = createAction(
  "ACTIVE_USER_LIST",
  function prepare(data) {
    return { payload: data };
  }
);
