import ArrowRight from "../../assets/icons/arrowRight";
import Trophy from "../../assets/icons/trophy";
import Button from "../../atoms/Button";
import styles from './CreateCampaign.module.scss';
import { CampaignCreatedProps } from "./interface";

const CampaignCreated = ({ onSubmit }: CampaignCreatedProps) => {
    return <div className={`${styles.createCampaign}`}>
        <div className={`${styles.campaignCreated}`}>

            <figure>
                <Trophy />
            </figure>

            <div className={`${styles.createCampaign__header}`}>

                <h3>You’ve created a campaign!</h3>
                <p>Track your stats in real-time, manage your campaign, and see your leads all in one place.</p>
            </div>

            <Button className="primary xl full animated" label={'Next'} prefix={<ArrowRight />} action={onSubmit} ></Button>
        </div>
    </div>

}

export default CampaignCreated;