import { postAPI, putAPI } from "../../apis/apis";

export const updateUserProfile = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/users/update-profile`,
    headers: {},
    data: payload,
  });
};

export const botTextToSpeech = async (payload: any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/users/text-to-speech-convert`,
    headers: {},
    data: payload,
  });
};
