
import styles from "./PriceCard.module.scss";
const PriceCard = ({  children }:React.PropsWithChildren<{}> ) => {

    return (
        <>
            <div className={`priceCard ${styles.priceCard}`}>
            {children}
            </div>
           
        </>

    );
};

export default PriceCard;

