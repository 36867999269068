import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
  notificationData : []
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateNotifications: (state, { payload }) => {
      return {
        ...state,
        payload,
      };
    },
    setNotificationData: (state, { payload }) => {
      return {
        ...state,
        notificationData: payload,
      };
    },
    resetNotificationState: (state, payload) => {
      return initialState;
    },
  },
});

export const {
  updateNotifications,
  setNotificationData,
  resetNotificationState,
} = notificationSlice.actions;
export default notificationSlice.reducer;
