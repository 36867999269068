import { Column } from "../../models/table.interface";

export const column = [
    {
      id: "nameFirst",
      displayName: "nameFirst",
      minWidth: 570,
      type: "string",
    },
    {
      id: "nameLast",
      displayName: "nameLast",
      minWidth: 70,
      type: "string",
    },
    {
      id: "addressStreet",
      displayName: "street_address",
      minWidth: 70,
      type: "",
    },
    { id: "addressCity", displayName: "city", minWidth: 70, type: "string" },
    { id: "addressState", displayName: "state", minWidth: 70, type: "string" },
    { id: "addressZip", displayName: "zip_code", minWidth: 70, type: "number" },
    { id: "phone1", displayName: "phone1", minWidth: 70, type: "number" },
    { id: "phone2", displayName: "phone2", minWidth: 70, type: "number" },
    { id: "phone3", displayName: "phone3", minWidth: 70, type: "number" },
  ];

  export const skipList_column = [
    {
      id: "Owner1FirstName",
      displayName: "Owner 1 First Name",
      minWidth: 570,
      type: "string",
    },
    {
      id: "Owner1LastName",
      displayName: "Owner 1 Last Name",
      minWidth: 70,
      type: "string",
    },
    {
      id: "Owner2FirstName",
      displayName: "Owner 2 First Name",
      minWidth: 570,
      type: "string",
    },
    {
      id: "Owner2LastName",
      displayName: "Owner 2 Last Name",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MailingStreetAddress",
      displayName: "Mailing Street Address",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MailingCity",
      displayName: "Mailing City",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MailingState",
      displayName: "Mailing State",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MailingZip",
      displayName: "Mailing Zip",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MailingCounty",
      displayName: "Mailing County",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyStreetAddress",
      displayName: "Property Street Address",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyCity",
      displayName: "Property City",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyState",
      displayName: "Property State",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyZip",
      displayName: "Property Zip",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyCounty",
      displayName: "Property County",
      minWidth: 70,
      type: "string",
    },
    { id: "Email1", displayName: "Email 1", minWidth: 70, type: "string" },
    { id: "Email2", displayName: "Email 2", minWidth: 70, type: "string" },
    { id: "Phone1", displayName: "Phone 1", minWidth: 70, type: "string" },
    { id: "Phone2", displayName: "Phone 2", minWidth: 70, type: "string" },
    { id: "Phone3", displayName: "Phone 3", minWidth: 70, type: "string" },
    { id: "Phone4", displayName: "Phone 4", minWidth: 70, type: "string" },
    { id: "Phone5", displayName: "Phone 5", minWidth: 70, type: "string" },
    { id: "Phone6", displayName: "Phone 6", minWidth: 70, type: "number" },
    {
      id: "IsVacant",
      displayName: "Is Vacant",
      minWidth: 70,
      type: "string",
    },
    {
      id: "IsAbsentee",
      displayName: "Is Absentee",
      minWidth: 70,
      type: "string",
    },
    {
      id: "APN",
      displayName: "APN",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyType",
      displayName: "Property Type ",
      minWidth: 70,
      type: "string",
    },
    {
      id: "PropertyUse",
      displayName: "Property Use",
      minWidth: 70,
      type: "string",
    },
    {
      id: "BedroomCount",
      displayName: "Bedroom Count",
      minWidth: 70,
      type: "string",
    },
    {
      id: "BathroomCount",
      displayName: "Bathroom Count",
      minWidth: 70,
      type: "string",
    },
    {
      id: "BuildingSquareFeet",
      displayName: "Building Square Feet",
      minWidth: 70,
      type: "string",
    },
    {
      id: "LotSquareFeet",
      displayName: "Lot Square Feet ",
      minWidth: 70,
      type: "string",
    },
    {
      id: "YearBuilt",
      displayName: "Year Built",
      minWidth: 70,
      type: "string",
    },
    {
      id: "AssessedValue",
      displayName: "Assessed Value",
      minWidth: 70,
      type: "string",
    },
    {
      id: "AssessedLandValue",
      displayName: "Assessed Land Value ",
      minWidth: 70,
      type: "string",
    },
    {
      id: "AssessedImprovementValue",
      displayName: "Assessed Improvement Value",
      minWidth: 70,
      type: "string",
    },
    {
      id: "LastSaleDate",
      displayName: "Last Sale Date",
      minWidth: 70,
      type: "string",
    },
    {
      id: "LastSalePrice",
      displayName: "Last Sale Price",
      minWidth: 70,
      type: "string",
    },
    {
      id: "MortgageBalanceOpen",
      displayName: "Mortgage Balance Open",
      minWidth: 70,
      type: "string",
    },
    // {
    //   id: "MortgageLenderName",
    //   displayName: "Mortgage Lender Name",
    //   minWidth: 70,
    //   type: "string",
    // },
    // {
    //   id: "MortgageType",
    //   displayName: "Mortgage Type",
    //   minWidth: 70,
    //   type: "string",
    // },
    {
      id: "EstimatedEquity",
      displayName: "Estimated Equity",
      minWidth: 70,
      type: "string",
    },
    {
      id: "EstimatedValue",
      displayName: "Estimated Value",
      minWidth: 70,
      type: "string",
    },{
      id: "MLSStatus",
      displayName: "MLS Status",
      minWidth: 70,
      type: "string",
    },{
      id: "MLSListingDate",
      displayName: "MLS Listing Date",
      minWidth: 70,
      type: "string",
    },{
      id: "MLSListingPrice",
      displayName: "MLS Listing Price",
      minWidth: 70,
      type: "string",
    },
    // {
    //   id: "MLSListingAgentFullName",
    //   displayName: "MLS Listing Agent FullName",
    //   minWidth: 70,
    //   type: "string",
    // },
    // {
    //   id: "MLSListingAgentPhone",
    //   displayName: "MLS Listing Agent Phone",
    //   minWidth: 70,
    //   type: "string",
    // },
    // {
    //   id: "MLSListingAgentEmail",
    //   displayName: "MLS Listing Agent Email",
    //   minWidth: 70,
    //   type: "string",
    // },
    // {
    //   id: "MLSListingBrokerageName",
    //   displayName: "MLS Listing Brokerage Name",
    //   minWidth: 70,
    //   type: "string",
    // },
    // {
    //   id: "MLSListingBrokeragePhone",
    //   displayName: "MLS Listing Brokerage Phone",
    //   minWidth: 70,
    //   type: "string",
    // },
    {
      id: "AISummary",
      displayName: "AI Summary",
      minWidth: 70,
      type: "string",
    },
   
  ];

 export const columns: Column[] = [
    {
      id: "list",
      label: "List",
      minWidth: 570,
      type: "titleDescription",
      sortKey: "name",
    },
    {
      id: "uploadDate",
      label: "Upload Date",
      minWidth: 70,
      type: "",
      sortKey: "createdAt",
    },
    { id: "records", label: "Records", minWidth: 70, type: "" },
    { id: "phone", label: "Phone", minWidth: 70, type: "" },
    { id: "downloadList", label: "", minWidth: 70, type: "icon" },
  ];