import React, { useEffect } from 'react'
import styles from "./Policy.module.scss";

const SatisfactionGuarantee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
     <div className={`${styles.policy}`}>
     <div className={`${styles.policy__header}`}>
     <h3>Last updated July 26, 2024</h3>
     <h1>OttoLeads Satisfaction Guarantee</h1>
     <p>At OttoLeads, we are committed to providing our customers with the highest quality data and exceptional service. While we guarantee the accuracy of the data we provide, we understand that results may vary. Here's our promise to you:
    </p>
   
     </div>
     <div className={`${styles.policy__wrapper}`}>
     <div className={`${styles.policy__text}`}>
     <h2> Our Guarantee:</h2>
   <p>
     <strong>1. Data Accuracy:</strong> We stand behind the accuracy and reliability of our data. Our advanced systems and rigorous quality checks ensure that you receive precise and up-to-date information for your real estate needs.
    </p>
    <p>
   <strong> 2. Exceptional Support:</strong> Your satisfaction is our top priority. If you encounter any issues or have concerns about the data or services we provide, our dedicated support team is here to assist you promptly and effectively.
    </p>
    <p>
    <strong>3. Customer Care:</strong> We believe in building long-term relationships with our customers. If something isn't right, we will work diligently to address your concerns and make things right. Your success is our success, and we are committed to supporting you every step of the way.
    </p>
<p>
<strong>Please Note:</strong> While we guarantee the accuracy of our data, the results of using OttoLeads may vary based on numerous factors, including market conditions, individual strategies, and external influences. Our tools and data are designed to give you the optimum amount of quality leads given said factors, and empower you with the information you need, but they do not guarantee specific outcomes.
</p>
<p>
    <strong>Our Commitment:</strong> We are dedicated to continuous improvement and innovation, ensuring that OttoLeads remains a valuable resource for your real estate business. Your feedback is invaluable to us, and we encourage you to reach out with any suggestions or issues you may have.
</p>
<p>
Thank you for choosing OttoLeads. We look forward to supporting your real estate journey and helping you achieve your goals. 

</p>


 

   </div>
   </div>
   </div>
    </>
  )
}

export default SatisfactionGuarantee