import Upload from "../assets/icons/upload";

const UploadFile = ({
  label,
  name,
  errors,
  onUpload,
  uplodeInputRef
}: {
  label: string;
  name: string;
  errors: any;
  onUpload: Function;
  uplodeInputRef?:any
}) => {
  const handleOnChange = (e: any) => {
    const file = e?.target?.files[0];
    onUpload(file);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      onUpload(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  return (
    <div className="uploadFile" onDrop={handleOnDrop} onDragOver={handleDragOver}>
      <label className="label">{label}</label>
      <div className="uploadFile__input" data-testid="uploadFile__input">
        <div>
            <figure>
                <Upload/>
            </figure>
        <p><strong>Click to upload</strong> or drag and drop
       <small>CSV or XLSX (max. 200 MB)</small> </p>
        </div>
      <input type="file" onChange={handleOnChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref={uplodeInputRef}/>
      </div>
      {errors && errors?.[name] && <span className="error">{errors?.[name]?.message}</span>}
    </div>
  );
};
export default UploadFile;
