import * as yup from "yup";

const startCampaignFormDefaultSchema = {
  campaignName: yup.string().trim().required("Campaign Name is required"),
  startDate: yup.string().required("Start Date is required"),
  dialsPerNumber: yup.string().required("Dials Per Number is required"),
  maxAttemptPerNumber: yup.string().notRequired(),
  optimizeCampaign: yup.boolean(),
  callbackPhone: yup.string()
};

export const startCampaignFormSchema = yup
  .object()
  .shape({ ...startCampaignFormDefaultSchema })
  .when((value: any, schema: any) => {
    if (value[0].optimizeCampaign) {
      return schema.shape({ ...startCampaignFormDefaultSchema });
    } else {
      return schema.shape({
        ...startCampaignFormDefaultSchema,
        maxAttemptPerNumber: yup
          .string()
          .required("Attempt frequency is required"),
      });
    }
  });

export const selectCampaignFormSchema = yup.object({
  leadList: yup.array().required("Select a list").min(1,'Select a list'),
});

export const callBackNoSchema = yup.object({
  organisationName: yup.string().trim().required("Company name is required"),
  callbackPhone: yup
  .string()
  .transform((value) => value.replace(/\D/g, ""))
  .matches(/^[0-9]{10}$/, "Phone number is required")
});

export const campaignCallBackNoSchema = yup.object({
  callbackPhoneNo: yup
  .string()
  .transform((value) => value.replace(/\D/g, ""))
  .matches(/^[0-9]{10}$/, "Phone number is required")
});