import { all, put, takeLatest } from "redux-saga/effects";
import { getAPI } from "../../apis/apis";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchUserRoleList() {
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/users/organisation-roles`,
      headers: {},
    });
    yield put(slice.setUserRoleList(response?.data));
  } catch (error: any) {
    console.log(error);
    yield put(
      addToast({
        id: new Date().getTime(),
        message:
          error?.error?.message || error?.message || "Something went wrong!",
        type: ToastType.error,
      })
    );
  }
}

function* fetchActiveList() {
  yield put(slice.setActiveUserListLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/users/active-user-seat`,
      headers: {},
      data: {},
    });
    yield all([
      put(slice.setActiveUserList(response?.data?.data)),
      put(slice.setActiveUserListLoading(false)),
    ]);
  } catch (error: any) {
    console.log(error);
    yield put(slice.setActiveUserListLoading(false));
  }
}

function* fetchPendingList() {
  yield put(slice.setPendingUserListLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/users/pending-user-seat-invite`,
      headers: {},
      data: {},
    });
    yield all([
      put(slice.setPendingUserList(response?.data?.data)),
      put(slice.setPendingUserListLoading(false)),
    ]);
  } catch (error: any) {
    console.log(error);
    yield put(slice.setPendingUserListLoading(false));
  }
}

function* myTeamSaga() {
  yield takeLatest(sagaActions.fetchUserRoleList, fetchUserRoleList);
  yield takeLatest(sagaActions.fetchActiveUsersList, fetchActiveList);
  yield takeLatest(sagaActions.fetchPendingUsersList, fetchPendingList);
}

export default myTeamSaga;
