
const RefreshSmall = () => {
    return (
      <>
     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clipPath="url(#clip0_19859_16478)">
    <path d="M7 1C7 1 7.42462 1.06066 9.18198 2.81802C10.9393 4.57538 10.9393 7.42462 9.18198 9.18198C8.55934 9.80462 7.79964 10.2067 7 10.3881M7 1L10 1M7 1L7 4M5 10.9999C5 10.9999 4.57538 10.9392 2.81802 9.18188C1.06066 7.42452 1.06066 4.57528 2.81802 2.81792C3.44066 2.19528 4.20036 1.79325 5 1.61182M5 10.9999L2 11M5 10.9999L5 8" 
    stroke="currentColor"
    strokeWidth="1.5" 
    strokeLinecap="round" 
    strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_19859_16478">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg>
      </>
    );
  };
  
  export default RefreshSmall;
  