import { Column } from "../../models/table.interface";

export const webhook_COLUMN: Column[] = [
    { id: "status", label: "Status", type: "Pill" },
    { id: "type", label: "Type", type: "" , minWidth: 600},
    { id: "date", label: "Date", type: "" },
    { id: "buttons", label: "", type: "icon" },
  ];

  export const testPayload = {
    phone: "0000000000",
    address: {
      zip: "00000",
      city: "Test City",
      state: "Test State",
      street: "Test Street",
    },
    summary: "Summary from AI Bot",
    list_name: "List Name",
    name_full: "Full Name",
    name_last: "last Name",
    name_first: "First Name",
    webhook_type: "lead_created",
  };