import Menu from "@mui/material/Menu";
import * as React from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "socket.io-client";
import Bell from "../../assets/icons/bell";
import Check from "../../assets/icons/check";
import DropDownIcon from "../../assets/icons/dropDownIcon";
import Loader from "../../atoms/Loader";
import {
  notificationAction,
  readAllNotificationAction,
  readNotificationAction,
  setNotificationAction,
} from "../../organisms/notifications/sagaActions";
import { notificationDataResponse } from "../../organisms/notifications/selectors";
import { getDateDifferenceInWords } from "../../utils/utils";
import { getProfileInfo } from "../profileMenu/selectors";
import styles from "./NotificationsMenu.module.scss";
import { getSubscriptions } from "../../organisms/billing/selectors";
import { Tooltip } from "@mui/material";
import { SUBSCRITPTION_STATUS } from "../../constants/constants";
import { PermissionListEnum } from "../../organisms/auth/enum";

export default function NotificationsMenu() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const profileData = useSelector((state) => getProfileInfo(state));
  const notificationData = useSelector((state) =>
    notificationDataResponse(state)
  );
  const socketRef = React.useRef<any>();
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const [toggle, setToggle] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [notifyFilter, setNotifyFilter] = useState<{
    pageNumber: number;
    limit: number;
  }>({
    pageNumber: 1,
    limit: 20,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!sunscriptions?.generalSubData ||
      sunscriptions?.generalSubData?.lastPaymentStatus !=
        "card_declined") {
      setMenuOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (data: any) => {
    if (data?.isRead === false) {
      dispatch(readNotificationAction(data?.id));
    }
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleReadAll = () => {
    dispatch(readAllNotificationAction(1));
    setAnchorEl(null);
    setMenuOpen(false);
  };

  React.useEffect(() => {
    getNotification();
  }, [notifyFilter, sunscriptions]);

  const getNotification = () => {
    if (sunscriptions?.generalSubData) {
      const payload = {
        index: notifyFilter.pageNumber,
        size: notifyFilter.limit,
      };
      dispatch(notificationAction(payload));
    }
  };

  const navigateToPage = (type: string) => {
    if (type === "list") {
      window.open("/lists");
    }
  };

  const MenuList = () => {
    return (
      <div
        className={`${toggle ? `mobileNotificationActive` : ``} notification`}
      >
        <div
          className={`dflex justifySpaceBetween alignCenter notification__header`}
        >
          <h4>Notifications </h4>

          {notificationData?.unread !== 0 && (
            <span role="link" onClick={handleReadAll}>
              <Check />
            </span>
          )}
        </div>
        <div className={`notification__list`} id="notificationScrollableDiv">
          <InfiniteScroll
            dataLength={notificationData?.result?.length ?? 0}
            next={() =>
              setNotifyFilter({
                pageNumber: notifyFilter?.pageNumber + 1,
                limit: 20,
              })
            }
            hasMore={notificationData?.result?.length < notificationData?.count}
            scrollableTarget="notificationScrollableDiv"
            loader={
              <div className="table-loader">
                <Loader></Loader>
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b></b>
              </p>
            }
          >
            {notificationData?.result?.length > 0 ? (
              <>
                <ul>
                  {notificationData?.result?.map((el: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        navigateToPage(el?.type);
                        handleClose(el);
                      }}
                      className={el?.isRead ? "" : "unread"}
                    >
                      <p dangerouslySetInnerHTML={{ __html: el?.message }}></p>
                      <p className="notificationTime">
                        {getDateDifferenceInWords(
                          new Date(el?.createdAt),
                          true
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <div className={`noList`}>
                  <h4>Nothing here yet.</h4>
                  <p>New notifications will appear here.</p>
                </div>
              </>
            )}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    socketRef.current = (connect as any)(
      `${process.env.REACT_APP_WS_BASE_URL}/notifications`,
      {
        extraHeaders: {
          "x-user-id": profileData?.id,
        },
      }
    );

    socketRef.current.on("get-notification", (data: any) => {
      dispatch(setNotificationAction(data));
    });

    socketRef.current.on("read-notification", (data: any) => {
      getNotification();
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  return (
    <>
      <div className={`${styles.notificationsDesktop}`}>
        <div className={`${styles.notificationsMenu}`}>
          <Tooltip
            title={
              sunscriptions?.generalSubData
                ? sunscriptions?.generalSubData?.lastPaymentStatus ===
                  "card_declined"
                  ? SUBSCRITPTION_STATUS.CardDecline
                  : ""
                : profileData?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser : SUBSCRITPTION_STATUS.NoGeneralAccess
            }
          >
            <span
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              role="link"
              className={`${styles.notificationsMenu__item} ${
                anchorEl && styles.active
              }`}
            >
              <Bell />
              {notificationData?.unread > 0 ? (
                <span className={`${styles.notificationsNo}`}>
                  {notificationData?.unread <= 9
                    ? notificationData?.unread
                    : "9+"}
                </span>
              ) : (
                <></>
              )}
            </span>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="notificationsMenuList"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {MenuList()}
          </Menu>
        </div>
      </div>
      <div className={`${styles.notificationsMobile}`}>
        <div className={`${styles.notificationsMenu}`}>
          <div
            onClick={() => {
              if (
                !sunscriptions?.generalSubData ||
                sunscriptions?.generalSubData?.lastPaymentStatus !=
                  "card_declined"
              ) {
                setToggle(!toggle);
              }
            }}
            className={`flex justifySpaceBetween alignCenter ${styles.notificationsMenu}`}
            role="link"
          >
            <Tooltip
              title={
                sunscriptions?.generalSubData
                  ? sunscriptions?.generalSubData?.lastPaymentStatus ===
                    "card_declined"
                    ? SUBSCRITPTION_STATUS.CardDecline
                    : ""
                  :profileData?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser :  SUBSCRITPTION_STATUS.NoGeneralAccess
              }
            >
              <figure className="flex alignCenter">
                <span className={`${styles.notificationsMenu__item}`}>
                  <Bell />
                  {notificationData?.unread > 0 ? (
                    <span className={`${styles.notificationsNo}`}>
                      {notificationData?.unread <= 9
                        ? notificationData?.unread
                        : "9+"}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                Notifications
              </figure>
            </Tooltip>
            <div
              className={`${styles.toggleIconBefore} ${
                toggle && styles.toggleIcon
              }`}
            >
              <DropDownIcon />
            </div>
          </div>
          {toggle && (
            <div className="flex justifyEnd">
              {notificationData?.unread !== 0 && (
                <span role="link" onClick={handleReadAll}>
                  <Check />
                </span>
              )}
            </div>
          )}

          {toggle && MenuList()}
        </div>
      </div>
    </>
  );
}