import { MultiPolygonType } from "../../molecules/map";
import { createAdvancedFilters } from "../../organisms/propertySearchFilter/utils";
import { MapShapesPayload, MapShapesPayloadRaw } from "./interface";

const METER_SEGMENT = 33.3;

export const getMeterPercentage = (percentage: number) => {
  if (percentage > 95) {
    return Math.round(
      METER_SEGMENT * 2 + (METER_SEGMENT / 5) * (percentage - 95)
    );
  } else if (percentage >= 5 && percentage <= 95) {
    return Math.round(METER_SEGMENT + (METER_SEGMENT / 96) * (percentage - 4));
  } else {
    return Math.round((METER_SEGMENT / 5) * percentage);
  }
};

export const createSearchPayload = (
  filter: any,
  page: number,
  setAppliedAdvancedFiltersFlags: Function,
  setAdvancedFilterCount: Function
) => {
  const advancedFilter = getAdvancedFilterObject(
    filter,
    setAppliedAdvancedFiltersFlags,
    setAdvancedFilterCount
  );
  const payload = {
    ...(filter?.marketStatus?.length > 0
      ? { marketStatus: filter?.marketStatus }
      : {}),
    ...advancedFilter,
    ...(filter?.mapShapesPayload
      ? {}
      : {
          query: filter?.address?.title || "",
        }),
    ...filter?.mapShapesPayload,
    ...(filter?.quickFilters?.length > 0
      ? { quickFilters: filter.quickFilters }
      : {}),

    size: 2,
    resultIndex: page,
    requestId: filter?.requestId,
  };
  for (const key in payload) {
    if (!key.startsWith("exclude_")) {
      continue;
    }

    if (!payload.exclude) {
      payload.exclude = [];
    }

    payload.exclude.push({
      [key.replace("exclude_", "")]: payload[key],
    });

    delete payload[key];
  }
  return payload;
};

export const getAdvancedFilterObject = (
  data: any,
  setAppliedAdvancedFiltersFlags: Function,
  setAdvancedFilterCount: Function
) => {
  let advancedFilter = {};
  let advancedFilterCount = 0;
  const filterArray = createAdvancedFilters(
    data,
    setAppliedAdvancedFiltersFlags
  );

  filterArray.forEach((filter) => {
    advancedFilter = { ...advancedFilter, ...filter };
    if (
      Object.keys(filter).length > 0 &&
      Object.values(filter).some((val) => val !== undefined)
    ) {
      advancedFilterCount++;
    }
  });
  setAdvancedFilterCount(advancedFilterCount);
  return advancedFilter;
};

export const createMapShapePayload = (payload: MapShapesPayloadRaw) => {
  const newMapShapesPayload: MapShapesPayload = {};
  const geoPoints: MultiPolygonType = [];
  payload.polylines.forEach((polyline) => {
    const points = polyline.getPath().getArray();
    const result = points.map((point) => ({
      latitude: point.lat(),
      longitude: point.lng(),
    }));
    geoPoints.push(...result);
  })!;
  newMapShapesPayload.geoPoints = geoPoints;
  return newMapShapesPayload;
};
