import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CornerUpRight from "../../assets/icons/cornerUpRight";
import FileCheck from "../../assets/icons/fileCheck";
import Funnel from "../../assets/icons/funnel";
import Button from "../../atoms/Button";
import MultiSelectDropDown, {
  MultiDropDownOption,
} from "../../atoms/MultiSelectDropDownV2";
import SearchBar from "../../atoms/SearchBar";
import { LeadStatus, LeadStatusMap, LeadStatusValues } from "../../constants/leadstatus.constants";
import CreateLeads from "../../organisms/addLeads/CreateLeads";
import {
  resetCreateLeadState,
  setNewLeadCreated,
  toggleLeadsModal,
} from "../../organisms/addLeads/reducer";
import {
  getNewLeadCreatedFlag,
  getOpenCreateLeadModal,
} from "../../organisms/addLeads/selector";
import FilterDropdown from "../../organisms/filterDropdown/FilterDropdown";
import HeroSection from "../../organisms/heroSection/HeroSection";
import LeadTable from "../../organisms/leadsTable/LeadsTable";
import { ResponseGenerator } from "../detail/saga";
import styles from "./Leads.module.scss";
import { SortDirection } from "../../molecules/table/enum";
import SearchReflation from "../../assets/icons/searchReflation";
import Circle from "../../assets/icons/circle";
import {
  resetLeadState,
  setLeadListIsLoading,
} from "../../organisms/leadsTable/reducer";
import { getCampaignsData } from "../../organisms/leadsTable/LeadService";
import { getAPI } from "../../apis/apis";
import { LeadFilters } from "./interface";
import { fetchMinimalData } from "./leadsService";
import { useSearchParams } from "react-router-dom";
import { fbc, fbp, fetchIp, hashNumber, hashPhoneNumber, hashString } from "../../utils/utils";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { Tooltip } from "@mui/material";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

const LeadStatusOptions = Object.entries(LeadStatusMap).map(([key, value]) => ({
  label: key,
  value: LeadStatusValues[LeadStatus[value]],
}));

const INITIAL_STATE = {
  searchText: "",
  pageNumber: 1,
  limit: 20,
  isTodayFollowUp: false,
  leadStatus: [],
  campaignIds: [],
  sortDirection: SortDirection.DESC,
  sortKey: "updatedAt",
};
const Leads = () => {
  const { reset, control, getValues,setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      leadStatus: [],
      campaignIds: [''],
    },
  });
  const dispatch = useDispatch();
  const filterButtonRef = useRef<HTMLElement | null>(null);
  const [filter, setFilter] = useState<LeadFilters>(INITIAL_STATE);
  const [showActivityDrawer, setShowActivityDrawer] = useState(false);
  const [filterFlag, setFilterFlag] = useState(true);
  const [addressError, setAddressError] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [campaigns, setCampaigns] = useState<MultiDropDownOption[]>([]);
  const [statusLeads, setStatusLeads] = useState([]);
  const [filterCampaigns, setFilterCampaigns] = useState<string[]>([]);
   const [showfilterBtnDropdown, setShowFilterBtnDropdown] =
    useState<boolean>(false);
  const newLeadCreated = useSelector((state) => getNewLeadCreatedFlag(state));
  const [searchParams, setSearchParams] = useSearchParams();
  const searchByCampaign = searchParams.get("campaign") ?? ""
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, searchText: value, pageNumber: 1 }));
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
  };
  const openCreateLeadModal = useSelector((state) =>
    getOpenCreateLeadModal(state)
  );
  const toggleTodayFollowUp = () => {
    reset({
      leadStatus: [],
      campaignIds: [],
    });
    setFilter((prev) => ({
      searchText: "",
      pageNumber: 1,
      limit: 20,
      isTodayFollowUp: !prev.isTodayFollowUp,
      sortDirection: SortDirection.DESC,
      sortKey: "followUpAt",
    }));
  };

  const handleSorting = (sortKey: string | undefined) => {
    if (sortKey) {
      setFilter((prev) => ({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        isTodayFollowUp: filter.isTodayFollowUp,
        sortDirection:
          prev.sortKey === sortKey
            ? prev.sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
            : SortDirection.DESC,
        sortKey: sortKey,
      }));
    }
  };
  const handleFilterButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    filterButtonRef.current = e.currentTarget;
    setShowFilterBtnDropdown(true);
  };

  const handleAttemptClose = () => {
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
    setApplyClicked(false);
  };
  const handleAttemptApplyClose = () => {
    setApplyClicked(true);
    if (
      getValues("leadStatus").length !== 0 ||
      getValues("campaignIds").length !== 0
    ) {
      setFilterFlag(true);
    }
    setSearchParams({ campaign: encodeURIComponent(getValues()?.campaignIds.toString()) });
    setFilter({ ...filter, pageNumber: 1, ...getValues() });
    setStatusLeads(getValues()?.leadStatus);
    setFilterCampaigns(getValues()?.campaignIds  );
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
  };
  const handleCloseModal = () => {
    setAddressError(false);
    dispatch(toggleLeadsModal(false));
  };

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Lead_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(profileInfo?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  useEffect(() => {
    if (newLeadCreated) {
      setFilter({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        isTodayFollowUp: false,
        sortDirection: SortDirection.DESC,
        sortKey: "updatedAt",
      });
      dispatch(setNewLeadCreated(false));
    }
  }, [newLeadCreated]);

  useEffect(() => {
    const fetchCampaignMinimalData = async () => {
      // const response: ResponseGenerator = await getAPI({
      //   url: process.env.REACT_APP_BASE_URL + "/campaign/minimal-data",
      //   headers: {},
      // });
      try {
        const response = await fetchMinimalData();
        const data = response?.data;
        // Reverse the array to show the latest campaigns first
        data.reverse();
        setCampaigns(
          data.map((item: { id: string; name: string }) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignMinimalData();
  }, []);

  useEffect(() => {
    if (
      filterCampaigns.length === 0 &&
      statusLeads.length === 0
    ) {
      setFilterFlag(false);
    }
  }, [statusLeads, filterCampaigns]);



  useEffect(() => {
    if (
      getValues("leadStatus").length === 0 &&
      getValues("campaignIds").length === 0
    ) {
      setFilterFlag(false);
    }
  }, [getValues("leadStatus"), getValues("campaignIds")]);

  useEffect(() => {
    if (!showfilterBtnDropdown && !applyClicked) {
      reset({
        leadStatus: statusLeads,
        campaignIds: filterCampaigns,
      });
    }
  }, [showfilterBtnDropdown, applyClicked]);

  useEffect(() => {
    if (newLeadCreated) {
      setFilter({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        isTodayFollowUp: false,
        sortDirection: SortDirection.DESC,
        sortKey: "followUpAt",
      });
      dispatch(setNewLeadCreated(false));
    }
  }, [newLeadCreated]);

  useEffect(() => {
    if (searchByCampaign) {
      const idArray= decodeURIComponent(searchByCampaign)?.split(',')
      setFilter({
       ... filter,
        campaignIds: idArray
      });
      setValue("campaignIds",idArray);
      setFilterFlag(true);
      setApplyClicked(true); 
    }
  }, [searchByCampaign,]);




  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetLeadState({}));
      dispatch(resetCreateLeadState({}));
    };
  }, []);

  return (
    <>
      <div
        className={` ${styles.leads} ${
          showActivityDrawer && styles.leadsActive
        }`}
      >
        {/* <Header loggedIn></Header> */}
        <HeroSection
          title="Lead Manager"
          desc="See a list of existing leads and follow ups, or create new leads."
        >
           <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
             {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? 
             <Button
            label="Create Lead"
            className="primary"
            prefix={<FileCheck />}
            disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
          /> :
            <Button
            label="Create Lead"
            className="primary"
            prefix={<FileCheck />}
            action={() => dispatch(toggleLeadsModal(true))}
            dataTestId="createLeadTestId"
          />}
              
              </div>
              </Tooltip>
        </HeroSection>
        <div className="wrapper">
          <div className={`dflex ${styles.leads__header}`}>
            <div className={` ${styles.search}`}>
              <SearchBar
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = e.target.value;
                  if (
                    e.target.value.length > 0 &&
                    e.target.value.trim().length === 0
                  ) {
                    value = "";
                  }
                  if (filter.searchText !== value) {
                    setSearchText(value);
                  }
                }}
                searchText={filter.searchText}
                dataTestId="leadsSearchTestId"
              ></SearchBar>
            </div>
            <div className={`dflex ${styles.filters}`}>
              <Button
                label="Today’s Follow Ups"
                className={`outline ${
                  filter.isTodayFollowUp ? "selcted-status" : ""
                }`}
                prefix={<CornerUpRight />}
                action={toggleTodayFollowUp}
                dataTestId="TodayFollowUpBtnId"
              />
              <Button
                label="Filter"
                className="outline filter-icon"
                prefix={<Funnel />}
                action={handleFilterButtonClick}
                postfix={filterFlag ? <Circle color="#D92D20" /> : null}
              />
              {showfilterBtnDropdown && (
                <FilterDropdown
                  handleClose={handleAttemptClose}
                  anchorEl={filterButtonRef.current}
                  dataTestId="FilterBtnId"
                >
                  <div
                    className={`filterMenu`}
                    data-testid="filterButtonModalId"
                  >
                    <div className="filterMenu__info">
                      <div
                        className={`filterMenu__list`}
                        data-testid="SelectCampaignTestId"
                      >
                        <p>Campaign</p>
                        <MultiSelectDropDown
                          options={campaigns}
                          label="Select campaign(s)"
                          control={control}
                          name="campaignIds"
                          emptySearchMetaData={{
                            icon: <SearchReflation />,
                            title: "No results found",
                          }}
                          dataTestId="seletCampaignOptionId"
                        />
                      </div>
                      <div
                        className={`filterMenu__list`}
                        data-testid="SelectStatusTestId"
                      >
                        <p>Status</p>
                        <MultiSelectDropDown
                          options={LeadStatusOptions}
                          label="Select status(es)"
                          control={control}
                          name="leadStatus"
                          emptySearchMetaData={{
                            icon: <SearchReflation />,
                            title: "No results found",
                          }}
                          dataTestId="seletStatusOptionId"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`dflex filterMenuFooter`}>
                    <div className={`filterMenuFooter__half`}>
                      <Button
                        label="Reset"
                        className="outline full"
                        action={() => {
                          resetLeadState({});
                          setLeadListIsLoading(true);
                          setStatusLeads([]);
                          setFilterCampaigns([]);
                          reset({
                            leadStatus: [],
                            campaignIds: [],
                          });
                          setFilter(INITIAL_STATE);
                          setFilterFlag(false);
                          setShowFilterBtnDropdown(false);
                          setSearchParams("")
                        }}
                        dataTestId="ResetFilterBtnId"
                      />
                    </div>
                    <div className={`filterMenuFooter__half`}>
                      <Button
                        label="Apply"
                        className="primary full"
                        action={handleAttemptApplyClose}
                        dataTestId={"ApplyFilterBtnId"}
                      />
                    </div>
                  </div>
                </FilterDropdown>
              )}
            </div>
          </div>
          <div className={` ${styles.leads__table}`}>
            <LeadTable
              filter={filter}
              handleNextPage={handleNextPage}
              handleSorting={handleSorting}
              handleClearSearch={() => setSearchText("")}
              showActivityDrawer={showActivityDrawer}
              setShowActivityDrawer={setShowActivityDrawer}
            ></LeadTable>
          </div>
        </div>
      </div>
     {openCreateLeadModal && <CreateLeads
        open={openCreateLeadModal}
        closeModal={handleCloseModal}
        addressError={addressError}
        setAddressError={setAddressError}
      ></CreateLeads>}
    </>
  );
};
export default Leads;
