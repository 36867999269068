import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchMyList({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/lead-list/all?search=${payload?.search?.trim()}&index=${
        payload?.index
      }&size=${payload?.size}&sortDirection=${payload?.sortDirection}&sortKey=${
        payload?.sortKey
      }&type=${
        payload?.type
      }`,
      headers: {},
    });

    yield all([
      put(slice.setListIsLoading(false)),
      put(
        payload?.index > 1
          ? slice.appendMyLists({
              data: response?.data?.result,
              total: response?.data?.total,
            })
          : slice.setMyLists({
              data: response?.data?.result,
              total: response?.data?.total,
            })
      ),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setListIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchLeadListDetail({ payload }: any) {
  try {
    const {id, filter}=payload
   if(filter?.skip === 1){ yield put(slice.setLeadListDetailLoading(true))}
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/list/${id}/property?skip=${
        filter?.skip
      }&count=${filter?.count}`,
      headers: {},
    });
      yield all([
        put(
          filter?.skip > 1
            ? slice.appendLeadListDetail(response?.data?.results)
            : slice.setLeadListDetail(response?.data?.results)
        ),
        put(slice.setLeadListDetailCount(response?.data)),
        put(slice.setLeadListDetailLoading(false)),
      ]);
  } catch (error: any) {
       yield all([
         put(slice.setLeadListDetailLoading(false)),
         put(
           addToast({
             id: new Date().getTime(),
             message: error?.response?.data?.message?error?.response?.data?.message : error?.message ?? "Something went wrong!",
             type: ToastType.error,
           })
         ),
       ]);
  }
}

function* myListsSaga() {
  yield takeLatest(sagaActions.fetchMyList, fetchMyList);
  yield takeLatest(sagaActions.fetchLeadListDetail, fetchLeadListDetail);
}

export default myListsSaga;
