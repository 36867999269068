import { all, put, takeLatest } from "redux-saga/effects";
import { getAPI, postAPI } from "../../apis/apis";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import store from "../../store/store";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* searchAddress({ payload }: any) {
  try {
    yield put(slice.setAddressPredictionLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/address/autocomplete`,
      headers: {},
      data: {
        searchTerm: payload?.trim(),
      },
    });
    yield put(slice.setAddressPredictionLoading(false));
    if (store.getState().propertySearch?.searchTerm) {
      yield put(slice.setAddressPredictions(response));
    }
  } catch (error: any) {
    yield all([
      put(slice.setAddressPredictionLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message:
            error?.error?.message || error?.message || "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* searchProperty({ payload }: any) {
  yield put(slice.setPropertiesListLoading(true));
  try {
    const apiPayload = { ...payload };
    delete apiPayload["size"];
    delete apiPayload["requestId"];
    delete apiPayload["resultIndex"];
    const response: ResponseGenerator = yield postAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/property/${payload.geoPoints ? "search-polygon" : "search"}?index=${
          payload.resultIndex
        }&size=${payload.resultIndex > 1 ? payload?.size : 6}${
          payload?.requestId ? `&requestId=${payload.requestId}` : ""
        }`,
      headers: {},
      data: apiPayload,
    });
    const properties = [...response.data.results];

    for (const property of properties) {
      const mapResponse: ResponseGenerator = yield getAPI({
        url: `https://maps.googleapis.com/maps/api/streetview/metadata?location=${property.latitude},${property.longitude}&size=400x400&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      });
      if (mapResponse.data.status === "OK") {
        property.propertyImageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${property.latitude},${property.longitude}&size=400x400&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      }
      if (property?.images && property?.images?.length > 0) {
        property["images"] = property?.images?.slice(0, 5);
      } else {
        if (property.propertyImageUrl) {
          property["images"] = [property.propertyImageUrl];
        }
      }
    }
    properties.sort((a: any, b: any) => {
      if ((b?.images?.length || 0) > (a?.images?.length || 0)) {
        return 1;
      } else {
        return -1;
      }
    });
    response.data.results = properties;
    yield put(slice.setPropertiesListLoading(false));
    if (store.getState().propertySearch.searchTerm) {
      response.data.searchTerm = store.getState().propertySearch.searchTerm;
      yield put(
        payload.resultIndex > 1
          ? slice.appendSearchedProperties(response)
          : slice.setSearchedProperties(response)
      );
    }
  } catch (error: any) {
    yield all([
      put(slice.setPropertiesListLoading(false)),
      put(
        slice.setSearchedProperties({
          data: {
            results: [],
            results_count: -1,
            potential_count: 0,
          },
        })
      ),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* sendEmailNotificationData({ payload }: any) {
  try {
    yield postAPI({
      url: process.env.REACT_APP_BASE_URL + `/mail/send`,
      headers: {},
      data: payload,
    });
  } catch (error: any) {
    console.log("error=", error);
    yield put(
      addToast({
        id: new Date().getTime(),
        message:
          error?.error?.message || error?.message || "Something went wrong!",
        type: ToastType.error,
      })
    );
  }
}

function* propertySearchSaga() {
  yield takeLatest(sagaActions.searchAddress, searchAddress);
  yield takeLatest(sagaActions.searchProperty, searchProperty);
  yield takeLatest(sagaActions.sendEmailAction, sendEmailNotificationData);
}

export default propertySearchSaga;
