import { deleteAPI, getAPI, postAPI, putAPI } from "../../apis/apis";

export const getPropertySingleSkipStatus = async (
  propertyId: string | number
) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-details/${propertyId}/skip-trace-status`,
  });
};

export const propertySingleSkip = async (propertyId: string | number) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property/single-skip/${propertyId}`,
  });
};

export const getPropertyComment = (id: any, payload: any,type:any) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-comment?${type}=${id}`,
    headers: {},
    data: payload,
  });
};

export const postPropertyComment = async (id: any, payload: any,type:any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-comment?${type}=${id}`,
    headers: {},
    data: payload,
  });
};

export const putPropertyComment = async (commentId: any, payload: any) => {
  return putAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-comment/${commentId}`,
    headers: {},
    data: payload,
  });
};

export const deletePropertyComment = async (commentId: any) => {
  return deleteAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-comment/${commentId}`,
    headers: {},
  });
};

export const propertyCount = async (payload: any) => {
  const apiPayload = { ...payload };
  delete apiPayload["size"];
  delete apiPayload["requestId"];
  delete apiPayload["resultIndex"];
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/property/${
        payload.geoPoints ? "search-polygon" : "search"
      }?index=${1}&size=${0}${
        payload?.requestId ? `&requestId=${payload.requestId}` : ""
      }`,
    headers: {},
    data: apiPayload,
  });
};

export const ownerNameUpdate = async (id: any, payload: any,type:any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-owner?${type}=${id}`,
    headers: {},
    data: payload,
  });
};

export const resetOwnerName = async (id: any,type:any) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/property/owner/reset?${type}=${id}`,
    headers: {},
  });
};
export const deleteContact = async (id: any) => {
  return deleteAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-phone/${id}`,
    headers: {},
  });
};

export const addPropertyPhone = async (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-phone`,
    headers: {},
    data: payload,
  });
};

export const addPropertyEmail = async (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-email`,
    headers: {},
    data: payload,
  });
};

export const deleteEmail = async (id: any) => {
  return deleteAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-email/${id}`,
    headers: {},
  });
};

export const createSavedSearchFilters = (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/search-filters`,
    data: payload,
  });
};

export const updateSavedSearchFilters = (id: string, payload: any) => {
  return putAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/search-filters/${id}`,
    data: payload,
  });
};
export const daleteSavedSearchFilters = (id: string) => {
  return deleteAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/search-filters/${id}`,
  });
};
export const fetchSavedSearchFilters = () => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/search-filters`,
  });
};

export const getPropertyAISummary = (id: any, payload: any,type:any) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/lead/${id}/summary?skip=${payload.index}&count=${payload.size}`,
    headers: {}
  });
};