import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Controller, useFormContext } from "react-hook-form";
import Close from "../assets/icons/cross";
import Button from "./Button";
import { enUS } from "date-fns/locale";

const CustomStaticRangeLabelContent = ({ text }: any) => {
  const [currentDateString, setCurrentDateString] = useState(
    new Date().toString()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateString(new Date().toString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span>
      <i>{text}</i>
    </span>
  );
};

const DateRangePickerCalendar = ({
  control,
  name,
  errors,
  label,
  disabled = false,
  placeHolder = "",
}: {
  control: any;
  name: string;
  errors: any;
  label?: string;
  disabled?: boolean;
  placeHolder?: string;
}) => {
  const { getValues, setValue } = useFormContext();
  const fieldValue = getValues(name);

  const [selectionRange, setSelectionRange] = useState({
    startDate: fieldValue?.startDate || new Date(),
    endDate: fieldValue?.endDate || new Date(),
    key: "selection",
  });
  const [selectDate, setSelectDate] = useState(false);
  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.selection);
  };

  const customRanges = [
    {
      label: "This Year",
      range: () => ({
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "All Time",
      range: () => ({
        startDate: new Date(0),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: false,
    },
  ];

  const defaultStaticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Last 4 weeks",
      range: () => ({
        startDate: new Date(new Date().getTime() - 28 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Month to date",
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
    {
      label: "Quarter to date",
      range: () => {
        const today = new Date();
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
        const startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
        return {
          startDate,
          endDate: today,
        };
      },
      isSelected: () => {
        return false;
      },
      hasCustomRendering: true,
    },
  ];

  const staticRanges = [...defaultStaticRanges, ...customRanges];

  const renderStaticRangeLabel = (staticRange: any) => {
    return <CustomStaticRangeLabelContent text={`${staticRange.label}`} />;
  };

  const handleCancel = () => {
    if (fieldValue?.endDate && fieldValue?.startDate) {
      setSelectionRange((prev) => {
        if (
          fieldValue?.startDate !== prev.startDate ||
          fieldValue?.endDate !== prev.endDate
        ) {
          return {
            startDate: fieldValue?.startDate,
            endDate: fieldValue?.endDate,
            key: prev.key,
          };
        }
        return prev;
      });
    } else {
      setValue(name, "");
      setSelectionRange({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      });
    }
    setSelectDate(false);
  };

  return (
    <div className={`dateRangePickerMain ${disabled && `dateRangeDisabled`}`}>
      <div className={`dateRangePickerInput ${fieldValue && `crossDisabled`}`}>
        <button
          onClick={() => {
            if (!disabled) {
              setSelectDate(true);
            }
          }}
        >
          {fieldValue?.startDate && fieldValue?.endDate ? (
            <>
              {" "}
              {`${format(fieldValue?.startDate, "MMM dd, yyyy")} - ${format(
                fieldValue?.endDate,
                "MMM dd, yyyy"
              )}`}
            </>
          ) : (
            "Select dates"
          )}
        </button>
        {fieldValue && (
          <span
            role="link"
            onClick={() => {
              setValue(name, "");
              setSelectionRange({
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              });
            }}
          >
            <Close />
          </span>
        )}
      </div>
      {selectDate && !disabled && (
        <div className="dateRangePickerMainModal">
          <div className="dateRangePicker">
            {label && <label className="label">{label}</label>}
            <Controller
              name={name}
              control={control}
              render={({ field, ...props }) => {
                return (
                  <>
                    <DateRangePicker
                      ranges={[
                        {
                          startDate: selectionRange?.startDate,
                          endDate: selectionRange?.endDate,
                          key: "selection",
                        },
                      ]}
                      dateDisplayFormat={"MM/dd/yyyy"}
                      showDateDisplay={true}
                      editableDateInputs={true}
                      onChange={handleSelect}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      direction="horizontal"
                      inputRanges={[]}
                      staticRanges={staticRanges}
                      renderStaticRangeLabel={renderStaticRangeLabel}
                      locale={enUS}
                      startDatePlaceholder={"MM/DD/YYYY"}
                      endDatePlaceholder={"MM/DD/YYYY"}
                    />

                    <div className="dateRangePicker__button dflex alignCenter">
                      <Button
                        label="Cancel"
                        className="button outline"
                        action={() => {
                          field.onChange({
                            startDate: fieldValue?.startDate
                              ? fieldValue?.startDate
                              : "",
                            endDate: fieldValue?.endDate
                              ? fieldValue?.endDate
                              : "",
                          });
                          handleCancel();
                        }}
                      />

                      <Button
                        label="Apply"
                        className="button primary"
                        disabled={
                          !selectionRange ||
                          selectionRange.startDate > selectionRange.endDate ||
                          selectionRange.startDate.getFullYear() < 1970 ||
                          selectionRange.endDate.getFullYear() < 1970
                        }
                        action={() => {
                          field.onChange({
                            startDate: selectionRange.startDate,
                            endDate: selectionRange.endDate,
                          });
                          setSelectDate(false);
                        }}
                      />
                    </div>

                    {/* <div className="dateRangePicker__footer justifySpaceBetween dflex alignCenter">
                      <div className="dateRangePicker__date dflex alignCenter">
                        <div className="filterRow">
                          <div className="filterRow__col">
                            <div className="inputGroup">
                              <span className="inputGroup__input">
                                <input
                                  type="date"
                                  defaultValue={formatDate(selectionRange.startDate)}
                                  className="no-calendar"
                                  max="31/12/9999"
                                  onChange={(e) => {
                                    if(!e.target.value) return
                                    const selectedDate = new Date(
                                      e.target.valueAsNumber
                                    );
                                    console.log("selectedDate",selectedDate)
                                    if (!isNaN(selectedDate.getTime()) && selectedDate.getTime()>0) {
                                      setSelectionRange((prevRange) => ({
                                        ...prevRange,
                                        startDate: selectedDate,
                                      }));
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <span className="separate">-</span>
                          <div className=" filterRow__col">
                            <div className="inputGroup">
                              <span className="inputGroup__input">
                                <input
                                  type="date"
                                  value={formatDate(selectionRange.endDate)}
                                  className="no-calendar"
                                  max="31-12-9999"
                                  onChange={(e) => {
                                    if(!e.target.value) return
                                    const selectedDate = new Date(
                                      e.target.valueAsNumber
                                    );
                                    if (!isNaN(selectedDate.getTime()) && (selectedDate.getTime()>0)) {
                                      setSelectionRange((prevRange) => ({
                                        ...prevRange,
                                        endDate: selectedDate,
                                      }));
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dateRangePicker__button dflex alignCenter">
                        <Button
                          label="Cancel"
                          className="button outline"
                          action={() => {
                            field.onChange({
                              startDate: fieldValue?.startDate
                                ? fieldValue?.startDate
                                : "",
                              endDate: fieldValue?.endDate
                                ? fieldValue?.endDate
                                : "",
                            });
                            handleCancel();
                          }}
                        />

                        <Button
                          label="Apply"
                          className="button primary"
                          disabled={
                            !selectionRange ||
                            selectionRange.startDate > selectionRange.endDate ||
                            selectionRange.startDate.getFullYear() < 1970 ||
                            selectionRange.endDate.getFullYear() < 1970
                          }
                          action={() => {
                            field.onChange({
                              startDate: selectionRange.startDate,
                              endDate: selectionRange.endDate,
                            });
                            setSelectDate(false);
                          }}
                        />
                      </div>
                    </div>
                    {errors && errors?.[name] && (
                      <span className="error">{errors?.[name]?.message}</span>
                    )}
                    {errors && errors?.message && (
                      <span className="error">{errors?.message}</span>
                    )} */}
                  </>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerCalendar;
