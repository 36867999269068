const XTwitter = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9435 23L10.3941 15.0901L3.44691 23H0.507812L9.09013 13.2311L0.507812 1H8.05376L13.284 8.45502L19.8373 1H22.7764L14.5924 10.3165L23.4895 23H15.9435ZM19.2165 20.77H17.2378L4.71616 3.23H6.69515L11.7102 10.2532L12.5774 11.4719L19.2165 20.77Z"
                    fill="currentColor" />
            </svg>
        </>
    );
};

export default XTwitter;


