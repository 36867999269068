const FSquare = () => {
    return (
        <>
         <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
<circle cx="16.5" cy="16" r="16" fill="white" fill-opacity="0.08"/>
<circle cx="16.5" cy="16" r="15.75" stroke="url(#paint0_linear_17258_46914)" strokeOpacity="0.2" strokeWidth="0.5"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.5928 22V16.6H19.2322L19.5 14.2H17.5928V13.0311C17.5928 12.4131 17.6086 11.8 18.4721 11.8H19.3468V10.0841C19.3468 10.0583 18.5955 10 17.8354 10C16.248 10 15.254 10.9943 15.254 12.8201V14.2H13.5V16.6H15.254V22H17.5928Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_17258_46914" x1="-53.6111" y1="118.588" x2="37.5568" y2="111.258" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stop-color="white" stop-opacity="0.2"/>
</linearGradient>
</defs>
</svg>

        </>
    );
};

export default FSquare;

