import { takeLatest, put, all} from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchLeadList({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/lead`,
      data: {
        ...payload,
      },
      headers: {},
    });
    yield all([
      put(
        (payload?.index ?? 0) > 1
          ? slice.appendLeadList({
              data: response?.data
            })
          : slice.setLeadList({
              data: response?.data
            })
      ),
      put(slice.setLeadListIsLoading(false)),
    ]);
  } catch (error: any) {
       yield all([
         put(slice.setLeadListIsLoading(false)),
         put(
           addToast({
             id: new Date().getTime(),
             message: error?.response?.data?.message?error?.response?.data?.message : error?.message ?? "Something went wrong!",
             type: ToastType.error,
           })
         ),
       ]);
  }
}

function* leadSaga() {
  yield takeLatest(sagaActions.fetchLeadList, fetchLeadList);
}

export default leadSaga;
