import { useEffect } from "react";
import styles from "./Policy.module.scss";
import { scrollToSection } from "./utils";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  useEffect(() => {
    const handleAnchorClick = (event: any) => {
      const anchor = event.target.closest("a");
  
      if (anchor && anchor.getAttribute("href")) {
        const href = anchor.getAttribute("href");
        if (href.startsWith("#")) {
          event.preventDefault();
          const id = href.slice(1);
          scrollToSection(id);
        } else if (href.startsWith("http://") || href.startsWith("https://") || href.startsWith("//")) {
          return;
        }
      }
    };

    document.addEventListener("click", handleAnchorClick);

    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Privacy Policy - OttoLeads</title>
    </Helmet>
      <div className={`${styles.policy}`}>
        <div className={`${styles.policy__header}`}>
          <h3>Last updated March 25, 2024</h3>
          <h1>PRIVACY POLICY</h1>
          <p>
            Your privacy is important to us at OttoLeads. We respect your
            privacy regarding any information we may collect from you across our
            website.
          </p>
        </div>
        <div className={`${styles.policy__wrapper}`}>
          <div className={`${styles.policy__text}`}>
            <p>
              This privacy notice for OttoLeads LLC ("<b>we</b>," "<b>us</b>,"
              or "<b>our</b>"), describes how and why we might collect, store,
              use, and/or share ("<b>process</b>") your Information when you use
              our services ("<b>Services</b>"), such as when you:
            </p>
            <ul className={`${styles.policy__listNoMargin}`}>
              <li>
                Visit our website at{" "}
                <a
                  href="https://www.ottoleads.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.ottoleads.ai
                </a>
                , or any website of ours that links to this Privacy Policy
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <b>Questions or concerns?</b> Reading this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at  <a href="mailto:support@ottoleads.ai" target="_blank" rel="noreferrer">support@ottoleads.ai</a> or by post to:
              .
            </p>
            <h2>SUMMARY OF KEY POINTS</h2>
            <p>
              <b>
                <em>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our{" "}
                  <a href="#toc">table of contents</a> below to find the section
                  you are looking for.
                </em>
              </b>
            </p>
            <p>
              <b>What Personal Information do we process?</b> When you visit,
              use, or navigate our Services, we may process Personal Information
              depending on how you interact with us and the Services, the
              choices you make, and the products and features you use. Learn
              more about{" "}
              <a href="#personalinfo">Personal Information you disclose to us</a>
              .
            </p>
            <p>
              <b>Do we process any Sensitive Information?</b> We may process
              Sensitive Information when necessary with your consent or as
              otherwise permitted by applicable law. Learn more about{" "}
              <a href="#sensitiveinfo">Sensitive Information we process</a>
              .
            </p>
            <p>
              <b>Do we receive any Information from third parties?</b> We do not
              receive any Information from third parties.
            </p>
            <p>
              <b>How do we process your Information?</b> We process your
              Information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your Information for other
              purposes with your consent. We process your Information only when
              we have a valid legal reason to do so. Learn more about{" "}
              <a href="#infouse">how we process your Information</a>
              .
            </p>
            <p>
              <b>
                In what situations and with which parties do we share
                Information?
              </b>{" "}
              We may share Information in specific situations and with specific
              third parties. Learn more about{" "}
              <a href="#whoshare">
                when and with whom we share your Information
              </a>
              .
            </p>
            <p>
              <b>How do we keep your Information safe?</b> We have
              organizational and technical processes and procedures in place to
              protect your Information. However, no electronic transmission over
              the internet or information storage technology can be guaranteed
              to be 100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be
              able to defeat our security and improperly collect, access, steal,
              or modify your Information. Learn more about{" "}
              <a href="#infosafe">how we keep your Information safe</a>
              .
            </p>
            <p>
              <b>What are your rights?</b> Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your Information. Learn more about{" "}
              <a href="#uslaws"> your privacy rights</a>
              .
            </p>
            <p>
              <b>How do you exercise your rights?</b> The easiest way to
              exercise your rights is by submitting a{" "}
              <a
                href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                target="_blank"
                rel="noreferrer"
              >
                data subject access request
              </a>
              ,{" "}
              or by contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </p>
            <p>
              Want to learn more about what we do with any information we
              collect? <a href="#toc">Review the privacy notice in full</a>
              .
            </p>
            <div className={`${styles.policy__toc}`}>
              <h2 id="toc">TABLE OF CONTENTS</h2>

              <ol>
                <li data-list-text="1.">
                  <a href="#infocollect"> WHAT INFORMATION DO WE COLLECT?</a>
                </li>
                <li data-list-text="2.">
                  <a href="#infouse"> HOW DO WE PROCESS YOUR INFORMATION?</a>
                </li>
                <li data-list-text="3.">
                  <a href="#whoshare">
                    {" "}
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </li>
                <li data-list-text="4.">
                  <a href="#cookies">
                    {" "}
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </a>
                </li>
                <li data-list-text="5.">
                  <a href="#inforetain">
                    {" "}
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                  </a>
                </li>
                <li data-list-text="6.">
                  <a href="#infosafe"> HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                </li>
                <li data-list-text="7.">
                  <a href="#infominors">
                    {" "}
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </a>
                </li>
                <li data-list-text="8.">
                  <a href="#DNT">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                </li>
                <li data-list-text="9.">
                  <a href="#uslaws">
                    {" "}
                    DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </a>
                </li>
                <li data-list-text="10.">
                  <a href="#policyupdates">
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </a>
                </li>
                <li data-list-text="11.">
                  <a href="#contact">
                    {" "}
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                </li>
              </ol>
              <div className={`${styles.policy__tocInfo}`}>
                <ol>
                  <li id="infocollect" data-list-text="1.">
                    <h2>WHAT INFORMATION DO WE COLLECT?</h2>

                    <p>
                      We collect Information about you and your use of our
                      Services. “Information” includes both Personal Information
                      and Sensitive Personal Information we collect either from
                      you directly or automatically.
                    </p>

                    <h3 id="personalinfo">Personal information you disclose to us</h3>

                    <p>
                      <em>
                        <b>In Short:</b> We collect Personal Information that
                        you provide to us.
                      </em>
                    </p>

                    <p>
                      “Personal Information” is any information that identifies,
                      relates to, describes, or is reasonably capable of being
                      associated, directly or indirectly, with you. Personal
                      Information is information that you voluntarily provide to
                      us when you register on the Services, express an interest
                      in obtaining information about us or our products and
                      Services, when you participate in activities on the
                      Services, or otherwise when you contact us.
                    </p>

                    <p>
                      <b>Personal Information Provided by You.</b> The Personal
                      Information that we collect depends on the context of your
                      interactions with us and the Services, the choices you
                      make, and the products and features you use. The Personal
                      Information we collect may include the following:
                    </p>
                    <ul>
                      <li>names</li>
                      <li>phone numbers</li>
                      <li>email addresses</li>
                      <li>usernames</li>
                      <li>passwords</li>
                      <li>mailing addresses</li>
                      <li>contact preferences</li>
                      <li>contact or authentication data</li>
                      <li>billing addresses</li>
                      <li>debit/credit card numbers</li>
                      <li>Company name</li>
                      <li>Internet Protocol (IP) address</li>
                    </ul>

                    <p>
                      <b id="sensitiveinfo">Sensitive Information.</b> “Sensitive Information”
                      refers to specific types of Personal Information which is
                      not publically available. When necessary, with your
                      consent or as otherwise permitted by applicable law, we
                      may process the following categories of Sensitive
                      Information:
                    </p>
                    <ul>
                      <li>
                        Social Security Numbers or other government identifiers
                      </li>
                      <li>Payment Data as discussed below</li>
                    </ul>

                    <p>
                      <b>Payment Data.</b> “Payment Data” refers to information
                      relating to your financial information, including, but not
                      limited to, financial account, debit card, or credit card
                      number, as well as any security or access codes,
                      passwords, or other account credentials. We may collect
                      Payment Data necessary to process your payment if you make
                      purchases. All Payment Data is stored by Stripe and
                      Paypal. You may find their privacy notice link(s) here:{" "}
                      <a
                        href="https://stripe.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://stripe.com/privacy{" "}
                      </a>{" "}
                      and
                      <a
                        href="https://www.paypal.com/us/legalhub/privacy-full#personalData"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        https://www.paypal.com/us/legalhub/privacy-full#personalData
                      </a>
                      .
                    </p>

                    <p>
                      All Information that you provide to us must be true,
                      complete, and accurate, and you must notify us of any
                      changes to such Information.
                    </p>

                    <h3>Information automatically collected</h3>

                    <p>
                      <em>
                        <b>In Short:</b> Some Information — such as your
                        Internet Protocol (IP) address and/or browser and device
                        characteristics — is collected automatically when you
                        visit our Services.
                      </em>
                    </p>

                    <p>
                      We may automatically collect certain information when you
                      visit, use, or navigate the Services. This information may
                      not reveal your specific identity (like your name or
                      contact information) but may include device and usage
                      information, such as your IP address, browser and device
                      characteristics, operating system, language preferences,
                      referring URLs, device name, country, location,
                      information about how and when you use our Services, and other
                      technical information. This information is primarily
                      needed to maintain the security and operation of our
                      Services, and for our internal analytics and reporting
                      purposes.
                    </p>

                    <p>
                      Like many businesses, we also collect information through
                      cookies and similar technologies.
                    </p>

                    <p>The information we collect may include:</p>

                    <ul>
                      <li>
                        <em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic, usage, and performance
                        information our servers automatically collect when you
                        access or use our Services and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type, and settings and information about your
                        activity in the Services (such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches, and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called
                        &quot;crash dumps&quot;), and hardware settings).
                      </li>
                      <li>
                        <em>Device Data. </em>We collect device data such as
                        information about your computer, phone, tablet, or other
                        device you use to access the Services. Depending on the
                        device used, this device data may include information
                        such as your IP address (or proxy server), device and
                        application identification numbers, location, browser
                        type, hardware model, Internet service provider and/or
                        mobile carrier, operating system, and system
                        configuration information.
                      </li>
                      <li>
                        <em>Location Data.</em> We collect location data such as
                        information about your device&#39;s location, which can
                        be either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                      </li>
                    </ul>
                  </li>
                  <li id="infouse" data-list-text="2.">
                    <h2>HOW DO WE PROCESS YOUR INFORMATION?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> We process your Information to provide,
                        improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply
                        with law. We may also process your Information for other
                        purposes with your consent.
                      </em>
                    </p>
                    <p>
                      <b>
                        We process your Information for a variety of reasons,
                        depending on how you interact with our Services,
                        including:
                      </b>
                    </p>
                    <ul>
                      <li>
                        <b>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.
                        </b>{" "}
                        We may process your Information so you can create and
                        log in to your account, as well as keep your account in
                        working order. We may process your Information to
                        respond to your inquiries and solve any potential issues
                        you might have with the requested service.
                      </li>
                      <li>
                        <b>
                          To deliver and facilitate delivery of services to the
                          user.
                        </b>{" "}
                        We may process your Information to provide you with the
                        requested service. We may process your Information to
                        fulfill and manage your orders, payments, returns, and
                        exchanges made through the Services.
                      </li>
                      <li>
                        <b>To send administrative information to you.</b> We may
                        process your Information to send you details about our
                        products and services, changes to our terms and
                        policies, and other similar information.
                      </li>
                      <li>
                        <b>To request feedback.</b> We may process your
                        Information when necessary to request feedback and to
                        contact you about your use of our Services.
                      </li>
                      <li>
                        <b>
                          To send you marketing and promotional communications.
                        </b>{" "}
                        We may process your Information for our marketing
                        purposes, if this is in accordance with your marketing
                        preferences.
                      </li>
                      <li>
                        <b>To post testimonials.</b> We post testimonials on our
                        Services that may contain Information.
                      </li>
                      <li>
                        <b>To protect our Services.</b> We may process your
                        Information as part of our efforts to keep our Services
                        safe and secure, including fraud monitoring and
                        prevention.
                      </li>
                      <li>
                        <b>
                          To evaluate and improve our Services, products,
                          marketing, and your experience.
                        </b>{" "}
                        We may process your Information when we believe it is
                        necessary to identify usage trends, determine the
                        effectiveness of our promotional campaigns, and to
                        evaluate and improve our Services, products, marketing,
                        and your experience.
                      </li>
                      <li>
                        <b>To comply with our legal obligations.</b> We may
                        process your Information to comply with our legal
                        obligations, respond to legal requests, and exercise,
                        establish, or defend our legal rights.
                      </li>
                    </ul>
                  </li>
                  <li id="whoshare" data-list-text="3.">
                    <h2>
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </h2>
                    <p>
                      <em>
                        <b>In Short:</b> We may share Information in specific
                        situations described in this section and/or with the
                        following third parties.
                      </em>
                    </p>

                    <p>
                      We may need to share your Information in the following
                      situations:
                    </p>

                    <ul>
                      <li>
                        <b>Business Transfers.</b> We may share or transfer your
                        Information in connection with, or during negotiations
                        of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to
                        another company.
                      </li>
                      <li>
                        <b>When we use Google Maps Platform APIs.</b> We may
                        share your Information with certain Google Maps Platform
                        APIs (e.g., Google Maps API, Places API).
                      </li>
                    </ul>
                  </li>
                  <li id="cookies" data-list-text="4.">
                    <h2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> We may use cookies and other tracking
                        technologies to collect and store your information.
                      </em>
                    </p>

                    <p>
                      We may use cookies and similar tracking technologies (like
                      web beacons and pixels) to access or store information.
                      Specific information about how we use such technologies
                      and how you can refuse certain cookies is set out in our
                      Cookie Notice.
                    </p>

                    <p>
                      <b>Chat Bot Technology.</b> We may also use third-party
                      service providers, such as{" "}
                      <a
                        href="https://www.intercom.com/legal/privacy"
                        target="_blank"
                       
                      >
                        Intercom
                      </a>{" "}
                      to provide chat services for our clients to communicate
                      with us regarding your account, payments, and other
                      general questions you may have. As you navigate our
                      websites and use the chat systems with us, we attempt to
                      protect your privacy to the maximum extent possible.
                      However, some of the information that we receive through
                      this website and your interactions with the chat systems
                      may be tracked, such as the date you communicate with us,
                      the last day you contacted us, and other messages,
                      comments and conversations made through the chat messages.
                      The chat system collects the information that you
                      voluntarily type in the chat bot when you initiate a query
                      through the chat system and respond to a query from the
                      chat system. Additionally, when you are using our chat
                      system, it collects interactions that you have made into
                      the chat system while communicating with the chat system.
                      This data is used to understand your preferences, provide
                      personalized responses, and enhance the performance and
                      functionality of the chat bot. We may retain this
                      information for a reasonable period unless you request its
                      deletion. You can maximize the benefits of your privacy partnership with us
                      by making informed decisions about whether to share
                      personally identifiable information with us through our
                      website. Third party chat bot systems may capture, record,
                      or analyze your chat bot communications with us.
                    </p>
                    <p>
                      Under no circumstances shall OttoLeads be liable for any
                      direct, indirect, special, incidental, or consequential
                      damages arising out of or in connection with the use of
                      the chat bots on its websites. This includes, without
                      limitation, any damage, loss, or injury caused by error,
                      omission, interruption, deletion, defect, delay in
                      operation or transmission, computer virus, communication
                      line failure, theft or destruction, or unauthorized access
                      to, alteration of, or use of the chat bot.
                    </p>
                    <p>
                      While we make efforts to ensure the availability and
                      reliability of the chat bot, there may be instances where
                      it is unavailable or experiences technical issues.
                      OttoLeads shall not be held responsible for any
                      inconvenience or damages arising from such occurrences.
                    </p>
                  </li>
                  <li id="inforetain" data-list-text="5.">
                    <h2>HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> We keep your information for as long as
                        necessary to fulfill the purposes outlined in this
                        privacy notice unless otherwise required by law.
                      </em>
                    </p>

                    <p>
                      We retain your Information for as long as it is necessary
                      for the purposes set out in this privacy notice, unless a
                      longer retention period is required or permitted by law
                      (such as tax, accounting, or other legal requirements).
                    </p>

                    <p>
                      When we have no ongoing legitimate business need to
                      process your Information, we will either delete or
                      anonymize such Information. If this is not possible (for
                      example, because your Information has been stored in
                      backup archives), then we will securely store your
                      Information and isolate it from any further processing
                      until deletion is possible.
                    </p>
                  </li>
                  <li id="infosafe" data-list-text="6.">
                    <h2>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> We aim to protect your personal
                        information through a system of organizational and
                        technical security measures.
                      </em>
                    </p>
                    <p>
                      We have implemented appropriate and reasonable technical
                      and organizational security measures designed to protect
                      the security of any personal information we process.
                      However, despite our safeguards and efforts to secure your
                      information, no electronic transmission over the Internet
                      or information storage technology can be guaranteed to be
                      100% secure, so we cannot promise or guarantee that
                      hackers, cybercriminals, or other unauthorized third
                      parties will not be able to defeat our security and
                      improperly collect, access, steal, or modify your
                      information. Although we will do our best to protect your
                      personal information, transmission of personal information
                      to and from our Services is at your own risk. You should
                      only access the Services within a secure environment.
                    </p>
                  </li>
                  <li id="infominors" data-list-text="7.">
                    <h2>DO WE COLLECT INFORMATION FROM MINORS?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> We do not knowingly collect data from
                        or market to children under 18 years of age.
                      </em>
                    </p>

                    <p>
                      We do not knowingly solicit data from or market to
                      children under 18 years of age. By using the Services, you
                      represent that you are at least 18 or that you are the
                      parent or guardian of such a minor and consent to such
                      minor dependent’s use of the Services. If we learn that
                      personal information from users less than 18 years of age
                      has been collected, we will deactivate the account and
                      take reasonable measures to promptly delete such data from
                      our records. If you become aware of any data we may have
                      collected from children under age 18, please contact us at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      .
                    </p>
                  </li>
                  <li id="DNT" data-list-text="8.">
                    <h2>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                    <p>
                      Most web browsers and some mobile operating systems and
                      mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can
                      activate to signal your privacy preference not to have
                      data about your online browsing activities monitored and
                      collected. If a standard for online tracking is adopted
                      that we must follow in the future, we may inform you about
                      that practice in a revised version of this privacy notice.
                    </p>
                  </li>
                  <li id="uslaws" data-list-text="9.">
                    <h2>
                      DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </h2>

                    <p>
                      <em>
                        <b>In Short:</b> If you are a resident of California,
                        Colorado, Connecticut, Utah or Virginia, you are granted
                        specific rights regarding access to your personal
                        information.
                      </em>
                    </p>
                    <p>
                      <em>
                        We are headquartered in the United States. The Services
                        are designed to provide services in the United States
                        and are governed by the laws of the United States, and
                        the services are directed to users in the United States.
                        We make no representation that the Services are governed
                        by or operated in accordance with the laws of any other
                        foreign jurisdiction, including but not limited to, data
                        protection laws.
                      </em>
                    </p>

                    <p>
                      <b>What categories of Information do we collect?</b>
                    </p>

                    <p>
                      We have collected the following categories of Information
                      in the past twelve (12) months:
                    </p>
                    <div className="custom-table table-striped">
                      <div className="custom-table-wrapper">
                        <table>
                          <tr>
                            <td>
                              <p>Category</p>
                            </td>
                            <td>
                              <p>Examples</p>
                            </td>
                            <td>
                              <p>Collected</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>A. Identifiers</p>
                            </td>
                            <td>
                              <p>
                                Contact details, such as real name, alias,
                                postal address, telephone or mobile contact
                                number, unique personal identifier, online
                                identifier, Internet Protocol address, email
                                address, and account name
                              </p>
                            </td>
                            <td>
                              <p>YES</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p>
                                B. Personal Information as defined in the
                                California Customer Records statute
                              </p>
                            </td>
                            <td>
                              <p>
                                Name, contact information, education,
                                employment, employment history, and financial
                                information
                              </p>
                            </td>
                            <td>
                              <p>YES</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p>
                                C. Protected classification characteristics
                                under state or federal law
                              </p>
                            </td>
                            <td>
                              <p>Gender and date of birth</p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>D. Commercial information</p>
                            </td>
                            <td>
                              <p>
                                Transaction information, purchase history,
                                financial details, and payment information
                              </p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>E. Biometric information</p>
                            </td>
                            <td>
                              <p>Fingerprints and voiceprints</p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <p>
                                F. Internet or other similar network activity
                              </p>
                            </td>
                            <td>
                              <p>
                                Browsing history, search history, online
                                behavior, interest data, and interactions with
                                our and other websites, applications, systems,
                                and advertisements
                              </p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>G. Geolocation data</p>
                            </td>
                            <td>
                              <p>Device location</p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                                H. Audio, electronic, visual, thermal,
                                olfactory, or similar information
                              </p>
                            </td>
                            <td>
                              <p>
                                Images and audio, video or call recordings
                                created in connection with our business
                                activities
                              </p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>I. Professional or employment-related information</p>
                            </td>
                            <td>
                              <p>
                                Business contact details in order to provide you
                                our Services at a business level or job title,
                                work history, and professional qualifications if
                                you apply for a job with us
                              </p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>J. Education Information</p>
                            </td>
                            <td>
                              <p>Student records and directory information</p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                                K. Inferences drawn from collected personal
                                information
                              </p>
                            </td>
                            <td>
                              <p>
                                Inferences drawn from any of the collected
                                personal information listed above to create a
                                profile or summary about, for example, an
                                individual’s preferences and characteristics
                              </p>
                            </td>
                            <td>
                              <p>NO</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>L. Sensitive Information</p>
                            </td>
                            <td>
                              <p>
                                Account login information and debit or credit
                                card numbers, social security number or other
                                unique government identifier.
                              </p>
                            </td>
                            <td>
                              <p>YES</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <p>
                      We will use and retain the collected Information as needed
                      to provide the Services or for:
                    </p>

                    <ul>
                      <li>
                        Category A - 120 months after user account termination.
                      </li>
                      <li>
                        Category B - 120 months after user account termination.
                      </li>
                      <li>
                        Category L - As long as the user has an account with us
                      </li>
                    </ul>

                    <p>
                      Category L information may be used, or disclosed to a
                      service provider or contractor, for additional, specified
                      purposes. You have the right to limit the use or
                      disclosure of your Sensitive Information
                    </p>
                    <p>
                      We may also collect other Personal Information outside of
                      these categories through instances where you interact with
                      us in person, online, or by phone or mail in the context
                      of:
                    </p>
                    <ul>
                      <li>
                        Receiving help through our customer support channels;
                      </li>
                      <li>
                        Participation in customer surveys or contests; and
                      </li>
                      <li>
                        Facilitation in the delivery of our Services and to
                        respond to your inquiries.
                      </li>
                    </ul>

                    <p>
                      <b>How do we use and share your Information?</b>
                    </p>

                    <p>
                      Learn about how we use your Information in the section,{" "}
                      <a href="#infouse" target="_blank">
                        "HOW DO WE PROCESS YOUR INFORMATION?"
                      </a>
                    </p>

                    <p>
                      <b>Will your Information be shared with anyone else?</b>
                    </p>

                    <p>
                      We may disclose your Information to our service providers
                      pursuant to a written contract between us and each service
                      provider. Learn more about how we disclose Information in
                      the section,{" "}
                      <a href="#whoshare" target="_blank">
                        {" "}
                        "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                        INFORMATION?"
                      </a>
                    </p>

                    <p>
                      We may use your Information for our own business purposes,
                      such as for undertaking internal research for
                      technological development and demonstration.
                    </p>

                    <p>
                      We have not disclosed, sold, or shared any personal
                      information to third parties for a business or commercial
                      purpose in the preceding twelve (12) months. We will not
                      sell or share personal information in the future belonging
                      to website visitors, users, and other consumers.
                    </p>

                    <h3>California Residents</h3>

                    <p>
                      California Civil Code Section 1798.83, also known as the
                      &quot;Shine The Light&quot; law permits our users who are
                      California residents to request certain details about how
                      their information is shared with third parties for direct
                      marketing purposes. If you are a California resident and
                      would like to make such a request, please submit your
                      request in writing to us using the contact information
                      provided below.
                    </p>
                    <p>
                      <b>
                        <u>CCPA Privacy Notice</u>
                      </b>
                    </p>

                    <p>
                      This section applies only to California residents. Under
                      the California Consumer Privacy Act (CCPA), you have the
                      rights listed below.
                    </p>

                    <p>
                      The California Code of Regulations defines a
                      &quot;residents&quot; as:
                    </p>

                    <ul>
                      <li>
                        every individual who is in the State of California for
                        other than a temporary or transitory purpose and
                      </li>
                      <li>
                        every individual who is domiciled in the State of
                        California who is outside the State of California for a
                        temporary or transitory purpose
                      </li>
                    </ul>

                    <p>
                      All other individuals are defined as
                      &quot;non-residents.&quot;
                    </p>

                    <p>
                      If this definition of &quot;resident&quot; applies to you,
                      we must adhere to certain rights and obligations regarding
                      your personal information.
                    </p>

                    <p>
                      <b>Your rights with respect to your personal data</b>
                    </p>

                    <p>
                      <u>
                        Right to request deletion of the data — Request to
                        delete
                      </u>
                    </p>

                    <p>
                      You can ask for the deletion of your personal information.
                      If you ask us to delete your personal information, we will
                      respect your request and delete your personal information,
                      subject to certain exceptions provided by law, such as
                      (but not limited to) the exercise by another consumer of
                      his or her right to free speech, our compliance
                      requirements resulting from a legal obligation, or any
                      processing that may be required to protect against illegal
                      activities.
                    </p>

                    <p>
                      <u>Right to be informed — Request to know</u>
                    </p>

                    <p>
                      Depending on the circumstances, you have a right to know:
                    </p>

                    <ul>
                      <li>
                        whether we collect and use your personal information;
                      </li>
                      <li>
                        the categories of personal information that we collect;
                      </li>
                      <li>
                        the purposes for which the collected personal
                        information is used;
                      </li>
                      <li>
                        whether we sell or share personal information to third
                        parties;
                      </li>
                      <li>
                        the categories of personal information that we sold,
                        shared, or disclosed for a business purpose;
                      </li>
                      <li>
                        the categories of third parties to whom the personal
                        information was sold, shared, or disclosed for a
                        business purpose;
                      </li>
                      <li>
                        the business or commercial purpose for collecting,
                        selling, or sharing personal information; and
                      </li>
                      <li>
                        the specific pieces of personal information we collected
                        about you.
                      </li>
                    </ul>

                    <p>
                      In accordance with applicable law, we are not obligated to
                      provide or delete consumer information that is
                      de-identified in response to a consumer request or to
                      re-identify individual data to verify a consumer request.
                    </p>

                    <p>
                      <u>
                        Right to Non-Discrimination for the Exercise of a
                        Consumer’s Privacy Rights
                      </u>
                    </p>

                    <p>
                      We will not discriminate against you if you exercise your
                      privacy rights.
                    </p>
                    <p>
                      <u>
                        Right to Limit Use and Disclosure of Sensitive Personal
                        Information
                      </u>{" "}
                    </p>
                    <p>
                      If the business collects any of the following:
                    </p>

                    <ul>
                      <li>
                        social security information, drivers&#39; licenses,
                        state ID cards, passport numbers
                      </li>
                      <li>account login information</li>
                      <li>
                        credit card numbers, financial account information, or
                        credentials allowing access to such accounts
                      </li>
                      <li>precise geolocation</li>
                      <li>
                        racial or ethnic origin, religious or philosophical
                        beliefs, union membership
                      </li>
                      <li>
                        the contents of email and text, unless the business is
                        the intended recipient of the communication
                      </li>
                      <li>genetic data, biometric data, and health data</li>
                      <li>data concerning sexual orientation and sex life</li>
                    </ul>

                    <p>
                      you have the right to direct that business to limit its
                      use of your sensitive personal information to that use
                      which is necessary to perform the Services.
                    </p>
                    <p>
                      Once a business receives your request, they are no longer
                      allowed to use or disclose your sensitive personal
                      information for any other purpose unless you provide
                      consent for the use or disclosure of sensitive personal
                      information for additional purposes.
                    </p>

                    <p>
                      Please note that sensitive personal information that is
                      collected or processed without the purpose of inferring
                      characteristics about a consumer is not covered by this
                      right, as well as the publicly available information.
                    </p>

                    <p>
                      To exercise your right to limit use and disclosure of
                      sensitive personal information, please email{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>{" "}
                      or submit a{" "}
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        data subject access request
                      </a>
                      .
                    </p>

                    <p>
                      <u>Verification process</u>
                    </p>

                    <p>
                      Upon receiving your request, we will need to verify your
                      identity to determine you are the same person about whom
                      we have the information in our system. These verification
                      efforts require us to ask you to provide information so
                      that we can match it with information you have previously
                      provided us. For instance, depending on the type of
                      request you submit, we may ask you to provide certain
                      information so that we can match the information you
                      provide with the information we already have on file, or
                      we may contact you through a communication method (e.g.,
                      phone or email) that you have previously provided to us.
                      We may also use other verification methods as the
                      circumstances dictate.
                    </p>

                    <p>
                      We will only use personal information provided in your
                      request to verify your identity or authority to make the
                      request. To the extent possible, we will avoid requesting
                      additional information from you for the purposes of
                      verification. However, if we cannot verify your identity
                      from the information already maintained by us, we may
                      request that you provide additional information for the
                      purposes of verifying your identity and for security or
                      fraud-prevention purposes. We will delete such
                      additionally provided information as soon as we finish
                      verifying you.
                    </p>

                    <p>
                      <u>Other privacy rights</u>
                    </p>

                    <ul>
                      <li>
                        You may object to the processing of your personal
                        information.
                      </li>
                      <li>
                        You may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the information.
                      </li>
                      <li>
                        You can designate an authorized agent to make a request
                        under the CCPA on your behalf. We may deny a request
                        from an authorized agent that does not submit proof that
                        they have been validly authorized to act on your behalf
                        in accordance with the CCPA.
                      </li>
                      <li>
                        You may request to opt out from future selling or
                        sharing of your personal information to third parties.
                        Upon receiving an opt-out request, we will act upon the
                        request as soon as feasibly possible, but no later than
                        fifteen (15) days from the date of the request
                        submission.
                      </li>
                    </ul>
                    <p>
                      To exercise these rights, you can contact us by submitting
                      a{" "}
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        data subject access request
                      </a>
                      , by email at{" "}
                      <a
                        href="mailto:support@ottleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottleads.ai
                      </a>
                      , or by referring to the contact details at the bottom of
                      this document. If you have a complaint about how we handle
                      your data, we would like to hear from you.
                    </p>

                    <h3>Colorado Residents</h3>

                    <p>
                      This section applies only to Colorado residents. Under the
                      Colorado Privacy Act (CPA), you have the rights listed
                      below. However, these rights are not absolute, and in
                      certain cases, we may decline your request as permitted by
                      law.
                    </p>
                    <ul>
                      <li>
                        Right to be informed whether or not we are processing
                        your personal data
                      </li>
                      <li>Right to access your personal data</li>
                      <li>
                        Right to correct inaccuracies in your personal data
                      </li>
                      <li>Right to request deletion of your personal data</li>
                      <li>
                        Right to obtain a copy of the personal data you
                        previously shared with us
                      </li>
                      <li>
                        Right to opt out of the processing of your personal data
                        if it is used for targeted advertising, the sale of
                        personal data, or profiling in furtherance of decisions
                        that produce legal or similarly significant effects
                        (&quot;profiling&quot;)
                      </li>
                    </ul>
                    <p>
                      To submit a request to exercise these rights described
                      above, please email{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>{" "}
                      or submit a{" "}
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        data subject access request
                      </a>
                      .
                    </p>

                    <p>
                      If we decline to take action regarding your request and
                      you wish to appeal our decision, please email us at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      . Within forty-five (45) days of receipt of an appeal, we
                      will inform you in writing of any action taken or not
                      taken in response to the appeal, including a written
                      explanation of the reasons for the decisions.
                    </p>

                    <h3>Connecticut Residents</h3>
                    <p>
                      This section applies only to Connecticut residents. Under
                      the Connecticut Data Privacy Act (CTDPA), you have the
                      rights listed below. However, these rights are not
                      absolute, and in certain cases, we may decline your
                      request as permitted by law.
                    </p>
                    <ul>
                      <li>
                        Right to be informed whether or not we are processing
                        your personal data
                      </li>
                      <li>Right to access your personal data</li>
                      <li>
                        Right to correct inaccuracies in your personal data
                      </li>
                      <li>Right to request deletion of your personal data</li>
                      <li>
                        Right to obtain a copy of the personal data you
                        previously shared with us
                      </li>
                      <li>
                        Right to opt out of the processing of your personal data
                        if it is used for targeted advertising, the sale of
                        personal data, or profiling in furtherance of decisions
                        that produce legal or similarly significant effects
                        (&quot;profiling&quot;)
                      </li>
                    </ul>
                    <p>
                      To submit a request to exercise these rights described
                      above, please email{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>{" "}
                      or submit a{" "}
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        data subject access request
                      </a>
                      .
                    </p>

                    <p>
                      If we decline to take action regarding your request and
                      you wish to appeal our decision, please email us at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      . Within sixty (60) days of receipt of an appeal, we will
                      inform you in writing of any action taken or not taken in
                      response to the appeal, including a written explanation of
                      the reasons for the decisions.
                    </p>

                    <h3>Utah Residents</h3>

                    <p>
                      This section applies only to Utah residents. Under the
                      Utah Consumer Privacy Act (UCPA), you have the rights
                      listed below. However, these rights are not absolute, and
                      in certain cases, we may decline your request as permitted
                      by law.
                    </p>
                    <ul>
                      <li>
                        Right to be informed whether or not we are processing
                        your personal data
                      </li>
                      <li>Right to access your personal data</li>
                      <li>Right to request deletion of your personal data</li>
                      <li>
                        Right to obtain a copy of the personal data you
                        previously shared with us
                      </li>
                      <li>
                        Right to opt out of the processing of your personal data
                        if it is used for targeted advertising or the sale of
                        personal data
                      </li>
                    </ul>
                    <p>
                      To submit a request to exercise these rights described
                      above, please email{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>{" "}
                      or submit a{" "}
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        data subject access request
                      </a>
                      .
                    </p>
                    <h3>Virginia Residents</h3>

                    <p>
                      Under the Virginia Consumer Data Protection Act (VCDPA):
                    </p>

                    <p>
                      &quot;Consumer&quot; means a natural person who is a
                      resident of the Commonwealth acting only in an individual
                      or household context. It does not include a natural person
                      acting in a commercial or employment context.
                    </p>

                    <p>
                      &quot;Personal data&quot; means any information that is
                      linked or reasonably linkable to an identified or
                      identifiable natural person. &quot;Personal data&quot;
                      does not include de-identified data or publicly available
                      information.
                    </p>

                    <p>
                      &quot;Sale of personal data&quot; means the exchange of
                      personal data for monetary consideration.
                    </p>

                    <p>
                      If this definition of &quot;consumer&quot; applies to you,
                      we must adhere to certain rights and obligations regarding
                      your personal data.
                    </p>

                    <p>Your rights with respect to your personal data</p>

                    <ul>
                      <li>
                        Right to be informed whether or not we are processing
                        your personal data
                      </li>
                      <li>Right to access your personal data</li>
                      <li>
                        Right to correct inaccuracies in your personal data
                      </li>
                      <li>Right to request deletion of your personal data</li>
                      <li>
                        Right to obtain a copy of the personal data you
                        previously shared with us
                      </li>
                      <li>
                        Right to opt out of the processing of your personal data
                        if it is used for targeted advertising, the sale of
                        personal data, or profiling in furtherance of decisions
                        that produce legal or similarly significant effects
                        (&quot;profiling&quot;)
                      </li>
                    </ul>
                    <p>
                      <u>
                        Exercise your rights provided under the Virginia VCDPA
                      </u>
                    </p>

                    <p>
                      You may contact us by email at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>
                      {" "}or submit a
                      <a
                        href="https://app.termly.io/notify/a93485be-c88b-4769-9d97-b23b2362bae1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        data subject access request
                      </a>
                      .{" "}
                    </p>

                    <p>
                      If you are using an authorized agent to exercise your
                      rights, we may deny a request if the authorized agent does
                      not submit proof that they have been validly authorized to
                      act on your behalf.
                    </p>
                    <p>
                      <u>Verification process</u>
                    </p>

                    <p>
                      We may request that you provide additional information
                      reasonably necessary to verify you and your consumer&#39;s
                      request. If you submit the request through an authorized
                      agent, we may need to collect additional information to
                      verify your identity before processing your request.
                    </p>

                    <p>
                      Upon receiving your request, we will respond without undue
                      delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended
                      once by forty-five (45) additional days when reasonably
                      necessary. We will inform you of any such extension within
                      the initial 45-day response period, together with the
                      reason for the extension.
                    </p>

                    <p>
                      <u>Right to appeal</u>
                    </p>

                    <p>
                      If we decline to take action regarding your request, we
                      will inform you of our decision and reasoning behind it.
                      If you wish to appeal our decision, please email us at
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        support@ottoleads.ai.
                      </a>{" "}
                      Within sixty (60) days of receipt of an appeal, we will inform you in
                      writing of any action taken or not taken in response to
                      the appeal, including a written explanation of the reasons
                      for the decisions. If your appeal is denied, you may
                      contact the{" "}
                      <a
                        href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Attorney General to submit a complaint
                      </a>
                      .{" "}
                    </p>
                  </li>
                  <li id="policyupdates" data-list-text="10.">
                    <h2>DO WE MAKE UPDATES TO THIS NOTICE?</h2>

                    <p>
                      <em>
                        <b>In Short:</b> Yes, we will update this notice as
                        necessary to stay compliant with relevant laws.
                      </em>
                    </p>

                    <p>
                      We may update this privacy notice from time to time. The
                      updated version will be indicated by an updated
                      &quot;Revised&quot; date and the updated version will be
                      effective as soon as it is accessible. If we make material
                      changes to this privacy notice, we may notify you either
                      by prominently posting a notice of such changes or by
                      directly sending you a notification. We encourage you to
                      review this privacy notice frequently to be informed of
                      how we are protecting your Information.
                    </p>
                  </li>
                  <li id="contact" data-list-text="11.">
                    <h2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p>
                      If you have questions or comments about this notice, you
                      may email us at{" "}
                      <a
                        href="mailto:support@ottoleads.ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@ottoleads.ai
                      </a>{" "}
                      or contact us by post at:
                    </p>
                    <p>
                      OttoLeads LLC
                      <br />
                      30 N Gould St, Ste R, Sheridan, WY 82801
                      <br />
                      United States
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
