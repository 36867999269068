import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import Message from "../../assets/icons/message";
import { VerifyOtpProps } from "./interface";

const VerifyOtp = ({ open, setOpen, onSubmit, userDetails ,otpVerificationLoading}: VerifyOtpProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  // const resendCode = async () => {
  //   try {
  //     const res = await resendConfirmationCode(userDetails?.email || "");
  //     console.log(res);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // const handleShowToast = (message: string, type: ToastType=ToastType.success) => {
  //   dispatch(
  //     addToast({
  //       id: new Date().getTime(),
  //       message: message,
  //       type: type,
  //     })
  //   );
  // };

  // const verifyAccount = async () => {
  //   try {
  //     const data: any = await verifyViaOtp({ email: userDetails?.email, OTP });
  //     handleShowToast(data?.message);
  //     openLogin(true);
  //     handleClose();
  //   } catch (err: any) {
  //     console.error(err);
  //     handleShowToast(err.message, ToastType.error);
  //   }
  // };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog loginRegister`}
      >
        <div className={`dialogWrapper authStyle otpDialog`}>
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>

          <div className={`authStyle__Icon`}>
            <span className={`flag`}>
              <Message />
            </span>
          </div>
          <div className="authStyle__header">
            <h2>Please check your email.</h2>
            <p>
              We've sent a link to <strong>{userDetails?.email}</strong>
            </p>
          </div>
          {/* <form> */}
          {/* <div className="otp">
            <div className="otp__form">
              <OtpInput
                value={OTP}
                inputType="number"
                onChange={setOTP}
                numInputs={6}
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <p>
              Didn’t get a code?{" "}
              <span role="link" onClick={resendCode}>
                Click to resend.
              </span>
            </p>
          </div> */}

          {/* <div className="authStyle__footer dflex">
            <div className="buttonHalf">
              <Button
                label="Cancel"
                className="outline xl full"
                action={handleClose}
              ></Button>
            </div>
            <div className="buttonHalf">
              <Button
                label="Verify"
                className="primary xl full"
                disabled={!OTP||otpVerificationLoading}
                action={()=>onSubmit(OTP)}
                clicked={otpVerificationLoading}
              ></Button>
            </div>
          </div> */}
          {/* </form> */}
        </div>
      </Dialog>
    </>
  );
};

export default VerifyOtp;
