
const UserIcon = () => {
    return (
        <>
       <svg xmlns="http://www.w3.org/2000/svg" 
width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12.0018 15C8.83173 15 6.0126 16.5306 4.2178 18.906C3.83151 19.4172 3.63836 19.6728 3.64468 20.0183C3.64956 20.2852 3.81716 20.6219 4.02717 20.7867C4.29899 21 4.67567 21 5.42904 21H18.5746C19.3279 21 19.7046 21 19.9764 20.7867C20.1864 20.6219 20.354 20.2852 20.3589 20.0183C20.3652 19.6728 20.1721 19.4172 19.7858 18.906C17.991 16.5306 15.1719 15 12.0018 15Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
<path d="M12.0018 12C14.4871 12 16.5018 9.98528 16.5018 7.5C16.5018 5.01472 14.4871 3 12.0018 3C9.51652 3 7.5018 5.01472 7.5018 7.5C7.5018 9.98528 9.51652 12 12.0018 12Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default UserIcon;



