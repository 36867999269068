
const InCircle = () => {
    return (
        <>
         <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <circle cx="16.5" cy="16" r="16" fill="white" fill-opacity="0.08"/>
  <circle cx="16.5" cy="16" r="15.75" stroke="url(#paint0_linear_17258_46920)" strokeOpacity="0.2" strokeWidth="0.5"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M21.5 21H19.5V17.5005C19.5 16.5405 19.0765 16.0049 18.317 16.0049C17.4905 16.0049 17 16.563 17 17.5005V21H15V14.5H17V15.231C17 15.231 17.6275 14.1299 19.0415 14.1299C20.456 14.1299 21.5 14.9931 21.5 16.7791V21ZM12.721 13.4604C12.0465 13.4604 11.5 12.9095 11.5 12.23C11.5 11.551 12.0465 11 12.721 11C13.395 11 13.9415 11.551 13.9415 12.23C13.942 12.9095 13.395 13.4604 12.721 13.4604ZM11.5 21H14V14.5H11.5V21Z" fill="white"/>
  <defs>
    <linearGradient id="paint0_linear_17258_46920" x1="-53.6111" y1="118.588" x2="37.5568" y2="111.258" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.2"/>
    </linearGradient>
  </defs>
</svg>
        </>
    );
};

export default InCircle;

