import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HelpCircle from "../../assets/icons/helpCircle";
import SearchReflation from "../../assets/icons/searchReflation";
import SearchDropDown from "../../atoms/SearchDropDown";
import { useHelpModalContext } from "../../context/HelpModalContext";
import { useDbounce } from "../../hooks/debounce.hook";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
  MARKET_STATUS_OPTION,
} from "./constants";
import styles from "./PropertySearch.module.scss";
import {
  setClearPropertySearch,
  setSearchTerm,
  setSearchTermSelected,
  setSearchedProperties,
} from "./reducer";
import { searchAddress } from "./sagaActions";
import {
  getAddressPredictionLoading,
  getAddressPredictions,
  getClearPropertySearch,
  getSearchPropertiesResultCount,
} from "./selectors";
import { updateFilterFlags } from "./utils";
// import { PropertySearchFilterProps } from "./interface";
import { pageNameFn } from "../auth/enum";

const PropertySearchFilter = ({
  disableSearch = false,
  setAppliedAdvancedFiltersFlags,
  dispatchSearchProperty,
  resetAdvancedFilter,
  setPropertiesCount,
}: any) => {
  const { control, watch, setValue, trigger, getValues ,formState: { isValid },}: any =
    useFormContext();
  const searchForm = useForm({ defaultValues: { searchText: "" } });
  const dispatch = useDispatch();
  const location = useLocation();
  const searchRef = useRef<any>(null);
  const seletcedRef = useRef<string | null>(null);
  const [predictions, setPredictions] = useState<any[]>([]);

  const [isSearched, setIsSearched] = useState(false);
  const clearPropertySearch = useSelector((state) =>
    getClearPropertySearch(state)
  );

  const searchPropertiesResultCount = useSelector((state) =>
    getSearchPropertiesResultCount(state)
  );

  const addressPredictions = useSelector((state) =>
    getAddressPredictions(state)
  );
  const addressPredictionLoading = useSelector((state) =>
    getAddressPredictionLoading(state)
  );
  const { openWelcomeModal, modalContent, setModalContent } =
    useHelpModalContext();

  const page: any = pageNameFn(location?.pathname.split("/")[1]);

  const debounce = useDbounce(500, (data: any) => {
    if (data?.length > 2) {
      dispatch(searchAddress(data));
    } else {
      setIsSearched(false);
      setPredictions([]);
    }
  });

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (
      e?.target?.value?.length > 0 &&
      e?.target?.value?.trim()?.length === 0
    ) {
      value = "";
    }
    dispatch(setSearchTerm(value));
    searchForm.setValue("searchText", value);
  };

  const resetSearchedPropertie = () => {
    dispatch(
      setSearchedProperties({
        data: { result: [], results_count: -1, potential_count: 0 },
      })
    );
  };

  const handlePredictionClick = (value: string, label: string) => {
    searchForm.setValue("searchText", label);
    dispatch(setSearchTerm(label));
    seletcedRef.current = label;
    setValue("searchTerm", value);
    let address = addressPredictions.find((el: any) => {
      return Object.values(el).join(",") == value;
    });
    setValue("address", address);
    setValue("mapShapesPayload", null);
    dispatch(setSearchTermSelected(address));
    if(isValid){
      dispatchSearchProperty({ ...watch(), address }, 1, false);
    }
    setPredictions([]);
    setIsSearched(false);
  };

  const handleSearchClick = () => {
    searchRef?.current?.focus();
  };

  const handleFilterReset=()=>{
    resetAdvancedFilter(
      DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
      DEFAULT_FORM_VALUE
    );
  }

  const clearSearch = () => {
    resetSearchedPropertie();
    setValue("searchTerm", "");
    setValue("address", {});
    dispatch(setSearchTerm(""));
    setIsSearched(false);
    setPredictions([]);
    setPropertiesCount(-1);
    handleFilterReset()
  };

  const handleSearchIconClick = (e: any) => {
    if (predictions.length > 0) {
      dispatchSearchProperty(watch(), 1, false);
    }
  };

  useEffect(() => {
    if (seletcedRef.current !== searchForm.watch("searchText")) {
      debounce(searchForm.watch("searchText"));
      seletcedRef.current = null;
      setIsSearched(false);
    }
    if (!searchForm.watch("searchText")) {
      clearSearch();
    }
  }, [searchForm.watch("searchText")]);

  useEffect(() => {
    if (watch("searchTerm") === "") {
      searchForm.setValue("searchText", "");
    }
  }, [watch("searchTerm")]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });
  }, [location.pathname]);

  useEffect(() => {
    const subscription = watch((value: any, { name, type }: any) => {
      trigger([
        "propertyCharacteristicsFilter",
        "auctionsPreforeclousreFilter",
        "ownershipFilter",
        "taxLienFilter",
        "valuationEquityFilter",
        "mortgageInfoFilter",
        "cashBuyersFilter",
        "demographicsFilter",
      ]);
      updateFilterFlags(value, setAppliedAdvancedFiltersFlags);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const options = addressPredictions?.map((el: any) => {
      return {
        value: Object.values(el).join(","),
        label: el.title,
        searchType: el.entityType,
      };
    });
    setPredictions(options);
    setIsSearched(true);
  }, [addressPredictions]);

  useEffect(() => {
    if (clearPropertySearch) {
      handleFilterReset()
      dispatch(setClearPropertySearch(false));
    }
  }, [clearPropertySearch]);

  useEffect(() => {
    if (searchPropertiesResultCount || searchPropertiesResultCount === 0) {
      setPropertiesCount(searchPropertiesResultCount);
    } else {
      setPropertiesCount(-1);
    }
  }, [searchPropertiesResultCount]);

  return (
    <div className={`flex alignCenter ${styles.propertySearch}`}>
      <div
        className={` ${styles.propertySearch__searchBar} search-noresult-found flex alignCenter justifySpaceBetween`}
      >
        <SearchDropDown
          options={predictions}
          focused={searchForm.watch("searchText")?.length > 0 ? true : false}
          closeIcon={
            searchForm.watch("searchText") ===
            addressPredictions.find((el: any) => {
              return Object.values(el).join(",") === watch("searchTerm");
            })?.title
              ? true
              : false
          }
          placeholder={"State, Zip, County, Street, Neighborhood, or Address"}
          searchText={searchForm.watch("searchText")}
          handleInputChange={handleSearchTermChange}
          handlePredictionClick={handlePredictionClick}
          noBorder
          handleCloseClick={() => {
            searchForm.setValue("searchText", "");
            clearSearch();
          }}
          isSearchLoading={addressPredictionLoading}
          onSearchClick={handleSearchClick}
          searchRef={searchRef}
          emptySearchMetaData={{
            icon: <SearchReflation />,
            title: "No results found",
          }}
          isSearched={isSearched}
          disabled={disableSearch}
          handleSearchIconClick={handleSearchIconClick}
        ></SearchDropDown>
        <div className={` ${styles.propertySearch__filter}`}>
          <ul className="flex">
            <li
              onClick={() => {
                setValue("marketStatus", []);
              }}
            >
              <span
                role="link"
                className={` ${
                  watch("marketStatus")?.length === 0 ? styles.active : ""
                }`}
              >
                All
              </span>
            </li>
            {MARKET_STATUS_OPTION.map((option) => (
              <li
                onClick={() => {
                  setValue("marketStatus", [option.value]);
                }}
              >
                <span
                  className={`${
                    watch("marketStatus")?.length !==
                      MARKET_STATUS_OPTION.length &&
                    watch("marketStatus")?.includes(option.value)
                      ? styles.active
                      : ""
                  }`}
                  role="link"
                >
                  {option.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Tooltip onClick={() => openWelcomeModal(page)} title="">
        <span className={` ${styles.info}`} role="link">
          <HelpCircle />
        </span>
      </Tooltip>
    </div>
  );
};
export default PropertySearchFilter;
