import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../../atoms/Button";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import {
    getPropertySingleSkipStatus,
    propertySingleSkip,
} from "../propertySearchFilter/PropertyService";
import Loader from "../../atoms/Loader";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../notifications/sagaActions";
import { fetchPropertyDetailsAction } from "../../pages/property/property.sagaAction";
import styles from './SingleSkipTraceModal.module.scss';
import TargetIcon from "../../assets/icons/targetIcon";
import DbIcon from "../../assets/icons/dbIcon";
import Zap from "../../assets/icons/zap";
import InfoCircle from "../../assets/icons/infoCircle";
import { toast } from "react-toastify";
import SubscribeModal from "../subscribeModal/SubscribeModal";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { getSubscriptions, getSubscriptionsIsLoading } from "../billing/selectors";
import { PermissionListEnum } from "../auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { Tooltip } from "@mui/material";
import { getProfileInfo } from "../../components/profileMenu/selectors";

interface SingleSkipStatus {
    status: "OK" | "LIMIT_REACHED" | "SKIPPED_LAST_MONTH";
    currentSingleSkipTraceLimit: number;
    maxSingleSkipTraceLimit: number;
    lastSkippedAt?: Date;
}

interface SingleSkipTraceModalProps {
    setShow: (value: boolean) => void;
    show: boolean;
    propertyId: number | string;
}

const SingleSkipTraceModal = ({
    setShow,
    show,
    propertyId,
}: SingleSkipTraceModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<SingleSkipStatus | null>(null);
    const dispatch = useDispatch();
    const [isInitiateSkipTrace, setIsInitiateSkipTrace] =
        useState<boolean>(false);
    const [openConfirmationModal, setOpenConfirmationModal] =
        useState<boolean>(false);
    const [openUpgradeNow, setOpenUpgradeNow] = useState<boolean>(false);
    const subscriptions = useSelector((state) => getSubscriptions(state))
    const subscriptionsLoading = useSelector((state) => getSubscriptionsIsLoading(state));

    const profileInfo = useSelector((state) => getProfileInfo(state));
    const displayErrorMessage = (error: any) => {
        dispatch(
          addToast({
            id: new Date().getTime(),
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message ?? "Something went wrong!",
            type: ToastType.error,
          })
        );
      };
    
      const displaySuccessMessage = (message: string) => {
        dispatch(
          addToast({
            id: new Date().getTime(),
            message: message,
            type: ToastType.success,
          })
        );
      };

    const initiateSkipTrace = async () => {
        try {
            setIsInitiateSkipTrace(true);
            const response = await propertySingleSkip(propertyId);
            if (response.data.status === "NO_PHONE_RECORDS_FOUND") {
                toast.warn('No Phone Records Found');
            } else {
                dispatch(
                    triggerNotification({
                        message: response?.data?.message,
                        type: "success",
                    })
                );
            }
            dispatch(fetchPropertyDetailsAction({ id: propertyId, type:"referenceId" }));
            setOpenConfirmationModal(false);
            setShow(false);
        } catch (error: any) {
            dispatch(
                triggerNotification({
                    message: error?.response?.data?.message,
                    type: "error",
                })
            );
            console.log(error);
        } finally {
            setIsInitiateSkipTrace(false);
        }
    };

    const handleUpgradeNow = () =>{
        setOpenUpgradeNow(true);
        setShow(false)
    }

    const handleCloseUpgradeNow = () =>{
        setOpenUpgradeNow(false);

    }

    useEffect(() => {
        if (!show) return;

        (async () => {
            try {
                setIsLoading(true);
                const response = await getPropertySingleSkipStatus(propertyId);
                setStatus(response.data);
            } catch (error: any) {
                dispatch(
                    triggerNotification({
                        message: error?.response?.data?.message,
                        type: "error",
                    })
                );
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [dispatch, propertyId, show ]);

    return (
      <>
        <ConfirmationModal
          isOpen={show}
          setIsOpen={setShow}
          showCloseButton={false}
          rootClasses=""
          buttonComponent={
            !isLoading && (
              <Button
                clicked={isInitiateSkipTrace}
                disabled={
                  status?.status !== "OK" &&
                  status?.status !== "SKIPPED_LAST_MONTH"
                }
                action={() => {
                  if (status?.status === "SKIPPED_LAST_MONTH") {
                    setOpenConfirmationModal(true);
                  } else {
                    initiateSkipTrace();
                  }
                }}
                prefix={<TargetIcon />}
                className="primary xl full animated"
                label="Skip Trace"
              />
            )
          }
        >
          {isLoading ? (
            <div className="loader-main">
              <Loader></Loader>
            </div>
          ) : (
            <>
              <div className={`${styles.createCampaign}`}>
                <div
                  className={`headerIcon ${styles.headerIcon} ${styles.spaceBottom}`}
                >
                  <span className={`${styles.flag} ${styles.flagBorder}`}>
                    <TargetIcon />
                  </span>
                </div>

                <div className={`${styles.createCampaign__header}`}>
                  <h3>Single skip-trace</h3>
                  <p>Perform a single skip-trace on a property.</p>
                </div>

                {
                  //in place of true, sunscriptions.isTrial
                  subscriptions?.generalSubData?.isTrial &&
                  !subscriptionsLoading ? (
                    <div
                      className={`${styles.createCampaign__limit} ${styles.upgrade} flex alignCenter`}
                    >
                      <span className={`animated-svg `}>
                        <InfoCircle />
                      </span>
                      <aside>
                        <strong>
                          Skip the trial and unlock your single-skips right now!
                        </strong>
                        <p>
                          This feature is available exclusively for subscribers.
                        </p>
                      </aside>
                      <div className={`${styles.createCampaign__limitBtn}`}>
                        <Tooltip
                          title={
                            profileInfo?.userRoleName !==
                            PermissionListEnum.ORGANISATION_OWNER
                              ? ROLE_PERMISSION_STATUS.NoAccess
                              : ""
                          }
                        >
                          <div>
                            {profileInfo?.userRoleName !==
                            PermissionListEnum.ORGANISATION_OWNER ? (
                              <Button
                                label="Upgrade now"
                                className="primary "
                                prefix={<Zap />}
                               disabled
                              />
                            ) : (
                              <Button
                                label="Upgrade now"
                                className="primary "
                                prefix={<Zap />}
                                action={handleUpgradeNow}
                              />
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  ) : status?.status === "LIMIT_REACHED" ? (
                    <div
                      className={`${styles.createCampaign__limit} flex alignCenter`}
                    >
                      <span className={`animated-svg `}>
                        <InfoCircle />
                      </span>
                      <aside>
                        <strong>
                          You've used 100% of your monthly single-skips
                        </strong>
                        <p>
                          Single-skips are currently limited to{" "}
                          {status?.maxSingleSkipTraceLimit} per month. If you
                          need more for your particular use case, please contact
                          our support team.
                        </p>
                      </aside>
                    </div>
                  ) : (
                    ""
                  )
                }

                {/* {status?.status === "LIMIT_REACHED" && (
                                    <div className={`${styles.createCampaign__limit} flex alignCenter`}>
                                    <span className={`animated-svg `}>
                                      <InfoCircle />
                                    </span>
                                    <aside>
                                      <strong>
                                      You've used 100% of your monthly single-skips
                                      </strong>
                                      <p>
                                      Single-skips are currently limited to {status.maxSingleSkipTraceLimit} per month. If you need more for your particular use case, please contact our support team.
                                  
                                      </p>
                                    </aside>
                                  </div>
                          
                            )} */}
                <div className={`${styles.createCampaign__skipTrace}`}>
                  <div
                    className={`dflex justifySpaceBetween alignCenter ${styles.createCampaign__skipTraceRow}`}
                  >
                    <label>Single Skip Balance:</label>
                    <h5>
                      {status?.currentSingleSkipTraceLimit
                        ? status?.currentSingleSkipTraceLimit
                        : "-"}
                    </h5>
                  </div>

                  <p>
                    <small>
                      Pressing “Skip Trace” below will deduct one skip from your
                      monthly single-skip allotment.
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={openConfirmationModal}
          setIsOpen={setOpenConfirmationModal}
          closeButtonLabel="Cancel"
          buttonComponent={
            <Button
              clicked={isInitiateSkipTrace}
              action={initiateSkipTrace}
              className="primary xl full animated"
              label="Yes, Skip Trace"
              prefix={<Zap />}
            />
          }
        >
          <div className={`${styles.createCampaign}`}>
            <div
              className={`headerIcon ${styles.headerIcon} ${styles.orange} ${styles.spaceBottom}`}
            >
              <span className={`${styles.flag} ${styles.flagBorder}`}>
                <span>
                  <DbIcon />
                </span>
              </span>
            </div>

            <div className={`${styles.createCampaign__header}`}>
              <h3>You just recently skipped this property</h3>
              <div className={`${styles.createCampaign__reSkip}`}>
                <p>
                  Are you sure that you’d like to <strong>re-skip</strong> this
                  property?
                </p>
                <p>
                  You just skipped this property in the last <u>month</u>.
                </p>
              </div>
            </div>
          </div>
        </ConfirmationModal>
        <SubscribeModal
          displayErrorMessage={displayErrorMessage}
          displaySuccessMessage={displaySuccessMessage}
          handleClose={handleCloseUpgradeNow}
          open={openUpgradeNow}
          changeSubscription={true}
          product={subscriptions?.generalSubData}
          setShow={setShow}
        />
      </>
    );
};
export default SingleSkipTraceModal;
