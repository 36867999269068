const EmptyPlaceholder = ({icon, title, description, button1, button2, smallSize, bgGray, extraStyles, blared}:any) =>{
    return (<>
        <div className={` noData ${smallSize ? `smallSize` : ``} ${bgGray ? `bgGray` : `` } ${blared ? `blared` : `` }`} style={extraStyles}>
            <figure>
                {icon}
            </figure>
            <figcaption>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="flex justifyCenter noData__button">
            {button2} {button1} 
            </div>
            </figcaption>
        </div>
    </>)
}

export default EmptyPlaceholder;
