import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

// Initialize Google Analytics (GA4) with tracking ID
// ReactGA.initialize("G-50L224W7LQ");

Sentry.init({
  dsn: "https://0a1ca796b9f6656cae0c1b409cf99710@o4506700352126976.ingest.sentry.io/4506708133675008",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://dev.api.ottoleads.ai"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
if (process.env.REACT_APP_NODE_ENV === "PROD" || process.env.REACT_APP_NODE_ENV === "STAGING") {
  //for now added it but should be on basis of process.env.NODE_ENV
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
