import { createAction } from "@reduxjs/toolkit";

export const fetchProfileInfo = createAction(
  "FETCH_PROFILE_INFO",
  function prepare(data) {
    return { payload: data };
  }
);

export const updateProfileAction = createAction(
  "UPDATE_PROFILE",
  function prepare(data) {
    return { payload: data };
  }
);
