import { all, put, takeLatest } from "redux-saga/effects";
import { postAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
function* fetchSkipPropertyCounts({ payload }: any) {
  try {
    yield put(slice.toggleSkipPropertyCountLoading(true));
    const response: ResponseGenerator = yield postAPI({
      url: process.env.REACT_APP_BASE_URL + `/skip-property-counts`,
      headers: {},
      data:payload
    });
    yield all([
      put(slice.setSkipPropertyCount(response.data)),
      put(slice.toggleSkipPropertyCountLoading(false)),
    ]);
  } catch (error: any) {
    yield all([put(slice.toggleSkipPropertyCountLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* skipTraceSaga(){
     yield takeLatest(
       sagaActions.fetchSkipPropertyCounts,
       fetchSkipPropertyCounts
     );
} 
export default skipTraceSaga;