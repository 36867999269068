import React from 'react'
import { CampaignCreatedProps } from './interface'
import styles from './CreateCampaign.module.scss';

import ArrowRight from '../../assets/icons/arrowRight'
import Button from '../../atoms/Button'
import CheckCircle from '../../assets/icons/checkCircle';

const CampaignExpect = ({ onSubmit }: CampaignCreatedProps) => {
    return (
        <div className={`${styles.createCampaign}`}>
        
            <div className={`dflex ${styles.expect}`}>
            <div className={`headerIcon ${styles.headerIcon}`}  >
               <span> <CheckCircle /></span>
            </div>
            <div className={`${styles.expectContent}`}>
                <h3>
                    Here’s what to expect:
                </h3>
                <p>Your campaign will begin within <strong>24 hours</strong> of the scheduled date.</p>
                <p>Up to <strong>3 call attempts</strong> per number will take place over the span of <strong>3 days</strong>, at the following times (in no particular order):</p>
                <ul>
                    <li>12-3 PM EST</li>
                    <li>3-6 PM EST</li>
                    <li>6-9 PM EST</li>
                </ul>
                </div>
            </div>

                <Button className="primary xl full animated" label={'Go to campaign details'} prefix={<ArrowRight />} action={onSubmit} ></Button>
          
        </div>
    )
}

export default CampaignExpect