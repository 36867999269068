

const Master = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.9053 16.4396C13.3266 17.7704 11.2787 18.5737 9.04092 18.5737C4.04776 18.5737 0 14.5741 0 9.64036C0 4.70662 4.04776 0.707031 9.04092 0.707031C11.2787 0.707031 13.3266 1.51036 14.9053 2.84109C16.484 1.51036 18.5319 0.707031 20.7697 0.707031C25.7628 0.707031 29.8106 4.70662 29.8106 9.64036C29.8106 14.5741 25.7628 18.5737 20.7697 18.5737C18.5319 18.5737 16.484 17.7704 14.9053 16.4396Z" fill="#ED0006" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.9062 16.4396C16.8502 14.8011 18.0828 12.363 18.0828 9.64036C18.0828 6.91776 16.8502 4.47962 14.9062 2.84108C16.485 1.51036 18.5329 0.707031 20.7706 0.707031C25.7638 0.707031 29.8115 4.70662 29.8115 9.64036C29.8115 14.5741 25.7638 18.5737 20.7706 18.5737C18.5329 18.5737 16.485 17.7704 14.9062 16.4396Z" fill="#F9A000" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.907 16.4393C16.8509 14.8008 18.0835 12.3627 18.0835 9.64007C18.0835 6.91748 16.8509 4.47936 14.907 2.84082C12.9631 4.47936 11.7305 6.91748 11.7305 9.64007C11.7305 12.3627 12.9631 14.8008 14.907 16.4393Z" fill="#FF5E00" />
            </svg>
        </>
    );
};

export default Master;
