import { initialState } from "./reducer";

const getStateData = (state: any) => state["auth"] || initialState;

export const channels = (state: any) => {
  const reducer = getStateData(state);

  return reducer.promotionChannels;
};

export const getUser = (state:any) =>{
  const reducer = getStateData(state);
  return reducer.user
}

export const getAuthIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.authIsLoading;
};
export const getVerifyProcess = (state: any) => {
  const reducer = getStateData(state);
  return reducer.verifyProcess;
};

export const getRefreshPage=(state:any)=>{
   const reducer = getStateData(state);
   return reducer.refreshPage;
}