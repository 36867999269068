import { AttemptStatus } from "./enum";

export function calculateProgress(attempts:any) {
    let doneCount = 0;
    let inProgressCount = 0;

    attempts.forEach((attempt:any) => {
        if (attempt.status ===AttemptStatus.DONE) {
            doneCount++;
        } else if (attempt.status === AttemptStatus.IN_PROGRESS) {
            inProgressCount++;
        }
    });

    if (doneCount >= 3) {
        return 100;
    } else if (doneCount === 2) {
        return inProgressCount > 0 ? 82.5 : 66; 
    } else if (doneCount === 1) {
        return inProgressCount > 0 ? 49.5 : 33; 
    } else {
        return inProgressCount > 0 ? 16.5 : 0; 
    }
}