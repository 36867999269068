import { all, put, takeLatest } from "redux-saga/effects";
import { getAPI } from "../../apis/apis";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* searchAddress({ payload }: any) {
  try {
    yield put(slice.setserachLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/address/autocomplete`,
      data: {
        searchTerm: payload?.search?.trim(),
        suggestionTypes: ["address"],
      },
      headers: {},
    });
    yield put(slice.setSearchList(response?.data?.results));
    yield put(slice.setserachLoading(false));
  } catch (error: any) {
    yield all([
      put(slice.setserachLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message:
            error?.error?.message || error?.message || "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* createLeadSaga() {
  yield takeLatest(sagaActions.fetchAddressList, searchAddress);
}

export default createLeadSaga;
