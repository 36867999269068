const FileHeart = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M40 20V13.6C40 10.2397 40 8.55953 39.346 7.27606C38.7708 6.14708 37.8529 5.2292 36.7239 4.65396C35.4405 4 33.7603 4 30.4 4H17.6C14.2397 4 12.5595 4 11.2761 4.65396C10.1471 5.2292 9.2292 6.14708 8.65396 7.27606C8 8.55953 8 10.2397 8 13.6V34.4C8 37.7603 8 39.4405 8.65396 40.7239C9.2292 41.8529 10.1471 42.7708 11.2761 43.346C12.5595 44 14.2397 44 17.6 44H24M25 22H16M18 30H16M32 14H16M33.9945 29.6612C32.395 27.8432 29.7278 27.3542 27.7238 29.0189C25.7197 30.6836 25.4376 33.467 27.0114 35.4359C28.5851 37.4048 33.9945 42 33.9945 42C33.9945 42 39.4039 37.4048 40.9777 35.4359C42.5515 33.467 42.3038 30.6661 40.2653 29.0189C38.2268 27.3717 35.594 27.8432 33.9945 29.6612Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default FileHeart;


