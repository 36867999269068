import { useFormContext } from "react-hook-form";
import { QUICK_FILTERS_LIST } from "../constants";

const QuickFilter = () => {
  const { watch, setValue } = useFormContext();
  const handleQuickFilterClick = (value: string) => {
    if (!watch("quickFilters")) {
      setValue("quickFilters", [value]);
      return;
    }
    const selectedFilters: string[] = watch("quickFilters");
    if (watch("quickFilters")?.includes(value)) {
      const index = selectedFilters.findIndex((el) => el === value);
      selectedFilters.splice(index, 1);
      setValue("quickFilters", [...selectedFilters]);
    } else {
      setValue("quickFilters", [...selectedFilters, value]);
    }
  };

  return (
    <div className="quick-filters">
      {QUICK_FILTERS_LIST.map((quickfilter) => (
        <>
          <h2>{quickfilter.categoryName}</h2>
          <div className="dflex gap-8">
          {quickfilter.filters.map((filter) => (
            <span
              onClick={() => handleQuickFilterClick(filter.value)}
              role="link"
              className={`quick-filter ${
                watch("quickFilters")?.includes(filter.value)
                  ? "active-pill"
                  : ``
              }`}
            >
              {filter.label}
            </span>
          ))}
        </div>
        </>
      ))}
    </div>
  );
};
export default QuickFilter;
