const Alert = () => {
    return (
        <>
            <svg
                width='56'
                height='56'
                viewBox='0 0 56 56'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='4'
                    y='4'
                    width='48'
                    height='48'
                    rx='24'
                    fill='#FEE4E2'
                />
                <rect
                    x='4'
                    y='4'
                    width='48'
                    height='48'
                    rx='24'
                    stroke='#FEF3F2'
                    strokeWidth='8'
                />
                <path
                    d='M27.9978 25.0002V29.0002M27.9978 33.0002H28.0078M26.6131 19.892L18.3882 34.0986C17.932 34.8866 17.7039 35.2806 17.7376 35.6039C17.767 35.886 17.9148 36.1423 18.1442 36.309C18.4071 36.5002 18.8624 36.5002 19.7729 36.5002H36.2227C37.1332 36.5002 37.5885 36.5002 37.8514 36.309C38.0808 36.1423 38.2286 35.886 38.258 35.6039C38.2917 35.2806 38.0636 34.8866 37.6074 34.0986L29.3825 19.892C28.9279 19.1068 28.7006 18.7142 28.4041 18.5824C28.1454 18.4673 27.8502 18.4673 27.5915 18.5824C27.295 18.7142 27.0677 19.1068 26.6131 19.892Z'
                    stroke='#D92D20'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
};

export default Alert;
