
const CallIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M12.4874 14.2428C13.736 16.4384 15.5616 18.264 17.7572 19.5125L18.9365 17.8615C19.3287 17.3127 20.0688 17.1431 20.6608 17.4664C22.5365 18.4911 24.6095 19.1137 26.7719 19.2849C27.4653 19.3399 28 19.9185 28 20.6141V26.5645C28 27.2481 27.4829 27.8209 26.8029 27.8908C26.0964 27.9635 25.3836 28 24.6667 28C13.2528 28 4 18.7472 4 7.33333C4 6.61636 4.03656 5.90361 4.10919 5.19701C4.17907 4.517 4.75192 4 5.43553 4H11.3859C12.0815 4 12.6602 4.53472 12.7151 5.22812C12.8862 7.39051 13.5089 9.46352 14.5336 11.3393C14.8569 11.9312 14.6873 12.6714 14.1385 13.0634L12.4874 14.2428ZM9.12567 13.3669L11.6589 11.5575C10.9406 10.0069 10.4484 8.36244 10.1964 6.66667H6.67876C6.67071 6.88843 6.66667 7.11067 6.66667 7.33333C6.66667 17.2744 14.7256 25.3333 24.6667 25.3333C24.8893 25.3333 25.1116 25.3293 25.3333 25.3212V21.8036C23.6376 21.5516 21.9932 21.0593 20.4425 20.3411L18.6331 22.8744C17.9011 22.59 17.1941 22.2553 16.5165 21.8748L16.4391 21.8307C13.8263 20.3449 11.6551 18.1737 10.1693 15.5609L10.1253 15.4835C9.74465 14.8059 9.41005 14.0989 9.12567 13.3669Z" fill="#9B84F7"/>
</svg>
        </>
    );
};

export default CallIcon;



