import { useDispatch } from "react-redux";
import Copy from "../../assets/icons/copy";
import EmailOpen from "../../assets/icons/emailOpen";
import Facebook from "../../assets/icons/facebook";
import LinkedIn from "../../assets/icons/linkedIn";
import Reddit from "../../assets/icons/reddit";
import XTwitter from "../../assets/icons/xTwitter";
import LogoIcon from "../../components/LogoIcon";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

const SocialShare = ({}: any) => {
  const dispatch = useDispatch();
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText("www.ottoleads.ai");
    handleShowToast("Copied");
  };

  let socialShareMessage =
    "Check out #OttoLeads, a conversational AI bot that handles ALL your real estate outreach using AI! 🤯 OttoLeads.ai";

  return (
    <>
      <div className="authStyle__logo logo-left flex justifyStart">
        <LogoIcon />
      </div>
      <div className="authStyle__header">
        <h2>Share OttoLeads!</h2>
      </div>
      <ul className="dflex alignCenter authStyle__social">
        <li>
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              socialShareMessage
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <XTwitter />
          </a>
        </li>
        <li>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://ottoleads.ai/`}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn />
          </a>
        </li>
        <li>
          <a
            href={`https://www.facebook.com/sharer.php?u=https://ottoleads.ai/`}
            target="_blank"
            rel="noreferrer"
          >
            <Facebook />
          </a>
        </li>
        <li>
          <a
            href={`https://reddit.com/submit?url=${encodeURIComponent(
              socialShareMessage
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <Reddit />
          </a>
        </li>
        {/* <li>
          <a
            href={"https://www.instagram.com/sharer.php?u=www.ottoleads.ai"}
            target="_blank"
          >
            <Instagram />
          </a>
        </li>
        <li>
          <a
            href={
              "https://www.pinterest.com/pin/create/button?url=www.ottoleads.ai"
            }
            target="_blank"
          >
            <PinTerEst />
          </a>
        </li> */}
        <li>
          <a
            href={`mailto:?subject=Check out OttoLeads!&body=Check out OttoLeads, an all-in-one real estate AI platform. Go to www.OttoLeads.ai and take a look!`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <EmailOpen />
          </a>
        </li>
      </ul>
      <div className="authStyle__copy">
        <input
          type="text"
          disabled
          value="www.ottoleads.ai"
          placeholder="www.ottoleads.ai"
        />
        <span role="link" onClick={copyToClipboard}>
          <Copy /> Copy{" "}
        </span>
      </div>
    </>
  );
};

export default SocialShare;
