import styles from "./AnalyticCards.module.scss";
import ArrowUp from "../../assets/icons/arrowUp";
import Pill from "../../atoms/Pill";
import Circle from "../../assets/icons/circle";
import { AnalyticCardsProps } from "./interface";
import Refresh from "../../assets/icons/refresh";
import RefreshSmall from "../../assets/icons/refereshSmall";

const AnalyticCards = ({
  title,
  value,
  status,
  statusToday,
  statusValue,
  showStatusKey,
}: AnalyticCardsProps) => {
  return (
    <>
      <div className={`${styles.card}`}>
        <p>{title}</p>
        <div
          className={`dflex justifySpaceBetween alignCenter ${styles.card__info}`}
        >
          <h2 data-testid="CampaignCard">{value}</h2>
          {Boolean(status) && showStatusKey && (
            <Pill
              infix={statusValue ? <RefreshSmall />: "" || statusToday ? <ArrowUp /> : <Circle />}
              label={status}
              className={statusValue ?  `reset` : ``  || statusToday ? `calling` : ``}
            />
          )}
         
        </div>
      </div>
    </>
  );
};

export default AnalyticCards;
