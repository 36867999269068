import React, { useCallback, useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import PropTypes from "prop-types";
import styles from "./WaveForm.module.scss";
import PlayIcon from "../../../../assets/icons/playIcon";
import PauseIcon from "../../../../assets/icons/pauseIcon";
import { WaveformProps } from "../../HomeInterface";

const Waveform = ({
  audio,
  infoTitle,
  infoDesc,
  playBtnText,
  waveformId,
  playing,
  setPlaying,
  handleAudioFn,
  waveformRef,
  audioId
}: WaveformProps) => {
  const [currentTime, setCurrentTime] = useState<number>(0);
const[showInfo,setShowInfo]=useState(false)
  const handlePlay = () => {
    if (playing) {
      waveformRef.current.pause();
    }
    setPlaying(!playing);
    handleAudioFn(waveformId);
    if (!playing) {
      waveformRef.current.seekTo(0);
      waveformRef.current.play();
    } 
  };

  const formatTime = useCallback((timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, []);

  useEffect(() => {
    const track = document.querySelector(`#${audioId}`);

    waveformRef.current = WaveSurfer.create({
      barWidth: 2,
      barRadius: 2,
      barGap: 1,
      // barMinHeight: 1,
      cursorWidth: 1,
      container: `#${waveformId}`,
      backend: "WebAudio",
      height: 80,
      progressColor: "#fff",

      // responsive: true,
      waveColor: "#868686",
      cursorColor: "transparent",
    });

    waveformRef.current.on("audioprocess", () => {
      setCurrentTime(waveformRef.current.getCurrentTime());
    });

    waveformRef.current.on("error", (err: any) => {
      console.error("Waveform error:", err);
    });

    waveformRef.current.load(track);
    return () => {
      waveformRef.current.destroy();
    };
  }, [audio, waveformId, waveformRef]);

  return (
    <div className={`${styles.toggleMain}`}  >
      <div
        className={`flex alignCenter ${styles.WaveformContainer} ${
          playing && styles.playOn
        }`}
        onClick={handlePlay}
        onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)}
      >
        <button className={`${styles.playButton}`}>
          <>{!playing ? <PlayIcon /> : <PauseIcon />}</>
        </button>
        <div className={`${styles.wave}`}>
          {!playing && (
            <figcaption>
              <p>{playBtnText}</p>
            </figcaption>
          )}
          <div className={`${styles.waveLength}`}  >
            <div
              id={waveformId}
              className={`${playing && styles.Wave} ${
                playing ? "" : styles.hidden
              }`}
             
            />
            <audio
              id={audioId ?? "track"}
              src={audio ?? null}
              onError={(e) => console.error("Audio error:", e)}
            />
          </div>
        </div>
        <div className={playing ? styles.playTime : styles.none}>
          {formatTime(currentTime)}
        </div>
      </div>
      {(playing || showInfo)  && (
        <div className={` ${styles.toggleCardInfo}`}>
          <h5>{infoTitle}</h5>
          <p>{infoDesc}</p>
        </div>
      )}
    </div>
  );
};

const MemoizedWaveform = React.memo(Waveform);

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
  infoTitle: PropTypes.string.isRequired,
  infoDesc: PropTypes.string.isRequired,
  playBtnText: PropTypes.string.isRequired,
  keyIndex: PropTypes.number,
  audioId:PropTypes.string
};

export default MemoizedWaveform;
