import React from "react";
import InputMask from "react-input-mask";

const MaskedInput = ({register,name,hint, onChange , errors, label, placeholder ,mask="999-999-9999",maskChar=undefined,disabled=false}: any) => {
  return (
    <>
    <div className="inputGroup inputPhone">
    <label className="label">{label}</label>
    <span className="inputGroup__input">
      <InputMask
        mask={mask}
        {...register(name)}
        errors={errors}
        maskChar={maskChar}
        className={errors?.[name] ? `inputError` : ``}
        placeholder={placeholder}
        disabled={disabled}
      ></InputMask>
      </span>
       {errors?.[name] && <span className="error"> {errors?.[name]?.message} </span>}
       {hint && <div className="hint">{hint}</div>}
       </div>

    </>
  );
};

export default MaskedInput;
