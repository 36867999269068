const Rocket = ({color}:{color?:any}) => {
    return (
      <>
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" 
fill={color ? color : `none`}
>
<path d="M9.9987 12.5L7.4987 10M9.9987 12.5C11.1627 12.0573 12.2794 11.499 13.332 10.8334M9.9987 12.5V16.6667C9.9987 16.6667 12.5237 16.2084 13.332 15C14.232 13.65 13.332 10.8334 13.332 10.8334M7.4987 10C7.94215 8.84957 8.50053 7.74676 9.16536 6.70838C10.1363 5.15587 11.4884 3.87758 13.0929 2.99512C14.6973 2.11266 16.5009 1.65535 18.332 1.66671C18.332 3.93338 17.682 7.91671 13.332 10.8334M7.4987 10H3.33203C3.33203 10 3.79036 7.47504 4.9987 6.66671C6.3487 5.76671 9.16536 6.66671 9.16536 6.66671M3.7487 13.75C2.4987 14.8 2.08203 17.9167 2.08203 17.9167C2.08203 17.9167 5.1987 17.5 6.2487 16.25C6.84036 15.55 6.83203 14.475 6.1737 13.825C5.84979 13.5159 5.42311 13.3372 4.97555 13.3234C4.528 13.3096 4.09109 13.4615 3.7487 13.75Z" 
stroke="currentColor" 
strokeWidth="1.66667" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
      </>
    );
  };
  
  export default Rocket;
  