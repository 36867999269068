import axios from "axios";
import { getAPI } from "./apis";

export const createUser = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/users`, payload, {
        headers: {},
      })
      .then((response) => {
        resolve({ success: true, data: response.data });
      })
      .catch((err) => {
        console.error(err);
        reject({ success: false, message: err.response.data.message });
      });
  });
};

export const fetchPromotionChannels = async () => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + `/users/promotion-channel`, {
        headers: {},
      })
      .then((response) => {
        resolve({ success: true, data: response.data });
      })
      .catch((err) => {
        console.error(err);
        reject({ success: false, message: err.response.data.message });
      });
  });
};
export const checkUserInDb = (email: string) => {
  return getAPI({ url: `${process.env.REACT_APP_BASE_URL}/users/is-verify/${email}` });
};
