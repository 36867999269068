

const CurrencyDollar = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5 13.3337C5 15.1746 6.49238 16.667 8.33333 16.667H11.6667C13.5076 16.667 15 15.1746 15 13.3337C15 11.4927 13.5076 10.0003 11.6667 10.0003H8.33333C6.49238 10.0003 5 8.50794 5 6.66699C5 4.82604 6.49238 3.33366 8.33333 3.33366H11.6667C13.5076 3.33366 15 4.82604 15 6.66699M10 1.66699V18.3337"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default CurrencyDollar;


