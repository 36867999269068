import { postAPI } from "../../apis/apis";

export const createCampaignFn = (payload : Record<string,any>) =>{
    return postAPI({
        url: process.env.REACT_APP_BASE_URL + `/campaign`,
        headers: {},
        data: payload,
      });
}

export const fetchInsufficientCredits = (payload : any) =>{
  return postAPI({
      url: process.env.REACT_APP_BASE_URL + `/campaign/additional-credits-amount-details`,
      headers: {},
      data: {listIds:payload},
    });
}