import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";
import DropDownIcon from "../../../assets/icons/dropDownIcon";
import powerAi from "../../../assets/images/power-ai.svg";
import powerAiMobile from "../../../assets/images/power-aiMobile.svg";
import Button from "../../../atoms/Button";
import EarlyAccessSignup from "../../../organisms/earlyAccess/EarlyAccessSignup";
import { homeFaqSec } from "../HomeConstants";
import styles from "./HomeFAQ.module.scss";
import Register from "../../../organisms/auth/Register";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HomeFAQ() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openEarlyAccessModal, setOpenEarlyAccessModal] =
    useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  return (
    <div className={` ${styles.main}`}>
      <div className={` ${styles.faq}`}>
        <div className={` ${styles.faq__inner}`}>
          <h2
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-anchor-placement="bottom-bottom"
          >
            Frequently Asked Questions
          </h2>

          <div
            className={` ${styles.tabs}`}
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {homeFaqSec.questions.map((item, index) => (
                <Tab label={item.question} {...a11yProps(index)} />
              ))}
            </Tabs>
            <div className={` ${styles.tabsContent}`}>
              {homeFaqSec.questions.map((item, index) => (
                <TabPanel value={value} index={index}>
                  <h3>{item.question}</h3>
                  {item.answer}
                </TabPanel>
              ))}
            </div>
          </div>

          <div className={` ${styles.accordion}`}>
            {homeFaqSec.questions.map((item, index) => (
              <Accordion defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<DropDownIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails>
                  <h3>{item.question}</h3>
                  {item.answer}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <div className={` ${styles.powered}`}>
        <figure
          className={` ${styles.powered__img}`}
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <img src={powerAi} alt="powerAi" className={` ${styles.desktop}`} />
          <img
            src={powerAiMobile}
            alt="powerAi"
            className={` ${styles.mobile}`}
          />
        </figure>

        <div className={` ${styles.powered__inner}`}>
          <h2 data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            Powered by AI
          </h2>
          <p data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            OttoLeads is AI-driven lead generation at its finest. Reach more motivated sellers, make smarter investment decisions, and manage your entire real estate business effortlessly with our all-in-one platform.
          </p>
          <div
            className="flex justifyCenter"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <Button
              className="gradient gradientBlack"
              label="Start your free trial"
              action={() => setOpenEarlyAccessModal(true)}
            />
          </div>
        </div>
      </div>
      {openEarlyAccessModal ? (
        // <EarlyAccessSignup
        //   open={openEarlyAccessModal}
        //   setOpen={setOpenEarlyAccessModal}
        // ></EarlyAccessSignup>
        <Register
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  );
}
