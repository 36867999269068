import { takeLatest, put, call, select } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI, putAPI } from "../../apis/apis";
import { notificationDataResponse } from "./selectors";
import { toast } from "react-toastify";
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* triggerNotification({ payload }: any) {
  yield put(slice.updateNotifications({ payload }));
}

function* notificationData({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/notification?index=${payload?.index || "1"}&size=20`,
      headers: {},
    });
    const notifyData: ResponseGenerator = yield select(
      notificationDataResponse
    );
    if ((notifyData as any)?.count && payload?.index > 1) {
      yield put(
        slice.setNotificationData({
          ...notifyData,
          result: (notifyData as any)?.result?.concat(response?.data?.result),
        })
      );
    } else {
      yield put(slice.setNotificationData(response?.data));
    }
  } catch (error) {
    console.log("error=", error);
  }
}

function* setNotificationsFromWS({ payload }: any) {
  try {
    const notifyData: ResponseGenerator = yield select(
      notificationDataResponse
    );
    const newResult = [payload,...(notifyData as any)?.result];

    yield put(
      slice.setNotificationData({
        count: (notifyData as any).count + 1,
        unread: (notifyData as any).unread + 1,
        result: newResult,
      })
    );
    toast.info(payload.message.replace(/(<([^>]+)>)/gi, ""));
  } catch (error) {
    console.log("error=", error);
    toast.error("Something went wrong");
  }
}

function* readNotification({ payload }: any) {
  try {
    yield putAPI({
      url: process.env.REACT_APP_BASE_URL + `/notification/read/${payload}`,
      data: payload,
      headers: {},
    });
    const value = {
      index: 1
    };

    yield call(notificationData, { payload: value });
  } catch (error) {
    console.log("error=", error);
  }
}

function* readAllNotification() {
  try {
    yield putAPI({
      url: process.env.REACT_APP_BASE_URL + `/notification/read-all`,
      headers: {},
    });
    const value = {
      index: 1
    };
    yield call(notificationData, { payload: value });
  } catch (error) {
    console.log("error=", error);
  }
}

function* notificationSaga() {
  yield takeLatest(sagaActions.triggerNotification, triggerNotification);
  yield takeLatest(sagaActions.notificationAction, notificationData);
  yield takeLatest(sagaActions.readNotificationAction, readNotification);
  yield takeLatest(sagaActions.readAllNotificationAction, readAllNotification);
  yield takeLatest(sagaActions.setNotificationAction, setNotificationsFromWS);
}

export default notificationSaga;
