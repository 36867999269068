const Sun = ({ color }: { color?: any }) => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clipPath="url(#clip0_21499_47228)">
<path d="M10.0013 1.66675V3.33341M10.0013 16.6667V18.3334M3.33464 10.0001H1.66797M5.26307 5.26184L4.08455 4.08333M14.7395 5.26184L15.9181 4.08333M5.26307 14.7417L4.08455 15.9203M14.7395 14.7417L15.9181 15.9203M18.3346 10.0001H16.668M14.168 10.0001C14.168 12.3013 12.3025 14.1667 10.0013 14.1667C7.70012 14.1667 5.83464 12.3013 5.83464 10.0001C5.83464 7.6989 7.70012 5.83341 10.0013 5.83341C12.3025 5.83341 14.168 7.6989 14.168 10.0001Z" 
 stroke={color ? color : 'currentColor'}  
strokeWidth="1.66667" 
strokeLinecap="round" 
strokeLinejoin="round"
/>
</g>
<defs>
<clipPath id="clip0_21499_47228">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
</>
    );
};

export default Sun;