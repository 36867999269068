import { addInterestOptions, symbolOptions } from "../../columnsData";

export const DEFAULT_CREATIVE_CALCULATOR_DATA = {
  totalFigurePurchasePrice: "0",
  downPayment:  "0",
  downPaymentSymbol: symbolOptions[1].value,
  closingCostsSymbol: symbolOptions[1].value,
  closingCosts:"0",
  agentFee: "0",
  agentFeeSymbol:symbolOptions[1].value,
  homeInspection:"0",
  Attorney: "0",
  balloonTerm: "0",
  addInterest:addInterestOptions[0].value,
  interestRate: "0",
  amortization: "0",
  mortgageBalance:"0",
  mortgagePayment:"0",
  mortgagInterestRate:"0",
  DesiredProfit:"0",
  RentalIncome:"0",
  propertyTaxesEscrowedIntoMortgage:addInterestOptions[0].value,
  propertyTaxes:"0",
  InsuranceEscrowedIntoMortgage:addInterestOptions[0].value,
  Insurance:"0",
  servicingCompany:"0",
  Utilities:"0",
  management:"0",
  capitalExpenditures:"0",
  capitalExpendituresSymbol:symbolOptions[1].value,
  vacany:"0",
  estimatedAppreciationUpToBalloonEnd:"0",
  howManyUnits:"0",
  record:undefined
};

