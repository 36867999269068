import * as yup from "yup";
export const creativeFinanceSchema = yup.object({
  record:yup.string(),
  totalFigurePurchasePrice: yup.string().required("Total purchase price is required."),
  downPayment: yup.string().required("Down payment is required."),
  downPaymentSymbol:yup.string(),
  closingCostsSymbol:yup.string(),
  closingCosts: yup.string().required("Closing costs is required."),
  agentFee: yup.string().required("Agent fee is required."),
  agentFeeSymbol:yup.string(),
  homeInspection: yup.string().required("Home inspection is required."),
  Attorney: yup.string().required("Attorney is required."),
  balloonTerm: yup.string().required("Balloon term is required."),
  addInterest: yup.string(),
  interestRate: yup.string().required("Interest rate is required.").test(
    "min-max",
    "Interest Rate cannot be greater than 100",
    function (value) {
      return +(value??0) <= 100;
    }
  ).test(
    "min-max",
    "Interest Rate cannot be less than 0",
    function (value) {
      return +(value??0) >= 0;
    }
  ),
  amortization: yup.string().required("Amortization is required.").test(
    "min-max",
    "Amortization cannot be greater than 10000",
    function (value) {
      return +(value??0) <=10000;
    }
  ),
  mortgageBalance:yup.string().required("Mortgage balance is required."),
  mortgagePayment:yup.string().required("Mortgage payment is required."),
  mortgagInterestRate:yup.string().required("Interest rate is required."),
  DesiredProfit:yup.string().required("Desired profit is required."),
  RentalIncome:yup.string().required("Rental income is required."),
  propertyTaxesEscrowedIntoMortgage:yup.string(),
  propertyTaxes:yup.string().required("Property taxes is required."),
  InsuranceEscrowedIntoMortgage:yup.string(),
  Insurance:yup.string().required("Insurance is required."),
  servicingCompany:yup.string().required("Servicing company is required."),
  Utilities:yup.string().required("Utilities is required."),
  management:yup.string().required("Management is required."),
  capitalExpenditures:yup.string(),
  capitalExpendituresSymbol:yup.string(),
  vacany:yup.string().required("Vacancy is required."),
  estimatedAppreciationUpToBalloonEnd:yup.string().required("Estimated appreciation up to balloon end is required."),
  howManyUnits:yup.string().required("Units are required."),

});
