
import styles from "./PriceCard.module.scss";

const PriceCardFooterText = ({ children }: React.PropsWithChildren<{}> ) => {
    return (
        <>
            <div className={` ${styles.priceCardFooterText}`}>
                {children}
            </div>

        </>

    );
};

export default PriceCardFooterText;