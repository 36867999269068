import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import MaskedInput from "../../atoms/MaskedInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { addContactSchema } from "./validation";
import styles from "./AddContactModal.module.scss";
import User from "../../assets/icons/user";
import UserIcon from "../../assets/icons/userIcon";
import Call from "../../assets/icons/call";
import Message from "../../assets/icons/message";
import CallIcon from "../../assets/icons/callIcon";
import Phone from "../../assets/icons/phone";
import {
  addPropertyEmail,
  addPropertyPhone,
} from "../propertySearchFilter/PropertyService";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDetails } from "../../pages/property/property.selector";
import { setPropertyDetails } from "../../pages/property/property.reducer";

const AddContactModal = ({ setShow, show }: any) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(addContactSchema),
  });

  const params = useParams();
  const dispatch= useDispatch();
  const [loading, setLoading] = useState(false);
  const [tabName, setTabName] = useState<string>("");
  const propertyDetails = useSelector((state) => getPropertyDetails(state));

  const onSubmit = async (data: any) => {
    if (tabName === "phone") {
      try {
        setLoading(true);
       const response= await addPropertyPhone({ propertyId: propertyDetails?.propertyId, number: data?.phone });
        const updatedPropertyDetails = {
          ...propertyDetails,
          phones: response?.data ? [response.data, ...(propertyDetails?.phones || [])] : propertyDetails.phones,
      };
      dispatch(setPropertyDetails(updatedPropertyDetails));
        setLoading(false);
        setShow(false);
      } catch (err) {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
       const response= await addPropertyEmail({ propertyId: propertyDetails?.propertyId, email: data?.email });
        const updatedPropertyDetails = {
          ...propertyDetails,
          emails:  [...(Array.isArray(response?.data) ? response?.data : [response?.data]), ...(propertyDetails?.emails || [])]
        };
       dispatch(setPropertyDetails( updatedPropertyDetails));
        setLoading(false);
        setShow(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!show) {
      reset();
      setTabName("");
    }
  }, [show]);

  useEffect(() => {
    reset();
  }, [tabName, reset]);

  return (
    <>
      <ConfirmationModal
        isOpen={show}
        setIsOpen={setShow}
        showCloseButton={false}
        rootClasses="contactInfoModal"
        buttonComponent={
          <Button
            action={handleSubmit(onSubmit)}
            className="primary xl full animated"
            label="Add Contact Info"
            disabled={tabName === "" || loading}
            clicked={loading}
          />
        }
      >
        <>
          <div className={` ${styles.contactInfo}`}>
            <div
              className={`headerIcon ${styles.headerIcon} ${styles.spaceBottom}`}
            >
              <span className={`${styles.flag} ${styles.flagBorder}`}>
                <UserIcon />
              </span>
            </div>
            <div className={` ${styles.contactInfo__header}`}>
              <h3>Add Contact Info</h3>
              <p>Let's add an email or phone number to your lead.</p>
            </div>
            <div className={` ${styles.contactInfo__tab}`}>
              <p>What type of info would you like to add?*</p>
              <ul>
                <li>
                  <span
                    onClick={() => setTabName("phone")}
                    className={` ${tabName === "phone" && styles.active}  ${
                      loading && styles.disabled
                    }`}
                    role="link"
                  >
                    <Phone />
                    Phone
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setTabName("email")}
                    className={` ${tabName === "email" && styles.active} ${
                      loading && styles.disabled
                    }`}
                    role="link"
                  >
                    <Message />
                    Email
                  </span>
                </li>
              </ul>
            </div>

            <div className={` ${styles.contactInfo__form}`}>
              {tabName === "phone" && (
                <MaskedInput
                  placeholder="Enter the phone number"
                  label="Phone Number*"
                  name="phone"
                  register={register}
                  errors={errors}
                  maskChar={""}
                  mask={"(999) 999-9999"}
                ></MaskedInput>
              )}
              {tabName === "email" && (
                <Input
                  type="email"
                  label="Email*"
                  register={register}
                  name="email"
                  placeholder="Enter your email"
                  errors={errors}
                ></Input>
              )}
            </div>
          </div>
        </>
      </ConfirmationModal>
    </>
  );
};

export default AddContactModal;
