

const Tangle = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" fill="none">
<g id="triangle">
<g id="Icon">
<path d="M5.40294 1.97536L3.34672 5.52701C3.23308 5.7233 3.17626 5.82145 3.10212 5.85441C3.03746 5.88316 2.96364 5.88316 2.89897 5.85441C2.82484 5.82145 2.76802 5.7233 2.65438 5.52701L0.598158 1.97536C0.484107 1.77836 0.427081 1.67986 0.435509 1.59902C0.442861 1.52851 0.479803 1.46443 0.537143 1.42274C0.602882 1.37494 0.716698 1.37494 0.944328 1.37494L5.05677 1.37494C5.2844 1.37494 5.39822 1.37494 5.46396 1.42274C5.5213 1.46443 5.55824 1.52851 5.56559 1.59902C5.57402 1.67986 5.51699 1.77836 5.40294 1.97536Z" 
fill="currentColor"/>
<path d="M5.40294 1.97536L3.34672 5.52701C3.23308 5.7233 3.17626 5.82145 3.10212 5.85441C3.03746 5.88316 2.96364 5.88316 2.89897 5.85441C2.82484 5.82145 2.76802 5.7233 2.65438 5.52701L0.598158 1.97536C0.484107 1.77836 0.427081 1.67986 0.435509 1.59902C0.442861 1.52851 0.479803 1.46443 0.537143 1.42274C0.602882 1.37494 0.716698 1.37494 0.944328 1.37494L5.05677 1.37494C5.2844 1.37494 5.39822 1.37494 5.46396 1.42274C5.5213 1.46443 5.55824 1.52851 5.56559 1.59902C5.57402 1.67986 5.51699 1.77836 5.40294 1.97536Z" 
fill="currentColor" 
fillOpacity="0.2"/>
<path d="M5.40294 1.97536L3.34672 5.52701C3.23308 5.7233 3.17626 5.82145 3.10212 5.85441C3.03746 5.88316 2.96364 5.88316 2.89897 5.85441C2.82484 5.82145 2.76802 5.7233 2.65438 5.52701L0.598158 1.97536C0.484107 1.77836 0.427081 1.67986 0.435509 1.59902C0.442861 1.52851 0.479803 1.46443 0.537143 1.42274C0.602882 1.37494 0.716698 1.37494 0.944328 1.37494L5.05677 1.37494C5.2844 1.37494 5.39822 1.37494 5.46396 1.42274C5.5213 1.46443 5.55824 1.52851 5.56559 1.59902C5.57402 1.67986 5.51699 1.77836 5.40294 1.97536Z" 
fill="currentColor" 
fillOpacity="0.2"
/>
<path d="M5.40294 1.97536L3.34672 5.52701C3.23308 5.7233 3.17626 5.82145 3.10212 5.85441C3.03746 5.88316 2.96364 5.88316 2.89897 5.85441C2.82484 5.82145 2.76802 5.7233 2.65438 5.52701L0.598158 1.97536C0.484107 1.77836 0.427081 1.67986 0.435509 1.59902C0.442861 1.52851 0.479803 1.46443 0.537143 1.42274C0.602882 1.37494 0.716698 1.37494 0.944328 1.37494L5.05677 1.37494C5.2844 1.37494 5.39822 1.37494 5.46396 1.42274C5.5213 1.46443 5.55824 1.52851 5.56559 1.59902C5.57402 1.67986 5.51699 1.77836 5.40294 1.97536Z" 
fill="currentColor" 
fillOpacity="0.2"
/>
<path d="M5.40294 1.97536L3.34672 5.52701C3.23308 5.7233 3.17626 5.82145 3.10212 5.85441C3.03746 5.88316 2.96364 5.88316 2.89897 5.85441C2.82484 5.82145 2.76802 5.7233 2.65438 5.52701L0.598158 1.97536C0.484107 1.77836 0.427081 1.67986 0.435509 1.59902C0.442861 1.52851 0.479803 1.46443 0.537143 1.42274C0.602882 1.37494 0.716698 1.37494 0.944328 1.37494L5.05677 1.37494C5.2844 1.37494 5.39822 1.37494 5.46396 1.42274C5.5213 1.46443 5.55824 1.52851 5.56559 1.59902C5.57402 1.67986 5.51699 1.77836 5.40294 1.97536Z" 
fill="currentColor" 
fillOpacity="0.2"
/>
</g>
</g>
</svg>
        </>
    );
};

export default Tangle;



