import * as yup from "yup";

export const uploadListFormSchema = yup.object({
  listName: yup
    .string()
    .required("List Name is required")
    .max(100, "List Name must be at most 100 characters"),
  skipTracedList: yup.string().required("Skip-traced list is required"),
  filePath: yup.string(),
  nameFirst: yup.string().required("First name is required"),
  nameLast: yup.string().required("Last name is required"),
  addressStreet: yup.string().required("Street address is required"),
  addressCity: yup.string().required("City is required"),
  addressState: yup.string().required("State is required"),
  addressZip: yup.string().required("Zipcode is required"),
  phone1: yup.string().required("Phone 1 is required"),
  phone2: yup.string().required("Phone 2 is required"),
  phone3: yup.string(),
});
