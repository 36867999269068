
const EditIcon = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
<g clipPath="url(#clip0_20476_58072)">
<path d="M1.43802 9.05797C1.46099 8.85122 1.47248 8.74785 1.50376 8.65123C1.53151 8.56551 1.57072 8.48393 1.62032 8.40871C1.67623 8.32392 1.74977 8.25038 1.89686 8.10329L8.50001 1.50015C9.0523 0.947866 9.94773 0.947867 10.5 1.50015C11.0523 2.05244 11.0523 2.94787 10.5 3.50015L3.89686 10.1033C3.74977 10.2504 3.67623 10.3239 3.59144 10.3798C3.51622 10.4294 3.43464 10.4686 3.34892 10.4964C3.2523 10.5277 3.14893 10.5392 2.94218 10.5621L1.25 10.7502L1.43802 9.05797Z" 
stroke="currentColor" 
strokeWidth="1.5" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_20476_58072">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>
        </>
    );
};

export default EditIcon;


