const SnowFlakes = ({ color }: { color?: any }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_21499_47234)">
                    <path d="M15.0499 7.08317L4.94631 12.9165M15.0499 7.08317L15.965 3.66811M15.0499 7.08317L18.465 7.99823M4.94631 12.9165L1.53125 12.0014M4.94631 12.9165L4.03125 16.3316M15.0499 12.9164L4.94624 7.08305M15.0499 12.9164L18.465 12.0013M15.0499 12.9164L15.965 16.3315M4.94624 7.08305L4.03137 3.6682M4.94624 7.08305L1.53137 7.99833M9.99813 4.1665L9.99813 15.8332M9.99813 4.1665L7.49813 1.6665M9.99813 4.1665L12.4981 1.6665M9.99813 15.8332L7.49813 18.3332M9.99813 15.8332L12.4981 18.3332"
                        stroke={color ? color : 'currentColor'}
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_21499_47234">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default SnowFlakes;