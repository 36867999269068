
const BlueTick = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_12832_32689)">
                    <path d="M10.8125 2.4798C10.9455 2.80138 11.2007 3.05699 11.5221 3.19044L12.649 3.65721C12.9706 3.79043 13.2261 4.04594 13.3593 4.36753C13.4925 4.68913 13.4925 5.05048 13.3593 5.37208L12.8929 6.49817C12.7596 6.81991 12.7594 7.18163 12.8933 7.50321L13.3589 8.62897C13.425 8.78826 13.459 8.95899 13.459 9.13142C13.459 9.30385 13.4251 9.4746 13.3591 9.6339C13.2931 9.79321 13.1964 9.93795 13.0744 10.0599C12.9525 10.1818 12.8077 10.2784 12.6484 10.3444L11.5223 10.8108C11.2007 10.9438 10.9451 11.199 10.8117 11.5204L10.3449 12.6473C10.2117 12.9689 9.95616 13.2244 9.63456 13.3576C9.31296 13.4908 8.95162 13.4908 8.63002 13.3576L7.50392 12.8912C7.18231 12.7583 6.8211 12.7586 6.49969 12.8919L5.37279 13.358C5.05137 13.4909 4.69034 13.4908 4.369 13.3577C4.04766 13.2246 3.7923 12.9694 3.65899 12.6482L3.19208 11.5209C3.05911 11.1993 2.80389 10.9437 2.48251 10.8103L1.35561 10.3435C1.03415 10.2104 0.778717 9.95501 0.645457 9.63359C0.512197 9.31218 0.512016 8.951 0.644955 8.62945L1.1114 7.50336C1.24428 7.18175 1.24401 6.82054 1.11064 6.49913L0.64487 5.37142C0.578832 5.21213 0.544828 5.0414 0.544801 4.86897C0.544773 4.69654 0.578722 4.52579 0.644709 4.36649C0.710695 4.20718 0.807425 4.06244 0.929372 3.94053C1.05132 3.81862 1.19609 3.72194 1.35542 3.65601L2.48151 3.18956C2.80281 3.05671 3.05826 2.80181 3.19181 2.4808L3.65859 1.3539C3.7918 1.0323 4.04731 0.776794 4.36891 0.643583C4.69051 0.510372 5.05185 0.510372 5.37345 0.643583L6.49954 1.11003C6.82115 1.24291 7.18237 1.24264 7.50378 1.10927L8.63115 0.644306C8.95271 0.51117 9.31398 0.511197 9.63551 0.644382C9.95705 0.777566 10.2125 1.033 10.3458 1.35452L10.8127 2.48176L10.8125 2.4798Z" fill="#0788F5" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.7416 5.16548C9.83513 5.01858 9.86646 4.84055 9.82872 4.67055C9.79098 4.50054 9.68725 4.3525 9.54035 4.25898C9.39346 4.16545 9.21542 4.13412 9.04542 4.17186C8.87542 4.2096 8.72738 4.31333 8.63385 4.46023L6.06398 8.49835L4.88798 7.02835C4.77926 6.89236 4.62097 6.80513 4.44793 6.78585C4.27489 6.76657 4.10128 6.81682 3.96529 6.92554C3.8293 7.03426 3.74207 7.19255 3.72279 7.36559C3.70351 7.53863 3.75376 7.71224 3.86248 7.84823L5.61248 10.0357C5.6777 10.1174 5.76146 10.1822 5.85681 10.225C5.95216 10.2677 6.05633 10.287 6.16065 10.2814C6.26498 10.2757 6.36646 10.2452 6.45663 10.1925C6.54681 10.1397 6.62308 10.0662 6.6791 9.97798L9.7416 5.16548Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_12832_32689">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>


        </>
    );
};

export default BlueTick;

