import { MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import DropDownIcon from "../assets/icons/dropDownIcon";
import Tick from "../assets/icons/tick";
import EmptyPlaceholder from "./EmptyPlacholder";

export interface MultiDropDownOption {
  value: any;
  label: string;
}

interface MultiSelectDropDownProps {
  options: MultiDropDownOption[];
  label: string;
  control: any;
  name: string;
  emptySearchMetaData?: any;
  dataTestId?: string;
}

const MultiSelectDropDown = ({
  options,
  label,
  control,
  name,
  emptySearchMetaData,
  dataTestId,
}: MultiSelectDropDownProps) => {
  return (
    <>
      <div className="multiSelect">
        <Controller
          render={({ field }) => (
            <Select
              fullWidth
              label={label}
              multiple
              displayEmpty
              MenuProps={{ classes: { paper: "multiSelectOverlay" } }}
              IconComponent={(props) => {
                if (props.className.includes("MuiSelect-iconOpen")) {
                  return (
                    <span className={`dropdownIcon opened`} role="link">
                      <DropDownIcon />
                    </span>
                  );
                }
                return (
                  <span className={`dropdownIcon`} role="link">
                    <DropDownIcon />
                  </span>
                );
              }}
              renderValue={(value) => {
                if (value.length === 0 || !value) {
                  return (
                    <>
                      <span className="multiSelect__placeholder">{label}</span>
                    </>
                  );
                }
                return options
                  .map((item) =>
                    value.includes(item.value) ? item.label : null
                  )
                  .filter(Boolean)
                  .join(", ");
              }}
              {...field}
              data-testid={dataTestId}
            >
              {options.length > 0 ? (
                options.map((option) => (
                  <MenuItem
                    className="multiSelectItem"
                    value={option.value}
                    data-testid="optionTestId"
                  >
                    <div className="flex optionList alignCenter justifySpaceBetween">
                      <div className="optionList__left">{option.label}</div>
                      <div className="optionList__right">
                        <Tick />
                      </div>
                    </div>
                  </MenuItem>
                ))
              ) : (
                <EmptyPlaceholder
                  icon={emptySearchMetaData.icon}
                  title={emptySearchMetaData.title}
                  description={emptySearchMetaData.description}
                />
              )}
            </Select>
          )}
          name={name}
          control={control}
        />
      </div>
    </>
  );
};

export default MultiSelectDropDown;
