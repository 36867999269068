

const Shield = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M7.9987 9.58266L9.66536 11.2493L13.4154 7.49932M17.1654 9.99932C17.1654 14.0897 12.7037 17.0647 11.0804 18.0117C10.8959 18.1194 10.8036 18.1732 10.6734 18.2011C10.5724 18.2228 10.425 18.2228 10.324 18.2011C10.1938 18.1732 10.1015 18.1194 9.91704 18.0117C8.29367 17.0647 3.83203 14.0897 3.83203 9.99932V6.01399C3.83203 5.34773 3.83203 5.0146 3.941 4.72824C4.03726 4.47527 4.19368 4.24955 4.39675 4.0706C4.62661 3.86802 4.93853 3.75105 5.56237 3.51711L10.0305 1.84155C10.2038 1.77658 10.2904 1.7441 10.3795 1.73122C10.4586 1.7198 10.5388 1.7198 10.6179 1.73122C10.707 1.7441 10.7936 1.77658 10.9669 1.84155L15.435 3.51711C16.0589 3.75105 16.3708 3.86802 16.6006 4.0706C16.8037 4.24955 16.9601 4.47527 17.0564 4.72824C17.1654 5.0146 17.1654 5.34773 17.1654 6.01399V9.99932Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Shield;


