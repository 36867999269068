import { initialState } from "./reducer";

const getStateData = (state: any) => state["billing"] || initialState;

export const getGeneralAccessProduct = (state: any) => {
  const reducer = getStateData(state);
  return reducer.generalAccessProducts;
};

export const getCreditAccessProduct = (state: any) => {
  const reducer = getStateData(state);
  return reducer.creditAccessProducts;
};
export const getCardList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.cardList;
};

export const getInvoices = (state: any) => {
  const reducer = getStateData(state);
  return reducer.invoices;
};

export const getInvoicesIsLoading=(state:any)=>{
   const reducer = getStateData(state);
   return reducer.invoicesIsLoading;
}

export const getSubscriptions=(state:any)=>{
   const reducer = getStateData(state);
   return reducer.subscriptions;
}
export const getSubscriptionsIsLoading=(state:any)=>{
   const reducer = getStateData(state);
   return reducer.subscriptionsIsLoading;
}

export const getCreditUsage = (state: any) => {
  const reducer = getStateData(state);
  return reducer.creditUsage;
};
export const getCreditUsageIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.creditUsageIsLoading;
};
export const getGeneralAccessProductsIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.generalAccessProductsIsLoading;
};