import React, { useEffect, useState } from "react";
import Input from "../../atoms/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { webhookSchema } from "./validation";
import Button from "../../atoms/Button";
import CustomTable from "../../molecules/table/CustomTable";
import SearchReflation from "../../assets/icons/searchReflation";
import { testPayload, webhook_COLUMN } from "./constant";
import { fetchWebhooklist } from "./sagaActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Integrations.module.scss";
import {
  getWebhookList,
  getWebhookListIsLoading,
  getWebhookListTotal,
} from "./selectors";
import { deleteWebhook, reSendWebhook, sendTestWebhook, sendWebhook } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import Eye from "../../assets/icons/eye";
import Loader from "../../atoms/Loader";
import { Dialog, Tooltip } from "@mui/material";
import Close from "../../assets/icons/close";
import Refresh from "../../assets/icons/refresh";
import Check from "../../assets/icons/check";
import { copyToClipboard, formatDateAndTime } from "../../utils/utils";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { setwebhookList } from "./reducer";
import Trash from "../../assets/icons/trash";
import { setProfileInfo } from "../../components/profileMenu/reducer";
import Copy from "../../assets/icons/copy";
import { PermissionListEnum } from "../auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import InfoCircle from "../../assets/icons/infoCircle";
const Integrations = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(webhookSchema),
  });

  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({ index: 1, size: 10 });
  const webhookList = useSelector((state) => getWebhookList(state));
  const total = useSelector((state) => getWebhookListTotal(state));
  const tableLoading = useSelector((state) => getWebhookListIsLoading(state));
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [data, setData] = useState<Record<string, any>>({});
  const [currentId, setCurrentId] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(false);
 


const formatPayload=(data:any)=> {
  const stringifiedData = JSON.stringify(data ?? {}, null, 2);
  return stringifiedData
    .replace(/ /g, '&nbsp;')
    .replace(/\n/g, '<br>');

}

  const handleDetailModal = () => {
    setShowDetailModal(!showDetailModal);
  };
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await sendWebhook(data);
      setLoading(false);
      handleShowToast("Webhook url sent successfully!", ToastType.success);
      dispatch(setProfileInfo({
        ...profileInfo,
        organisation: {
          ...profileInfo.organisation,
          webhookUrl: data?.webhookUrl,
        }
      }));
    } catch (error: any) {
      setLoading(false);
      handleShowToast(error?.response?.statusText, ToastType.error);
    }
  };

  const handleTestSubmit = async () => {
    setTestLoading(true);
    try {
      await sendTestWebhook();
      setTestLoading(false);
      handleShowToast("Webhook test url sent successfully!", ToastType.success);
    } catch (error: any) {
      setTestLoading(false);
      handleShowToast(error?.response?.data?.message, ToastType.error);
    }
  };

  const handleResendWebhook = async (id: any) => {
    setCurrentId(id);
    try {
     const response= await reSendWebhook(id);
      setCurrentId(-1);
      handleShowToast("Webhook URL re-sent successfully!", ToastType.success);
      setValue("webhookUrl", response?.data?.data?.organisation?.webhookUrl);
     const updatedWebhookList = webhookList.map((item:any) => {
        if (item?.id === id) {
          return {
            ...item,
            responseCode: response?.data?.data?.responseCode,
            status: response?.data?.data?.status
          };
        }
        return item;
      });
      dispatch(setwebhookList({ data: updatedWebhookList ,total}));
    } catch (error: any) {
      setCurrentId(-1);
      handleShowToast(error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong!", ToastType.error);
    }
  };

  
  const handleDeleteWebhook = async () => {
    setDeleteLoading(true);
    try {
      await deleteWebhook();
     setDeleteLoading(false);
       handleShowToast("Webhook URL removed successfully!", ToastType.success);
       setValue("webhookUrl", '');
       dispatch(setProfileInfo({
        ...profileInfo,
        organisation: {
          ...profileInfo.organisation,
          webhookUrl: null,
        }
      }));
    } catch (error: any) {
      setDeleteLoading(false);
      handleShowToast(error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong!", ToastType.error);
    }
  };

  useEffect(() => {
    const detail = webhookList?.map((item: any) => {
      return {
        status: {
          label: item?.responseCode,
          status: item?.status,
        },
        type: item?.webhookType,
        date: formatDateAndTime(item?.createdAt),
        buttons: (
          <>
            <div className={`${styles.integrationBtn} flex alignCenter`}>
              <Button
                label="Latest"
                className="outline"
                prefix={<Eye />}
                action={(e: any) => {
                  e.stopPropagation();
                  setData(item);
                  setShowDetailModal(true);
                }}
              ></Button>
              { profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? 
               <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess} placement="top">
                  <span>
                    <Button
                      className="outline"
                      prefix={<Refresh />}
                      label="Resend"
                    
                      disabled
                    />
                  </span>
                </Tooltip>:
              
              !profileInfo?.organisation?.webhookUrl ? (
                <Tooltip title="Webhook URL is not present." placement="top">
                  <span>
                    <Button
                      className="outline"
                      prefix={<Refresh />}
                      label="Resend"
                      action={() => handleResendWebhook(item?.id)}
                      clicked={currentId === item?.id}
                      disabled
                    />
                  </span>
                </Tooltip>
              ) : (
                <Button
                  className="outline"
                  prefix={<Refresh />}
                  label="Resend"
                  action={() => handleResendWebhook(item?.id)}
                  clicked={currentId === item?.id}
                  disabled={currentId === item?.id}
                />
              )}
            </div>
          </>
        ),
        activityDrawerLeadId: false,
      };
    });
    setTableData(detail);
  }, [webhookList, currentId, profileInfo]);

  useEffect(() => {
    dispatch(fetchProfileInfo({}));
    dispatch(fetchWebhooklist(filter));
  }, []);

  useEffect(() => {
    if (profileInfo?.organisation?.webhookUrl) {
      setValue("webhookUrl", profileInfo?.organisation?.webhookUrl);
    }
  }, [profileInfo]);

  
  return (
    <>
      <div className={`${styles.integration}`}>
        <div className={`${styles.integration__header}`}>
          <h3>Webhook
          <Tooltip title="Provide your webhook URL to receive lead data directly to your system" placement="top">

          <span>
          <InfoCircle />
          </span>
          </Tooltip>
          </h3>
          <p>Call a webhook when you get a new lead.</p>
        </div>
        <div className={`${styles.integration__form}`}>
          <div className={`${styles.input}`}>
            <div className={`${styles.input__wrap}`}>
              <Input
                type="text"
                label="Webhook URL"
                register={register}
                name="webhookUrl"
                errors={errors}
                placeholder="https://mywebsite.com/webhhook"
              ></Input>


{profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess} placement="top">
                  <span
                    className={`${styles.input__close} ${
                      deleteLoading && styles.disabled
                    }`}
                    role="link"
                  >
                    {deleteLoading ? <Loader size={20} /> : <Close />}
                  </span>
                </Tooltip> :profileInfo?.organisation?.webhookUrl ? (
                <Tooltip title="Remove URL" placement="top">
                  <span
                    className={`${styles.input__close} ${
                      deleteLoading && styles.disabled
                    }`}
                    role="link"
                    onClick={handleDeleteWebhook}
                  >
                    {deleteLoading ? <Loader size={20} /> : <Close />}
                  </span>
                </Tooltip>
              ) : null}
            </div>
            <div className={`${styles.input__wrap}`}>
              <div className="inputGroup">
                <label className="label"></label>
                <div className={`inputGroup__input ${styles.input__payload}`}>
                  <span
                    dangerouslySetInnerHTML={{ __html: formatPayload(testPayload) }}
                  />
                  <span
                    role="link"
                    className={`${styles.input__copy}`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      copyToClipboard(JSON.stringify(testPayload));
                    }}
                  >
                    <Copy />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.gap} flex justifyEnd`}>
        <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
                {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? (
                  <Button
                  type="button"
                  label="Test"
                  className="primary"
                    disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
                  />
                ) : (
          <Button
            type="button"
            label="Test"
            className="primary"
            action={handleSubmit(handleTestSubmit)}
            clicked={testLoading}
            disabled={testLoading || loading}
            ></Button>
          )}
          </div>
        </Tooltip>
          
        <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
                {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? (
                  <Button
                  type="submit"
                  label="Save"
                  className="primary"
                    disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
                  />
                ) : (
          <Button
            type="submit"
            label="Save"
            className="primary"
            action={handleSubmit(onSubmit)}
            clicked={loading}
            disabled={loading || testLoading}
          ></Button>
           )}
              </div>
            </Tooltip>
        </div>
        <div
          className={`${styles.integration__header} ${styles.integration__headerOther}`}
        >
          <h3>Webhook history</h3>
          <p>Access a historic log of your webhook calls.</p>
        </div>

        {tableLoading ? (
          <div className={`loader-main-large`}>
            {" "}
            <Loader></Loader>
          </div>
        ) : (
          <CustomTable
            rows={tableData}
            columns={webhook_COLUMN}
            hasMore={tableData?.length < total}
            tableStriped
            currentPageIndex={1}
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search or filtering criteria.",
            }}
            handleNextPage={() => {}}
          ></CustomTable>
        )}
        <Dialog
          className="stepsDialog"
          open={showDetailModal}
          onClose={handleDetailModal}
        >
          <div className="dialogWrapper">
            <span
              role="link"
              onClick={handleDetailModal}
              className="closeDialog"
            >
              <Close />
            </span>

            <div className={`${styles.integration__modal}`}>
              <h2>Webhook log</h2>
              <table
                className={`${
                  typeof data?.responseData === "object"
                    ? styles.integration__tableSpace
                    : ""
                }`}
              >
                <tr>
                  <th>URL:</th>
                  <td> {data?.webhookUrl}</td>
                </tr>
                <tr>
                  <th>Payload:</th>
                  {/* <td>{JSON.stringify(data?.payloadData)}</td> */}
                  <td>

                  <span
                    dangerouslySetInnerHTML={{ __html: formatPayload(data?.payloadData) }}
                    />
                    </td>
                </tr>
                <tr>
                  <th>Response code:</th>
                  <td> {data?.responseCode}</td>
                </tr>
                {typeof data?.responseData === "object" ? (
                  <tr>
                    <th>Response:</th>
                    <td> 
                    <span
                    dangerouslySetInnerHTML={{ __html: formatPayload(data?.responseData) }}
                  />
                      {/* {JSON.stringify(data?.responseData)} */}

                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </table>

              {typeof data?.responseData === "string" ? (
                <div className={`${styles.integration__codeMain}`}>
                  <h2>Response:</h2>

                  <div className={`${styles.integration__code}`}>
                    <pre>{data?.responseData}</pre>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <Button
              label="Okay"
              className="primary full animated"
              action={handleDetailModal}
              prefix={<Check />}
            ></Button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Integrations;
