import { useDispatch, useSelector } from "react-redux";
import {
  getProfileInfo,
  getProfileInfoLoading,
} from "../../components/profileMenu/selectors";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";
import { Navigate } from "react-router-dom";
import { setRefreshPage } from "./reducer";
import { getRefreshPage } from "./selectors";

const OnboardingWrapper = ({ children }: { children: React.ReactNode }) => {
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const profileInfoLoading = useSelector((state) =>
    getProfileInfoLoading(state)
  );
  const refreshPage = useSelector((state) => getRefreshPage(state));
  const dispatch = useDispatch();

  if (!profileInfo?.id || (profileInfoLoading && refreshPage)) {
    return <GlobalLoader></GlobalLoader>;
  }
  if (profileInfo?.organisation?.stepsCompleted > 1) {
    if (refreshPage) {
      dispatch(setRefreshPage(false));
    }
    return <>{children}</>;
  } else {
    return <Navigate to="/onboarding" />;
  }
};
export default OnboardingWrapper;
