import { yupResolver } from "@hookform/resolvers/yup";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useDispatch} from "react-redux";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import {
  resendConfirmationCode,
} from "../../services/auth";
import { resetPasswordFormSchema } from "./validation";
import Message from "../../assets/icons/message";
import Close from "../../assets/icons/cross";
import Logo from "../../components/Logo";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { ResetPasswordProps } from "./interface";

const ResetPassword = ({ open, setOpen, email}: ResetPasswordProps) => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordFormSchema),
  });
  const [OTP, setOTP] = useState("");
  const [otpEntered, setOtpEntered] = useState<boolean>(false);
  const [submitClicked,setSubmitClicked] = useState<boolean>(false);
   
 const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(), // Unique ID for the toast
        message: message,
        type: type, // You can use 'error', 'info', etc.
      })
    );
  };

  const resendCode = async () => {
    try {
      await resendConfirmationCode(email);
      handleShowToast("OTP sent", ToastType.success);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (values: any) => {
    setSubmitClicked(true);
    try {
      // const res = await confirmPassword({
      //   email: email,
      //   code: OTP,
      //   password: values.password,
      //   handleShowToast,
      //   setOtpEntered,
      //   handleClose
      // });
    
    }
    finally{
      setSubmitClicked(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog loginRegister`}
      >
        <div className="dialogWrapper authStyle">
          <span
            role="link"
            className="closeDialog"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </span>
          <form>
            {otpEntered ? (
              <>
                <div className="authStyle__logo flex alignCenter justifyCenter">
                  <Logo />
                </div>
                <div className="authStyle__header">
                  <h2>Reset password</h2>
                  <p>Please enter your password below.</p>
                </div>
                <Input
                  type="password"
                  label="Password*"
                  register={register}
                  name="password"
                  placeholder="Enter password"
                  errors={errors}
                ></Input>
                <Input
                  type="password"
                  label="Confirm Password*"
                  register={register}
                  name="confirmPassword"
                  placeholder="Re-enter password"
                  errors={errors}
                ></Input>
                <div className="authStyle__footer">
                  <Button
                    label="Reset password"
                    className="primary full xl"
                    action={handleSubmit(onSubmit)}
                    clicked={submitClicked}
                    disabled={submitClicked}
                  ></Button>
                </div>
              </>
            ) : (
               <>
            <div className={`authStyle__Icon`}>
            <span className={`flag`}>
            <Message />
            </span>
            </div>
            <div className="authStyle__header">
            <h2>Please check your email.</h2>
            <p>
            We've sent a code to <strong>your email.</strong>
            </p>
            </div>

            <div className="otp">
            <div className="otp__form">
            <OtpInput
            value={OTP}
            inputType="number"
            onChange={setOTP}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
            />
            </div>
            <p>
            Didn’t get a code?{" "}
            <span role="link" onClick={resendCode}>
            Click to resend.
            </span>
            </p>
            <div className="authStyle__footer">
            <Button
            label="Verify"
            className="primary full xl"
            action={() => {
            setOtpEntered(true);
            }}
            clicked={submitClicked}
            disabled={submitClicked}
            ></Button>
            </div>
            </div>
             </>
             )}
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default ResetPassword;
