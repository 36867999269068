import { initialState } from "./reducer";

const getStateData = (state: any) => state["webhookTable"] || initialState;

export const getWebhookList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.webhookList;
};

export const getWebhookListTotal=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.total
}

export const getWebhookListIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.webhookListIsLoading;
};