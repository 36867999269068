import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
export enum ArrowDirection {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}
function ArrowButton(props: {
  className: any;
  style: any;
  onClick: any;
  type: ArrowDirection;
}) {
  const { className, style, onClick, type } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      {type === ArrowDirection.LEFT && <ArrowLeftIcon />}
      {type === ArrowDirection.RIGHT && <ArrowRightIcon />}
    </div>
  );
}
export default ArrowButton;
