import { Tooltip } from "@mui/material";
import styles from "./InfoCard.module.scss";
import { InfoCardProps } from "./interface";
import InfoCircle from "../../assets/icons/infoCircle";

const InfoCard = ({ title, description,icon }: InfoCardProps) => {
    return (
        <div className={`${styles.infoCard}`}>
            <h4>{title}
                {icon?   <Tooltip title="Assuming 7% cost of sale" placement="top">
                        <span>
                          <InfoCircle />
                        </span>
                      </Tooltip>:""}
            </h4>
            <p>{description}</p>
        </div>

    );
};

export default InfoCard;