import { useState } from "react";
import property from "../assets/images/map-img.jpeg";
import FallbackPropertyImage from "../assets/images/no-property-image-found.png";
const ImageWithLoading = ({
  src,
  alt,
  onClick,
  loading = false,
}: {
  src: string;
  alt: string;
  onClick?: any;
  loading?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {(isLoading || loading) && (
        <div className="image-loading">
          <img src={property} alt="property" />
          <div className="image-loading__loader">
            <div className="dot-pulse"></div>
          </div>
        </div>
      )}
      <img
        src={hasError ? FallbackPropertyImage : src}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setHasError(true);
          setIsLoading(false);
        }}
        alt={alt}
        onClick={() => onClick && onClick()}
      ></img>
    </>
  );
};
export default ImageWithLoading;
