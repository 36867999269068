import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
//reducers
import profileInfoSlice from "../components/profileMenu/reducer";
import toastReducer from "../molecules/toaster/toastSlice";
import createLeadSlice from "../organisms/addLeads/reducer";
import authSlice from "../organisms/auth/reducer";
import authGuardReducer from "../organisms/authroute/Reducer";
import campaignSlice from "../organisms/campaignTable/reducer";
import createCampaignSlice from "../organisms/createCampaign/reducer";
import signupForEarlyAccessSlice from "../organisms/earlyAccess/reducer";
import leadsTableSlice from "../organisms/leadsTable/reducer";
import myListsTableSlice from "../organisms/listsTable/reducer";
import notificationSlice from "../organisms/notifications/reducer";
import uploadListSlice from "../organisms/uploadList/reducer";
import campaignDetailSlice from "../pages/detail/reducer";
import propertyDetailsSlice from "../pages/property/property.reducer";
import createListSlice from "../organisms/createList/reducer";
import propertySearch from "../organisms/propertySearchFilter/reducer";
import billing from "../organisms/billing/reducer";
import webhookSlice from "../organisms/integrations/reducer";
import myTeamSlice from "../pages/myTeam/reducer";
//saga
import profileInfoSaga from "../components/profileMenu/saga";
import createLeadSaga from "../organisms/addLeads/saga";
import rootSaga from "../organisms/campaignTable/saga";
import createCampaignSaga from "../organisms/createCampaign/saga";
import leadSaga from "../organisms/leadsTable/saga";
import myListsSaga from "../organisms/listsTable/saga";
import notificationSaga from "../organisms/notifications/saga";
import uploadListSaga from "../organisms/uploadList/saga";
import campaignDetailSaga from "../pages/detail/saga";
import propertyDetailsSaga from "../pages/property/property.saga";
import propertySearchSaga from "../organisms/propertySearchFilter/saga";
import billingSaga from "../organisms/billing/saga";
import skipTraceSaga from "../organisms/createList/saga";
import webhookSaga from "../organisms/integrations/saga";
import myTeamSaga from "../pages/myTeam/saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    toasts: toastReducer,
    campaign: campaignSlice,
    createCampaign: createCampaignSlice,
    auth: authSlice,
    earlyAccess: signupForEarlyAccessSlice,
    notification: notificationSlice,
    myListsTable: myListsTableSlice,
    uploadList: uploadListSlice,
    leadsTable: leadsTableSlice,
    createLead: createLeadSlice,
    authGuard: authGuardReducer,
    profileInfo: profileInfoSlice,
    campaignDetail: campaignDetailSlice,
    propertyDetails: propertyDetailsSlice,
    createList:createListSlice,
    propertySearch:propertySearch,
    billing:billing,
    webhookTable:webhookSlice,
    myTeam:myTeamSlice
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
sagaMiddleware.run(notificationSaga);
sagaMiddleware.run(createCampaignSaga);
sagaMiddleware.run(leadSaga);
sagaMiddleware.run(myListsSaga);
sagaMiddleware.run(uploadListSaga);
sagaMiddleware.run(createLeadSaga);
sagaMiddleware.run(profileInfoSaga);
sagaMiddleware.run(campaignDetailSaga);
sagaMiddleware.run(propertyDetailsSaga);
sagaMiddleware.run(propertySearchSaga)
sagaMiddleware.run(billingSaga);
sagaMiddleware.run(skipTraceSaga);
sagaMiddleware.run(webhookSaga);
sagaMiddleware.run(myTeamSaga);
export default store;
