

const RocketIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g id="rocket-01">
<path id="Icon" d="M10.8345 9.16684L2.91783 17.0835M11.6832 2.94889C12.6982 3.62239 13.6738 4.41642 14.5855 5.32808C15.505 6.2476 16.3048 7.23217 16.982 8.25663M7.71363 6.58029L5.31791 5.78171C5.04201 5.68974 4.73812 5.74827 4.5161 5.93612L2.13514 7.95079C1.6477 8.36324 1.78618 9.14815 2.38533 9.36889L4.64133 10.2001M9.73537 15.2939L10.5665 17.5499C10.7873 18.1491 11.5722 18.2876 11.9846 17.8001L13.9993 15.4192C14.1872 15.1972 14.2457 14.8933 14.1537 14.6174L13.3551 12.2216M16.125 1.89244L12.0363 2.57389C11.5948 2.64748 11.1898 2.86448 10.884 3.19135L5.37313 9.0823C3.94467 10.6093 3.9844 12.9939 5.46295 14.4725C6.94149 15.951 9.32613 15.9907 10.8531 14.5623L16.7441 9.05139C17.0709 8.74561 17.2879 8.34066 17.3615 7.89915L18.043 3.81043C18.231 2.68239 17.253 1.70443 16.125 1.89244Z" 
stroke="currentColor"
 strokeWidth="1.66667" 
 strokeLinecap="round" 
 strokeLinejoin="round"/>
</g>
</svg>
        </>
    );
};

export default RocketIcon;