const ArrowRight = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <g id="arrow-right">
                    <path
                        id="Icon"
                        d="M4.66602 9.99996H16.3327M16.3327 9.99996L10.4993 4.16663M16.3327 9.99996L10.4993 15.8333"
                        stroke="currentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
            </svg>
        </>
    );
};

export default ArrowRight;
