import * as yup from "yup";

export const SecuritySchema = yup.object({
  oldPassword: yup.string().trim().required("Current password is required."),
  newPassword: yup
    .string()
    .trim()
    .required("New password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s])[A-Za-z\d=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s]{8,}$/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    ),
  confirmNewPassword: yup
    .string()
    .trim()
    .required("Confirm password is required.")
    .oneOf(
      [yup.ref("newPassword")],
      "Confirm new password do not match with the new password."
    ),
});
