const Phone = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M6.28325 6.77112C6.80525 7.79794 7.51684 8.76031 8.41803 9.61143C9.31921 10.4625 10.3382 11.1346 11.4254 11.6276C11.5189 11.67 11.5657 11.6912 11.6249 11.7075C11.8351 11.7654 12.0933 11.7238 12.2714 11.6034C12.3215 11.5695 12.3644 11.529 12.4501 11.448C12.7123 11.2004 12.8434 11.0766 12.9752 10.9956C13.4724 10.6903 14.1133 10.6903 14.6105 10.9956C14.7423 11.0766 14.8734 11.2004 15.1356 11.448L15.2818 11.5861C15.6804 11.9625 15.8797 12.1507 15.9879 12.3529C16.2032 12.7549 16.2032 13.2297 15.9879 13.6317C15.8797 13.8338 15.6804 14.0221 15.2818 14.3985L15.1636 14.5102C14.7664 14.8853 14.5677 15.0729 14.2977 15.2161C13.9981 15.3751 13.5327 15.4894 13.1891 15.4884C12.8793 15.4876 12.6677 15.4308 12.2443 15.3173C9.9693 14.7075 7.82253 13.5568 6.03154 11.8653C4.24056 10.1738 3.0222 8.14634 2.37647 5.99768C2.25632 5.59787 2.19624 5.39796 2.19532 5.10546C2.1943 4.78088 2.31532 4.34137 2.48363 4.05838C2.63532 3.80336 2.83393 3.61579 3.23114 3.24064L3.34937 3.12898C3.74795 2.75254 3.94724 2.56432 4.16128 2.46208C4.58695 2.25873 5.08965 2.25873 5.51532 2.46208C5.72936 2.56432 5.92865 2.75254 6.32723 3.12898L6.47338 3.26701C6.73559 3.51465 6.86669 3.63847 6.9524 3.76298C7.27565 4.23252 7.27565 4.83785 6.9524 5.3074C6.86669 5.43191 6.73559 5.55573 6.47338 5.80337C6.38765 5.88434 6.34478 5.92482 6.3089 5.97215C6.18139 6.14033 6.13736 6.38418 6.19866 6.58276C6.2159 6.63864 6.23835 6.6828 6.28325 6.77112Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Phone;


