import { getAPI } from "../../apis/apis";

export const fetchCreditAccessProducts = () => {
  return getAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/payment-gateway/credit-access-product`,
    headers: {},
  });
};
