
const ShareIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M27 12C29.4853 12 31.5 9.98528 31.5 7.5C31.5 5.01472 29.4853 3 27 3C24.5147 3 22.5 5.01472 22.5 7.5C22.5 9.98528 24.5147 12 27 12Z"
                    fill="currentColor" />
                <path d="M9 22.5C11.4853 22.5 13.5 20.4853 13.5 18C13.5 15.5147 11.4853 13.5 9 13.5C6.51472 13.5 4.5 15.5147 4.5 18C4.5 20.4853 6.51472 22.5 9 22.5Z"
                    fill="currentColor" />
                <path d="M27 33C29.4853 33 31.5 30.9853 31.5 28.5C31.5 26.0147 29.4853 24 27 24C24.5147 24 22.5 26.0147 22.5 28.5C22.5 30.9853 24.5147 33 27 33Z"
                    fill="currentColor" />
                <path d="M12.885 20.265L23.13 26.235M23.115 9.765L12.885 15.735M31.5 7.5C31.5 9.98528 29.4853 12 27 12C24.5147 12 22.5 9.98528 22.5 7.5C22.5 5.01472 24.5147 3 27 3C29.4853 3 31.5 5.01472 31.5 7.5ZM13.5 18C13.5 20.4853 11.4853 22.5 9 22.5C6.51472 22.5 4.5 20.4853 4.5 18C4.5 15.5147 6.51472 13.5 9 13.5C11.4853 13.5 13.5 15.5147 13.5 18ZM31.5 28.5C31.5 30.9853 29.4853 33 27 33C24.5147 33 22.5 30.9853 22.5 28.5C22.5 26.0147 24.5147 24 27 24C29.4853 24 31.5 26.0147 31.5 28.5Z"
                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default ShareIcon;


