import { Amplify, Auth } from "aws-amplify";
import awsmobile from "../aws-export";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Cookies from "js-cookie";
import { getLocalStorageData } from "../utils/utils";
import { LOGIN_TYPE } from "../organisms/auth/enum";
import { GetCognitoSessionDefault, defaultLogout } from "./auth";
import { generateRefreshToken } from "../organisms/auth/authServices";
// Amplify.configure(awsmobile);

export const GetSession = async () => {
  try {
    // await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    if (session.isValid() === true) {
      return session;
    } else {
      throw new Error("Session expired");
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

// signIn with google identity provider
export const GoogleSignIn = async () => {
  return await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });
};

export const signOut = () => {
  defaultLogout();
  // if (getLocalStorageData("loginType") === LOGIN_TYPE.GOOGLE) {
  //   GoogleSigOut();
  // } else if (getLocalStorageData("loginType") === LOGIN_TYPE.DEFAULT) {
  //   defaultLogout();
  //   // window.location.href = "/";
  // }
};

export const GoogleSigOut = async () => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("loginType");
    window.location.href = `https://${awsmobile.oauth.domain}/logout?client_id=${awsmobile.aws_user_pools_web_client_id}&logout_uri=${awsmobile.oauth.redirectSignOut}`;
    window.Intercom("shutdown");
    localStorage.removeItem(`intercom.intercom-state-${"hadvw7mr"}`);
    Cookies.remove(`intercom-device-id-${"hadvw7mr"}`, { path: "" });
    Cookies.remove(`intercom-id-${"hadvw7mr"}`, { path: "" });
    Cookies.remove(`intercom-session-${"hadvw7mr"}`, { path: "" });
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

export const generateToken = async (wantToken = false) => {
  try {
    const session = await GetSession();
    const accessToken = session?.getAccessToken().getJwtToken();
    localStorage.setItem("accessToken", accessToken);
    if (wantToken) {
      return accessToken;
    }
  } catch {
    throw new Error("Session expired");
  }
};

export const refreshSession = async (
  callBackFunc?: any,
  loaderFuncStart?: any,
  loaderFuncStop?: any
) => {
  try {
    if (loaderFuncStart) {
      loaderFuncStart();
    }
      const accessToken = await generateRefreshToken();
      localStorage.setItem("accessToken", accessToken);
    // await GetCognitoSessionDefault();
    if (callBackFunc) {
      callBackFunc();
    }
    // if (getLocalStorageData("loginType") === LOGIN_TYPE.DEFAULT) {
    //   await GetCognitoSessionDefault();
    // } else if (getLocalStorageData("loginType") === LOGIN_TYPE.GOOGLE) {
    //   await generateToken();
    // }
  } catch (err) {
    console.log(err, "while refresh token");
    if (loaderFuncStop) {
      loaderFuncStop();
    }
  }
};
