import Button from "../../atoms/Button";
import styles from "./DashboardHeaderBar.module.scss";
import SearchBar from "../../atoms/SearchBar";
import { CampaignStatusType } from "./enum";
import { DashboardHeaderBarProps } from "./interface";

const DashboardHeaderBar = ({
  searchText,
  handleSearchChange,
  handleFilterChange,
  selectedFilter,
}: DashboardHeaderBarProps) => {
  return (
    <>
      <div className={` flex ${styles.filters}`}>
        <div className={`${styles.filters__left}`}>
          <SearchBar
            searchText={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              if (
                e.target.value.length > 0 &&
                e.target.value.trim().length === 0
              ) {
                value = "";
              }
              if (searchText !== value) {
                handleSearchChange(value);
              }
            }}
            data-testid="SearchCampaign"
          ></SearchBar>
        </div>
        <div className={`${styles.filters__right}`}>
          <ul className="flex">
            <li>
              <Button
                label="All Campaigns"
                className={`outline xl ${
                  CampaignStatusType.ALL === selectedFilter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.ALL)}
              />
            </li>
            <li>
              <Button
                label="Scheduled Campaigns"
                className={`outline xl scheduled ${
                  CampaignStatusType.SCHEDULED === selectedFilter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.SCHEDULED)}
              />
            </li>
            <li>
              <Button
                label="Active Campaigns"
                className={`outline xl active ${
                  CampaignStatusType.ACTIVE === selectedFilter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.ACTIVE)}
              />
            </li>
            <li>
              <Button
                label="Completed Campaigns"
                className={`outline xl completed ${
                  CampaignStatusType.COMPLETED === selectedFilter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.COMPLETED)}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DashboardHeaderBar;
