import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PlayIcon from "../../../assets/icons/playIcon";
import Button from "../../../atoms/Button";
import EarlyAccessSignup from "../../../organisms/earlyAccess/EarlyAccessSignup";
import { happyCustomerSec } from "../HomeConstants";
import styles from "./HappyCustomerSec.module.scss";
import Register from "../../../organisms/auth/Register";

function SampleNextArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowRightIcon />
    </div>
  );
}

function SamplePrevArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowLeftIcon />
    </div>
  );
}

const HappyCustomerSec = () => {
  const [openEarlyAccessModal, setOpenEarlyAccessModal] =
  useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [playingIndex, setPlayingIndex] = useState(-1);
  const videoRef = useRef<HTMLVideoElement | any>(null);
  const settings = {
    autoplay: false,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    buttons: false,
    nextArrow: (
      <SampleNextArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 1.3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };


  const handlePlay = (index: any) => {
    const prevElement =
      playingIndex !== null
        ? (document.getElementById(
            `video-element${playingIndex}`
          ) as HTMLVideoElement)
        : null;
    if (prevElement) {
      prevElement.pause();
      prevElement.controls = false;
    }
    if (playingIndex !== index) {
      const currentElement = document.getElementById(
        `video-element${index}`
      ) as HTMLVideoElement;

      if (currentElement) {
        currentElement.controls = true;
        setTimeout(() => {
          currentElement.play().catch(error => {
            console.error('Error occurred while playing video:', error);
          });
        }, 0);
      } else {
        console.error(`Video element with id 'video-element${index}' not found.`);
      }
    }
    
    setPlayingIndex(playingIndex !== index ? index : -1);
  };

  const handlePause = (index: any) => {
    if (index === playingIndex) {
      const videoElement = document.getElementById(
        `video-element${index}`
      ) as HTMLVideoElement;
      const isSeekingPause = videoElement.seeking;
      if (!isSeekingPause) {
        videoElement.controls = false;
        setPlayingIndex(-1);
      }
    }
  };

  return (
    <div className={` ${styles.customer}`}>
      <div className={` ${styles.relative}`}>
        <div className={`dflex  ${styles.customer__inner}`}>
          <div className={` ${styles.customer__left}`}>
            <div className={` ${styles.leftWrapper}`}>
              <div className={` ${styles.leftWrapper__inner}`}>
                <h2
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-offset="50"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  {happyCustomerSec.title}
                </h2>
                <p
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-offset="50"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  {happyCustomerSec.description}
                </p>
                <div
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-offset="50"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <Button
                    className="gradient"
                    label="Start your free trial"
                    action={() => setOpenEarlyAccessModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={` ${styles.customer__right}`}
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-offset="50"
            data-aos-duration="1000"
          >
            <Slider className="slick" {...settings}>
              {happyCustomerSec.customers.map((item, index) => {
                
                return (
                  <>
                    <div
                      className={` ${styles.customerCard} ${
                        playingIndex === index && styles.videoPlay
                      }`}
                      id={`${index}`}
                      onClick={() => handlePlay(index)}
                    >
                      <div className={` ${styles.cardImg}`}>
                        <video
                          id={`video-element${index}`}
                          key={item?.video}
                          width="400"
                          controlsList={"nodownload noplaybackrate"}
                          //controls
                          poster={item.cardImage}
                        >
                          {playingIndex === index && (
                            <source src={item?.video} type="video/mp4" />
                          )}
                        </video>
                        {playingIndex !== index && (
                          <>
                            <span className={`${styles.playPauseBtn}`}>
                              <PlayIcon />
                            </span>

                            <div className={`flex ${styles.cardBottom}`}>
                              <figcaption>
                                <h5>{item.name}</h5>
                                <p>{item.title}</p>
                              </figcaption>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div key={item.id}>
                  <div className={` ${styles.customerCard}`}>
                    <div className={` ${styles.cardImg}`}>
                      <img src={item.cardImage} alt={`${item.name}`} />
                      <span className={` ${styles.playPauseBtn}`}>
                        <PlayIcon />
                      </span>
                      <div className={`flex ${styles.cardBottom}`}>
                        <figure>
                          <img
                            src={item.headshotImage}
                            alt={`${item.name} headshot`}
                          />
                        </figure>
                        <figcaption>
                          <h5>{item.name}</h5>
                          <p>{item.title}</p>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      {openEarlyAccessModal ? (
        // <EarlyAccessSignup
        //   open={openEarlyAccessModal}
        //   setOpen={setOpenEarlyAccessModal}
        // ></EarlyAccessSignup>
        <Register
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  );
};

export default HappyCustomerSec;
