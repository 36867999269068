export const DEFAULT_LIST_DATA = {
  listName: "",
  skipTracedList: "",
  filePath: "",
  nameFirst: "",
  nameLast: "",
  addressStreet: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  phone1: "",
  phone2: "",
  phone3: "",
};

export const DB_COLUMNS = [
  "nameFirst",
  "nameLast",
  "addressStreet",
  "addressCity",
  "addressState",
  "addressZip",
  "phone1",
  "phone2",
  "phone3",
];
