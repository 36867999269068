import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Delete from "../../assets/icons/delete";
import MinusCircle from "../../assets/icons/minusCircle";
import PlusCircle from "../../assets/icons/plusCircle";
import Save from "../../assets/icons/save";
import Button from "../../atoms/Button";
import { formartNumberAsLocalString } from "../../utils/utils";
import AuctionsPreForeclosure from "./advancedFilterAccordians/AuctionsPreForeclosure";
import CashBuyers from "./advancedFilterAccordians/CashBuyers";
import Demographics from "./advancedFilterAccordians/Demographics";
import MortgageInfo from "./advancedFilterAccordians/MortgageInfo";
import Ownership from "./advancedFilterAccordians/Ownership";
import PropertyCharacteristics from "./advancedFilterAccordians/PropertyCharacteristics";
import QuickFilter from "./advancedFilterAccordians/QuickFilter";
import TaxLiens from "./advancedFilterAccordians/TaxLiens";
import ValuationEquity from "./advancedFilterAccordians/ValuationEquity";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
} from "./constants";
import { AdvancedFilterAccordians } from "./enum";
import { AdvancedFilterProps } from "./interface";

const AdvancedFilter = ({
  onApplyFilter,
  appliedAdvancedFilters,
  resetAdvancedFilter,
  handleKeyDownFn,
  propertiesCount,
  loading,
  handleSaveFilter,
  dispatchSearchProperty,
}: AdvancedFilterProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    watch,
  }: any = useFormContext();
  const [expandedAccordion, setExpandedAccordion] =
    useState<AdvancedFilterAccordians>(AdvancedFilterAccordians.None);
  const handleApply = (data: any) => {
    onApplyFilter(data, true);
  };

  const resetAllFilters = () => {
    const filterState: any = { ...DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE };
    delete filterState["searchTerm"];
    const formValue: any = { ...DEFAULT_FORM_VALUE };
    delete formValue["searchTerm"];
    delete formValue["mapShapesPayload"];
    delete formValue["address"];
    resetAdvancedFilter(filterState, formValue);
  };
  useEffect(() => {
    const elementId =
      AdvancedFilterAccordians[
        expandedAccordion as keyof typeof AdvancedFilterAccordians
      ];

    if (elementId) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 300);
    }
  }, [expandedAccordion]);

  const handleOnInputBlur = () => {
    if (isValid) {
      dispatchSearchProperty(watch(), 1, true);
    }
  };

  return (
    <>
      <div className={`filterMenu`}>
        <div className={`filterMenu__info`}>
          <div className={`accordion`} onKeyDown={handleKeyDownFn}>
            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.QuickFilter
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.QuickFilter);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.QuickFilter ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Quick Filter{" "}
                  {watch("quickFilters")?.length > 0
                    ? `(${watch("quickFilters")?.length})`
                    : ""}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <QuickFilter></QuickFilter>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion ===
                AdvancedFilterAccordians.PropertyCharacteristics
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(
                      AdvancedFilterAccordians.PropertyCharacteristics
                    );
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.PropertyCharacteristics ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Property Characteristics{" "}
                  {appliedAdvancedFilters.propertyCharacteristicsFilter > 0 &&
                    `(${appliedAdvancedFilters.propertyCharacteristicsFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expandedAccordion ===
                  AdvancedFilterAccordians.PropertyCharacteristics && (
                  <PropertyCharacteristics
                    onInputBlur={handleOnInputBlur}
                  ></PropertyCharacteristics>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion ===
                AdvancedFilterAccordians.AuctionsPreForeclosure
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(
                      AdvancedFilterAccordians.AuctionsPreForeclosure
                    );
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.AuctionsPreForeclosure ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Auctions & Pre-Foreclosure{" "}
                  {appliedAdvancedFilters.auctionsPreforeclousreFilter > 0 &&
                    `(${appliedAdvancedFilters.auctionsPreforeclousreFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expandedAccordion ===
                  AdvancedFilterAccordians.AuctionsPreForeclosure && (
                  <AuctionsPreForeclosure></AuctionsPreForeclosure>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.Ownership
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.Ownership);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.Ownership ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Ownership & Occupancy{" "}
                  {appliedAdvancedFilters.ownershipFilter > 0 &&
                    `(${appliedAdvancedFilters.ownershipFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expandedAccordion === AdvancedFilterAccordians.Ownership && (
                  <Ownership onInputBlur={handleOnInputBlur}></Ownership>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expandedAccordion === AdvancedFilterAccordians.TaxLiens}
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.TaxLiens);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.TaxLiens ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Tax Liens{" "}
                  {appliedAdvancedFilters.taxLienFilter > 0 &&
                    `(${appliedAdvancedFilters.taxLienFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expandedAccordion === AdvancedFilterAccordians.TaxLiens && (
                  <TaxLiens onInputBlur={handleOnInputBlur}></TaxLiens>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.ValuationEquity
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(
                      AdvancedFilterAccordians.ValuationEquity
                    );
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.ValuationEquity ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Valuation & Equity{" "}
                  {appliedAdvancedFilters.valuationEquityFilter > 0 &&
                    `(${appliedAdvancedFilters.valuationEquityFilter})`}
                </Typography>
              </AccordionSummary>

              <AccordionDetails id="ValuationEquity">
                {expandedAccordion ===
                  AdvancedFilterAccordians.ValuationEquity && (
                  <ValuationEquity
                    onInputBlur={handleOnInputBlur}
                  ></ValuationEquity>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.MortgageInfo
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.MortgageInfo);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.MortgageInfo ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Mortgage Info{" "}
                  {appliedAdvancedFilters.mortgageInfoFilter > 0 &&
                    `(${appliedAdvancedFilters.mortgageInfoFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="MortgageInfo">
                {expandedAccordion ===
                  AdvancedFilterAccordians.MortgageInfo && (
                  <MortgageInfo onInputBlur={handleOnInputBlur}></MortgageInfo>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.CashBuyers
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.CashBuyers);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion === AdvancedFilterAccordians.CashBuyers ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Cash Buyers{" "}
                  {appliedAdvancedFilters.cashBuyersFilter > 0 &&
                    `(${appliedAdvancedFilters.cashBuyersFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="CashBuyers">
                {expandedAccordion === AdvancedFilterAccordians.CashBuyers && (
                  <CashBuyers onInputBlur={handleOnInputBlur}></CashBuyers>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={
                expandedAccordion === AdvancedFilterAccordians.Demographics
              }
              onChange={(e, expanded) => {
                if (isValid) {
                  if (expanded) {
                    setExpandedAccordion(AdvancedFilterAccordians.Demographics);
                  } else {
                    setExpandedAccordion(AdvancedFilterAccordians.None);
                  }
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedAccordion ===
                  AdvancedFilterAccordians.Demographics ? (
                    <MinusCircle />
                  ) : (
                    <PlusCircle />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Demographics{" "}
                  {appliedAdvancedFilters.demographicsFilter > 0 &&
                    `(${appliedAdvancedFilters.demographicsFilter})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails id="Demographics">
                {expandedAccordion ===
                  AdvancedFilterAccordians.Demographics && (
                  <Demographics onInputBlur={handleOnInputBlur}></Demographics>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={` filterMenu__footer`}>
          <div className={`colRow justifyCenter`}>
            <div className={`colRow__col`} onKeyDown={handleKeyDownFn}>
              <Button
                label="Clear All Filters"
                prefix={<Delete />}
                className={`outline full`}
                action={resetAllFilters}
              />
            </div>
            <div className={`colRow__col`} onKeyDown={handleKeyDownFn}>
              <Button
                label="Save filters"
                prefix={<Save />}
                className={`outline full`}
                disabled={!isValid }
                action={handleSaveFilter}
              />
            </div>
            <div className={`colRow__full`}>
              <Button
                label={
                  propertiesCount !== -1
                    ? `Search ${
                        propertiesCount
                          ? formartNumberAsLocalString(propertiesCount)
                          : propertiesCount
                      } properties`
                    : "Apply"
                }
                className={`primary full`}
                action={handleSubmit(handleApply)}
                disabled={!isValid || loading}
                clicked={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvancedFilter;
