import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI, postAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchCampaignLeadList({ payload }: any) {
  try {
    yield put(slice.setLoadingCampaignLeadList(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/lead-list?successList=${payload?.successList}`,
      headers: {},
      data: {},
    });

    yield all([
      put(slice.setCampaignLeadList(response?.data)),
      put(slice.setLoadingCampaignLeadList(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setLoadingCampaignLeadList(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* createCampaignSaga() {
  yield takeLatest(sagaActions.fetchCampaignLeadList, fetchCampaignLeadList);
}

export default createCampaignSaga;
