import { useSelector } from "react-redux";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formatCurrency } from "../../../../utils/utils";
import { getPropertyDetails } from "../../property.selector";
import styles from "./TaxTab.module.scss";

const TaxTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { taxInformation } = propertyDetails;

  return (
    <div className={` ${styles.tax}`}>
      <div className="wrapper">
        <ShadowBox
          title="Tax Information"
          subTitle="Most recent taxes for this property."
        >
          <div className={`dflex ${styles.row}`}>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="APN"
                description={taxInformation?.apn ? taxInformation?.apn : "-" }
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard title="Tax Year" description={taxInformation?.taxYear} />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Tax Amount "
                description={formatCurrency(taxInformation?.taxAmount)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessment Year"
                description={taxInformation?.assessmentYear}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Total Assessed Value"
                description={formatCurrency(taxInformation?.assessedLandValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard title="County" description={taxInformation?.county ? taxInformation?.county  : "-" } />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Land Value"
                description={formatCurrency(taxInformation?.landMarketValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Value"
                description={formatCurrency(taxInformation?.marketValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Market Improvement Value"
                description={formatCurrency(taxInformation?.improvementMarketValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessed Land Value"
                description={formatCurrency(taxInformation?.assessedLandValue)}
              />
            </div>
            <div className={` ${styles.row__col}`}>
              <InfoCard
                title="Assessed Improvement Value"
                description={formatCurrency(taxInformation?.assessedImprovementValue)}
              />
            </div>
          </div>
        </ShadowBox>
      </div>
    </div>
  );
};
export default TaxTab;
