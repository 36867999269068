import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import Close from "../../assets/icons/cross";
import FilterLines from "../../assets/icons/filterLines";
import Save from "../../assets/icons/save";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { createSavedSearchFilters } from "./PropertyService";
import SelectedFilterList from "./SelectedFilterList";
import { SELECTED_FILTER_UPDATE_LIST } from "./constants";
const SaveFilterModal = ({
  isOpen,
  handleClose,
  isAllFilterOpen,
  dispatchSearchProperty,
}: {
  isOpen: boolean;
  handleClose: Function;
  isAllFilterOpen: boolean;
  dispatchSearchProperty: Function;
}) => {
  const { watch, setValue } = useFormContext();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      yup.object({
        searchFiltersName: yup
          .string()
          .trim()
          .required("Search Name is required"),
      })
    ),
    defaultValues: { searchFiltersName: "" },
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (data: any) => {
      const filterFormValue = watch();
      Object.keys(filterFormValue).forEach((key) => {
        if (
          ![
            ...SELECTED_FILTER_UPDATE_LIST,
            "marketStatus",
            "quickFilters",
          ]?.includes(key)
        ) {
          delete filterFormValue[key];
        }
      });
      try {
        setLoading(true);
        const res = await createSavedSearchFilters({
          searchFiltersName: data.searchFiltersName,
          searchFilters: filterFormValue,
        });

        dispatch(
          addToast({
            id: new Date().getTime(),
            message: "Filters Saved Successfully!",
            type: ToastType.success,
          })
        );
        await handleClose();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        dispatch(
          addToast({
            id: new Date().getTime(),
            message:
              error?.response?.data?.message ||
              error?.message ||
              "Something went wrong!",
            type: ToastType.error,
          })
        );
      }
    },
    [watch]
  );

  return (
    <Dialog
      className={`stepsDialog `}
      onClose={() => handleClose()}
      open={isOpen}
    >
      <span role="link" className="closeDialog" onClick={() => handleClose()}>
        <Close />
      </span>
      <div className="dialogWrapper">
        <span className="dialogWrapper__icon">
          <FilterLines />
        </span>
        <div className="dialogWrapper__header">
          <h2>Save Search</h2>
        </div>
        <div className="dialogWrapper__info">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="text"
              label="Search Name"
              register={register}
              name="searchFiltersName"
              placeholder="Search Name"
              errors={errors}
            ></Input>
            <SelectedFilterList
              isAllFilterOpen={isAllFilterOpen}
              dispatchSearchProperty={dispatchSearchProperty}
            />
            <div className="actions">
              <Button
                className="primary full animated"
                prefix={<Save />}
                type="submit"
                label="Save Search"
                disabled={loading}
                clicked={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
export default SaveFilterModal;
