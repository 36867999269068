
const CloseCircle = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
<circle cx="8.46826" cy="8.53174" r="8.46826" fill="#D9D9D9"/>
<path d="M7.02659 4.71582L8.67767 7.46152H8.73625L10.3946 4.71582H11.9432L9.63317 8.46462L11.9798 12.2134H10.4056L8.73625 9.48602H8.67767L7.00829 12.2134H5.44141L7.81003 8.46462L5.47069 4.71582H7.02659Z" fill="white"/>
</svg>
        </>
    );
};

export default CloseCircle;
