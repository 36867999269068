import { createSlice } from "@reduxjs/toolkit";
import { EarlyAccessSteps } from "./enum";

export const initialState = {
  loading: false,
  registeredUser: {},
  currentStep: EarlyAccessSteps.Signup,
  isLoading: false,
};

const signupForEarlyAccessSlice = createSlice({
  name: "signupForEarlyAccess",
  initialState,
  reducers: {
    toggleLoader: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: data.loading,
      };
    },
    registeration: (state, { payload: { data } }) => {
      return {
        ...state,
        registeredUser: data?.data,
      };
    },
    updateCurrentStepState: (state, { payload }) => {
      return {
        ...state,
        currentStep: payload,
      };
    },
    setIsLoading: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
    resetSignupForEarlyAccessState: (state, { payload: { data } }) => {
      return initialState;
    },
  },
});

export const {
  toggleLoader,
  registeration,
  updateCurrentStepState,
  setIsLoading,
  resetSignupForEarlyAccessState,
} = signupForEarlyAccessSlice.actions;

export default signupForEarlyAccessSlice.reducer;
