import styles from "./RegisterSteps.module.scss";
import handDraw from "../../assets/images/hand-drawn-scribble.svg";
import Trophy from "../../assets/icons/trophy";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  createSubscription,
  skipCreditPayment,
} from "../../organisms/billing/billingService";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import {
  ProductPriceIntervalType,
  ProductType,
} from "../../organisms/billing/enum";
import CreditsSubscription from "../../organisms/creditsSubscription/CreditsSubscription";
import { fetchCreditAccessProducts } from "../../organisms/creditsSubscription/CreditsSubscriptionService";
import Loader from "../../atoms/Loader";
import AddPaymentModal from "../../organisms/billing/AddPaymentModal";
import { connect } from "socket.io-client";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { fbc, fbp, fetchIp, gtagEvent, hashNumber, hashString } from "../../utils/utils";
import ReactGA from "react-ga4";
import { GtagEventParams } from "../../models/gtag.interface";

const RegisterSteps2 = ({ paymentMethod }: any) => {
  const [selectedPricing, setSelectedPricing] = useState<any>(null);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [confirmSubscriptionLoading, setConfirmSubscriptionLoading] =
    useState(false);
  const [replacePaymentMethod, setReplacePaymentMethod] = useState(false);
  const [creditPoints, setCreditPoints] = useState(true)
 const [creditAccessProduct,setCreditAccessProduct]= useState([])
const[ openAddPaymentModal, setOpenAddPaymentModal]= useState(false)
const[currentCredits,setCurrentCredits]= useState('')
const profileData = useSelector((state) => getProfileInfo(state));
const socketRef = useRef<any>();
  const confirmSubscription = async () => {
    const payloadData = {
      data: [
        {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          event_source_url: window.location.href,
          action_source: "website",
          user_data: {
            client_ip_address: fetchIp,
            client_user_agent: navigator.userAgent,
            fbc: fbc,
            fbp: fbp,
            em: hashString(profileData?.email),
            ph: hashNumber(profileData?.phone),
            external_id: hashNumber(profileData?.organisationId),
            fn:hashString(profileData?.nameFirst),
            ln:profileData?.nameLast ? hashString(profileData?.nameLast) : null,
          },
          custom_data: {
            value: selectedPricing?.unitAmount,
            currency: "USD",
         },

        },
      ],
    };
    const params: GtagEventParams = {
      event_category: "User",
      event_label: "Purchase",
      user_email: profileData?.email,
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      Id: profileData?.organisationId,
      first_name: profileData?.nameFirst,
      last_name: profileData?.nameLast ? profileData?.nameLast : null,
      value: selectedPricing?.unitAmount,
      currency: "USD",
    };
    if (paymentMethod) {
      try {
        let payload = {
          paymentMethod: paymentMethod,
          price: selectedPricing.stripePriceId,
          subsProductType: ProductType.CREDIT_ACCESS,
          trialPeriodDays: selectedPricing.trialPeriodDays,
        };
        setConfirmSubscriptionLoading(true);
        await createSubscription(payload);
        await FbConversionApi(payloadData);
        gtagEvent('event', 'Purchase', params);
        // ReactGA.event({
        //   category: 'User',
        //   action: 'Purchase',
        //   value: selectedPricing?.unitAmount,
        // });
        setTimeout(() => {
          dispatch(fetchProfileInfo({}));
          setConfirmSubscriptionLoading(false);
          displaySuccessMessage("Subscription confirmed successfully!");
          navigate("/property-search", { state: { prevPath: pathname } });
        }, 3000);
      } catch (error:any) {
        setConfirmSubscriptionLoading(false);
        displayErrorMessage(error);
        if(error?.response?.data?.errorCode === "card_declined"){
          setReplacePaymentMethod(true)
          setOpenAddPaymentModal(true)
        } 
      }
    }
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  const skipCreateSubscription = async () => {
    try {
      const result = await skipCreditPayment({ noThanks: true });
      console.log(result, "result");
      dispatch(fetchProfileInfo({}));
      navigate("/property-search", { state: { prevPath: pathname } });
    } catch (error: any) {
      displayErrorMessage(error);
    }
  };


  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const creditAccessProduct: any = await fetchCreditAccessProducts();
        setCreditAccessProduct(creditAccessProduct.data);
        setCreditPoints(false)
      } catch (error:any) {
        handleShowToast(error?.message, ToastType.error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    socketRef.current = (connect as any)(
      `${process.env.REACT_APP_WS_BASE_URL}/notifications`,
      {
        extraHeaders: {
          "x-user-id": profileData?.id,
        },
      }
    );
   
    socketRef.current.on("subscription-status", (data: any) => { 
      console.log("data",data)
      if(data?.subscriptionStatus ==='success'){
        window?.location?.reload();
      }
      
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);
  
  return (
    <>
    {creditPoints ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
      <div className={` ${styles.register}`}>
        <div className="wrapper-md">
          <div className={`dflex alignCenter ${styles.register__info}`}>
            <div className={` ${styles.registerInfo} ${styles.thanks}`}>
              <div className={` ${styles.success}`}>
                <figure>
                  <Trophy />
                </figure>
              </div>

              <h2>Thanks for signing up.</h2>
              {selectedPricing?.interval === ProductPriceIntervalType.YEAR ? (
                <>
                  <p>
                    As a token of our gratitude, your{" "}
                    <strong>first month</strong> of AI calling is{" "}
                    <strong>free </strong>
                    when subscribed annually.
                  </p>
                </>
              ) : (
                <>
                  {/* <p>
                    As a token of our gratitude, we’re giving you{" "}
                    <strong>
                      {selectedPricing?.discountPercent ?? 0}% off
                    </strong>{" "}
                    your first month of AI calling.
                  </p> */}
                  <p>
                    Let's get you signed up for an <strong>AI calling</strong>{" "}
                    package.
                    <img src={handDraw} alt="hand draw" />
                  </p>
                </>
              )}
              {/* <p>
                <strong> You will never see this offer again.</strong>
       
              </p> */}

              {/* <img src={handDraw} alt="hand draw" /> */}
            </div>
            <div className={` ${styles.formRight}`}>
              <CreditsSubscription
                selectedPricing={selectedPricing}
                confirmSubscriptionLoading={confirmSubscriptionLoading}
                onSubmit={confirmSubscription}
                setSelectedPricing={setSelectedPricing}
                creditAccessProduct={creditAccessProduct}
                setCurrentCredits={setCurrentCredits}
                footerText
              ></CreditsSubscription>
              <div className={` ${styles.formRight__text}`}>
                <p>
                  <span role="link" onClick={skipCreateSubscription}>
                    No thanks, continue without AI Calling.
                  </span>
                </p>
              </div>
            </div>
          </div>
          {openAddPaymentModal && <AddPaymentModal
        handleClose={() => {
          setOpenAddPaymentModal(false);
        }}
        open={openAddPaymentModal}
        replacePaymentMethod={replacePaymentMethod}
      ></AddPaymentModal>}
        </div>
      </div>
      )}
      {/* <WelcomeModal
        topTitle="Who taught you to think like that?"
        topSubtitle="Seriously, Tom just made $30,000 last month using OttoLeads. Check out what he has to say before passing up on this <strong>one-time</strong> offer."
        button={
          <Button
            action={()=>setOpenWelcome(false)}
            className="primary xl full"
            label="Return to Offer."
          />
        }
        video="https://dkmwbxteeq2p9.cloudfront.net/videos/video-launch.mp4"
        button2={
          <Button
            action={skipCreateSubscription}
            className="outline xl full"
            label="No, thanks."
          />
        }
        open={openWelcome}
        handleClose={()=>setOpenWelcome(false)}
      /> */}
      {/* <Footer footerLinks footerWhite /> */}
   
    </>
  );
};
export default RegisterSteps2;
