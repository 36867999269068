
const TickRounded = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M10.666 16.6719L14.666 20.6719L21.3327 12.6719" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default TickRounded;

