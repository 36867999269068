import { initialState } from "./reducer";

const getStateData = (state: any) => state["createList"] || initialState;

export const getOpenCreateListModal = (state:any) => {
  const reducer = getStateData(state);
  return reducer.openCreateListModal;
};
export const getSkipPropertyCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.skipPropertyCount;
};
export const getSkipPropertyCountLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.skipPropertyCountLoading;
};
export const getPropertyCardsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.propertyCardsLoading;
}