

const CallGradient = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<path d="M9.1257 21.4387C7.40748 18.7706 6.12738 15.9022 5.28539 12.9357C5.01838 11.995 4.88488 11.5246 4.88283 10.8364C4.88055 10.0727 5.14949 9.03851 5.52353 8.37267C5.8606 7.77261 6.30196 7.33126 7.18466 6.44856L7.44739 6.18583C8.33312 5.30009 8.77599 4.85722 9.25163 4.61665C10.1976 4.1382 11.3147 4.1382 12.2606 4.61665C12.7362 4.85722 13.1791 5.30009 14.0648 6.18583L14.3896 6.51061C14.9723 7.09329 15.2636 7.38463 15.4541 7.67759C16.1724 8.78241 16.1724 10.2067 15.4541 11.3115C15.2636 11.6045 14.9723 11.8958 14.3896 12.4785C14.1991 12.669 14.1038 12.7643 14.0241 12.8756C13.7408 13.2714 13.6429 13.8451 13.7791 14.3124C13.8175 14.4439 13.8674 14.5478 13.9671 14.7556C14.1685 15.1751 14.3826 15.5899 14.6094 15.9996M18.6362 21.3634L18.7111 21.4387C20.7137 23.4413 22.9781 25.0226 25.3941 26.1826C25.602 26.2824 25.7059 26.3323 25.8373 26.3706C26.3046 26.5068 26.8784 26.409 27.2741 26.1256C27.3854 26.0459 27.4807 25.9506 27.6712 25.7601C28.2539 25.1774 28.5452 24.8861 28.8382 24.6956C29.943 23.9773 31.3673 23.9773 32.4721 24.6956C32.7651 24.8861 33.0564 25.1774 33.6391 25.7601L33.9639 26.0849C34.8496 26.9706 35.2925 27.4135 35.5331 27.8891C36.0115 28.835 36.0115 29.9522 35.5331 30.8981C35.2925 31.3737 34.8496 31.8166 33.9639 32.7023L33.7012 32.9651C32.8185 33.8478 32.3771 34.2891 31.7771 34.6262C31.1112 35.0002 30.0771 35.2692 29.3134 35.2669C28.6251 35.2648 28.1547 35.1313 27.214 34.8643C22.1583 33.4294 17.3877 30.7219 13.4078 26.742L13.3329 26.6669M34.9994 5.00021L4.99935 35.0002" 
stroke="url(#paint0_linear_21497_46228)" 
strokeWidth="2" strokeLinecap="round" 
strokeLinejoin="round"/>
<defs>
<linearGradient id="paint0_linear_21497_46228" x1="4.88281" y1="4.25781" x2="35.8919" y2="35.2669" gradientUnits="userSpaceOnUse">
<stop stop-color="#72EDF2"/>
<stop offset="1" stopColor="#5151E5"/>
</linearGradient>
</defs>
</svg>
        </>
    );
};

export default CallGradient;



