

const CloseDeal = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_21558_45140)">
                    <path d="M18.3346 9.23794V10.0046C18.3336 11.8016 17.7517 13.5502 16.6757 14.9895C15.5998 16.4288 14.0874 17.4817 12.3641 17.9912C10.6408 18.5007 8.79902 18.4395 7.11336 17.8168C5.4277 17.194 3.98851 16.043 3.01044 14.5355C2.03236 13.028 1.56779 11.2447 1.68603 9.45154C1.80427 7.65841 2.49897 5.95155 3.66654 4.5855C4.8341 3.21946 6.41196 2.26743 8.16479 1.8714C9.91763 1.47537 11.7515 1.65655 13.393 2.38794M18.3346 3.33317L10.0013 11.6748L7.5013 9.17484" stroke="url(#paint0_linear_21558_45140)" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_21558_45140" x1="1.66797" y1="1.6665" x2="18.3346" y2="18.3332" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#72EDF2" />
                        <stop offset="1" stopColor="#5151E5" />
                    </linearGradient>
                    <clipPath id="clip0_21558_45140">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default CloseDeal;