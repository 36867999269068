import * as yup from "yup";

export const resetPasswordNewUserSchema = yup.object({
  otp: yup.string(),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s])[A-Za-z\d=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s]{8,}$/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .trim()
    .required("Password is required")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});
