import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import SearchReflation from "../../../assets/icons/searchReflation";
import Input from "../../../atoms/Input";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import { LOAN_TYPE_OPTIONS } from "../constants";

const MortgageInfo = ({ onInputBlur }: { onInputBlur: Function }) => {
  const {
    formState: { errors },
    control,
    register,
  }: any = useFormContext();

  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="mortgageInfoFilter.freeClear"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Free and Clear</h5>
                  <p>Properties without an open mortgage.</p>
                </div>
              }
            />
          </div>
        </div>
        {/* <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="mortgageInfoFilter.assumableDebt"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Assumable Debt</h5>
                  <p>Properties where the mortgage is assumable.</p>
                </div>
              }
            />
          </div>
        </div> */}
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Mortgage Balance ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="mortgageInfoFilter.mortgageMin"
                placeholder="No Min"
                errors={errors?.["mortgageInfoFilter"]?.["mortgageMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="mortgageInfoFilter.mortgageMax"
                placeholder="No Max"
                errors={errors?.["mortgageInfoFilter"]?.["mortgageMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>All Loan Type</p>
          <MultiSelectDropDown
            options={LOAN_TYPE_OPTIONS}
            label="Any"
            name={"mortgageInfoFilter.loanType"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__full`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="mortgageInfoFilter.adjustable_rate"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Exclude Adjustable</h5>
                  <p>Don’t include properties with an adjustable mortgage.</p>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MortgageInfo;
