import { getAPI, putAPI } from "../../apis/apis";

export const addAttempt = (id: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/campaign/add-attempt/${id}`,
    headers: {},
    data: {},
  });
};

export const fetchleadsExportList = (payload:any) => {
  return getAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/campaign/leads/${payload?.id?.id}?sortDirection=${payload?.sortDirection}&sortKey=${payload?.sortKey}&index=${payload?.index}&size=${payload?.size}`,
    headers: {},
  });
};

export const fetchListData = async (payload:any) => {
  return getAPI({
    url: process.env.REACT_APP_BASE_URL + `/campaign/get-list/${payload?.id}`,
    headers: {},
  });
};

export const fetchLeadStatus = (payload:any) => {
  const { id ,status,preview} = payload;
  return getAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/campaign/attempt-status-data/${id}?status=${status}&isPreview=${preview}`,
    headers: {},
  });
};