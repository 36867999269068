import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  promotionChannels: [],
  user: {},
  authIsLoading: false,
  verifyProcess: false,
  refreshPage:true
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    channelsList: (state, { payload: { data } }) => {
      return {
        ...state,
        promotionChannels: data,
      };
    },
    setUser: (state, { payload }) => {
      return {
        ...state,
        user: payload,
      };
    },
    setAuthIsLoading: (state, { payload }) => {
      return { ...state, authIsLoading: payload };
    },
    setVerifyProcess: (state, { payload }) => {
      return { ...state, verifyProcess: payload };
    },
    setRefreshPage:(state, { payload })=>{
      return { ...state, refreshPage: payload };
    },
    resetAuthState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  channelsList,
  setUser,
  setAuthIsLoading,
  setVerifyProcess,
  setRefreshPage,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
