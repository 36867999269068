import Dialog from "@mui/material/Dialog";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import UserPool from "../../userpool";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import Close from "../../assets/icons/cross";
import Logo from "../../components/Logo";
import DropDown from "../../atoms/DropDown";
import { DropDownOption } from "../../models/dropDown.interface";
import Message from "../../assets/icons/message";
import { registerFormSchema } from "../../pages/register/validations";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../molecules/toaster/toastSlice";
// import { createUser } from "./sagaActions";
import {
  channels,
  getAuthIsLoading,
  getUser,
  getVerifyProcess,
} from "./selectors";
import {
  channelsList,
  setAuthIsLoading,
  setUser,
  setVerifyProcess,
} from "./reducer";
import { ToastType } from "../../molecules/toaster/enum";
import MaskedInput from "../../atoms/MaskedInput";
import { Checkbox, FormControlLabel } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  FbConversionApi,
  createUserFn,
  fetchPromotionChannels,
  resendEmailLink,
} from "./authServices";
import {
  fbc,
  fbp,
  fetchIp,
  getParamsValueForSignup,
  hashString,
  hashNumber,
  gtagEvent,
} from "../../utils/utils";
import ReactGA from "react-ga4";
import { GtagEventParams } from "../../models/gtag.interface";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
interface CustomError {
  response?: {
    data?: {
      message?: string;
    };
  };
}

export interface IUSER_DETAILS {
  alternateEmail: null | string;
  bio: null | string;
  cognitoId: string;
  companyName: string;
  country: null | string;
  createdAt: string;
  deletedAt: null | string;
  email: string;
  id: string;
  isSubscribed: boolean;
  jobTitle: null | string;
  name: string;
  nameFirst: string;
  nameLast: null | string;
  profilePhoto: null | string;
  timezone: null | string;
  updatedAt: string;
}

const Register = ({ open, setOpen, stepData, openLogin }: any) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const list = useSelector((state: any) => channels(state));
  const user = useSelector((state: any) => getUser(state));
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerFormSchema),
    defaultValues: getParamsValueForSignup(),
  });

  const userDetails = useSelector((state: any) => state);
  const submitCliked = useSelector((state: any) => getAuthIsLoading(state));
  const verifyProcess = useSelector((state: any) => getVerifyProcess(state));
  const [OTP, setOTP] = useState("");
  const [channel, setChannelList] = useState([]);
  const [filteredColumnsHeader, setFilteredColumnsHeader] = useState<
    DropDownOption[]
  >([]);

  const [columnsHeader, setColumnsHeader] = useState<DropDownOption[]>([]);
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  const handleClose = (event?: any, reason?: string) => {
    if (reason && reason === "backdropClick" && verifyProcess) {
      return;
    }
    dispatch(setVerifyProcess(false));
    reset();
    setOpen(false);
  };
  let referralId = (window as any)?.Rewardful?.referral || null;

  const getPromotionChannelFn = async () => {
    try {
      const response = await fetchPromotionChannels();
      dispatch(channelsList(response));
    } catch (error) {
      console.log("error=", error);
    }
  };

  useEffect(() => {
    getPromotionChannelFn();
  }, []);

  useEffect(() => {
    if (list?.length) {
      const channels = list.map(
        (item: { channelName: string }, index: number) => {
          return {
            key: index,
            label: item.channelName,
            value: item.channelName,
          };
        }
      );
      setChannelList(channels);
    }
  }, [list]);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  // const resendCode = async () => {
  //   try {
  //     const res = await resendConfirmationCode(
  //       userDetails?.auth?.user?.email || ""
  //     );
  //     handleShowToast("OTP sent again", ToastType.success);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  const handleResendCode = async () => {
    setMsg("");
    const data = {
      email: userDetails?.auth?.user?.email,
    };
    try {
      setSubmitClicked(true);
      await resendEmailLink(data);
      setMsg(
        `We've resent the link to your email : ${userDetails?.auth?.user?.email}`
      );
    } catch (error) {
      console.log("Email Not sent");
    } finally {
      setSubmitClicked(false);
    }
  };

  const onSubmit = async (values: any) => {
    let payload = values;
    if (!payload.companyName) {
      payload = { ...payload, companyName: payload.name };
    }
   
    dispatch(setAuthIsLoading(true));
    if(executeRecaptcha){
     const Token = await executeRecaptcha('SignUp')
     payload = {
      ...payload,
      referralCode: referralId,
      email: payload.email.toLowerCase(),
      captchaToken:Token
    };
     try {
      const response = await createUserFn(payload);
      const payloadData = {
        data: [
          {
            event_name: "Complete_registration",
            event_time: Math.floor(Date.now() / 1000),
            event_source_url: window.location.href,
            action_source: "website",
            user_data: {
              client_ip_address: fetchIp,
              client_user_agent: navigator.userAgent,
              fbc: fbc,
              fbp: fbp,
              em: hashString(payload.email),
              ph: hashNumber(payload.phone),
              external_id: hashNumber(response?.data?.result?.organisationId),
            },
          },
        ],
      };
      const params: GtagEventParams = {
        event_category: "User",
        event_label: "sign_up",
        user_email: payload.email,
        event_time: Math.floor(Date.now() / 1000),
        event_source_url: window.location.href,
        ID:response?.data?.result?.organisationId
      };
      gtagEvent('event', 'sign_up', params);
     await FbConversionApi(payloadData);
      dispatch(setUser(response?.data?.result));
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: response?.data?.message,
          type: ToastType.success,
        })
      );
      dispatch(setVerifyProcess(true));
    } catch (e) {
      dispatch(
        addToast({
          id: new Date().getTime(),
          message:
            (e as CustomError)?.response?.data?.message ??
            "Something went wrong!",
          type: ToastType.error,
        })
      );
    } finally {
      dispatch(setAuthIsLoading(false));
    }}else{
      console.error('executeRecaptcha is undefined');
    }
  };

  const verifyAccount = (e: any) => {
    e.preventDefault();
    setOtpVerificationLoading(true);
    const cognitoUser = new CognitoUser({
      Username: user.email,
      Pool: UserPool,
    });

    try {
      cognitoUser.confirmRegistration(OTP, true, (err, data) => {
        if (err) {
          handleShowToast(err.message, ToastType.error);
        } else {
          handleShowToast(data?.message || "Signup successfully");
          openLogin(true);
          handleClose();
        }
        setOtpVerificationLoading(false);
      });
    } catch (e: any) {
      handleShowToast(e.message, ToastType.error);
      setOtpVerificationLoading(false);
    }
  };
  const handleOnDropDownValueChange = () => {
    const filterData = columnsHeader.filter((header: DropDownOption) => {
      let res = true;
      Object.keys(stepData).forEach((key: any) => {
        const value = watch(key);
        if (res) {
          res = header.value !== value.toString();
        }
      });
      return res;
    });
    setFilteredColumnsHeader(filterData);
  };
  // const handleGoogleSignIn = async () => {
  //   try {
  //     await GoogleSignIn();
  //   } catch (err: any) {
  //     handleShowToast(err?.message ?? "something went wrong", ToastType.error);
  //   }
  // };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog loginRegister`}
        data-testid="signUpDialog"
      >
        <div
          className={`dialogWrapper authStyle ${
            verifyProcess == false ? `left-align` : `otpDialog`
          }`}
        >
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>

          {verifyProcess == false ? (
            <>
              <div className="authStyle__logo flex alignCenter justifyCenter">
                <Logo />
              </div>
              <div className="authStyle__header">
                <h2>Sign up</h2>
                <p>Create your OttoLeads account.</p>
              </div>
              <form>
                <Input
                  type="text"
                  label="Name*"
                  register={register}
                  name="name"
                  placeholder="Enter your name"
                  errors={errors}
                ></Input>
                <Input
                  type="email"
                  label="Email*"
                  register={register}
                  name="email"
                  placeholder="Enter your email"
                  errors={errors}
                ></Input>
                <MaskedInput
                  placeholder="Enter your phone number"
                  label="Phone*"
                  name="phone"
                  register={register}
                  errors={errors}
                  mask={"999-999-9999"}
                ></MaskedInput>
                <Input
                  type="text"
                  label="Company name"
                  register={register}
                  name="companyName"
                  placeholder="Enter your company name"
                  errors={errors}
                ></Input>

                <Input
                  type="password"
                  label="Password*"
                  register={register}
                  name="password"
                  placeholder="Create a password"
                  errors={errors}
                ></Input>
                <DropDown
                  options={channel}
                  label="How did you hear about us?*"
                  control={control}
                  name="hearAboutUs"
                  errors={errors}
                  placeholder="Tell us how you hear about us"
                  //disabled={watch("skiipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                ></DropDown>
                <div className="authStyle__links flexCol ">
                  <FormControlLabel
                    control={
                      <Controller
                        name="confirmPolicy"
                        control={control}
                        render={({ field, ...props }) => {
                          return (
                            <>
                              <Checkbox
                                checked={field.value}
                                onChange={field.onChange}
                              />
                            </>
                          );
                        }}
                      />
                    }
                    label={
                      <div className="checkboxDesc">
                        By checking the checkbox and clicking “Sign Up” below, I
                        am providing my ESIGN signature and express written
                        consent agreement to permit OttoLeads, and parties
                        calling on their behalf, to contact me at the number and
                        email provided above for marketing purposes, including
                        through the use of automated technology, SMS/MMS
                        messages, AI generative voice, email, and prerecorded
                        and/or artificial voice messages. I also agree to the{" "}
                        <NavLink
                          target="_blank"
                          to="/policies/terms-of-use"
                          role="link"
                        >
                          TERMS OF USE
                        </NavLink>{" "}
                        and{" "}
                        <NavLink
                          target="_blank"
                          to="/policies/privacy-policy"
                          role="link"
                        >
                          PRIVACY POLICY.
                        </NavLink>
                      </div>
                    }
                  />
                  {(errors?.confirmPolicy?.message as string) && (
                    <span className="error">
                      {errors?.confirmPolicy?.message as string}
                    </span>
                  )}
                </div>
                <div className="authStyle__footer">
                  <Button
                    label="Sign Up"
                    className="primary full xl"
                    action={handleSubmit(onSubmit)}
                    clicked={submitCliked}
                    disabled={submitCliked}
                  ></Button>
                  {/* <Button
                    label="Sign in with Google"
                    className="outline full xl"
                    action={handleGoogleSignIn}
                    prefix={<GoogleIcon />}
                  ></Button> */}
                </div>
                <div className="authStyle__footerInfo">
                    <p>
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"  target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms"  target="_blank">Terms of Service</a> apply.
    </p>
    </div>
              </form>
            </>
          ) : (
            <>
              <div className={`authStyle__Icon`}>
                <span className={`flag`}>
                  <Message />
                </span>
              </div>
              <div className="authStyle__header">
                <h2>Please check your email.</h2>
                {!msg && (
                  <p>
                    We've sent a link to{" "}
                    <strong>{userDetails?.auth?.user?.email}</strong>
                  </p>
                )}
                {msg && <p>{msg}</p>}
              </div>
              <div className="flex justifyCenter">
                <Button
                  className="primary"
                  label="Resend Link"
                  action={handleResendCode}
                  disabled={submitClicked}
                  clicked={submitClicked}
                />
              </div>

              {/* <form onSubmit={verifyAccount}>
                <div className="otp">
                  <div className="otp__form">
                    <OtpInput
                      value={OTP}
                      inputType="number"
                      onChange={setOTP}
                      numInputs={6}
                      renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <p>
                    Didn’t get a code?{" "}
                    <span role="link" onClick={resendCode}>
                      Click to resend.
                    </span>
                  </p>
                </div>

                <div className="authStyle__footer dflex">
                  <div className="buttonHalf">
                    <Button
                      label="Cancel"
                      className="outline xl full"
                      action={handleClose}
                    ></Button>
                  </div>
                  <div className="buttonHalf">
                    <Button
                      label="Verify"
                      className="primary xl full"
                      disabled={!OTP || otpVerificationLoading}
                      clicked={otpVerificationLoading}
                      action={verifyAccount}
                    ></Button>
                  </div>
                </div>
              </form> */}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default Register;
