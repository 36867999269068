
const LeadsNav = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g id="target-04" clipPath="url(#clip0_21249_41007)">
<g id="Icon">
<path d="M15.8346 1.66699L13.3346 4.16699V6.66699H15.8346L18.3346 4.16699L16.6679 3.33366L15.8346 1.66699Z" fill="#FAC515"/>
<path d="M13.3346 6.66699L10.0013 10.0003L13.3346 6.66699Z" fill="#FAC515"/>
<path d="M13.3346 6.66699V4.16699L15.8346 1.66699L16.6679 3.33366L18.3346 4.16699L15.8346 6.66699H13.3346ZM13.3346 6.66699L10.0013 10.0003M18.3346 10.0003C18.3346 14.6027 14.6037 18.3337 10.0013 18.3337C5.39893 18.3337 1.66797 14.6027 1.66797 10.0003C1.66797 5.39795 5.39893 1.66699 10.0013 1.66699M14.168 10.0003C14.168 12.3015 12.3025 14.167 10.0013 14.167C7.70012 14.167 5.83464 12.3015 5.83464 10.0003C5.83464 7.69914 7.70012 5.83366 10.0013 5.83366" 
stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_21249_41007">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
        </>
    );
};

export default LeadsNav;


