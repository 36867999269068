const Flag = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24" height="24"
                viewBox="0 0 24 24"
                fill="none">
                <g
                    id="flag-05">
                    <path
                        id="Icon"
                        d="M14.0904 6.72222H20.0442C20.5163 6.72222 20.7524 6.72222 20.8904 6.82149C21.0109 6.9081 21.0893 7.04141 21.1065 7.18877C21.1263 7.35767 21.0116 7.56403 20.7823 7.97677L19.3615 10.5343C19.2783 10.684 19.2367 10.7589 19.2204 10.8381C19.206 10.9083 19.206 10.9806 19.2204 11.0508C19.2367 11.13 19.2783 11.2049 19.3615 11.3545L20.7823 13.9121C21.0116 14.3248 21.1263 14.5312 21.1065 14.7001C21.0893 14.8475 21.0109 14.9808 20.8904 15.0674C20.7524 15.1667 20.5163 15.1667 20.0442 15.1667H12.6126C12.0215 15.1667 11.7259 15.1667 11.5001 15.0516C11.3015 14.9504 11.14 14.7889 11.0388 14.5903C10.9237 14.3645 10.9237 14.0689 10.9237 13.4778V10.9444M7.2293 21.5L3.00708 4.61111M4.59045 10.9444H12.4015C12.9927 10.9444 13.2883 10.9444 13.5141 10.8294C13.7127 10.7282 13.8742 10.5667 13.9754 10.3681C14.0904 10.1423 14.0904 9.84672 14.0904 9.25556V4.18889C14.0904 3.59772 14.0904 3.30214 13.9754 3.07634C13.8742 2.87773 13.7127 2.71625 13.5141 2.61505C13.2883 2.5 12.9927 2.5 12.4015 2.5H4.64237C3.90504 2.5 3.53638 2.5 3.28422 2.65278C3.06322 2.78668 2.89901 2.99699 2.82271 3.24387C2.73566 3.52555 2.82508 3.88321 3.00391 4.59852L4.59045 10.9444Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>

        </>
    );
};

export default Flag;
