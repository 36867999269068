const Close = (props: any) => {
  return (
    <>
      <svg
        onClick={props.onClick ? props.onClick : () => {}}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g id="x-close">
          <path
            id="Icon"
            d="M18 6L6 18M6 6L18 18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default Close;
