import Marquee from "react-fast-marquee";
import styles from "./homeSliders.module.scss";

const Slider = ({ children, rightDirection }: any) => {
  return (
    <div className={` ${styles.sliderMain}`}>
      <Marquee autoFill direction={rightDirection} speed={100}>
        {children}
      </Marquee>
    </div>
  );
};

export default Slider;