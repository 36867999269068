import { useCallback } from "react";
import { MapViewMode } from "./map.types";

interface ViewModeControlsProps {
  setViewMode: (value: MapViewMode) => void;
}

const ViewModeControls = ({ setViewMode }: ViewModeControlsProps) => {
  const handleMapButtonClick = useCallback(() => {
    setViewMode(MapViewMode.ROADMAP);
  }, [setViewMode]);

  const handleSatelliteButtonClick = useCallback(() => {
    setViewMode(MapViewMode.HYBRID);
  }, [setViewMode]);

  return (
    <div className="mapControls">
         <div className="mapControls__inner">
         <button role="link" onClick={handleMapButtonClick}>Map</button>
      <button role="link" onClick={handleSatelliteButtonClick}>Satellite</button>
         </div>
     
    </div>
  );
};

export default ViewModeControls;
