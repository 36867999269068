import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { setSubscriptions } from "../../organisms/billing/reducer";
import { createNewOrganization } from "../../pages/settings/settingsService";
import { fetchProfileInfo } from "../profileMenu/sagaActions";
import { getProfileInfoLoading } from "../profileMenu/selectors";
const CreateOrganizationModal = ({
  isOpen,
  handleIsOpen,
}: {
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const profileInfoLoading = useSelector((state) =>
    getProfileInfoLoading(state)
  );

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      yup.object({
        organisationName: yup
          .string()
          .trim()
          .required("Organisation Name is required"),
      })
    ),
    defaultValues: { organisationName: "" },
  });

  useEffect(() => {
    if (!profileInfoLoading && isLoading) {
      setIsLoading(false);
      displayToast("Organisation Created successfully!", ToastType.success);
      navigate("/onboarding");
    }
  }, [profileInfoLoading]);

  const onSubmit = async (data: { organisationName: string }) => {
    try {
      setIsLoading(true);
      const res = await createNewOrganization(data);
      console.log(res);
      dispatch(fetchProfileInfo({}));
      dispatch(setSubscriptions({ creditSubData: null, generalSubData: null }));
    } catch (error: any) {
      setIsLoading(false);
      displayToast(error?.message, ToastType.error);
    }
  };

  const displayToast = (message: string, type: ToastType) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  return (
    <>
      <Dialog
        className="stepsDialog contactInfoModal"
        onClose={() => !isLoading && handleIsOpen(false)}
        open={isOpen}
      >
        <span
          role="link"
          className="closeDialog"
          onClick={() => handleIsOpen(false)}
        >
          <Close />
        </span>
        <div className="dialogWrapper">
          <div className="dialogWrapper__header">
            <h2>Create New Organisation</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="text"
              label="Organisation Name"
              register={register}
              name="organisationName"
              placeholder="Enter Organisation Name"
              errors={errors}
            ></Input>
            <div className="actions">
              <div className="mainCol">
                <div className="mainCol__half">
                  <Button
                    className="outline animated full"
                    label="Cancel"
                    action={() => handleIsOpen(false)}
                  ></Button>
                </div>
                <div className="mainCol__half">
                  <Button
                    className="primary animated full"
                    type="submit"
                    label="Create Organisation"
                    clicked={isLoading}
                  ></Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
export default CreateOrganizationModal;
