import * as yup from "yup";

export const registerFormSchema = yup.object({
  name: yup.string().trim().required("Name is required"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  companyName: yup.string().trim(),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s])[A-Za-z\d=|[\]\\*.!@#$%^&(){}:;<>,.?/~_+-\s]{8,}$/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  hearAboutUs: yup.string().required("This field is required"),
  phone: yup
    .string()
    .transform((value) => {
      return value.replaceAll("-", "");
    })
    .required("Please enter valid phone no.")
    .matches(
      new RegExp("[0-9]{10}"),
      "Phone number should have atleast 10 digits."
    ),
  confirmPolicy: yup.boolean().oneOf([true], "Please confirm policy first."),
});
