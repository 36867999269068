import { useEffect, useState } from "react";
import styles from "./HeroSection.module.scss";
import Pill from "../../atoms/Pill";
import { Tooltip } from "@mui/material";
import HelpCircle from "../../assets/icons/helpCircle";
import { useHelpModalContext } from "../../context/HelpModalContext";
import { useLocation } from "react-router-dom";
import { pageNameFn } from "../auth/enum";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import WelcomeModal from "../welcomeModal/WelcomeModal";
import Button from "../../atoms/Button";
import { getPillStatus } from "../../constants/status.constants";
import { getPillStatusIcon } from "../../atoms/PillStatusIcon";
import { HeroSectionProps } from "./interface";
import { ModalCloseReason } from "../welcomeModal/interface";

const HeroSection = ({
  title,
  desc,
  campaign,
  children,
}: React.PropsWithChildren<HeroSectionProps>) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { openWelcomeModal, modalContent, setModalContent } = useHelpModalContext();
  const [showModal, setShowModal] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const campaignId : string =location?.pathname.split("/")[2];

  const page: any = pageNameFn(location?.pathname.split("/")[1],campaignId);
 

  const handleClose = (event : React.SyntheticEvent<Element, Event>, reason : ModalCloseReason) => {
    if(reason === "backdropClick" || reason === "escapeKeyDown") return;
    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      dispatch(
        updateProfileAction({
          [page?.pagename]: true,
          organisationName: profileInfo?.organisation?.organisationName,
        })
      );
    }

    setShowModal(false);
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      setShowModal(true);
    }
  }, [location?.pathname]);

  return (
    <>
      <div className={`${styles.hero} flex alignCenter`}>
        <div className={`${styles.hero__inner}`}>
          <div
            className={`dflex justifySpaceBetween alignCenter ${styles.heroInfo}`}
          >
            <div>
              <h1>
                {title}{" "}
                {!location?.pathname.includes("/settings") && (
                  <Tooltip title={""} data-testid={"helpTestId"}>
                    <span
                      onClick={() => openWelcomeModal(page)}
                      role="link"
                      className="inlineFlex"
                    >
                      <HelpCircle />
                    </span>
                  </Tooltip>
                )}
              </h1>
              {desc && <p>{desc}</p>}
              {campaign && (
                <p className={`${styles.campaigns}`} >
                  Campaign Status:{" "}
                  <Pill
                    className={getPillStatus(campaign)}
                    infix={getPillStatusIcon(campaign)}
                    label={campaign.charAt(0).toUpperCase() + campaign.slice(1)}
                  />
                </p>
              )}
            </div>
            {showModal && !location?.pathname.includes("/settings") && (
              <WelcomeModal
                title={modalContent.title}
                subtitle={modalContent.subtitle}
                button={
                  <Button
                    action={handleClose}
                    className="primary xl full"
                    label="Got it"
                  />
                }
                video={modalContent.video}
                open={showModal}
                handleClose={handleClose}
              />
            )}

            <div className={`dflex ${styles.heroButton}`}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
