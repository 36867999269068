import Slider from "./Slider";
import slider1 from "../../../assets/images/slider-ai.webp";
import slider2 from "../../../assets/images/slider2.png";
import styles from "./homeSliders.module.scss";
import Bag from "../../../assets/icons/bag";
import PauseIcon from "../../../assets/icons/pauseIcon";
import audio from "../../../assets/images/audio-graph.svg";

const TopSlider = () => {
  return (
    <Slider>
      <div>
        <div className={` ${styles.sliderBox}`}>
          <figure>
            <img src={slider1} alt="customer" />
          </figure>
        </div>
      </div>
      <div>
        <div className={` ${styles.sliderBox}`}>
          <figure>
            <img src={slider2} alt="customer" />
          </figure>
        </div>
      </div>
       <div>
        <div className={` ${styles.sliderBoxContent}`}>
          <figure className={` ${styles.icon}`}>
            <Bag />
          </figure>
          <figcaption>Your first fully-trained, intelligent AI employee.</figcaption>
        </div>
      </div>
      <div>
        <div className={` ${styles.sliderBoxBlue}`}>
          <div className={`flex alignCenter ${styles.audio}`}>
            <figure>
              <PauseIcon />
            </figure>
            <figcaption>
              <img src={audio} alt="icon" />
            </figcaption>
          </div>
          <h2>Your AI Smart Lead Summary</h2>
          <p>
            Ronald who expressed interested in selling his property at 372 Chuck
            Rd. Jonathan mentioned the house is in pretty good shape and just
            needs paint. He’s looking to get
          </p>
        </div>
      </div> 
    </Slider>
  );
};

export default TopSlider;