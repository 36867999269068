import { ReactNode } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const CircularProgressbar = ({
  value,
  children,
}: {
  value: number;
  children: ReactNode;
}) => {
  return (
    <CircularProgressbarWithChildren
      value={value}
      styles={{
        path: {
          stroke: `#005DA4`,
          strokeLinecap: 'round',
        },
        trail: {
          stroke: "#E9ECEF",
          strokeLinecap: 'round',
        },

      }}
      
    >
      {children}
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressbar;
