import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import DropDown from "../../atoms/DropDown";
import Header from "../../components/header/Header";
import styles from "./BuyList.module.scss";
import Zap from "../../assets/icons/zap";
import MessageChat from "../../assets/icons/messageChat";
import ChartBreakOut from "../../assets/icons/chartBreakout";
import Pill from "../../atoms/Pill";
const RECORDS_OPTION = [{ value: 'option1', label: 'option1' }, { value: 'option1', label: 'option1' }, { value: 'option1', label: 'option1' }]

const BuyList = () => {
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        mode: "onSubmit"
    });
    return <>
        {/* <Header loggedIn></Header> */}
        <div className={` ${styles.buyList}`}>
            <div className="wrapper-md">
                <div className={` ${styles.buyList__header}`}>
                    <h2>Data made easy</h2>
                    <p>Get Ready to revolutionize your leads flow </p>
                </div>
                <div className={`dflex alignStart ${styles.buyList__info}`}>
                    <div className={` ${styles.buyInfo}`}>
                        <ul>
                            <li>
                                <figure>
                                    <MessageChat />
                                </figure>
                                <figcaption>
                                    <h3>The new era of data.</h3>
                                    <p>Our unrivaled data is fine-tuned by top experts in the industry, setting stringent parameters for extracting the most accurate and relevant data, delivering unparalleled results.</p>
                                </figcaption>
                            </li>
                            <li>
                                <figure>
                                    <Zap />
                                </figure>
                                <figcaption>
                                    <h3>Precision targeting.</h3>
                                    <p>Harnessing the power of our expertly-tuned filters, we curate opportunities for traditional purchase, wholesale, and creative finance deals.</p>
                                </figcaption>
                            </li>
                            <li>
                                <figure>
                                    <ChartBreakOut />
                                </figure>
                                <figcaption>
                                    <h3>Revolutionary ecosystem.</h3>
                                    <p>Step into the world of effortlessly pulling data with OttoLeads, where choosing the right filter, exporting data, and managing spreadsheets is a thing of the past.</p>
                                </figcaption>
                            </li>
                        </ul>
                    </div>
                    <div className={` ${styles.buyCard}`}>
                        <div className={`dflex justifySpaceBetween alignCenter ${styles.buyCard__header}`}>
                            <div>
                            <h2>Skip Traced Data <Pill label="Popular" className="completed"/></h2>
                            <p>Data like you’ve never seen.</p>
                            </div>
                            <div className={` ${styles.price}`}>
                                <small>$</small>
                                349
                            </div>
                        </div>
                        <div className={`dflex justifySpaceBetween alignCenter ${styles.buyCard__info}`}>
                        <header className="dflex justifySpaceBetween alignCenter">
                            <div>
                            <h3>RECORDS</h3>
                            <p>
                            Select a list size.
                            </p>
                            </div>
                            <DropDown
                            options={RECORDS_OPTION}
                            label=""
                            control={control}
                            name="records"
                            errors={errors}
                            placeholder="2,000 records"
                        ></DropDown>
                        </header>
                        <ul>
                            <li>
                            Up to 3 numbers per record
                            </li>
                            <li>
                            Up to 3 emails per record
                            </li>
                            <li>
                            Mortgage details
                            </li>
                            <li>
                            Equity details
                            </li>
                            <li>
                            Full property details
                            </li>
                            <li>
                            Lien & foreclosure details
                            </li>

                        </ul>
                        </div>
                        <div className={`dflex justifySpaceBetween alignCenter ${styles.buyCard__footer}`}>
                        <Button label={'Get started'} className="primary animated full xl" action={handleSubmit}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default BuyList;