export const downPaymentCalculator = (symbol: any, value1: any, value2: any) => {
  if (isNaN(value1) || isNaN(value2)) {
    return 0;
  }
  if (symbol === "percent") {
    return (value1 * value2) / 100;
  } else {
    return value2;
  }
};

export function calculateCumulativeInterest(
  includeCalculation: any,    
  annualRate: any,             
  totalYears: any,            
  presentValue: any,           
  calculationYears: any   
): number {

  const convertToNumber = (value: any): number => {
    if (typeof value === 'string') {
      const number = parseFloat(value);
      return isNaN(number) ? 0 : number;
    }
    return typeof value === 'number' ? value : 0;
  };

  const convertToBoolean = (value: any): boolean => {
    if (typeof value === 'string') {
      return value.trim().toLowerCase() === 'true' || value === '1';
    }
    return Boolean(value);
  };
  const annualRateDecimal = convertToNumber(annualRate) / 100; // Convert percentage to decimal
  const totalMonths = convertToNumber(totalYears) * 12; // Total number of months
  const principal = convertToNumber(presentValue);
  const monthsForCalculation = convertToNumber(calculationYears) * 12; // Number of months for calculation

  // Check if all values are valid
  if ([annualRateDecimal, totalMonths, principal, monthsForCalculation].some(isNaN)) {
    return 0;
  }
  const monthlyRate = annualRateDecimal / 12;
  const calculateCumulativeInterestPayment = (
    monthlyRate: number,
    totalMonths: number,
    principal: number,
    monthsForCalculation: number
  ): number => {
    const totalPayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1);
    let sumInterest = 0;
    let remainingPrincipal = principal;

    for (let i = 1; i <= monthsForCalculation; i++) {
      const interestPayment = remainingPrincipal * monthlyRate;
      sumInterest += interestPayment;
      const principalPayment = totalPayment - interestPayment;
      remainingPrincipal -= principalPayment;
    }

    return sumInterest;
  };

  let result:number;
  if (convertToBoolean(includeCalculation)) {
    const cumulativeInterest = calculateCumulativeInterestPayment(monthlyRate, totalMonths, principal, monthsForCalculation);
    result= cumulativeInterest * 1;
    return parseFloat(result.toFixed(2)); 
  } else {
    return 0;
  }
}


export function calculateSellerFinanceBalanceEnd(
  C11: boolean | string,
  C12: number | string,
  C13: number | string,
  C10: number | string,
  H13: number | string,
  H10: number | string
): number {

  if (typeof C11 === 'string') {
    C11 = C11.toLowerCase() === 'true';
  } else if (typeof C11 !== 'boolean') {
    return 0; // Return 0 if C11 is neither boolean nor a valid string representation
  }

  C12 = parseFloat(String(C12));
  C13 = parseFloat(String(C13));
  C10 = parseFloat(String(C10));
  H13 = parseFloat(String(H13));
  H10 = parseFloat(String(H10));

  // Check for validity of numeric inputs
  if (isNaN(C12) || isNaN(C13) || isNaN(C10) || isNaN(H13) || isNaN(H10)) {
    return 0; // Return 0 if any numeric input is invalid
  }

  function PV(rate: number, numberOfPeriods: number, paymentAmount: number): number {
    if (rate === 0) {
      return -paymentAmount * numberOfPeriods;
    }
    const factor = Math.pow(1 + rate, -numberOfPeriods);
    return -paymentAmount * (1 - factor) / rate;
  }
  if (C11) {
    const rate = C12 / 12 / 100; 
    const numberOfPeriods = (C13 - C10) * 12;
    const paymentAmount = -H13;
    return parseFloat(PV(rate, numberOfPeriods, paymentAmount).toFixed(2));
  } else {
    return parseFloat((H10 - H13 * 12 * C10).toFixed(2));
  }
}



export function calculatePaymentOrDifference(
  C11: boolean,
  C12: number|string,
  C13: number|string,
  H10: number|string,
  C20: number|string,
  C19: number|string,
  H29: number|string,
  H17: number|string
): number {
  
  const pmt = (rate: number, periods: number, principal: number): number => {
    if (rate === 0) return -principal / periods; 
    return (-principal * rate) / (1 - Math.pow(1 + rate, -periods));
  };

  
  C12 = parseFloat(String(C12));
  C13 = parseFloat(String(C13));
  H10 = parseFloat(String(H10));
  C20 = parseFloat(String(C20));
  C19 = parseFloat(String(C19));
  H29 = parseFloat(String(H29));
  H17 = parseFloat(String(H17));

  const isValidNumber = (value: any): value is number => !isNaN(parseFloat(value)) && isFinite(value);

  if (![C12, C13, H10, C20, C19, H29, H17].every(isValidNumber)) return 0;
  let result: number;
  if (C11) {
    const monthlyRate = C12 / 12 / 100; 
    const totalPeriods = 12 * C13;
    const principal = -H10; 
    result= pmt(monthlyRate, totalPeriods, principal);
  } else {
    result= C20 - C19 - H29 - H17;
  }
  return parseFloat(result.toFixed(2));
}

export const subToBalanceAtBalloonEnd = (
  rate: number | string,
  numberOfYears: number | string,
  paymentAmount: number | string,
  presentValue: number | string,
  endOrBeginning: boolean = false
): number => {
    const toNumber = (value: number | string): number => {
        const num = typeof value === 'number' ? value : parseFloat(value);
        return isNaN(num) ? 0 : num;
    };

    const monthlyRate = -toNumber(rate) / 12 / 100;
    const totalPeriods = -toNumber(numberOfYears) * 12;
    const payment = -toNumber(paymentAmount); 
    const presentValueNum = -toNumber(presentValue); 

    const paymentAdjustment = endOrBeginning ? 1 : 0;

    const futureValue = (
        presentValueNum * Math.pow(1 + monthlyRate, totalPeriods) +
        payment * (
            (Math.pow(1 + monthlyRate, totalPeriods) - 1) / monthlyRate
        ) * (1 + monthlyRate * paymentAdjustment)
    );

    return  parseFloat(Math.abs(futureValue).toFixed(2));
};

export const calculateTotalFinancedAmount = (
  totalFigurePurchase: number | string,
  upfront: number
): number => {
  const totalFigurePurchasePrice =
    typeof totalFigurePurchase === "number" ? totalFigurePurchase : parseFloat(totalFigurePurchase);
  const totalFinancedAmount = totalFigurePurchasePrice - upfront;
  return isNaN(totalFinancedAmount) || totalFinancedAmount < 0
    ? 0
    : totalFinancedAmount;
};

export const addValues = (...values: (number | string)[]): number => {
  const toNumber = (value: number | string): number => {
    const num = typeof value === "number" ? value : parseFloat(value);
    return isNaN(num) ? 0 : num;
  };
  const total = values.reduce<number>((acc, value) => acc + toNumber(value), 0);
  return parseFloat(total.toFixed(2));
};

export const conditionalValue = (
  condition: boolean | string,
  valueIfFalse: number | string
): number => {
  const numValueIfFalse =
    typeof valueIfFalse === "number" ? valueIfFalse : parseFloat(valueIfFalse);
   const result= !condition ? (isNaN(numValueIfFalse) ? 0 : numValueIfFalse) : 0
  return result;
};



  export const calculateMultiplication = (
    value1: number | string,
    value2: number | string  
  ): number => {
    const numValue1 = typeof value1 === 'number' ? value1 : parseFloat(value1);
    const numValue2 = typeof value2 === 'number' ? value2 : parseFloat(value2);
  let result:number
    if (isNaN(numValue1) || isNaN(numValue2)) {
      return 0;
    }
   result=numValue1 * numValue2
    return parseFloat(result.toFixed(2));
  }

  export const calculateDifference = (
    value1: number | string,
    value2: number | string
  ): number => {
    const toNumber = (value: number | string): number =>
      isNaN(Number(value)) ? 0 : Number(value);
    const difference = toNumber(value1) - toNumber(value2);
    return parseFloat(difference.toFixed(2));
  };


  export const calculateDivision = (
    value1: number | string,
    value2: number | string 
  ): number  => {
    const num1 = typeof value1 === 'number' ? value1 : Number(value1);
    const num2 = typeof value2 === 'number' ? value2 : Number(value2);
    if (num2 === 0) return 0;
    const result = num1 / num2
    return result; 
  };


  export const percentageToDecimal = (value: string): number => {
    if (typeof value === 'string') {
      const percentage = parseFloat(value);
      return isNaN(percentage) ? 0 : percentage / 100;
    }
    return 0;
  };


  export const estimatedResaleProfit = (
    value1: number | string,
    value2: number | string
  ): string => {
    const toNumber = (value: number | string): number =>
      isNaN(Number(value)) ? 0 : Number(value);
    const num1 = toNumber(value1);
    const num2 = toNumber(value2);
    const profit = num1 - num1 * 0.07 - num2;
    return profit.toFixed(2); 
  };