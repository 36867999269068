import {useRef, useState} from 'react';
import styles from "./VideoModal.module.scss";
import videoPoster from "../../../assets/images/video-poster-beta.jpg";
import VideoPlay from '../../../assets/icons/videoPlay';
import ShareIcon from '../../../assets/icons/shareIcons';
import { VideoModalProps } from '../HomeInterface';
import Dialog from "@mui/material/Dialog";
import Close from '../../../assets/icons/cross';
import welcomePoster from "../../../assets/images/welcome-poster.jpeg";


const VideoModal = ({setOpenShareModal,open,setOpen}:VideoModalProps) =>{

    const videoElement: any = useRef(null);
    const [videoStarted, setVideoStarted] = useState<boolean>(false);
    const handleOnPlayVideo = () => setVideoStarted(true);
    const handleOnPauseVideo = () => setVideoStarted(false);

    const playVideo = () => {
        if (videoElement) {
          videoElement?.current?.play();
        }
      };

    const handleClose=()=>{
        setOpen(false);
    }

    return(

        <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog videoModal`}
      >
           <div className="dialogWrapper authStyle">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>

        <div className={` ${styles.videoModal}`}>
          <video
            ref={videoElement}
            onPause={handleOnPauseVideo}
            onPlay={handleOnPlayVideo}
            poster={welcomePoster}
            width="400"
            controls
            controlsList={"nodownload noplaybackrate"}
          >
            <source
              src="https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Beta%20Launch%20Video_Updated_2024-05-08.mov"
              type="video/mp4"
            />
          </video>
          {!videoStarted && (
            <>
              <span
                role="link"
                className={` ${styles.videoPlay}`}
                onClick={playVideo}
              >
                <VideoPlay />
              </span>
              <span
                role="link"
                className={` ${styles.shareIcon}`}
                onClick={() => setOpenShareModal(true)}
              >
                <ShareIcon />
              </span>
            </>
          )}

        </div>
        </div>
        </Dialog>

    )
}

export default VideoModal;