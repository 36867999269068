import CsvDownloader from "react-csv-downloader";
import Cloud from "../assets/icons/cloud";
import Loader from "./Loader";
const ExportToCsv = ({
  data,
  headers,
  label,
  filename,
  handleChange,
  loadData,
  loading,
  modalButton
}: any) => {
  return (
    <CsvDownloader
      className={`button  ${modalButton?'primary animated':'outline'} ${label?'':'icon'} ${loading && 'loadingBtn'}`}
      filename={filename}
      datas={loadData}
      columns={headers}
      disabled={loading}
    >
      {loading ? <Loader size={20} /> : <Cloud />} {label}
    </CsvDownloader>
  );
};

export default ExportToCsv;
