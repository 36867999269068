import { Dialog } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { CustomModalProps } from "./interface";

const CustomModal = ({
  open,
  handleClose,
  children,
  customClass,
}: PropsWithChildren<CustomModalProps>) => {
  return (
    <Dialog className={`stepsDialog ${customClass ? customClass : 'propertyDetail'}`} onClose={handleClose} open={open}>
        {children}
    </Dialog>
  );
};

export default CustomModal;
