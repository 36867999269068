import { ClickAwayListener, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownIcon from "../../assets/icons/arrowDownIcon";
import ListIcon from "../../assets/icons/listIcon";
import SearchReflation from "../../assets/icons/searchReflation";
import Trash from "../../assets/icons/trash";
import Button from "../../atoms/Button";
import DropDown from "../../atoms/DropDown";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../../organisms/auth/enum";
import CreateList from "../../organisms/createList/CreateList";
import { toggleCreateListModal } from "../../organisms/createList/reducer";
import {
  getOpenCreateListModal,
  getPropertyCardsLoading,
} from "../../organisms/createList/selectors";
import AdvancedFilter from "../../organisms/propertySearchFilter/AdvancedFilter";
import { SELECTED_FILTER_UPDATE_LIST } from "../../organisms/propertySearchFilter/constants";
import PropertyCard from "../../organisms/propertySearchFilter/PropertyCard";
import PropertyCardLoader from "../../organisms/propertySearchFilter/PropertyCardLoader";
import {
  daleteSavedSearchFilters,
  updateSavedSearchFilters,
} from "../../organisms/propertySearchFilter/PropertyService";
import { setClearPropertySearch } from "../../organisms/propertySearchFilter/reducer";
import SaveFilterModal from "../../organisms/propertySearchFilter/SaveFilterModal";
import SelectedFilterList from "../../organisms/propertySearchFilter/SelectedFilterList";
import {
  getPropertiesList,
  getPropertiesListLoading,
  getSearchPropertiesResultCount,
} from "../../organisms/propertySearchFilter/selectors";
import { formartNumberAsLocalString } from "../../utils/utils";
import styles from "./PropertySearchRightPanel.module.scss";
import { createSearchPayload } from "./utils";
declare global {
  interface Window {
    allFilterOpen: boolean;
  }
}
const PropertySearchRightPanel = ({
  pageNumber,
  setOpenAllFilter,
  openAllFilter,
  savedFilters,
  getSavedSearchFilters,
  updateSavedFilter,
  setUpdateSavedFilter,
  handleNextPage,
  selectedProperty,
  setAppliedAdvancedFiltersFlags,
  setAdvancedFilterCount,
  loading,
  handlePropertyClick,
  handleMouseHover,
  handleMouseLeave,
  onApplyFilter,
  appliedAdvancedFiltersFlags,
  resetAdvancedFilter,
  propertiesCount,
  dispatchSearchProperty,
  setPageNumber,
  propertiesInView,
  setPropertiesInView,
}: {
  pageNumber: number;
  openAllFilter: boolean;
  setOpenAllFilter: React.Dispatch<React.SetStateAction<boolean>>;
  savedFilters: any[];
  getSavedSearchFilters: Function;
  updateSavedFilter: boolean;
  setUpdateSavedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextPage: Function;
  selectedProperty: any;
  setAppliedAdvancedFiltersFlags: React.Dispatch<any>;
  setAdvancedFilterCount: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  handlePropertyClick: Function;
  handleMouseHover: Function;
  handleMouseLeave: Function;
  onApplyFilter: Function;
  appliedAdvancedFiltersFlags: any;
  resetAdvancedFilter: Function;
  propertiesCount: number;
  dispatchSearchProperty: Function;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  propertiesInView: any[];
  setPropertiesInView: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const propertiesListLoading = useSelector((state) =>
    getPropertiesListLoading(state)
  );
  const searchPropertiesResultCount = useSelector((state) =>
    getSearchPropertiesResultCount(state)
  );
  const methods = useFormContext();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const propertiesList = useSelector((state) => getPropertiesList(state));
  const [openSaveFilterModal, setOpenSaveFilterModal] = useState(false);
  const [deleteFilterID, setDeleteFilterID] = useState("");
  const [savedFilterLoading, setSavedFilterLoading] = useState(false);
  const dispatch = useDispatch();
  const propertyCardsLoading = useSelector((state) =>
    getPropertyCardsLoading(state)
  );
  const openCreateList = useSelector((state) => getOpenCreateListModal(state));
  const [propertySearchFilter, setPropertySearchFilter] = useState<any>({});

  useEffect(() => {
    const formValue = methods.watch();
    const savedValue: any = savedFilters.find(
      (el: any) => el.id === methods.watch("savedFilter")
    );
    if (savedValue) {
      const newValue: any = {};
      [...SELECTED_FILTER_UPDATE_LIST, "marketStatus", "quickFilters"].forEach(
        (key) => (newValue[key] = savedValue?.searchFilters[key])
      );
      methods.reset({ ...formValue, ...newValue });
      dispatchSearchProperty({ ...formValue, ...newValue }, 1, false);
      setPageNumber(1);
    }
  }, [methods.watch("savedFilter")]);

  useEffect(() => {
    window.allFilterOpen = openAllFilter;
  }, [openAllFilter]);

  const savedFilterOption = useMemo(() => {
    return savedFilters.map((el: any) => {
      return {
        label: el.searchFiltersName,
        value: el.id,
        optionRight: (
          <span
            className="delete-icon"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteFilterID(`${el.id}`);
            }}
          >
            <Trash />
          </span>
        ),
      };
    });
  }, [savedFilters]);

  const handleDeleteSavedFilter = async (id: string) => {
    try {
      setSavedFilterLoading(true);
      await daleteSavedSearchFilters(id);
      await getSavedSearchFilters();
      setSavedFilterLoading(false);
      setDeleteFilterID("");
      if (methods.watch("savedFilter") === id) {
        methods.setValue("savedFilter", "");
      }
      handleShowToast("Filter deleted successfully!", ToastType.success);
    } catch (error) {
      handleShowToast("Something went wrong!!", ToastType.error);
      setSavedFilterLoading(false);
    }
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleUpdateSavedFilter = async () => {
    try {
      const filterFormValue = methods.watch();
      const selctedFilter = savedFilterOption.find(
        (el) => el.value === methods.watch("savedFilter")
      );
      Object.keys(filterFormValue).forEach((key) => {
        if (
          ![
            ...SELECTED_FILTER_UPDATE_LIST,
            "marketStatus",
            "quickFilters",
          ]?.includes(key)
        ) {
          delete filterFormValue[key];
        }
      });

      setSavedFilterLoading(true);
      await updateSavedSearchFilters(methods.watch("savedFilter"), {
        searchFiltersName: selctedFilter?.label,
        searchFilters: filterFormValue,
      });
      await getSavedSearchFilters();
      setSavedFilterLoading(false);
      setUpdateSavedFilter(false);
      handleShowToast("Filter Updated successfully!", ToastType.success);
    } catch (error) {
      handleShowToast("Something went wrong!!", ToastType.error);
      setSavedFilterLoading(false);
    }
  };

  const dispatchToggleCreateListModalAction = (state: boolean) => {
    dispatch(toggleCreateListModal(state));
    if (
      state &&
      (methods.watch("searchTerm") || methods.watch("mapShapesPayload"))
    ) {
      const payload = createSearchPayload(
        methods.watch(),
        pageNumber,
        setAppliedAdvancedFiltersFlags,
        setAdvancedFilterCount
      );
      setPropertySearchFilter(payload);
      // dispatch(fetchSkipPropertyCounts(payload));
    }
  };

  const clearSearch = () => {
    dispatch(setClearPropertySearch(true));
  };

  const handleKeyDownFn = (event: any) => {
    if (event?.key?.toLowerCase() === "tab") {
      event.stopPropagation();
    }
  };
  return (
    <>
      <div className={`${styles.propertySearchRight}`}>
        <>
          <div className={` ${styles.propertySearchRight__filters}`}>
            <div
              className={` ${styles.propertySearchRight__filtersHead} flex justifySpaceBetween alignCenter`}
            >
              <div className={` ${styles.propertySearchRight__AllFiltersBtn}`}>
                <Button
                  label="All Filters"
                  className={` animated full ${
                    openAllFilter
                      ? "outline iconSmall rotateIcon"
                      : " outline iconSmall"
                  }`}
                  action={() => {
                    if (openAllFilter && !methods.formState.isValid) {
                      return;
                    }
                    if (openAllFilter) {
                      dispatchSearchProperty(methods.watch(), 1, false);
                    }
                    setOpenAllFilter((prev) => !prev);
                  }}
                  postfix={<ArrowDownIcon />}
                />
              </div>
              <p>
                <strong>
                  {propertiesListLoading && pageNumber === 1
                    ? "-"
                    : formartNumberAsLocalString(
                        searchPropertiesResultCount,
                        {},
                        "0"
                      )}{" "}
                </strong>
                Properties
              </p>
              <div className={` ${styles.propertySearchRight__createList}`}>
                <Tooltip
                  title={
                    profileInfo?.userRoleName ===
                    PermissionListEnum.ORGANISATION_VIEWER
                      ? ROLE_PERMISSION_STATUS.NoAccess
                      : ""
                  }
                >
                  <div>
                    {(propertiesListLoading && pageNumber === 1) ||
                    profileInfo?.userRoleName ===
                      PermissionListEnum.ORGANISATION_VIEWER ? (
                      <Button
                        label="Create List"
                        className="primary animated"
                        prefix={<ListIcon />}
                        disabled
                      />
                    ) : (
                      <Button
                        label="Create List"
                        className="primary animated "
                        prefix={<ListIcon />}
                        disabled={propertiesList.length === 0}
                        action={() => dispatchToggleCreateListModalAction(true)}
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div
              className={`flex alignCenter ${styles.propertySearchRight__saveFilters}`}
            >
              <DropDown
                options={savedFilterOption}
                label=""
                freeSolo={false}
                control={methods.control}
                name="savedFilter"
                placeholder="Saved Filter"
                clearOnBlur={true}
                disabled={openAllFilter}
              ></DropDown>
              {updateSavedFilter && methods.watch("savedFilter") && (
                <Button
                  label="Update Filter"
                  action={() => handleUpdateSavedFilter()}
                  className="primary animated"
                  disabled={savedFilterLoading}
                  clicked={savedFilterLoading}
                ></Button>
              )}
            </div>

            <SelectedFilterList
              isAllFilterOpen={openAllFilter}
              dispatchSearchProperty={dispatchSearchProperty}
            />

            {openAllFilter && (
              <ClickAwayListener
                onClickAway={() => {
                  if (!window?.multiSelectOpen && methods.formState.isValid) {
                    setOpenAllFilter(false);
                    dispatchSearchProperty(methods.watch(), 1, false);
                  }
                }}
              >
                <div
                  className={` ${styles.propertySearchRight__filterAdvance}`}
                >
                  <AdvancedFilter
                    onApplyFilter={onApplyFilter}
                    appliedAdvancedFilters={appliedAdvancedFiltersFlags}
                    resetAdvancedFilter={resetAdvancedFilter}
                    handleKeyDownFn={handleKeyDownFn}
                    propertiesCount={propertiesCount}
                    loading={loading}
                    handleSaveFilter={() => setOpenSaveFilterModal(true)}
                    dispatchSearchProperty={dispatchSearchProperty}
                  ></AdvancedFilter>
                </div>
              </ClickAwayListener>
            )}
          </div>
          {propertiesListLoading && pageNumber === 1 ? (
            <div className="dflex">
              {Array(4)
                .fill(0)
                .map(() => (
                  <div className={`${styles.propertyList__box}`}>
                    <PropertyCardLoader></PropertyCardLoader>
                  </div>
                ))}
            </div>
          ) : (
            <>
              {propertyCardsLoading && (
                <div className={`${styles.propertyList__loader}`}>
                  <div className="dot-pulse"></div>
                </div>
              )}
              {propertiesList.length > 0 ? (
                <>
                  <div className={`${styles.propertyList}`}>
                    <div
                      className={`${styles.propertyList__inner}`}
                      id="scrollableDiv1"
                    >
                      <InfiniteScroll
                        dataLength={propertiesList.length}
                        next={() => handleNextPage && handleNextPage()}
                        hasMore={
                          propertiesList.length < searchPropertiesResultCount
                        }
                        scrollableTarget="scrollableDiv1"
                        loader={
                          <div className="table-loader w-full">
                            <Loader></Loader>
                          </div>
                        }
                      >
                        {propertiesList?.map((property: any, index: number) => {
                          return (
                            <div className={`${styles.propertyList__box}`}>
                              <PropertyCard
                                propertiesInView={propertiesInView}
                                setPropertiesInView={setPropertiesInView}
                                property={property}
                                handleMouseHover={handleMouseHover}
                                handleMouseLeave={handleMouseLeave}
                                selectedProperty={selectedProperty}
                                handlePropertyClick={handlePropertyClick}
                              ></PropertyCard>
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`flex alignCenter justifyCenter ${styles.empty} ${styles.box}`}
                  >
                    <EmptyPlaceholder
                      title="No results found"
                      description="We couldn’t find any results that match your search or filtering criteria."
                      icon={<SearchReflation />}
                      button1={
                        <Button
                          label="Clear search"
                          className="outline"
                          action={clearSearch}
                        />
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      </div>
      {openCreateList && (
        <CreateList
          open={openCreateList}
          setOpen={dispatchToggleCreateListModalAction}
          propertySearchFilter={propertySearchFilter}
        ></CreateList>
      )}
      {openSaveFilterModal && (
        <SaveFilterModal
          handleClose={async () => {
            await getSavedSearchFilters();
            setOpenSaveFilterModal(false);
          }}
          isOpen={openSaveFilterModal}
          isAllFilterOpen={openAllFilter}
          dispatchSearchProperty={dispatchSearchProperty}
        />
      )}
      <ConfirmationModal
        isOpen={!!deleteFilterID}
        setIsOpen={() => {
          setDeleteFilterID("");
        }}
        buttonComponent={
          <Button
            label="Yes"
            className="red xl full "
            action={() => handleDeleteSavedFilter(deleteFilterID)}
            disabled={savedFilterLoading}
            clicked={savedFilterLoading}
          />
        }
      >
        <h2>Delete saved filter</h2>
        <p>Are you sure you want to delete this filter?</p>
      </ConfirmationModal>
    </>
  );
};
export default PropertySearchRightPanel;
