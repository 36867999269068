import { initialState } from "./property.reducer";

const getStateData = (state: any) => state.propertyDetails || initialState;

export const getPropertyDetails = (state: any) => {
  const reducer = getStateData(state);
  return reducer.data;
};

export const isLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.isLoading;
};

export const getWholesaleCalculatorList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.wholesaleCalculatorlist;
};

export const getWholesaleCalculatorloading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.wholesaleCalculatorLoading;
};

export const getamortizationCalculatorList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.amortizationCalculatorlist;
};

export const getamortizationCalculatorloading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.amortizationCalculatorLoading;
}

export const getFinanceCalculatorlist = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorlist;
};

export const getFinanceCalculatorDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorDetail;
};
export const getFinanceCalculatorLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorLoading;
}