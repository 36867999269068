const SocialShareIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M7.65833 11.2584L13.35 14.5751M13.3417 5.42508L7.65833 8.74175M18 4.16675C18 5.54746 16.8807 6.66675 15.5 6.66675C14.1193 6.66675 13 5.54746 13 4.16675C13 2.78604 14.1193 1.66675 15.5 1.66675C16.8807 1.66675 18 2.78604 18 4.16675ZM8 10.0001C8 11.3808 6.88071 12.5001 5.5 12.5001C4.11929 12.5001 3 11.3808 3 10.0001C3 8.61937 4.11929 7.50008 5.5 7.50008C6.88071 7.50008 8 8.61937 8 10.0001ZM18 15.8334C18 17.2141 16.8807 18.3334 15.5 18.3334C14.1193 18.3334 13 17.2141 13 15.8334C13 14.4527 14.1193 13.3334 15.5 13.3334C16.8807 13.3334 18 14.4527 18 15.8334Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default SocialShareIcon;
