import React, { useEffect, useRef, useState } from "react";
import ArrowRight from "../../assets/icons/arrowRight";
import Button from "../../atoms/Button";
import { CampaignLegalProps } from "./interface";
import styles from "./CreateCampaign.module.scss";
import ArrowDown from "../../assets/icons/arrowDown";
import { getCampaignLoadingState } from "./selectors";
import { useSelector } from "react-redux";

const CampaignLegalTerms = ({ onSubmit, onCancel }: CampaignLegalProps) => {
  const [scrollPosition, setScrollPosition] = useState(false);
  const campaignLoadingState = useSelector((state: any) =>
    getCampaignLoadingState(state)
  );

 
  const bottomRef = useRef<HTMLParagraphElement>(null);
  const isInitialScrollPositionSet = useRef(false);

  const handleTermsClick = () => {
    window.open("/policies/terms-of-use");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isInitialScrollPositionSet.current && entry.isIntersecting) {
            setScrollPosition(true);
            isInitialScrollPositionSet.current = true;
          }
      },
      { threshold: 0.5 }
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef]);

  return (
    <div className={`${styles.createCampaign} ${styles.legal}`}>
      <div className={` ${styles.legal__header}`}>
        <h3>Review and accept campaign terms</h3>
      </div>
      <div className={`${styles.legal__info}`}>
        <p>
          Please review and accept the below terms related to initiating a
          campaign by use of the services provided by OttoLeads.
        </p>
        <p>
          YOU AGREE TO ABIDE BY OUR {" "}
          {/* <a target="_blank" 
          rel="noreferrer" href="https://app.termly.io/document/terms-of-service/495f6f8f-9a35-4f1e-9162-8cf7c0f9d562">TERMS OF USE</a> */}
           <span className={`${styles.termsLink}`} role="link" onClick={handleTermsClick}>TERMS OF USE</span>{" "}
          WHEN CREATING A CAMPAIGN AND
          MAKING USE OF THE SERVICES PROVIDED BY OTTOLEADS.
        </p>
        <p>
          Pursuant to the actions herein described, comprising the uploading a
          list of one or several phone numbers to the digital infrastructure of
          OttoLeads, or in the alternative, the acquisition of one or several
          lists of phone numbers from OttoLeads, with the intent to engage the
          services provided by OttoLeads, I, the initiator of the campaign, do
          hereby acknowledge and unequivocally accept the unassailable
          obligation, which devolves solely upon myself, to procure, in a manner
          consistent with prevailing legal standards and regulations, all
          necessary permissions and consents prerequisite for the initiation and
          making of any forms of communication, whether direct or indirect, with
          the parties delineated in the aforementioned list(s) of phone numbers.
          This obligation is understood to encompass the comprehensive assurance
          that all such communicative activities conform to, and do not
          contravene, any and all relevant statutory requirements and regulatory
          provisions.
        </p>
        <p ref={bottomRef}>
          OttoLeads expressly disclaims any and all liability, and shall not be
          deemed to assume any responsibility or obligation, whether direct,
          indirect, implicit, or statutory, in relation to the procurement,
          verification, or assurance of the necessary consents, permissions, or
          authorizations which may be required under applicable laws,
          regulations, or standards for the initiation, continuation, or conduct
          of any form of communication with individuals and their phone numbers
          identified in any list uploaded to or procured from OttoLeads for the
          purpose of utilizing its services. It is my sole and exclusive duty,
          not OttoLeads, to ensure that all such consents, permissions, or
          authorizations have been lawfully obtained, in full compliance with
          the relevant legal and regulatory provisions governing such
          activities.
        </p>
      </div>
      <div className={`${styles.legal__footer}`}>
        <div className={`${styles.buttons}`}>
          <div className={`${styles.buttons__first}`}>
            <Button
              className="outline xl full animated"
              label={"Cancel"}
              action={onCancel}
            ></Button>
          </div>
          <div className={`${styles.buttons__last}`}>
            <Button
              className="primary xl full animated"
              label={
                scrollPosition ? "I Agree" : "Scroll down"
              }
              labelNormal={scrollPosition ? "" : "to accept"}
              prefix={scrollPosition ? "" : <ArrowDown />}
              action={onSubmit}
              clicked={campaignLoadingState}
              disabled={
                !scrollPosition || campaignLoadingState
              }
            ></Button>
          </div>
        </div>
      </div>
  
    </div>
  );
};

export default CampaignLegalTerms;
