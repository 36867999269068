import { CSVLink } from "react-csv";
import Cloud from "../assets/icons/cloud";
const ExportToCsv = ({ data, headers, label, filename, handleChange }: any) => {
  return (
    <CSVLink
      className="button outline md"
      filename={filename}
      data={data}
      headers={headers}
      onClick={handleChange}
    >
      <Cloud /> {label}
    </CSVLink>
  );
};

export default ExportToCsv;
