const Zap = ({color}:{color?:any}) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={color ? color : `none`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="zap">
          <path
            id="Icon"
            d="M10.8335 1.66675L3.41142 10.5733C3.12075 10.9221 2.97541 11.0965 2.97319 11.2438C2.97126 11.3718 3.02832 11.4937 3.12792 11.5742C3.2425 11.6667 3.46952 11.6667 3.92357 11.6667H10.0002L9.16688 18.3334L16.589 9.42687C16.8797 9.07806 17.025 8.90366 17.0272 8.75636C17.0292 8.62832 16.9721 8.50649 16.8725 8.426C16.7579 8.33342 16.5309 8.33342 16.0768 8.33342H10.0002L10.8335 1.66675Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default Zap;
