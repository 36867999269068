import { getAPI } from "../../apis/apis";

export const fetchlistExportCSVList = (payload:any) => {
    return getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/lead-by-listId/${payload}`,
      headers: {},
    });
  };

 