export const scrollToSection = (id: any) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 110;
    const offsetPosition = element.offsetTop - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};
