interface Coordinate {
  lat: number;
  lng: number;
}

interface Marker {
  id: number;
  coordinate: Coordinate;
  component: React.ReactNode;
  streetno?: string;
}

enum MapViewMode {
  /** This map type displays a transparent layer of major streets on satellite images. */
  HYBRID = "hybrid",
  /** This map type displays a normal street map. */
  ROADMAP = "roadmap",
  /** This map type displays satellite images. */
  SATELLITE = "satellite",
  /** This map type displays maps with physical features such as terrain and vegetation. */
  TERRAIN = "terrain",
}

interface PolylineShape {
  latitude: number;
  longitude: number;
}

type MultiPolygonType = PolylineShape[];

export { MapViewMode };
export type { Coordinate, Marker, MultiPolygonType, PolylineShape };
