import React from 'react'
import styles from "./CustomCarousel.module.scss";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";

const CustomCarousel = ({ children, handlePrevProperty, handleNextProperty ,listDetailRes,propertyIndex}: {
  children: React.ReactNode;
  handlePrevProperty: Function;
  handleNextProperty: Function;
  listDetailRes:any
  propertyIndex:number
} ) => {
  return ( 
     <div className={`${styles.customCarousel}`}>
       {propertyIndex!==0 && <div className={`${styles.customCarousel__arrow}`} role='link' onClick={()=>handlePrevProperty()}>
        <ArrowLeftIcon />
        </div>}
        <div className={`${styles.customCarousel__content}`}>
         {children}
         </div>
        {propertyIndex !== listDetailRes.length-1 && <div  role='link' className={`${styles.customCarousel__arrow} ${styles.customCarousel__arrowRight}`} onClick={()=>handleNextProperty()}>
         <ArrowRightIcon />
         </div>}
         </div>
  )
}
export default CustomCarousel;
