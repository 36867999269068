

const Menu = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                <g id="Union">
                    <path d="M0 1.04272C0 0.628511 0.335786 0.292725 0.75 0.292725H14.75C15.1642 0.292725 15.5 0.628511 15.5 1.04272C15.5 1.45694 15.1642 1.79272 14.75 1.79272H0.75C0.335786 1.79272 0 1.45694 0 1.04272Z" fill="currentColor" />
                    <path d="M0 13C0 12.5858 0.335786 12.25 0.75 12.25H14.75C15.1642 12.25 15.5 12.5858 15.5 13C15.5 13.4143 15.1642 13.75 14.75 13.75H0.75C0.335786 13.75 0 13.4143 0 13Z" fill="currentColor" />
                    <path d="M0.75 6.25005C0.335786 6.25005 0 6.58583 0 7.00005C0 7.41426 0.335786 7.75005 0.75 7.75005H10.7206C11.1348 7.75005 11.5 7.41427 11.5 7.00006C11.5 6.58585 11.1348 6.25005 10.7206 6.25005H0.75Z" fill="currentColor" />
                </g>
            </svg>
        </>

    );
};

export default Menu;