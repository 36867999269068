
const CalendarCheckInside = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clipPath="url(#clip0_9227_6397)">
<path d="M10.5 5H1.5M8 1V3M4 1V3M4.5 8L5.5 9L7.75 6.75M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z" 
stroke="currentColor" 
strokeWidth="1.5" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_9227_6397">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>
        </>
    );
};

export default CalendarCheckInside;




