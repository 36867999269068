import FileCheckList from "../../assets/icons/fileCheckList";
import Tick from "../../assets/icons/tick";
import Button from "../../atoms/Button";
import styles from './CreateList.module.scss';

const SkipTraceSuccess=({onSubmit}:any)=>{

    return <>
    <div className={`${styles.createList}`}>
        <div className={`${styles.createListCreated} ${styles.noAnimations}`}>

            <figure>
                <FileCheckList/>
            </figure>

            <div className={`${styles.createList__header}`}>

            {/* <h3>Skip trace success!</h3> */}
            <h3>We’re processing your request.</h3>
                {/* <p>It's time to get your leads. Start a new campaign and let your AI assistant get to work!</p> */}
                <p>We’ll send you a notification once your list is ready. This should take less than 5 minutes.</p>
            </div>
            

            <Button className="primary xl full animated" label={'Got it'} prefix={<Tick  />} action={onSubmit} ></Button>
        </div>
    </div>

    </>
}
export default SkipTraceSuccess;