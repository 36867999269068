import Dialog from "@mui/material/Dialog";
import styles from "./WelcomeModal.module.scss";
import videoPoster from "../../assets/images/video-poster.png";
import welcomePoster from "../../assets/images/welcome-poster.jpeg";

import { WelcomeModalProps } from "./interface";

const WelcomeModal = ({
  handleClose,
  open,
  title,
  subtitle,
  topTitle,
  topSubtitle,
  button,
  button2,
  video,
  termsOfUseFlag,
}: WelcomeModalProps) => {
  const handleTermsClick = () => {
    window.open("/policies/terms-of-use");
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={`stepsDialog welcomeDialog`}
    >
      <div
        data-testid="welcomeModalId"
        className={`${styles.welcomeModal} ${termsOfUseFlag && styles.tos}`}
      >
        {/* <span role="link" onClick={() => handleClose()} className="closeDialog">
                    <Close />
                </span> */}
        <div className={`${styles.welcomeModal__top}`}>
          {topTitle && <h3>{topTitle}</h3>}
          {topSubtitle && (
            <p dangerouslySetInnerHTML={{ __html: topSubtitle }}></p>
          )}
        </div>
        <div className={`${styles.welcomeModal__video}`}>
          <video
            key={video}
            width="400"
            controls
            controlsList={"nodownload noplaybackrate"}
            poster={welcomePoster}
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className={`${styles.welcomeModal__info}`}>
          {title ? <h2>{title}</h2> : <></>}
          {subtitle ? <p>{subtitle} </p> : <></>}
        </div>
        <div
          className={`flex ${styles.welcomeModal__button} ${
            button2 ? styles.buttonsHalf : ``
          }`}
        >
          {button2 && <div className={`${styles.button}`}>{button2}</div>}
          {button && <div className={`${styles.button}`}>{button}</div>}
        </div>

        {termsOfUseFlag && (
          <div className={`${styles.welcomeModal__tos}`}>
            The use of the OttoLeads Property Search is subject to our
            <br />
            <span role="link" onClick={handleTermsClick}>Terms of Use</span>
          </div>
        )}
      </div>
    </Dialog>
  );
};
export default WelcomeModal;
