import { createAction } from "@reduxjs/toolkit";

export const campaignDataAction = createAction(
  "getCampaignData",
  function prepare(data) {
    return { payload: data };
  }
);

export const cancelCampaignAction = createAction(
  "cancelCampaign",
  function prepare(data) {
    return {payload : data} ;
  }
);

export const editCampaignAction = createAction(
  "editCampaign",
  function prepare(data: any, id: any) {
    return { payload: { data, id } };
  }
);

export const loadingAction = createAction("isLoading", function prepare(data) {
  return data;
});
export const loadingEditAction = createAction(
  "isLoadingEdit",
  function prepare(data) {
    return data;
  }
);

export const openAction = createAction("isOpen", function prepare(data) {
  return data;
});

export const topSectionAction = createAction(
  "topSection",
  function prepare(data) {
    return { payload: data };
  }
);

export const leadsAction = createAction("leads", function prepare(data) {
  return { payload: data };
});

export const activityAction = createAction("activity", function prepare(data) {
  return { payload: data };
});

export const topMatrixAction = createAction(
  "topMatrix",
  function prepare(data) {
    return { payload: data };
  }
);

export const graphAction = createAction("graph", function prepare(data) {
  return { payload: data };
});

export const campaignDetail = createAction("details", function prepare(data) {
  return { payload: data };
});

export const getAttemptIdAction = createAction("getAttemptId", function prepare(data) {
  return { payload: data };
});


export const fetchPropertyCountByCampaign = createAction("FETCH_PROPERTY_COUNT_BY_CAMPAIGN", function prepare(data) {
  return { payload: data };
});

export const fetchHungupData = createAction("fetchHungUp", function prepare(data) {
  return { payload: data };
});