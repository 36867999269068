import axios from "axios";

export const createUserFn = async(payload : Record<string,any>) =>{
  return axios.post(
     process.env.REACT_APP_BASE_URL + `/users`,
    payload);
}

export const verifyUserFn = async (payload: Record<string, any>) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/users/verify-email",
    payload,
    {
      headers: {},
    }
  );
};

export const resendEmailLink = async (payload: Record<string, any>) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/users/resend-signup-code",
    payload,
    {
      headers: {},
    }
  );
};

export const loginUserFn = async (payload: Record<string, any>) => {
  return await axios.post(
    process.env.REACT_APP_BASE_URL + "/users/login",
    payload,
    {
      headers: {},
    }
  );
};

export const generateRefreshToken = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/users/refresh",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data?.access_token;
  } catch (error) {
    console.error("Error during refresh token generation:", error);
  }
};

export const sendEmailForgotPass = async (payload: Record<string, any>) => {
  const { email } = payload;
  return axios.get(
    process.env.REACT_APP_BASE_URL + `/users/forgot-password-email/${email}`
  );
};

export const setNewPassword = async (payload: Record<string, any>) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/users/set-new-password`,
    payload
  );
};

export const resetPasswordFn = async (payload: Record<string, any>) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + "/users/reset-password",
    payload
  );
};

export const fetchPromotionChannels = async () =>{
  return axios.get(
   process.env.REACT_APP_BASE_URL + `/users/promotion-channel`,
  );
}

export const postAuditApiFn = async(payload: Record<string, any>)=>{
  return axios.post(process.env.REACT_APP_BASE_URL + `/users/audit`,payload);
}

export const fetchProfileApiFn =async()=>{
  return axios.get(process.env.REACT_APP_BASE_URL+`/users/profile`)
}

export const FbConversionApi = async (payload: Record<string, any>) => {
  return await axios.post(
    process.env.REACT_APP_BASE_URL + "/add-fb-conversion",
    payload,
    {
      headers: {},
    }
  );
};

export const resetNewUserPassword = async (payload: Record<string, any>) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + `/users/set-new-user-password`,
    payload
  );
};