import * as yup from 'yup';

export const addContactSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Please enter a valid email")
    .nullable(),
  phone: yup
    .string()
    .transform((value) => value.replace(/\D/g, ""))
    .matches(/^[0-9]{10}$/, "Phone number is required")
    .nullable(),
}).test('at-least-one', 'Either email or phone number is required', function(value) {
  return !!(value.email || value.phone);
});
