
const JCB = () => {
    return (
        <>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
  <path d="M14.9818 7.95424C15.5131 7.96578 16.0473 7.93078 16.5765 7.97242C17.1123 8.07239 17.2415 8.88363 16.7654 9.14931C16.4406 9.32434 16.0547 9.2145 15.7025 9.24537H14.9818V7.95424ZM16.8837 6.49284C17.0017 6.90946 16.6001 7.28353 16.1988 7.22615H14.9818C14.9903 6.83326 14.9651 6.40683 14.9942 6.03462C15.4817 6.04835 15.9737 6.00662 16.4585 6.05645C16.6667 6.10877 16.841 6.27999 16.8837 6.49284ZM19.8126 0.314482C19.8352 1.11012 19.8158 1.94781 19.8223 2.75959C19.8208 6.05999 19.8255 9.3603 19.8198 12.6609C19.7984 13.8978 18.7022 14.9726 17.4739 14.9972C16.2443 15.0022 15.0146 14.998 13.7849 14.9994V10.0099C15.1247 10.003 16.4652 10.0239 17.8045 9.99941C18.4258 9.96041 19.1063 9.55047 19.1352 8.86676C19.2084 8.18009 18.561 7.70514 17.9463 7.63013C17.7101 7.62399 17.717 7.56126 17.9463 7.53389C18.5326 7.40718 18.9929 6.80044 18.8204 6.19279C18.6735 5.55368 17.9671 5.30631 17.3795 5.30754C16.1815 5.2994 14.9832 5.30641 13.7852 5.30404C13.7929 4.37256 13.769 3.44008 13.798 2.50928C13.893 1.2947 15.0167 0.293069 16.2278 0.3148C17.4229 0.314618 18.6178 0.314618 19.8126 0.314573V0.314482Z" fill="url(#paint0_linear_1107_66752)"/>
  <path d="M0.24671 2.63518C0.277307 1.40023 1.37818 0.334272 2.60494 0.316223C3.82993 0.31245 5.0551 0.315678 6.28009 0.314587C6.27672 4.44645 6.28673 8.57877 6.27504 12.7104C6.22785 13.9303 5.13898 14.976 3.92558 14.9976C2.69828 15.002 1.47083 14.9982 0.243483 14.9994V9.84172C1.43565 10.1234 2.68582 10.2432 3.90203 10.0564C4.62903 9.93946 5.42444 9.58244 5.67057 8.82822C5.8517 8.18306 5.74972 7.50408 5.77668 6.84192V5.30415H3.6719C3.66245 6.32119 3.69127 7.34 3.65667 8.35582C3.59994 8.9802 2.9816 9.37691 2.39272 9.35572C1.66241 9.36345 0.215204 8.82645 0.215204 8.82645C0.211567 6.92093 0.23639 4.53456 0.24671 2.63536V2.63518Z" fill="url(#paint1_linear_1107_66752)"/>
  <path d="M7.06537 5.92439C6.95458 5.9479 7.04309 5.54701 7.01477 5.39494C7.02231 4.43341 6.99899 3.47083 7.02763 2.50998C7.12229 1.29026 8.2547 0.286131 9.47068 0.314681H13.0517C13.0483 4.44654 13.0582 8.57886 13.0466 12.7105C12.9994 13.9304 11.9104 14.976 10.697 14.9977C9.46964 15.0023 8.24216 14.9984 7.01472 14.9996V9.34859C7.853 10.0364 8.99233 10.1435 10.0367 10.1453C10.824 10.145 11.6067 10.0237 12.3713 9.84208V8.80681C11.5097 9.23625 10.4968 9.50903 9.5416 9.2623C8.87531 9.09645 8.39164 8.45252 8.40241 7.7649C8.32522 7.04982 8.74438 6.29487 9.44709 6.08229C10.3196 5.80915 11.2704 6.01805 12.0883 6.37316C12.2635 6.4649 12.4413 6.57869 12.3713 6.28582V5.47209C11.0035 5.14667 9.54788 5.02692 8.17374 5.38094C7.77599 5.49314 7.38847 5.6633 7.06537 5.92449V5.92439Z" fill="url(#paint2_linear_1107_66752)"/>
  <defs>
    <linearGradient id="paint0_linear_1107_66752" x1="13.7437" y1="7.34295" x2="19.7843" y2="7.34295" gradientUnits="userSpaceOnUse">
      <stop stopColor="#58B03A"/>
      <stop offset="1" stopColor="#55B330"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1107_66752" x1="0.115046" y1="7.79764" x2="6.18111" y2="7.79764" gradientUnits="userSpaceOnUse">
      <stop stopColor="#0F6EB6"/>
      <stop offset="1" stopColor="#006DBA"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1107_66752" x1="7.00587" y1="7.48541" x2="13.0505" y2="7.48541" gradientUnits="userSpaceOnUse">
      <stop stopColor="#DE0D3D"/>
      <stop offset="1" stopColor="#E30138"/>
    </linearGradient>
  </defs>
</svg>
        </>
    );
};

export default JCB;

