import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  generalAccessProducts: [],
  creditAccessProducts: [],
  cardList: [] as any[],
  invoices: {} as any,
  invoicesIsLoading: false,
  subscriptions: {
    generalSubData: {},
  } as any,
  subscriptionsIsLoading: false,
  creditUsage: null,
  creditUsageIsLoading: false,
  generalAccessProductsIsLoading: false,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setGeneralAccessProducts: (state, { payload }) => {
      return { ...state, generalAccessProducts: payload };
    },
    setCreditAccessProducts: (state, { payload }) => {
      return { ...state, creditAccessProducts: payload };
    },
    setCardList: (state, { payload }) => {
      return { ...state, cardList: payload };
    },
    setInvoices: (state, { payload }) => {
      return { ...state, invoices: payload };
    },
    setInvoicesIsLoading: (state, { payload }) => {
      return { ...state, invoicesIsLoading: payload };
    },
    setSubscriptions: (state, { payload }) => {
      return { ...state, subscriptions: payload };
    },
    setSubscriptionsIsLoading: (state, { payload }) => {
      return { ...state, subscriptionsIsLoading: payload };
    },
    setCreditUsage: (state, { payload }) => {
      return { ...state, creditUsage: payload };
    },
    setCreditUsageIsLoading: (state, { payload }) => {
      return { ...state, creditUsageIsLoading: payload };
    },
    appendInvoices: (state, { payload }) => {
      return {
        ...state,
        invoices: {
          ...payload,
          invoices: [
            ...(state?.invoices?.invoices ? state?.invoices?.invoices : []),
            ...(payload?.invoices ? payload?.invoices : []),
          ],
        },
      };
    },
    setGeneralAccessProductsIsLoading: (state, { payload }) => {
      return { ...state, generalAccessProductsIsLoading: payload };
    },
    resetBillingState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setGeneralAccessProducts,
  setCreditAccessProducts,
  setCardList,
  setInvoices,
  setInvoicesIsLoading,
  setSubscriptions,
  setSubscriptionsIsLoading,
  setCreditUsage,
  setCreditUsageIsLoading,
  appendInvoices,
  setGeneralAccessProductsIsLoading,
  resetBillingState,
} = billingSlice.actions;

export default billingSlice.reducer;
