import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";
import {
  getProfileInfo,
  getProfileInfoLoading,
} from "../../components/profileMenu/selectors";
import { UserCompletedStep } from "../../organisms/auth/enum";
import { fetchGeneralAccessProduct } from "../../organisms/billing/sagaActions";
import RegisterSteps from "../registerSteps/RegisterSteps";
import RegisterSteps2 from "../registerSteps/RegisterSteps2";
import { OnboardingSteps } from "./enum";

const Onboarding = () => {
  const [onboardingStep, setOnboardingStep] = useState(
    OnboardingSteps.STEP_ONE
  );
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(fetchCreditAccessProduct({}));
    dispatch(fetchGeneralAccessProduct({}));
  }, []);
  const profileInfoLoading = useSelector((state) =>
    getProfileInfoLoading(state)
  );
  const getStepComponent = () => {
    switch (onboardingStep) {
      case OnboardingSteps.STEP_ONE: {
        return (
          <>
            <RegisterSteps
              nextStep={() => setOnboardingStep(OnboardingSteps.STEP_TWO)}
              setPaymentMethod={setPaymentMethod}
            ></RegisterSteps>
          </>
        );
      }
      case OnboardingSteps.STEP_TWO: {
        return (
          <>
            <RegisterSteps2 paymentMethod={paymentMethod}></RegisterSteps2>
          </>
        );
      }
    }
  };

  useEffect(() => {
    switch (
      +profileInfo?.organisation?.stepsCompleted ??
      UserCompletedStep.STEP_ZERO
    ) {
      case UserCompletedStep.STEP_ZERO: {
        setOnboardingStep(OnboardingSteps.STEP_ONE);
        break;
      }
      case UserCompletedStep.STEP_ONE: {
        setOnboardingStep(OnboardingSteps.STEP_TWO);
        break;
      }
      case UserCompletedStep.STEP_TWO: {
        navigate("/campaigns");
        break;
      }
    }
    if (profileInfo?.organisation?.paymentMethodId) {
      setPaymentMethod(profileInfo?.organisation?.paymentMethodId ?? "");
    }
  }, [profileInfo]);

  if (profileInfoLoading) {
    return <GlobalLoader></GlobalLoader>;
  }

  return <>{getStepComponent()}</>;
};
export default Onboarding;
