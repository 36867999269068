import Dialog from "@mui/material/Dialog";
import Close from "../../assets/icons/cross";
import Logo from "../../components/Logo";
import Input from "../../atoms/Input";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordFormSchema } from "./validation";
import { useState } from "react";
import { sendEmailForgotPass } from "../auth/authServices";
import Message from "../../assets/icons/message";
import { toast } from "react-toastify";
import { CustomError, ForgotPasswordProps } from "./interface";


const ForgotPassword = ({
  open,
  setOpen,
  openResetPassword,
  setEmail,
  email
}:ForgotPasswordProps) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");

  const onSubmit = async (data: any) => {
    setSubmitClicked(true);
    const paylaod = {
      email: data?.email,
    };
    try {
      await sendEmailForgotPass(paylaod);
      setEmail(data.email);
      setLinkSent(true);
    } catch (err: any) {
      console.error(err);
      toast.error(`${(err as CustomError)?.response?.data?.message}`);
    } finally {
      setSubmitClicked(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleResendCode = async () => {
    setMsg("");
    const data = {
      email: email,
    };
    try {
      setSubmitClicked(true);
      await sendEmailForgotPass(data);
      setMsg(`We've resent the link to your email : ${email}`);
    } catch (error) {
      console.log("Email Not sent", error);
    } finally {
      setSubmitClicked(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog loginRegister ${linkSent && `otpDialog`}`}
      >
        <div className="dialogWrapper authStyle">
          <span
            role="link"
            className="closeDialog"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </span>
          <div className="authStyle__logo flex alignCenter justifyCenter">
            <Logo />
          </div>

          {!linkSent ? (
            <>
              <div className="authStyle__header title-xl">
                <h2>Forgot password?</h2>
                <p>No worries, we’ll send you reset instructions.</p>
              </div>
              <form>
                <Input
                  type="email"
                  label="Email"
                  register={register}
                  name="email"
                  placeholder="Enter your email"
                  errors={errors}
                ></Input>
                <div className="authStyle__footer">
                  <Button
                    type="submit"
                    label="Reset my password"
                    className="primary full xl"
                    disabled={!isValid || submitClicked}
                    action={handleSubmit(onSubmit)}
                    clicked={submitClicked}
                  ></Button>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className={`authStyle__Icon`}>
                <span className={`flag`}>
                  <Message />
                </span>
              </div>
              <div className="authStyle__header">
                <h2>Please check your email.</h2>
                {!msg && (
                  <p>
                    We've sent a link to <strong>{email}</strong>
                  </p>
                )}
                {msg && <p>{msg}</p>}
              </div>
              <div className="flex justifyCenter">
                <Button
                  className="primary"
                  label="Resend Link"
                  action={handleResendCode}
                  disabled={submitClicked}
                  clicked={submitClicked}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
