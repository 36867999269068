
const Circle = ({color}:any) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                <circle
                    cx="4.5" cy="4" r="3"
                    fill={color? color:`currentColor`}
                    />
            </svg>
        </>
    );
};

export default Circle;