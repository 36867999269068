
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./Policy.module.scss";

const Cookies = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);
    return (
        <>
         <Helmet>
      <title>Cookie Policy - OttoLeads</title>
    </Helmet>
        <div className={`${styles.policy}`}>
            
                <div className={`${styles.policy__header}`}>
                    <h3 >
                        Last updated March 25, 2024
                    </h3>
                    <h1>
                        Cookie Policy
                    </h1>
                    <p>We use some cookies to help improve your experience on this website. Here's some more info about what we do with information we collect.</p>
                </div>
                <div className={`${styles.policy__wrapper}`}>
                <div className={`${styles.policy__text}`}>

                    <p>
                        This Cookie Policy explains how OttoLeads LLC (&quot;<b>Company</b>
                        ,&quot; &quot;<b>we</b>,&quot; &quot;<b>us</b>,&quot; and &quot;
                        <b>our</b>
                       
                            &quot;) uses cookies and similar technologies to recognize you when
                            you visit our website at{" "}
                        
                        <a href="https://www.ottoleads.ai/"  target="_blank" rel="noreferrer">
                            https://www.ottoleads.ai
                            </a>
                        {" "}(&quot;<b>Website</b>&quot;). It explains what these technologies are
                        and why we use them, as well as your rights to control our use of them.
                    </p>

                    <p>
                        In some cases we may use cookies to collect Information as defined in
                        our Privacy Policy. We may combine the information collected with other
                        Information we have from you including Personal Information, as defined
                        in our Privacy Policy.
                    </p>

                    <h2>
                        What are cookies?
                    </h2>

                    <p>
                        Cookies are small data files that are placed on your computer or mobile
                        device when you visit a website. Cookies are widely used by website
                        owners in order to make their websites work, or to work more
                        efficiently, as well as to provide reporting information.
                    </p>

                    <p>
                        Cookies set by the website owner (in this case, OttoLeads LLC) are
                        called &quot;first-party cookies.&quot; Cookies set by parties other
                        than the website owner are called &quot;third-party cookies.&quot;
                        Third-party cookies enable third-party features or functionality to be
                        provided on or through the website (e.g., advertising, interactive
                        content, and analytics). The parties that set these third-party cookies
                        can recognize your computer both when it visits the website in question
                        and also when it visits certain other websites.
                    </p>

                    <h2>
                        Why do we use cookies?
                    </h2>

                    <p>
                        We use first- and third-party cookies for several reasons. Some cookies
                        are required for technical reasons in order for our Website to operate,
                        and we refer to these as &quot;essential&quot; or &quot;strictly
                        necessary&quot; cookies. Other cookies also enable us to track and
                        target the interests of our users to enhance the experience on our
                        Online Properties. Third parties serve cookies through our Website for
                        advertising, analytics, and other purposes. This is described in more
                        detail below.
                    </p>
                    <h2>
                        How can I control cookies?
                    </h2>

                    <p>
                        You have the right to decide whether to accept or reject cookies. You
                        can exercise your cookie rights by setting your preferences in the
                        Cookie Consent Manager. The Cookie Consent Manager allows you to select
                        which categories of cookies you accept or reject. Essential cookies
                        cannot be rejected as they are strictly necessary to provide you with
                        services.
                    </p>

                    <p>
                       
                            The Cookie Consent Manager can be found in the notification banner and
                            on <a
                            href="https://www.ottoleads.ai/"
                       
                            target="_blank"
                            rel="noreferrer"
                        >
                             our website
                            </a>
                        . If you choose to reject cookies, you may still use our website though
                        your access to some functionality and areas of our website may be
                        restricted. You may also set or amend your web browser controls to
                        accept or refuse cookies.
                    </p>

                    <p>
                        The specific types of first- and third-party cookies served through our
                        Website and the purposes they perform are described in the list below
                        (please note that the specific cookies served may vary depending on the
                        specific Online Properties you visit):
                    </p>

                    <ul>
                        <li>
                            We use Google Analytics, a web analysis service provided by
                            Google, and some similar services to better understand your use of
                            our Sites and apps. Google Analytics collects information such as
                            how often users visit the website, what pages they visit, and what
                            other sites they used prior to visiting. Google uses the data to
                            track and examine the use of the Site, to prepare reports on its
                            activities and share them with other Google services. Google’s
                            ability to use and share information collected by Google Analytics
                            about your visits to the Site is restricted by the{" "}
                            <a
                                href="https://www.google.com/analytics/terms/us.html"
                               
                                target="_blank"
                                rel="noreferrer"
                            >
                                Google Analytics Terms of Service
                            </a>
                            {" "}and the{" "}
                            <a
                                href="http://www.google.com/intl/en/policies/privacy"
                               
                                target="_blank"
                                rel="noreferrer"
                            >
                                Google Privacy Policy
                            </a>
                            . Google offers an opt-out mechanism for the web available{" "}
                            <a
                                href="https://tools.google.com/dlpage/gaoptout/"
                               
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>
                            ;
                        </li>
                        <li>
                           
                                Facebook. For more information about Facebook’s policies and
                                practices, please see Facebook’s privacy policy at{" "}
                            
                            <a
                                href="https://www.facebook.com/privacy/policy/"
                              
                                target="_blank"
                                rel="noreferrer"
                            >https://www.facebook.com/privacy/policy/</a>
                                ;
                        </li>
                        <li>
                            TikTok. For more information about Tiktok’s policies and
                            practices, please see Tiktok’s privacy policy at{" "}
                            <a
                                href="https://www.tiktok.com/legal/page/us/privacy-policy/en"
                               
                                target="_blank"
                                rel="noreferrer"
                            > https://www.tiktok.com/legal/page/us/privacy-policy/en
                            </a>
                            ;
                        </li>
                    </ul>
                            <h2>
                                How can I control cookies on my browser?
                            </h2>

                            <p>
                                As the means by which you can refuse cookies through your web
                                browser controls vary from browser to browser, you should visit your
                                browser&#39;s help menu for more information. The following is
                                information about how to manage cookies on the most popular
                                browsers:
                            </p>
                            <ul className={`${styles.policy__list}`}>
                                <li>
                                    <a
                                        href="https://support.google.com/chrome/answer/95647#zippy%3D%2Callow-or-block-cookies"

                                    >
                                        Chrome
                                    </a>
                                </li>
                                <li>

                                    <a
                                        href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                                    >
                                        Internet Explorer
                                    </a>

                                </li>
                                <li>

                                    <a
                                        href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US"
                                        className="s1"
                                    >
                                        Firefox
                                    </a>

                                </li>
                                <li>

                                    <a
                                        href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                                        className="s1"
                                    >
                                        Safari
                                    </a>

                                </li>
                                <li>

                                    <a
                                        href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                                        className="s1"
                                    >
                                        Edge
                                    </a>

                                </li>
                                <li>

                                    <a
                                        href="https://help.opera.com/en/latest/web-preferences/"
                                        className="s1"
                                    >
                                        Opera
                                    </a>
                                </li>
                            </ul>
                            <p>
                                In addition, most advertising networks offer you a way to opt
                                out of targeted advertising. If you would like to find out more
                                information, please visit:
                            </p>
                            <ul className={`${styles.policy__list}`}>
                                <li>
                                    
                                        <a href="http://www.aboutads.info/choices/" className="s1">
                                            Digital Advertising Alliance
                                        </a>
                                   
                                </li>
                                <li>
                                    
                                        <a href="https://youradchoices.ca/" className="s1">
                                            Digital Advertising Alliance of Canada
                                        </a>
                                    
                                </li>
                                <li>
                                   
                                        <a href="http://www.youronlinechoices.com/" className="s1">
                                            European Interactive Digital Advertising Alliance
                                        </a>
                                    
                                </li>
                            </ul>

                            <h2>
                                What about other tracking technologies, like web beacons?
                            </h2>

                            <p>
                                Cookies are not the only way to recognize or track visitors to a
                                website. We may use other, similar technologies from time to time,
                                like web beacons (sometimes called &quot;tracking pixels&quot; or
                                &quot;clear gifs&quot;). These are tiny graphics files that contain
                                a unique identifier that enables us to recognize when someone has
                                visited our Website or opened an email including them. This allows
                                us, for example, to monitor the traffic patterns of users from one
                                page within a website to another, to deliver or communicate with
                                cookies, to understand whether you have come to the website from an
                                online advertisement displayed on a third-party website, to improve
                                site performance, and to measure the success of email marketing
                                campaigns. In many instances, these technologies are reliant on
                                cookies to function properly, and so declining cookies will impair
                                their functioning.
                            </p>

                            <p>
                                When you visit our website, we use site visit recordation
                                technologies, to memorialize site visits, third-party cookies,
                                becaons, tags and scripts to help collect the categories of
                                information described above and aggregated information from your
                                internet browser such as your behavior on our website.
                                We and third parties also use tools that enable us to track certain
                                aspects of a user’s visit to our websites. These technologies help
                                us better manage content on our websites by informing us what
                                content is effective, how consumers engage with our websites, and
                                how consumers arrive at and/or depart from our websites. The
                                software typically uses two methods to track user activity: (1)
                                “tracking pixels” and (2) “clear gifs.” Tracking pixels are pieces
                                of executable code that are embedded in a web page that track usage
                                activity including which pages are viewed, when they are viewed, and
                                how long the pages are viewed. Clear gifs are tiny graphics with
                                unique identifiers which are embedded in web pages and email
                                messages that track whether a user views a web page or email
                                message. User activity information may be associated with additional
                                information about a user’s session and Personal Information, if
                                provided by the user.
                            </p>

                            <p>
                                We may use following technologies across our websites:
                            </p>
                        <ul>
                        <li>
                            <p>
                                We use Google Analytics, a web analysis service provided by
                                Google, and some similar services to better understand your use of
                                our Sites and apps. Google Analytics collects information such as
                                how often users visit the website, what pages they visit, and what
                                other sites they used prior to visiting. Google uses the data to
                                track and examine the use of the Site, to prepare reports on its
                                activities and share them with other Google services. Google’s
                                ability to use and share information collected by Google Analytics
                                about your visits to the Site is restricted by the{" "}
                                <a
                                    href="https://www.google.com/analytics/terms/us.html"
                                   
                                    target="_blank" rel="noreferrer"
                                >
                                    Google Analytics Terms of Service
                                </a>
                                {" "}and the{" "}
                                <span>
                                    Google Privacy Policy
                                </span>
                                
                                    . For more information about Google’s policies and practices,
                                    please see Google’s privacy policy at{" "}
                               
                                <span >
                                <a
                                    href="http://www.google.com/intl/en/policies/privacy"
                                   
                                    target="_blank" rel="noreferrer"
                                > https://www.google.com/intl/en/policies/privacy. </a> You may opt out
                                    of the aggregation and analysis of data collected about you on our
                                    website by Google Analytics by downloading the Google Analytics
                                    opt-out browser add-on, available at
                                    https://tools.google.com/dlpage/. Please note that if you opt out,
                                    analytics companies other than Google Analytics may continue to
                                    aggregate and analyze data collected about you on our website.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                
                                    Facebook. For more information about Facebook’s policies and
                                    practices, please see Facebook’s privacy policy at{" "}
                               
                                <a
                                    href="https://www.facebook.com/privacy/policy/"
                                   
                                    target="_blank" rel="noreferrer"
                                >
                                    https://www.facebook.com/privacy/policy/
                                    </a>
                                ;
                            </p>
                        </li>
                        <li>
                            <p>
                               
                                    Tiktok. For more information about Tiktok’s policies and
                                    practices, please see Tiktok’s privacy policy at{" "}
                                
                                <a
                                    href="https://www.tiktok.com/legal/page/us/privacy-policy/en"
                                   
                                    target="_blank" rel="noreferrer"
                                >
                                    https://www.tiktok.com/legal/page/us/privacy-policy/en
                                    </a>
                                ;
                            </p>
                        </li>
                    </ul>

                    <h2>
                        Do you use Flash cookies or Local Shared Objects?
                    </h2>
                    <p>
                        Websites may also use so-called &quot;Flash Cookies&quot; (also known as
                        Local Shared Objects or &quot;LSOs&quot;) to, among other things,
                        collect and store information about your use of our services, fraud
                        prevention, and for other site operations.
                    </p>

                    <p>
                        If you do not want Flash Cookies stored on your computer, you can
                        adjust the settings of your Flash player to block Flash Cookies
                        storage using the tools contained in the{" "}
                        <a
                            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                          
                            target="_blank" rel="noreferrer"
                        >
                            Website Storage Settings Panel
                        </a>
                        . You can also control Flash Cookies by going to the{" "}
                        <a
                            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                          
                            target="_blank" rel="noreferrer"
                        >
                            Global Storage Settings Panel
                        </a>
                        {" "}and following the instructions (which may include instructions that
                        explain, for example, how to delete existing Flash Cookies (referred
                        to &quot;information&quot; on the Macromedia site), how to prevent
                        Flash LSOs from being placed on your computer without your being
                        asked, and (for Flash Player 8 and later) how to block Flash Cookies
                        that are not being delivered by the operator of the page you are on at
                        the time).
                    </p>

                    <p>
                        Please note that setting the Flash Player to restrict or limit
                        acceptance of Flash Cookies may reduce or impede the functionality of
                        some Flash applications, including, potentially, Flash applications used
                        in connection with our services or online content.
                    </p>

                    <h2>
                        Do you serve targeted advertising?
                    </h2>

                    <p >
                        Third parties may serve cookies on your computer or mobile device to
                        serve advertising through our Website. These companies may use
                        information about your visits to this and other websites in order to
                        provide relevant advertisements about goods and services that you may be
                        interested in. They may also employ technology that is used to measure
                        the effectiveness of advertisements. They can accomplish this by using
                        cookies or web beacons to collect information about your visits to this
                        and other sites in order to provide relevant advertisements about goods
                        and services of potential interest to you. The information collected
                        through this process does not enable us or them to identify your name,
                        contact details, or other details that directly identify you unless you
                        choose to provide these.
                    </p>

                    <h2>
                        How often will you update this Cookie Policy?
                    </h2>

                    <p>
                        We may update this Cookie Policy from time to time in order to reflect,
                        for example, changes to the cookies we use or for other operational,
                        legal, or regulatory reasons. Please therefore revisit this Cookie
                        Policy regularly to stay informed about our use of cookies and related
                        technologies.
                    </p>

                    <p>
                        The date at the top of this Cookie Policy indicates when it was last
                        updated.
                    </p>
                    <h2>
                        Where can I get further information?
                    </h2>

                    <p>
                        
                            If you have any questions about our use of cookies or other
                            technologies, please email us at{" "}
                      
                        <a href="mailto:support@ottoleads.ai" target="_blank" rel="noreferrer">support@ottoleads.ai</a> or by post to:
                    </p>

                    <p>
                        OttoLeads LLC
                        <br />
                        30 N Gould St, Ste R, Sheridan, WY 82801
                        <br />
                        United States
                    </p>
                </div>
            </div>
        </div>
        </>
    );
};

export default Cookies;
