import { getAPI, postAPI, putAPI } from "../../apis/apis";

  export const addUser = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/users/add-user`,
      headers: {},
      data: payload,
    });
  };
 
  export const updateUserRole = async (payload:any) => {
    return putAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/users/update-user-role`,
      headers: {},
      data: payload,
    });
  };

  export const taxOnUser= async()=>{
    return getAPI({
      url : process.env.REACT_APP_BASE_URL + `/payment-gateway/user-seat-tax`,
      headers:{},
    })
  }
 