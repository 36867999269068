import { createAction } from "@reduxjs/toolkit";

export const fetchPropertyDetailsAction = createAction(
  "fetchPropertyDetails",
  (data) => ({ payload: data })
);

export const propertyUpdateDetailsAction = createAction(
  "updatePropertyDetails",
  (data) => ({ payload: data })
);

export const loadingAction = createAction("isLoading", (data) => data);

export const fetchWholesaleCalculatorAction = createAction(
  "fetchWholesaleCalculator",
  (data) => ({ payload: data })
);

export const fetchAmortizationCalculatorAction = createAction(
  "fetchAmortizationCalculator",
  (data) => ({ payload: data })
);

export const fetchFinanceCalculatorAction = createAction(
  "fetchFinanceCalculator",
  (data) => ({ payload: data })
);

export const fetchFinanceCalculatorIdAction = createAction(
  "fetchFinanceIdCalculator",
  (data) => ({ payload: data })
);