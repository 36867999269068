import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import Logo from "../../components/Logo";
import AlertTriangle from "../../assets/icons/alertTriangle";
import Button from "../../atoms/Button";
import ResendLink from "../resendLink/ResendLink";

interface NotConfirmedModalProps {
  open: boolean;
  setOpen: any;
  email: string;
  handleResendModal: Function;
  showResendModal: boolean;
  setShowResendModal: any;
  linkSent: boolean;
  setLinkSent: any;
}

const NotConfirmedModal = ({
  open,
  setOpen,
  email,
  handleResendModal,
  showResendModal,
  setShowResendModal,
  linkSent,
  setLinkSent,
}: NotConfirmedModalProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog loginRegister otpDialog`}
      >
        <div className="dialogWrapper authStyle notConfirm">
          <span
            role="link"
            className="closeDialog"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </span>
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>

          <div className="authStyle__logo flex alignCenter justifyCenter">
            <Logo />
          </div>
          <div className="verifyEmail__info">
            <figure className="notConfirmed">
              <AlertTriangle />
            </figure>
            <h2>Not confirmed</h2>
            <p>
              <strong>{email}</strong>
            </p>
            <p>Please click below to resend the link</p>{" "}
            <div className="dflex justifyCenter confirmButton">
              <Button
                className="primary"
                label="Verify your email"
                action={handleResendModal}
              />
            </div>
          </div>
        </div>
      </Dialog>

      {showResendModal && (
        <>
          <ResendLink
            open={showResendModal}
            setOpen={setShowResendModal}
            email={email ?? ""}
            linkSent={linkSent}
            setLinkSent={setLinkSent}
          />
        </>
      )}
    </>
  );
};

export default NotConfirmedModal;
