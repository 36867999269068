import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isFileUploading: false,
  listUploading: false,
  openImportFileModal: false,
  leadListCSV: {file:null,key:''},
  newListUploaded: false,
};

const uploadListSlice = createSlice({
  name: "uploadList",
  initialState,
  reducers: {
    setIsFileUploading: (state, { payload }) => {
      return { ...state, isFileUploading: payload };
    },
    setListUploading: (state, { payload }) => {
      return { ...state, listUploading: payload };
    },
    toggleImportFileModal: (state, { payload }) => {
      return { ...state, openImportFileModal: payload };
    },
    setLeadListCSV: (state, { payload }) => {
      return { ...state, leadListCSV: payload };
    },
    setNewListUploaded: (state, { payload }) => {
      return { ...state, newListUploaded: payload };
    },
    resetUploadListState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setIsFileUploading,
  setListUploading,
  toggleImportFileModal,
  setLeadListCSV,
  setNewListUploaded,
  resetUploadListState,
} = uploadListSlice.actions;

export default uploadListSlice.reducer;
