import { createAction } from "@reduxjs/toolkit";

export const fetchMyList = createAction("user/lists", function prepare(data) {
  return { payload: data };
});

export const fetchLeadListDetail=  createAction(
  "FETCH_LEAD_LIST_DETAIL",
  function prepare(data) {
    return { payload: data };
  }
)