import { createAction } from "@reduxjs/toolkit";

export const fetchCampaignList = createAction(
  "user/campaigns",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCampaignStats = createAction(
  "FETCH_CAMPAIGN_STATS",
  function prepare(data) {
    return { payload: data };
  }
);