import React, { useLayoutEffect, useState } from "react";
export enum MEDIA_TYPES {
  DESKTOP = "DESKTOP",
  TAB = "TAB",
  MOBILE = "MOBILE",
}
export interface MediaContextProps {
  media: MEDIA_TYPES;
}
export const MediaContext = React.createContext<MediaContextProps | null>(null);

const MediaContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [size, setSize] = useState([window?.innerWidth, window?.innerHeight]);
  const updateSize = () => {
    setSize([window?.innerWidth, window?.innerHeight]);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [updateSize]);
  let media = MEDIA_TYPES.DESKTOP;
  if (size[0] < 1025) {
    media = MEDIA_TYPES.TAB;
  }
  if (size[0] < 768) {
    media = MEDIA_TYPES.MOBILE;
  }

  return (
    <MediaContext.Provider value={{ media }}>{children}</MediaContext.Provider>
  );
};

export default MediaContextProvider;
