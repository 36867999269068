import { takeLatest, put, all} from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
 
function* fetchWebhookList({ payload }: any) {
  yield put(slice.setWebhookListIsLoading(true))
    const{index,size}=payload
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/users/webhook-log?index=${index}&size=${size}`, 
      headers: {},
    });
    yield all([
      put(slice.setwebhookList(response?.data)),
      put(slice.setWebhookListIsLoading(false)),
    ]);
  } catch (error: any) {
       yield all([
         put(slice.setWebhookListIsLoading(false)),
         put(
           addToast({
             id: new Date().getTime(),
             message: error?.response?.data?.message?error?.response?.data?.message : error?.message ?? "Something went wrong!",
             type: ToastType.error,
           })
         ),
       ]);
  }
}

function* webhookSaga() {
  yield takeLatest(sagaActions.fetchWebhooklist, fetchWebhookList);
}

export default webhookSaga;
