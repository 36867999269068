import axios from "axios";
import { decodetoken, getLocalStorageData } from "../utils/utils";
import { signOut } from "../services/cognitoService";
import { generateRefreshToken } from "../organisms/auth/authServices";

const handleLogout = () => {
  signOut();
  window.location.href = "/";
};
const interceptor = async () => {
  axios.interceptors.request.use(async (req) => {
    const controller = new AbortController();
    let JWTToken: any = localStorage.getItem("accessToken");
    if (
      req.url?.includes("s3.amazonaws.com") ||
      req.url?.includes("geolocation-db.com") ||
      req.url?.includes("maps.googleapis.com")
    ) {
      return req;
    }
    if (JWTToken != null) {
      if (getLocalStorageData("rememberMe")) {
        const expiryTime: any = decodetoken(JWTToken) ?? -1;
        const currentTime = new Date().getTime();
        if (expiryTime <= currentTime) {
          try {
            JWTToken = await generateRefreshToken();
            localStorage.setItem("accessToken", JWTToken);
            // JWTToken = await GetCognitoSessionDefault();
          } catch {
            handleLogout();
            controller.abort();
          }
        }
      }
      req.headers.Authorization = `Bearer ${JWTToken}`;
    }
    return {
      ...req,
      signal: controller.signal,
    };
  });

  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    (error: any) => {    
      if (
        (error?.response?.status === 401 &&
          error?.response?.data?.message === "Unauthorized") ||
        error?.response?.data?.errorCode === "USER_NOT_FOUND"
      ) {
        error.response.data.message = "";
        error.message = "";
        handleLogout();
      }

      if (!window.location?.pathname?.includes("/settings")) {
        if (error?.response?.data?.errorCode === "NO_GENERAL_ACCESS") {
          window.location.replace("/settings");
        }
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
