import ReactApexChart from "react-apexcharts";

export const CHART_TYPE: string = "bar";

const Chart = ({ options, series, type }: any) => {
  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type={type}
          height={230}
        />
      </div>
    </>
  );
};

export default Chart;
