import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setProfileInfo } from "../../components/profileMenu/reducer";
import { getProfileInfoLoading, getSessionLoading } from "../../components/profileMenu/selectors";
import GlobalLoader from "../../components/globalLoader/GlobalLoader";
import { getRefreshPage } from "./selectors";
// import { getRefreshPage } from "./selectors";

const LoginWrapper = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useSelector((state: any) => state.authGuard);
  const profileInfoLoading = useSelector((state) => getProfileInfoLoading(state));
  const sessionLoading = useSelector((state) => getSessionLoading(state));
  const refreshPage = useSelector((state) => getRefreshPage(state));
  const dispatch = useDispatch();
  if (sessionLoading || (profileInfoLoading && refreshPage)) {
    return <GlobalLoader></GlobalLoader>;
  } else if (auth) {
    return <>{children}</>;
  } else {
    dispatch(setProfileInfo({ payload: {} }));
    return <Navigate to="/" />;
  }
};

export default LoginWrapper;
