import { postAPI, putAPI } from "../../apis/apis";

export const resendInvite = async (payload: any) => {
  return putAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/users/resend-user-seat-invite/${payload}`,
    headers: {},
  });
};

export const approveInvite = async (payload: any) => {
  return putAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/users/approve-user-seat-invite/${payload}`,
    headers: {},
  });
};

export const declineInvite = async (payload: any) => {
  return putAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/users/decline-user-seat-invite/${payload}`,
    headers: {},
  });
};

export const disableUserSeat = async (payload: any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/users/disable-user-seat/${payload}`,
    headers: {},
  });
};

export const reactivateUserSeat = async (payload: any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/users/reactivate-user-seat/${payload}`,
    headers: {},
  });
};
