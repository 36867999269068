import { createContext, useContext } from "react";

interface MobileMenuDrawerContextType {
  closeDrawer: any;
}

const context = createContext<MobileMenuDrawerContextType>({
  closeDrawer: () => {},
});

const useMobileMenuDrawerContext = () => useContext(context);

export { useMobileMenuDrawerContext };
export default context;
export type { MobileMenuDrawerContextType };
