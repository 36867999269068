import {useState} from "react";
import Flag from "../../assets/icons/flag";
import styles from "../../organisms/createCampaign/CreateCampaign.module.scss";
import CustomDatePicker from "../../atoms/CustomDatePicker";
import Button from "../../atoms/Button";
import { startCampaignFormSchema } from "../../organisms/createCampaign/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import Save from "../../assets/icons/save";
import { useDispatch, useSelector } from "react-redux";
import { campaignDetail, editCampaignAction } from "./sagaAction";
import { isLoadingEdit } from "./selector";
import { useEffect } from "react";
import { setCreateCampaignData } from "../../organisms/createCampaign/reducer";
import { campaignDetailResponse } from "./selector";
import Loader from "../../atoms/Loader";
import { getProfileInfoLoading } from "../../components/profileMenu/selectors";
import { EditCampaignProps } from "./interface";
import CustomModal from "../../components/customModal/CustomModal";
import CampaignCallBackNo from "../../organisms/createCampaign/CampaignCallBackNo";

const EditCampaign = ({ openEdit, setOpenEdit, id }: EditCampaignProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    setValue
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(startCampaignFormSchema),
  });
  const [clicked, setClicked] = useState<boolean>(false);
  const closeModal = () => {
    setOpenEdit(false);
  };
  const dispatch = useDispatch();
  const loading = useSelector((state) => isLoadingEdit(state));
  const [showCallBackModal, setShowCallBackModal] = useState(false);
  const [showCallBackNo, setShowCallBackNo] = useState("");
  const createCompaignDetails = useSelector((state) =>
    campaignDetailResponse(state)
  );
  const profileInfoLoading = useSelector((state) =>
    getProfileInfoLoading(state)
  );

  const currentDate = new Date();

  const onSubmit = (data: any) => {

    setClicked(true);
    // dispatch(setCreateCampaignData(data));
    dispatch(editCampaignAction(data, id));
    setClicked(false);
    closeModal();
  };

  const handleCallBackPhoneModal =()=>{
    setShowCallBackModal(!showCallBackModal)
  }
  
  useEffect(()=>{
    setValue("callbackPhone",showCallBackNo)
   },[showCallBackNo])

  useEffect(() => {
    reset({
      campaignName: createCompaignDetails?.data?.name,
      optimizeCampaign: createCompaignDetails?.data?.optimizeEnabled,
      startDate: createCompaignDetails?.data?.startDate,
      maxAttemptPerNumber: createCompaignDetails?.data?.attemptFrequency,
      dialsPerNumber: createCompaignDetails?.data?.dpn,
      callbackPhone:createCompaignDetails?.data?.callbackPhone
    });
    setShowCallBackNo(createCompaignDetails?.data?.callbackPhone)
  }, [createCompaignDetails, reset]);

  useEffect(() => {
    dispatch(campaignDetail(id));
  }, [id]);

  return (
    <>
      <Dialog
        className="stepsDialog"
        onClose={() => {
          closeModal();
        }}
        open={openEdit}
      >
        {createCompaignDetails && !profileInfoLoading ? (
          <div className="dialogWrapper">
            <span
              role="link"
              onClick={() => {
                closeModal();
                // clearErrors();
              }}
              className="closeDialog"
            >
              <Close />
            </span>

            <div className={`${styles.createCampaign}`}>
              <div className={`headerIcon ${styles.headerIcon}`}>
                <span className={`${styles.flag}`}>
                  <Flag />
                </span>
              </div>
              <div className={`${styles.createCampaign__header}`}>
                <h3>Edit a campaign</h3>
                <p>Provide your campaign details below</p>
              </div>
              <Input
                type="text"
                label="Campaign Name*"
                register={register}
                name="campaignName"
                placeholder="Give your campaign a friendly name"
                errors={errors}
              ></Input>
              <CustomDatePicker
                label="Start date*"
                control={control}
                name="startDate"
                errors={errors}
                disabled={
                  createCompaignDetails?.data?.startDate <
                  currentDate.toISOString()
                    ? true
                    : false
                }
              ></CustomDatePicker>
              {/* {!watch("optimizeCampaign") && (
                <div>
                  <DropDown
                    options={MAX_ATTEMPTS_OPTIONS}
                    label="Maximum attempts per number*"
                    control={control}
                    name="maxAttemptPerNumber"
                    errors={errors}
                    placeholder="Select the maximum number of attempts"
                    hint="We recommend 7 attempts per number for optimal results. DNC, disconnected numbers, and numbers with other undesirable results are excluded on each subsequent list of phone numbers to attempt."
                    disabled={
                      createCompaignDetails?.data?.startDate <
                      currentDate.toISOString()
                        ? true
                        : false
                    }
                    dataTestId="maxAttemptNoTestId"
                  ></DropDown>
                </div>
              )} */}


          {/* <div className={`${styles.credits}`}>
          Your currect callback phone number is <strong>{showCallBackNo}</strong>. If you want to change then {" "}<span role="link" onClick={()=> handleCallBackPhoneModal()}>click here</span>.
          </div> */}

              <div className={`${styles.createCampaign__action}`}>
                <Button
                  label={"Save Your Campaign"}
                  prefix={<Save />}
                  className="primary xl full animated"
                  action={handleSubmit(onSubmit)}
                  clicked={clicked}
                  disabled={clicked}
                  dataTestId="saveEditCampaignId"
                ></Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={`loader-main`}>
            <Loader></Loader>
          </div>
        )}
      </Dialog>
      {<CustomModal customClass="contactInfoModal" open={showCallBackModal} handleClose={
      handleCallBackPhoneModal}>
       <CampaignCallBackNo showCallBackNo={showCallBackNo} setShowCallBackNo= { setShowCallBackNo} handleCallBackPhoneModal={handleCallBackPhoneModal}></CampaignCallBackNo>
        </CustomModal>}
    </>
  );
};

export default EditCampaign;
