import Slider from "./Slider";
import styles from "./homeSliders.module.scss";
import customer from "../../../assets/images/slider-map.png";
import EmailSpark from "../../../assets/images/email-spark.png";
import ClockIcon from "../../../assets/icons/clockIcon";

const BottomSlider = () => {
  return (
    <Slider rightDirection={"right"}>
      <div>
        <div className={` ${styles.sliderBox}`}>
          <figure>
            <img src={customer} alt="customer" />
          </figure>
        </div>
      </div>
      <div>
        <div className={` ${styles.sliderBoxContent}`}>
          {/* <figure>
              <EmailSpark />
            </figure> */}
          <img src={EmailSpark} alt="icon" />
          <figcaption>Sit back, relax, and let AI generate leads for you.</figcaption>
        </div>
      </div>
      <div>
        <div className={` ${styles.sliderBoxBlue} ${styles.sliderMapBg}`}>
          <h3>Find motivated sellers using property search</h3>
        </div>
      </div>
      <div>
        <div className={` ${styles.sliderBoxContent}`}>
          <figure className={` ${styles.icon}`}>
            <ClockIcon />
          </figure>
          <figcaption>Save time and focus on growing your business.</figcaption>
        </div>
      </div>
    </Slider>
  );
};

export default BottomSlider;
