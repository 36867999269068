import { createSlice } from "@reduxjs/toolkit";

export const initialState = {  
  voiceList: [],
  createCampaignData : [],
  campaignLoadingState: false,
  campaignCreated: false,
  campaignLeadList: [],
  loadingVoiceList: false,
  loadingCampaignLeadList:false,
};

const createCampaignSlice = createSlice({
  name: "createCampaign",
  initialState,
  reducers: {
    voiceList: (state, { payload: { data } }) => {
      return {
        ...state,
        voiceList: data,
      };
    },
    setCreateCampaignData:(state, { payload }) => {
      return {
        ...state,
        createCampaignData: payload,
      };
    },
    setCampaignLoadingState: (state, { payload }) => {
      return {
        ...state,
        campaignLoadingState: payload,
      };
    },
    setCampaignCreated: (state, { payload }) => {
      return {
        ...state,
        campaignCreated: payload,
      };
    },
    setCampaignLeadList: (state, { payload }) => {
      return {
        ...state,
        campaignLeadList: payload,
      };
    },
    setLoadingVoiceList: (state, { payload }) => {
      return {
        ...state,
        loadingVoiceList: payload,
      };
    },
    setLoadingCampaignLeadList: (state, { payload }) => {
      return {
        ...state,
        loadingCampaignLeadList: payload,
      };
    },
    resetCreateCampaignState: (state, payload ) => {
      return initialState;
    }
  },
});

export const {
  voiceList,
  setCreateCampaignData,
  setCampaignLoadingState,
  setCampaignCreated,
  setCampaignLeadList,
  setLoadingCampaignLeadList,
  setLoadingVoiceList,
  resetCreateCampaignState,
} = createCampaignSlice.actions;

export default createCampaignSlice.reducer;
