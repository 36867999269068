import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  profileInfo: {} as any,
  profileInfoLoading: false,
  sessionLoading: !!localStorage.getItem("accessToken"),
};

const profileInfoSlice = createSlice({
  name: "profileInfo",
  initialState,
  reducers: {
    setProfileInfo: (state, { payload }) => {
      return { ...state, profileInfo: payload };
    },
    setProfileInfoLoading: (state, { payload }) => {
      return { ...state, profileInfoLoading: payload };
    },
    setSessionLoading: (state, { payload }) => {
      return { ...state, sessionLoading: payload };
    },
    resetProfileInfoState: (state, { payload }) => {
      return { ...initialState, sessionLoading: false };
    },
  },
});

export const { setProfileInfo, setProfileInfoLoading, resetProfileInfoState, setSessionLoading } =
  profileInfoSlice.actions;

export default profileInfoSlice.reducer;
