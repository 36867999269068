import { getAPI } from "../../apis/apis";

export const fetchCampaignExportList = (payload:any) => {
  return getAPI({
    url:
      process.env.REACT_APP_BASE_URL +
      `/campaign/detail-table/export?filter=${payload?.filter}`,
    headers: {},
  });
};


