
import styles from "./PriceCard.module.scss";

const PriceCardFooter = ({ children }: React.PropsWithChildren<{}> ) => {
    return (
        <>
            <div className={`dflex justifySpaceBetween alignCenter ${styles.priceCardFooter}`}>
                {children}
            </div>

        </>

    );
};

export default PriceCardFooter;

