
const HelpCircle = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clipPath="url(#clip0_9364_32701)">
                    <path d="M6.0587 5.99992C6.21543 5.55436 6.5248 5.17866 6.932 4.93934C7.3392 4.70002 7.81796 4.61254 8.28348 4.69239C8.749 4.77224 9.17124 5.01427 9.47542 5.3756C9.77959 5.73694 9.94607 6.19427 9.94536 6.66659C9.94536 7.99992 7.94536 8.66659 7.94536 8.66659M7.9987 11.3333H8.00536M14.6654 7.99992C14.6654 11.6818 11.6806 14.6666 7.9987 14.6666C4.3168 14.6666 1.33203 11.6818 1.33203 7.99992C1.33203 4.31802 4.3168 1.33325 7.9987 1.33325C11.6806 1.33325 14.6654 4.31802 14.6654 7.99992Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_9364_32701">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    );
};

export default HelpCircle;