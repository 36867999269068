import { yupResolver } from "@hookform/resolvers/yup";
import Dialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import Close from "../../assets/icons/cross";
import { signupFormSchema } from "./validations";
import Input from "../../atoms/Input";
import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "../../atoms/Button";
import LogoIcon from "../../components/LogoIcon";
import MaskedInput from "../../atoms/MaskedInput";
import { isLoading } from "./selectors";
import { useSelector } from "react-redux";
import { SignupProps } from "./interface";
import { NavLink } from "react-router-dom";

const Signup = ({
  stepData,
  onSubmit,
}: SignupProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(signupFormSchema),
    defaultValues: {
      confirmation: false,
    },
  });
  const loading = useSelector((state) => isLoading(state));

  return (
    <>
      <div className="authStyle__logo logo-left flex justifyStart">
        <LogoIcon />
      </div>
      <div className="authStyle__header">
        <h2>Sign up for beta access</h2>
        <p>
          Keep an eye out for an email and text from us in the coming weeks for
          your beta invitation.
        </p>
      </div>

      <form>
        <Input
          type="text"
          label="Name*"
          register={register}
          name="name"
          placeholder="Enter your name"
          errors={errors}
        ></Input>
        <Input
          type="email"
          label="Email*"
          register={register}
          name="email"
          placeholder="Enter your email"
          errors={errors}
        ></Input>

        <MaskedInput
          placeholder="Enter your cell number"
          label="Phone*"
          name="phone"
          register={register}
          errors={errors}
          mask={"999-999-9999"}
        ></MaskedInput>
        <div className="authStyle__links flexCol ">
          <FormControlLabel
            control={
              <Controller
                name="confirmation"
                control={control}
                render={({ field, ...props }) => {
                  return (
                    <>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    </>
                  );
                }}
              />
            }
            label={
              <div className="checkboxDesc">
                By checking the checkbox and clicking “Sign Up” below, I am providing my
                ESIGN signature and express written consent agreement to permit OttoLeads,
                and parties calling on their behalf, to contact me at the number and email provided above for
                marketing purposes, including through the use of automated technology, SMS/MMS
                messages, AI generative voice, email, and prerecorded and/or artificial voice messages. I
                also agree to the{" "}
                <NavLink
                  target="_blank"
                  to="/policies/terms-of-use"
                  role="link"
                >
                  TERMS OF USE
                </NavLink>
                {" "}and{" "}
                <NavLink
                  target="_blank"
                  to="/policies/privacy-policy"
                  role="link"
                >
                  PRIVACY POLICY.
                </NavLink>
              </div>
            }
          />
          {(errors?.confirmation?.message as string) && (
            <span className="error">
              {errors?.confirmation?.message as string}
            </span>
          )}
        </div>
        <div className="authStyle__footer">
          <Button
            label="Sign Up"
            className="primary full xl animated"
            action={handleSubmit(onSubmit)}
            clicked={loading}
            disabled={loading}
          ></Button>
        </div>
      </form>
    </>
  );
};

export default Signup;
