import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import AddUser from "../../organisms/addUser/AddUser";
import { addUser } from "./TeamService";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomError } from "../../organisms/addLeads/interface";
import { fetchActiveUsersList, fetchUserRoleList } from "./sagaActions";
import { UsersHeader } from "../../components/usersHeader/UsersHeader";
import Plus from "../../assets/icons/plus";
import { getActiveUserList } from "./selectors";
import { setActiveUserList } from "./reducer";
import ActiveUsers from "../../organisms/activeUsers/ActiveUsers";
import { Tooltip } from "@mui/material";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

const MyTeam = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  const activeList = useSelector((state) => getActiveUserList(state));
  const handleAddModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await addUser(data);
      setLoading(false);
      setShowAddUserModal(false);
      handleShowToast("User added successfully!", ToastType.success);
      dispatch(setActiveUserList([...activeList,response?.data?.data]));
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => {
    dispatch(fetchUserRoleList({}));
  }, []);

  return (
    <>
      <div className="flex justifyEnd mb-15">
        <Tooltip
          title={
            profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
              ? ROLE_PERMISSION_STATUS.NoAccess
              : ""
          }
        >
          <div>
            {profileInfo?.userRoleName !==
            PermissionListEnum.ORGANISATION_OWNER ? (
              <Button
                prefix={<Plus />}
                className="primary animated"
                label={"Add user"}
                disabled={
                  profileInfo?.userRoleName !==
                  PermissionListEnum.ORGANISATION_OWNER
                }
              ></Button>
            ) : (
              <Button
                prefix={<Plus />}
                className="primary animated"
                label={"Add user"}
                action={() => handleAddModal()}
              ></Button>
            )}
          </div>
        </Tooltip>
      </div>

      <AddUser
        handleClose={handleAddModal}
        onSubmitDetail={onSubmit}
        loading={loading}
        open={showAddUserModal}
      />

      {/* <UsersHeader /> */}
      <ActiveUsers />
    </>
  );
};

export default MyTeam;
