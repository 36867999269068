import * as yup from "yup"
export const profileDetailsSchema = yup.object({
  name: yup.string().trim().required("Name is required"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Please enter a valid email"),
  companyName: yup.string().trim().required("Company name is required"),
  callbackPhone: yup
  .string()
  .transform((value) => value.replace(/\D/g, ""))
  .test(
    'is-valid-phone',
    'Please enter a valid phone number',
    (value) => !value || /^[0-9]{10}$/.test(value)
  )
  .notRequired() 
});