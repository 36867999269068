import { Column } from "../../models/table.interface";
import { ManageTeamActionType } from "./enum";

export const MANAGE_TEAM_TABLE_HEADER: Column[] = [
  {
    id: "name",
    label: "Name",

    minWidth: 30,
    type: "",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 70,
    type: "",
  },
  { id: "roleName", label: "Role", minWidth: 70, type: "statusPill" },
  { id: "actions", label: "", minWidth: 70, type: "" },
];

export const MANAGE_TEAM_ACTION_MODAL_DATA: {
  [key: string]: { header: string; content: string };
} = {
  [ManageTeamActionType.APPROVE]: { header: "Please Confirm", content: "" },
  [ManageTeamActionType.DECLINE]: {
    header: "Decline seat invitation",
    content: `Are you sure you want to decline this seat invitation? <br />
            This action cannot be undone.`,
  },
  [ManageTeamActionType.ENABLE]: { header: "Please Confirm", content: "" },
  [ManageTeamActionType.DISABLE]: {
    header: "Disable Seat",
    content: `Are you sure you want to disable this seat?`,
  },
};
