const Chat = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
<path d="M8.33333 10.3958H12.5M8.33333 14.0417H15.625M12.5 22.375C17.6777 22.375 21.875 18.1777 21.875 13C21.875 7.82233 17.6777 3.625 12.5 3.625C7.32233 3.625 3.125 7.82233 3.125 13C3.125 14.247 3.36848 15.4372 3.81048 16.5255C3.89508 16.7338 3.93737 16.838 3.95624 16.9222C3.9747 17.0045 3.98145 17.0655 3.98147 17.1499C3.98148 17.2361 3.96581 17.3301 3.93449 17.5181L3.3168 21.2242C3.25212 21.6123 3.21977 21.8064 3.27996 21.9467C3.33264 22.0695 3.4305 22.1674 3.55332 22.22C3.69364 22.2802 3.88769 22.2479 4.27579 22.1832L7.98193 21.5655C8.16988 21.5342 8.26386 21.5185 8.35014 21.5185C8.43453 21.5185 8.49548 21.5253 8.57783 21.5438C8.66202 21.5626 8.76617 21.6049 8.97447 21.6895C10.0628 22.1315 11.253 22.375 12.5 22.375Z" 
stroke="currentColor"
strokeWidth="1.66667"
strokeLinecap="round"
strokeLinejoin="round" />
</svg>
        </>
    );
};

export default Chat;

