import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Menu from "../../assets/icons/menu";
import styles from "./ToggleMenu.module.scss";
import Logo from "../../components/Logo";
import NavLeft from "../../components/navLeft/NavLeft";
import NavRight from "../../components/navRight/NavRight";
import { useSelector } from "react-redux";
import { notificationDataResponse } from "../notifications/selectors";
import MobileMenuDrawerContext from "../../context/MobileMenuDrawerContext";
import ToggleIcon from "../../assets/icons/toggleIcon";
import { ToggleMenuProps } from "./interface";

type Anchor = "top" | "left" | "bottom" | "right";

export default function ToggleMenu({
  blackFixed,
  loggedIn,
  onLogout,
  upgradeNow,
}: ToggleMenuProps) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const notificationData = useSelector((state) =>
    notificationDataResponse(state)
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const MobileMenuDrawerContextValue = React.useMemo(
    () => ({
      closeDrawer: toggleDrawer("left", false),
    }),
    []
  );

  return (
    <>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            className={`${blackFixed ? styles.toggleMenuWhite : ``}`}
            onClick={toggleDrawer("left", true)}
          >
            {loggedIn ? <Menu /> : <ToggleIcon />}
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <MobileMenuDrawerContext.Provider
              value={MobileMenuDrawerContextValue}
            >
              <div className={`${styles.toggleMenu__inner}`}>
                <div>
                  <Logo />
                  <NavLeft
                    loggedIn={loggedIn}
                    notificationData={notificationData}
                    upgradeNow={upgradeNow}
                  />
                </div>
                <div>
                  <NavRight
                    loggedIn={loggedIn}
                    notificationData={notificationData}
                    onLogout={onLogout}
                    upgradeNow={upgradeNow}
                  />
                </div>
              </div>
            </MobileMenuDrawerContext.Provider>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
