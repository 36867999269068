

const Camera = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                <path d="M1 6.70178C1 5.20963 2.20963 4 3.70178 4C4.47706 4 5.16537 3.5039 5.41053 2.7684L5.5 2.5C5.54219 2.37343 5.56329 2.31014 5.58586 2.254C5.87405 1.53712 6.54939 1.05037 7.32061 1.00366C7.38101 1 7.44772 1 7.58114 1H14.4189C14.5523 1 14.619 1 14.6794 1.00366C15.4506 1.05037 16.126 1.53712 16.4141 2.254C16.4367 2.31014 16.4578 2.37343 16.5 2.5L16.5895 2.7684C16.8346 3.5039 17.5229 4 18.2982 4C19.7904 4 21 5.20963 21 6.70178V15.2C21 16.8802 21 17.7202 20.673 18.362C20.3854 18.9265 19.9265 19.3854 19.362 19.673C18.7202 20 17.8802 20 16.2 20H5.8C4.11984 20 3.27976 20 2.63803 19.673C2.07354 19.3854 1.6146 18.9265 1.32698 18.362C1 17.7202 1 16.8802 1 15.2V6.70178Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M11 15.5C13.4853 15.5 15.5 13.4853 15.5 11C15.5 8.51472 13.4853 6.5 11 6.5C8.51472 6.5 6.5 8.51472 6.5 11C6.5 13.4853 8.51472 15.5 11 15.5Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>

        </>
    );
};

export default Camera;