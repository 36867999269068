import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listIsLoading: false,
  myLists: [] as any[],
  total: 0,
  listDetail:{},
  listdetailLoading:false,
  leadListDetail: [] as any[]
};

const myListsTableSlice = createSlice({
  name: "myListsTable",
  initialState,
  reducers: {
    setMyLists: (state, { payload: { data, total } }) => {
      return {
        ...state,
        myLists: data ?? [],
        total: total,
      };
    },
    appendMyLists: (state, { payload: { data, total } }) => {
      return {
        ...state,
        myLists: [...state.myLists, ...(data ? data : [])],
        total: total,
      };
    },
    setListIsLoading: (state, { payload }) => {
      return { ...state, listIsLoading: payload };
    },
    setLeadListDetailLoading: (state, { payload }) => {
      return { ...state, listdetailLoading: payload };
    },
    setLeadListDetail: (state, { payload}) => {
      return { ...state, leadListDetail: payload };
    },
    appendLeadListDetail: (state, { payload }) => {
      return {
        ...state,
        leadListDetail: [...state?.leadListDetail,...payload],
      };
    },
    setLeadListDetailCount: (state, { payload}) => {
      return { ...state, listDetail: payload };
    },
    resetMyListsTableState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setMyLists,
  setListIsLoading,
  appendMyLists,
  resetMyListsTableState,
  setLeadListDetail,
  setLeadListDetailLoading,
  appendLeadListDetail,
  setLeadListDetailCount
} = myListsTableSlice.actions;

export default myListsTableSlice.reducer;
