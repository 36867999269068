import { useState, useRef, useEffect, useCallback } from "react";
import styles from "./MeetOttoSec.module.scss";
import sampleFile from "../../../assets/audio/sample_audio.mp3";
import sampleOttoConvo1 from "../../../assets/audio/sample_otto_convo.mp3";
import { waveformInfo } from "../HomeConstants";
import MemoizedWaveform from "./waveform/Waveform";
import Counter from "./Counter";

const MeetOttoSec = () => {
  const meetOttoRef = useRef(null);
  const waveformRefOne = useRef<HTMLDivElement | any>(null);
  const waveformRefTwo = useRef<HTMLDivElement | any>(null);
  const waveformRefThree = useRef<HTMLDivElement | any>(null);
  const [isMeetOttoVisible, setIsMeetOttoVisible] = useState(false);
  const [playAudioOne, setPlayAudioOne] = useState(false);
  const [playAudioTwo, setPlayAudioTwo] = useState(false);
  const [playAudioThree, setPlayAudioThree] = useState(false);
  const [counter, setCounter] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
  });

  const handleAudio = (waveId: string) => {
    switch (waveId) {
      case "waveform1":
        setPlayAudioTwo(false);
        waveformRefTwo.current.pause();
        setPlayAudioThree(false);
        waveformRefThree.current.pause();
        break;
      case "waveform2":
        setPlayAudioThree(false);
        waveformRefThree.current.pause();
        setPlayAudioOne(false);
        waveformRefOne.current.pause();
        break;
      case "waveform3":
        setPlayAudioOne(false);
        waveformRefOne.current.pause();
        setPlayAudioTwo(false);
        waveformRefTwo.current.pause();
        break;
      default:
        break;
    }
  };

  const incrementCounter = useCallback(
    (counterName: string, limit: number, intervalTime: number) => {
      const decimalPart = limit % 1;
      const wholePart = limit - decimalPart;
      let currentValue = 0;

      return setInterval(() => {
        setCounter((prevCounter: any) => {
          if (currentValue < wholePart) {
            currentValue += 1;
            return {
              ...prevCounter,
              [counterName]: currentValue,
            };
          } else if (currentValue < limit) {
            currentValue += 0.1;
            return {
              ...prevCounter,
              [counterName]: currentValue.toFixed(1),
            };
          }
          return prevCounter;
        });
      }, intervalTime);
    },
    [setCounter]
  );

  useEffect(() => {
    if (isMeetOttoVisible) {
      const intervalOne = incrementCounter("one", 1.7, 400);
      const intervalTwo = incrementCounter("two", 144, 10);
      const intervalThree = incrementCounter("three", 2.4, 300);
      const intervalFour = incrementCounter("four", 40, 40);

      return () => {
        clearInterval(intervalOne);
        clearInterval(intervalTwo);
        clearInterval(intervalThree);
        clearInterval(intervalFour);
      };
    }
  }, [isMeetOttoVisible, incrementCounter]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsMeetOttoVisible(true);
        } else {
          setIsMeetOttoVisible(false);
        }
      });
    }, options);

    if (meetOttoRef.current) {
      observer.observe(meetOttoRef.current);
    }

    return () => {
      if (meetOttoRef.current) {
        observer.unobserve(meetOttoRef.current);
      }
    };
  }, [meetOttoRef]);

  return (
    <div className={`${styles.meet}`}>
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-anchor-placement="bottom-bottom"
        className={`${styles.header}`}
      >
        <h2>
          Meet <span>Otto,</span> your AI superhero.
        </h2>
        <p>Otto is an intelligent AI caller that will <u>automatically</u> generate real estate leads for you.</p>
      </div>
      <div className={` ${styles.meetInfo}`}>
        <div className={`dflex ${styles.meet__inner}`}>
          <div className={`${styles.meetInfo__left}`}>
            <ul
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <li>Quality leads that match or <u>exceed</u> human caller performance</li>
              <li><u>Smart</u> lead summaries detailing situation, motivation, closing timeline, price, and repairs.</li>
              <li>Leads generated at <u>impossible</u> speeds, compared to humans (see stats below)</li>
            </ul>
            <div
              className={`${styles.toggleCard}`}
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <MemoizedWaveform
                audio={waveformInfo.WAVE1.audio1}
                infoTitle={waveformInfo.WAVE1.infoTitle}
                infoDesc={waveformInfo.WAVE1.infoDesc}
                playBtnText={waveformInfo.WAVE1.playBtnText}
                waveformId="waveform1"
                playing={playAudioOne}
                setPlaying={setPlayAudioOne}
                handleAudioFn={handleAudio}
                waveformRef={waveformRefOne}
                audioId={waveformInfo.WAVE1.id}
              />

              {/* Toggle card on click open */}
            </div>
          </div>
          <div className={`${styles.meetInfo__right}`}>
            {/* Toggle card */}
            <div
              className={`${styles.toggleCard}`}
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="1000"
            >
              <MemoizedWaveform
                audio={waveformInfo.WAVE2.audio2}
                infoTitle={waveformInfo.WAVE2.infoTitle}
                infoDesc={waveformInfo.WAVE2.infoDesc}
                playBtnText={waveformInfo.WAVE2.playBtnText}
                waveformId="waveform2"
                playing={playAudioTwo}
                setPlaying={setPlayAudioTwo}
                handleAudioFn={handleAudio}
                waveformRef={waveformRefTwo}
                audioId={waveformInfo.WAVE2.id}
              />
            </div>
          </div>
          <div
            className={`${styles.toggleCardLast}`}
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <MemoizedWaveform
              audio={waveformInfo.WAVE3.audio3}
              infoTitle={waveformInfo.WAVE3.infoTitle}
              infoDesc={waveformInfo.WAVE3.infoDesc}
              playBtnText={waveformInfo.WAVE3.playBtnText}
              waveformId="waveform3"
              playing={playAudioThree}
              setPlaying={setPlayAudioThree}
              handleAudioFn={handleAudio}
              waveformRef={waveformRefThree}
              audioId={waveformInfo.WAVE3.id}
            />
            {/* Toggle card on click open */}
          </div>
        </div>
        <div ref={meetOttoRef} className={`${styles.numberSection}`}>
          <ul
            className="flex justifyCenter"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <Counter value={counter.one} label="Calls per day" lastDigit="M" />
            <Counter value={counter.two} label="Calls per hour" lastDigit="k" />
            <Counter
              value={counter.three}
              label="Calls per minute"
              lastDigit="k"
            />
            <Counter value={counter.four} label="Calls per second" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MeetOttoSec;
