export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
  }

  export enum SortCampaignKey {
    NAME = 'NAME',
    START_DATE='START_DATE',
    STATUS='STATUS',
    DNC='DNC',
    DISCONNECTED='DISCONNECTED',
    DEAD='DEAD',
    HANGUP='HANGUP',
    VOICEMAIL='VOICEMAIL',
    WRONG_NUMBER='WRONG_NUMBER',
    LEADS='LEADS',
    IN_QUEUE='IN_QUEUE',
    CALL_SMS='CALL_SMS',
  }

  export enum SortLeadsKey {
    STATUS='status',
    NAME='name',
    PHONE='phone',
    SUMMARY='summary',
    FOLLOWUP='followUpAt',
    UPDATEAT='updatedAt'
  }

  export enum SortLeadsListKey {
    LEAD='lead',
    DATECOLLECTED='datecollected'
  }