import React, { useEffect } from 'react'
import CreativeFinance from '../../pages/property/propertyTabs/creativeFinance/CreativeFinance'
import styles from "./Policy.module.scss";

const CreativeCalculatorPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);

  return (
    <>
        <div className={`${styles.calculator}`}>
        <div className={`${styles.calculator__header}`}>
            <h2>Creative Calculator</h2>
        </div>
        <div className={`wrapper`}>
        <CreativeFinance  />
        </div>
       
        </div>
    </>

  )
}

export default CreativeCalculatorPage