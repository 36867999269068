
const UnderContract = () => {
    return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M16.6654 10.4165V5.6665C16.6654 4.26637 16.6654 3.56631 16.3929 3.03153C16.1532 2.56112 15.7707 2.17867 15.3003 1.93899C14.7656 1.6665 14.0655 1.6665 12.6654 1.6665H7.33203C5.9319 1.6665 5.23183 1.6665 4.69706 1.93899C4.22665 2.17867 3.8442 2.56112 3.60451 3.03153C3.33203 3.56631 3.33203 4.26637 3.33203 5.6665V14.3332C3.33203 15.7333 3.33203 16.4334 3.60451 16.9681C3.8442 17.4386 4.22665 17.821 4.69706 18.0607C5.23183 18.3332 5.9319 18.3332 7.33203 18.3332H9.9987M11.6654 9.1665H6.66536M8.33203 12.4998H6.66536M13.332 5.83317H6.66536M12.082 15.8332L13.7487 17.4998L17.4987 13.7498" stroke="url(#paint0_linear_21499_47258)" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
<defs>
<linearGradient id="paint0_linear_21499_47258" x1="3.33203" y1="1.6665" x2="19.781" y2="15.6481" gradientUnits="userSpaceOnUse">
<stop stopColor="#81FFEF"/>
<stop offset="1" stopColor="#F067B4"/>
</linearGradient>
</defs>
</svg>
        </>
    );
};

export default UnderContract;