import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  webhookList: [] as any[],
  total: 0,
  webhookListIsLoading: false,
};

const webhookSlice = createSlice({
  name: "webhookTable",
  initialState,
  reducers: {
    setwebhookList: (state, { payload }) => {
      return {
        ...state,
        webhookList: payload?.data ?? [],
        total: payload?.total ?? 0,
      };
    },
    setWebhookListIsLoading: (state, { payload }) => {
      return { ...state, webhookListIsLoading: payload };
    },
  },
});

export const {
  setwebhookList,
  setWebhookListIsLoading,
} = webhookSlice.actions;

export default webhookSlice.reducer;
