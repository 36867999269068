import React, { useEffect, useState } from "react";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import styles from "./Security.module.scss";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastType } from "../../molecules/toaster/enum";
import { SecuritySchema } from "./Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { MailTypeEnum } from "../propertySearch/enum";
import { sendEmailAction } from "../../organisms/propertySearchFilter/sagaActions";
import { resetPasswordFn } from "../../organisms/auth/authServices";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { Tooltip } from "@mui/material";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
interface CustomError {
  response?: {
    data?: {
      message?: string;
    };
  };
}

export const Security = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(SecuritySchema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  
  // const [username, setUsername] = useState(getCookie("username") || "");

  // const getCookie = (name: string) => {
  //   const cookies = document.cookie.split("; ");
  //   for (const cookie of cookies) {
  //     const [cookieName, cookieValue] = cookie.split("=");
  //     if (cookieName === name) {
  //       return decodeURIComponent(cookieValue);
  //     }
  //   }
  //   return null;
  // }

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (e: any) => {
    if (e.newPassword === e.confirmNewPassword) {
      // const newData = {
      //   Username: username,
      //   Pool: userpool,
      // };
      // const cognitoUser = new CognitoUser(newData);
      // const authenticationDetails = new AuthenticationDetails({
      //   Username: username,
      //   Password: e.oldPassword,
      // });
      // cognitoUser.authenticateUser(authenticationDetails, {
      //   onSuccess: () => {
      //     cognitoUser.changePassword(
      //       e.oldPassword,
      //       e.newPassword,
      //       (err, result) => {
      //         if (err) {
      //           handleShowToast(err.message, ToastType.error);
      //           setLoading(false);
      //         } else {
      //           handleShowToast(
      //             "Password updated successfully!",
      //             ToastType.success
      //           );
      //           const payload = {
      //             type: MailTypeEnum.PASSWORD_CHANGED,
      //             data: { name: "asdf", cardLastDigit: "1234" },
      //           };
      //           dispatch(sendEmailAction(payload));
      //           reset();
      //           setLoading(false);
      //         }
      //       }
      //     );
      //   },
      //   onFailure: (err) => {
      //     handleShowToast("Current password is incorrect.", ToastType.error);
      //     setLoading(false);
      //   },
      // });
      const payload = {
        oldPassword: e?.oldPassword,
        newPassword: e?.newPassword,
      };

      setLoading(true);
      try {
        await resetPasswordFn(payload);
        handleShowToast("Password updated successfully!", ToastType.success);
        const data = {
          type: MailTypeEnum.PASSWORD_CHANGED,
          data: { name: "asdf", cardLastDigit: "1234" },
        };
        dispatch(sendEmailAction(data));
        reset();
      } catch (error) {
        console.log("error in reseting password",error);
        handleShowToast(`${(error as CustomError)?.response?.data?.message}`, ToastType.error);
      } finally {
        setLoading(false);
      }
    } else {
      // handleShowToast("New passwords do not match.", ToastType.error);
    }
  };

  const handleCancel = () => {
    reset();
  };
  
  useEffect(() => {
    if (watch("confirmNewPassword") === watch("newPassword")) {
      clearErrors("confirmNewPassword");
    }
  }, [watch("confirmNewPassword"), watch("newPassword"), setError]);

  useEffect(() => {
    if (watch("confirmNewPassword") === watch("newPassword")) {
      clearErrors("confirmNewPassword");
    }
  }, [watch("confirmNewPassword"), watch("newPassword"), setError]);

  return (
    <div className={`${styles.security}`}>
      <div className={`${styles.security__header}`}>
        <h3>Password</h3>
        <p>Please enter your current password to change your password.</p>
      </div>
      <div className={`${styles.security__form}`}>
        <form>
          <div className={`${styles.input}`}>
            <Input
              type="password"
              label="Current Password*"
              register={register}
              name="oldPassword"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="password"
              label="New Password*"
              register={register}
              name="newPassword"
              errors={errors}
            ></Input>
          </div>
          <div className={`${styles.input}`}>
            <Input
              type="password"
              label="Confirm new password*"
              register={register}
              errors={errors}
              name="confirmNewPassword"
            ></Input>
          </div>
        </form>
        <div className={`dflex justifyEnd ${styles.button}`}>
          <Button
            className="outline"
            label="Cancel"
            action={handleCancel}
          ></Button>
          <Tooltip
            title={
              profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""}
          >
            <div>
                <Button
                  className="primary"
                  label="Update Password"
                  type="submit"
                  action={handleSubmit(onSubmit)}
                  disabled={loading}
                  clicked={loading}
                ></Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
