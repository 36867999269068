import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  MEDIA_TYPES,
  MediaContext,
  MediaContextProps,
} from "../../../context/MediaContext";
import { formatNumber } from "../../../utils/utils";
import { PropertyDetailsParentIdType } from "../property.enum";
import { getPropertyDetails } from "../property.selector";
import ForeclosuresAndLines from "./ForeclosuresAndLinesTab/ForeclosuresAndLines";
import styles from "./PropertyTab.module.scss";
import CalculatorTab from "./calculatorTab/CalculatorTab";
import FollowUpTab from "./followUpTab/FollowUpTab";
import LinkedTab from "./linkedTab/LinkedTab";
import MlsTab from "./mlsTab/MlsTab";
import MortgageTab from "./mortgageTab/MortgageTab";
import NotesTab from "./notesTab/NotesTab";
import OverviewTab from "./overviewTab/OverviewTab";
import PropertyDetailTab from "./propertyDetailTab/PropertyDetailTab";
import SmartSummaryTab from "./smartSummaryTab/SmartSummaryTab";
import TaxTab from "./taxTab/TaxTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum BottomTabsIndex {
  PROPERTY_DETAILS = 0,
  CALCULATOR = 1,
  MLS_DETAILS = 2,
  TAX_INFORMATION = 3,
  LINKED_PROPERTIES = 4,
  FORECLOSURES_LIENS = 5,
  MORTGAGE_TRANSACTIONS = 6,
}
enum TopTabsIndex {
  CONTACT_INFORMATION = 0,
  NOTES = 1,
  SET_FOLLOW_UP = 2,
  AI_SMART_SUMMARY = 3,
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={` ${styles.propertyTabs__content}`}>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface PropertyTabsProps {
  handleClickSkipTrace?: () => void;
  handleClickAddContact?: () => void;
  selectedPropertyId: string;
  showNoteCount?: number;
  setShowNoteCount?: Function;
  type: PropertyDetailsParentIdType;
  displayText: string;
}

export default function PropertyTabs({
  handleClickSkipTrace,
  handleClickAddContact,
  selectedPropertyId,
  showNoteCount,
  setShowNoteCount,
  type,
  displayText,
}: PropertyTabsProps) {
  const [topSelectedTab, setTopSelectedTab] = React.useState(
    TopTabsIndex.CONTACT_INFORMATION
  );
  const [bottomSelectedTab, setBottomSelectedTab] = React.useState(
    BottomTabsIndex.PROPERTY_DETAILS
  );
  const { media } = React.useContext(MediaContext) as MediaContextProps;
  const handleBottomTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setBottomSelectedTab(newValue);
  };

  const handleTopTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTopSelectedTab(newValue);
  };
  const propertyDetailsData = useSelector((state) => getPropertyDetails(state));

  return (
    <div>
      <div className={` ${styles.propertyTabs} ${styles.propertyTabsTop}`}>
        <Tabs
          variant={media !== MEDIA_TYPES.DESKTOP ? "scrollable" : "standard"}
          value={topSelectedTab}
          onChange={handleTopTabChange}
          allowScrollButtonsMobile
        >
          <Tab
            label="Contact Information"
            {...a11yProps(TopTabsIndex.CONTACT_INFORMATION)}
          />
          {propertyDetailsData?.status !== "Hidden" ? (
            <Tab
              label={
                <React.Fragment>
                  <div className={` ${styles.propertyTabs__customLabel}`}>
                    Notes <span>{showNoteCount || 0}</span>
                  </div>
                </React.Fragment>
              }
              // label="Notes"
              {...a11yProps(TopTabsIndex.NOTES)}
            />
          ) : (
            ""
          )}
          {propertyDetailsData?.status !== "Hidden" ? (
            <Tab
              label="Set Follow up"
              {...a11yProps(TopTabsIndex.SET_FOLLOW_UP)}
            />
          ) : (
            ""
          )}
          {propertyDetailsData?.status !== "Hidden" ? (
            <Tab
              label="AI smart summary"
              {...a11yProps(TopTabsIndex.AI_SMART_SUMMARY)}
            />
          ) : (
            ""
          )}
        </Tabs>
        <CustomTabPanel
          value={topSelectedTab}
          index={TopTabsIndex.CONTACT_INFORMATION}
        >
          <OverviewTab
            handleClickSkipTrace={handleClickSkipTrace}
            handleClickAddContact={handleClickAddContact}
            type={type}
          />
        </CustomTabPanel>
        <CustomTabPanel value={topSelectedTab} index={TopTabsIndex.NOTES}>
          <NotesTab setShowNoteCount={setShowNoteCount} />
        </CustomTabPanel>

        <CustomTabPanel
          value={topSelectedTab}
          index={TopTabsIndex.SET_FOLLOW_UP}
        >
          <FollowUpTab type={type} />
        </CustomTabPanel>
        <CustomTabPanel
          value={topSelectedTab}
          index={TopTabsIndex.AI_SMART_SUMMARY}
        >
          <SmartSummaryTab />
        </CustomTabPanel>
      </div>

      <div className={` ${styles.propertyTabs}`}>
        <Tabs
          variant="scrollable"
          value={bottomSelectedTab}
          onChange={handleBottomTabChange}
          allowScrollButtonsMobile
        >
          <Tab
            label="Property Details"
            {...a11yProps(BottomTabsIndex.PROPERTY_DETAILS)}
          />
          <Tab label="Calculator" {...a11yProps(BottomTabsIndex.CALCULATOR)} />

          <Tab
            label="MLS Details"
            {...a11yProps(BottomTabsIndex.MLS_DETAILS)}
          />
          <Tab
            label="Tax Information"
            {...a11yProps(BottomTabsIndex.TAX_INFORMATION)}
          />
          <Tab
            label={
              <>
                <React.Fragment>
                  <span className={` ${styles.tabsButton}`}>
                    Linked Properties
                    {propertyDetailsData?.jsonData?.linkedProperties
                      ?.totalOwned && (
                      <span>
                        {formatNumber(
                          propertyDetailsData?.jsonData?.linkedProperties
                            ?.totalOwned - 1
                        )}
                      </span>
                    )}
                  </span>
                </React.Fragment>
              </>
            }
          />
          <Tab
            label={
              <>
                <React.Fragment>
                  <span className={` ${styles.tabsButton}`}>
                    Foreclosures & Liens
                    {(propertyDetailsData?.jsonData?.foreclosureInfo?.[0] ||
                      propertyDetailsData?.jsonData?.taxLien) && (
                      <span>
                        {propertyDetailsData?.jsonData?.foreclosureInfo?.[0] ||
                        propertyDetailsData?.jsonData?.taxLien
                          ? propertyDetailsData?.jsonData
                              ?.foreclosureInfo?.[0] &&
                            propertyDetailsData?.jsonData?.taxLien
                            ? 2
                            : 1
                          : 0}
                      </span>
                    )}
                  </span>
                </React.Fragment>
              </>
            }
          />
          <Tab
            label="Mortgage & Transactions"
            {...a11yProps(BottomTabsIndex.MORTGAGE_TRANSACTIONS)}
          />
        </Tabs>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.PROPERTY_DETAILS}
        >
          <PropertyDetailTab
            selectedPropertyId={selectedPropertyId}
            type={type}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.CALCULATOR}
        >
          <CalculatorTab selectedPropertyId={selectedPropertyId} type={type} />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.MLS_DETAILS}
        >
          <MlsTab />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.TAX_INFORMATION}
        >
          <TaxTab />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.LINKED_PROPERTIES}
        >
          <LinkedTab />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.FORECLOSURES_LIENS}
        >
          <ForeclosuresAndLines />
        </CustomTabPanel>
        <CustomTabPanel
          value={bottomSelectedTab}
          index={BottomTabsIndex.MORTGAGE_TRANSACTIONS}
        >
          <MortgageTab />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={7}>
        <CreativeFinance />
      </CustomTabPanel> */}
      </div>
    </div>
  );
}
