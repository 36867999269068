import { PopupButton, Widget } from "@typeform/embed-react";
import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";

const LearnMore = ({ open, handleClose }: any) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className={`stepsDialog upgradeModal`}
      >
        <span role="link" onClick={() => handleClose()} className="closeDialog">
          <Close />
        </span>
        <div className="dialogWrapper ">
          <Widget
            id="uYjJderi"
            style={{ width: "100%", height: "500px" }}
            className="my-form"
          />
        </div>
      </Dialog>
    </>
  );
};

export default LearnMore;
