
const Bag = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
                <path d="M4.16797 12.8252H40.8346V40.3252H4.16797V12.8252Z" stroke="currentColor" strokeWidth="2" />
                <path d="M31.6693 12.8288V12.4621C31.6693 11.3065 31.4417 10.1622 30.9994 9.0945C30.5572 8.02683 29.909 7.05673 29.0918 6.23957C28.2747 5.42241 27.3046 4.77421 26.2369 4.33197C25.1692 3.88973 24.0249 3.66211 22.8693 3.66211H22.1359C19.802 3.66211 17.5637 4.58925 15.9134 6.23957C14.2631 7.88989 13.3359 10.1282 13.3359 12.4621V12.8288"
                    stroke="currentColor"
                    strokeWidth="2" />
                <path d="M16.0859 20.1621V32.9954" stroke="currentColor" strokeWidth="2" />
                <path d="M28.918 20.1621V32.9954" stroke="currentColor" strokeWidth="2" />
            </svg>

        </>
    );
};

export default Bag;


