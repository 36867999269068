import { useFormContext } from "react-hook-form";
import Input from "../../../atoms/Input";

const ValuationEquity = ({ onInputBlur }: { onInputBlur: Function }) => {
  const {
    formState: { errors },
    control,
    register,
  }: any = useFormContext();
  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Equity Percentage (%)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="number"
                register={register}
                name="valuationEquityFilter.equityPercentMin"
                placeholder="No Min"
                errors={errors?.["valuationEquityFilter"]?.["equityPercentMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="number"
                register={register}
                name="valuationEquityFilter.equityPercentMax"
                placeholder="No Max"
                errors={errors?.["valuationEquityFilter"]?.["equityPercentMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Estimated Equity ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.estimatedEquityMin"
                placeholder="No Min"
                errors={
                  errors?.["valuationEquityFilter"]?.["estimatedEquityMin"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.estimatedEquityMax"
                placeholder="No Max"
                errors={
                  errors?.["valuationEquityFilter"]?.["estimatedEquityMax"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={`colRow`}>
                                <div className={`colRow__full`}>
                                    <div className={`switchLabel flex alignCenter`}>
                                        <FormControlLabel
                                            control={<Controller
                                                    name="valuationEquityFilter.negativeEquity"
                                                    control={control}
                                                    render={({ field, ...props }) => {
                                                    return (
                                                        <>
                                                        <Checkbox
                                                            checked={field.value}
                                                            onChange={field.onChange}
                                                        />
                                                        </>
                                                    );
                                                    }}
                                                />}
                                            label={  <div className="switchLabel__label">
                                                <h5>Negative Equity</h5>
                                                <p>
                                                Indicates if a property is estimated to have negative equity
                                                </p>
                                            </div>}
                                        />

                                    </div>
                                </div>
                            </div> */}
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Estimated Value ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.estimatedValueMin"
                placeholder="No Min"
                errors={
                  errors?.["valuationEquityFilter"]?.["estimatedValueMin"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.estimatedValueMax"
                placeholder="No Max"
                errors={
                  errors?.["valuationEquityFilter"]?.["estimatedValueMax"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Assessed Value ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedValueMin"
                placeholder="No Min"
                errors={errors?.["valuationEquityFilter"]?.["assessedValueMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedValueMax"
                placeholder="No Max"
                errors={errors?.["valuationEquityFilter"]?.["assessedValueMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Assessed Land Value ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedLandValueMin"
                placeholder="No Min"
                errors={
                  errors?.["valuationEquityFilter"]?.["assessedLandValueMin"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedLandValueMax"
                placeholder="No Max"
                errors={
                  errors?.["valuationEquityFilter"]?.["assessedLandValueMax"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Assessed Improvement Value ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedImprovementValueMin"
                placeholder="No Min"
                errors={
                  errors?.["valuationEquityFilter"]?.[
                    "assessedImprovementValueMin"
                  ]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="valuationEquityFilter.assessedImprovementValueMax"
                placeholder="No Max"
                errors={
                  errors?.["valuationEquityFilter"]?.[
                    "assessedImprovementValueMax"
                  ]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ValuationEquity;
