import * as yup from "yup";

export const createLeadFormSchema = yup.object({
  nameFirst: yup.string().trim().required("First name is required"),
  nameLast: yup.string().trim().required("Last name is required"),
  addressStreet: yup.string().trim(),
  addressCity: yup
    .string()
    .trim(),
  addressState: yup.string(),
  addressZip: yup.string().trim(),
  address: yup.string().trim().required("Address is required"),
  phones: yup
    .string()
    .transform((value) => {
      return value.replace(/\D/g, "");
    })
    .required("Phone number is required")
    .matches(
      new RegExp("[0-9]{10}"),
      "Phone number is invalid"
    ),
});
