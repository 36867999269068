
const Refresh = () => {
  return (
    <>
  
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="refresh-cw-05">
          <path
            id="Icon"
            d="M17.5443 10.7441C17.3128 12.9191 16.0805 14.9572 14.0413 16.1345C10.6534 18.0905 6.32132 16.9297 4.36531 13.5418L4.15698 13.1809M3.45527 9.25578C3.68677 7.08086 4.9191 5.04276 6.95825 3.86545C10.3462 1.90944 14.6783 3.07023 16.6343 6.45813L16.8426 6.81898M3.41113 15.055L4.02118 12.7783L6.29788 13.3883M14.7022 6.61161L16.9789 7.22165L17.5889 4.94495"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default Refresh;
