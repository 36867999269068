const VideoPlay = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                <g filter="url(#filter0_b_8657_107374)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM33.75 54.6721L56.25 42.096C57.9167 41.1645 57.9167 38.8355 56.25 37.904L33.75 25.3279C32.0833 24.3963 30 25.5608 30 27.4239L30 52.5761C30 54.4392 32.0833 55.6037 33.75 54.6721Z"
                        fill="rgba(255, 255, 255, 0.3)" floodOpacity="0.3" />
                </g>
                <defs>
                    <filter id="filter0_b_8657_107374" x="-16" y="-16" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8657_107374" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8657_107374" result="shape" />
                    </filter>
                </defs>
            </svg>
        </>
    );
};

export default VideoPlay;


