const FileHeartIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M16.6654 8.33317V5.6665C16.6654 4.26637 16.6654 3.56631 16.3929 3.03153C16.1532 2.56112 15.7707 2.17867 15.3003 1.93899C14.7656 1.6665 14.0655 1.6665 12.6654 1.6665H7.33203C5.9319 1.6665 5.23183 1.6665 4.69706 1.93899C4.22665 2.17867 3.8442 2.56112 3.60451 3.03153C3.33203 3.56631 3.33203 4.26637 3.33203 5.6665V14.3332C3.33203 15.7333 3.33203 16.4334 3.60451 16.9681C3.8442 17.4386 4.22665 17.821 4.69706 18.0607C5.23183 18.3332 5.9319 18.3332 7.33203 18.3332H9.9987M10.4154 9.1665H6.66536M7.4987 12.4998H6.66536M13.332 5.83317H6.66536M14.1631 12.3587C13.4966 11.6012 12.3853 11.3974 11.5503 12.091C10.7152 12.7847 10.5977 13.9444 11.2534 14.7648C11.9092 15.5852 14.1631 17.4998 14.1631 17.4998C14.1631 17.4998 16.417 15.5852 17.0727 14.7648C17.7285 13.9444 17.6253 12.7774 16.7759 12.091C15.9265 11.4047 14.8295 11.6012 14.1631 12.3587Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>

        </>
    );
};

export default FileHeartIcon;
