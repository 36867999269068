export interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
  checked: boolean;
  status: string;
  id: number;
}

export const createData = (
  name: string,
  code: string,
  population: number,
  size: number,
  checked: boolean,
  status: string
): Data => {
  const density = population / size;
  return { name, code, population, size, density, checked, status, id: 1 };
};

export let rows = [
  createData("India", "IN", 1324171354, 3287263, true, "Calling"),
  createData("China", "CN", 1403500365, 9596961, false, "Completed"),
  createData("Italy", "IT", 60483973, 301340, false, "Scheduled"),
  createData("United States", "US", 327167434, 9833520, true, "Processing"),
  createData("Canada", "CA", 37602103, 9984670, true, "Completed"),
  createData("Australia", "AU", 25475400, 7692024, true, "Completed"),
  createData("Germany", "DE", 83019200, 357578, true, "Completed"),
  createData("Ireland", "IE", 4857000, 70273, true, "Completed"),
  createData("Mexico", "MX", 126577691, 1972550, true, "Completed"),
  createData("Japan", "JP", 126317000, 377973, true, "Completed"),
  createData("France", "FR", 67022000, 640679, true, "Completed"),
  createData("United Kingdom", "GB", 67545757, 242495, true, "Off"),
  createData("Russia", "RU", 146793744, 17098246, false, "Off"),
  createData("Nigeria", "NG", 200962417, 923768, false, "Off"),
  createData("Brazil", "BR", 210147125, 8515767, false, "Off"),
];

export const csvData = [
  { label: "Off/On", key: "checked" },
  { label: "Name", key: "name" },
  { label: "Status", key: "status" },
  { label: "Code", key: "code" },
  { label: "Population", key: "population" },
  { label: "Size", key: "size" },
  { label: "Density", key: "density" },
];

export const columns = [
  { key: "name", label: "Campaign name", minWidth: 170, type: "name" },
  { key: "callCount", label: "Calls", minWidth: 170, type: "number" },
  { key: "inQueueCalls", label: "In Queue", minWidth: 170, type: "number" },
  { key: "leadCount", label: "Leads", minWidth: 170, type: "number" },
  { key: "voiceEmail", label: "Voicemail", minWidth: 170, type: "number" },
  { key: "noAnswerCalls", label: "Hangup", minWidth: 170, type: "number" },
  {
    key: "hangupCalls",
    label: "Hangup",
    minWidth: 170,
    type: "number",
  },
  { key: "deadCalls", label: "Dead", minWidth: 170, type: "number" },
  {
    key: "wrongNumberCalls",
    label: "Wrong #",
    minWidth: 170,
    type: "number",
  },
  { key: "failedCalls", label: "Inactive #", minWidth: 170, type: "number" },
  { key: "dncCalls", label: "DNC", minWidth: 170, type: "number" },
  { key: "status", label: "Status", minWidth: 170, type: "status" },
  { key: "startDate", label: "Start Date", minWidth: 170, type: "string" },
];

export const cards = [
  {
    title: "Total Campaigns",
    key: "totalCampaignCount",
    statusKey: "activeCampaignCount",
  },
  {
    title: "Total Calls",
    key: "totalCallCount",
    statusKey: "todayCallCount",
    statusToday: true,
  },
  {
    title: "Total Conversations",
    key: "totalConversion",
    statusKey: "todayConversion",
    statusToday: true,
  },
  {
    title: "Credits Remaining",
    key: "dailsRemaining",
    statusValue: true,
    statusKey: "productTotalCredit",
    statusKey2:"expiresAt"
  },
];
