import booleanValid from "@turf/boolean-valid";
import * as turf from "@turf/turf";
import { toast } from "react-toastify";

export function reducePoints(
  points: google.maps.LatLng[],
  coreLibrary: google.maps.CoreLibrary
): google.maps.LatLng[] | null {
  const turfPoints: turf.Position[] = points.map((item) => [
    item.lat(),
    item.lng(),
  ]);

  const turfPolygon = turf.polygon([turfPoints]);

  if (booleanValid(turfPolygon)) {
    return points;
  }

  const simplifiedTurfPolygon = turf.simplify(turfPolygon, {
    tolerance: 0.0001,
    highQuality: true,
  });

  if (!booleanValid(simplifiedTurfPolygon)) {
    toast.error("Shape is not valid");
    return null;
  }

  return simplifiedTurfPolygon.geometry.coordinates[0].map(
    (position) => new coreLibrary.LatLng(position[0], position[1])
  );
}

export function isAllLatsSame(path: google.maps.LatLng[]) {
  return path.every(
    (_, i, a) => i === a.length - 1 || a[i].lat() === a[i + 1].lat()
  );
}

export function isAllLngsSame(path: google.maps.LatLng[]) {
  return path.every(
    (_, i, a) => i === a.length - 1 || a[i].lng() === a[i + 1].lng()
  );
}

export function removeSameAdjacentePoints(path: google.maps.LatLng[]) {
  return path.filter(
    (_, i, a) =>
      i === a.length - 1 ||
      a[i].lat() !== a[i + 1].lat() ||
      a[i].lng() !== a[i + 1].lng()
  );
}

export function disableScroll() {
  document.body.classList.add("disableScroll");
}

export function enableScroll() {
  document.body.classList.remove("disableScroll");
}
