

import styles from "./PriceCard.module.scss";
const PriceCardHeader = ({children}:React.PropsWithChildren<{}> ) => {
    return (
        <>
                    <div className={`dflex justifySpaceBetween priceCardHeader alignCenter ${styles.priceCardHeader}`}>
                        {children}
                    </div>
      
        </>

    );
};

export default PriceCardHeader;

