import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Phone from "../../../../assets/icons/phone";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import {
    copyToClipboard,
    formatPhoneNumber,
    getDateDifferenceInWords,
} from "../../../../utils/utils";
import {
    getPropertyDetails,
} from "../../property.selector";
import { ContactInfoItemType } from "../../property.type";
import styles from "./OverviewTab.module.scss";
import BlueTick from "../../../../assets/icons/blueTick";

import SearchReflation from "../../../../assets/icons/searchReflation";
import Button from "../../../../atoms/Button";
import TargetIcon from "../../../../assets/icons/targetIcon";
import { MenuItem, Tooltip } from "@mui/material";
import Copy from "../../../../assets/icons/copy";
import DotVertical from "../../../../assets/icons/dotsVertical";
import FilterDropdown from "../../../../organisms/filterDropdown/FilterDropdown";
import Trash from "../../../../assets/icons/trash";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import {
    deleteContact,
    deleteEmail,
} from "../../../../organisms/propertySearchFilter/PropertyService";
import { setPropertyDetails } from "../../property.reducer";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import Plus from "../../../../assets/icons/plus";
import Message from "../../../../assets/icons/message";
import { getSubscriptions } from "../../../../organisms/billing/selectors";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../../../constants/constants";
import PhoneV2 from "../../../../assets/icons/phoneV2";
import CallGradient from "../../../../assets/icons/CallGradient";


interface OverviewTabsProps {
    handleClickSkipTrace?: () => void;
    handleClickAddContact?: () => void;
    type: string
}

const OverviewTabs = ({
    handleClickSkipTrace,
    handleClickAddContact,
    type
}: OverviewTabsProps) => {
    const propertyDetails = useSelector((state) => getPropertyDetails(state));
    const contactInfo = propertyDetails?.phones as ContactInfoItemType[];
    const profileInfo = useSelector((state) => getProfileInfo(state));
    const emails = propertyDetails.emails;

    const dispatch = useDispatch();
    //   formState: { errors, isValid, isDirty },
    //   register,
    //   watch,
    //   control,
    //   setValue,
    // } = useForm({
    //   mode: "onChange",
    //   defaultValues: {
    //     afterRepairValue: 0,
    //     costOfRepairs: 0,
    //     assignmentFee: 0,
    //     loanAmount: 0,
    //     loanTerm: "",
    //     interestRate: "",
    //     profitMargin: ProfitMarginOptions[0].value,
    //   },
    //   resolver: yupResolver(amortizationCalculatorSchema),
    // });


    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    const subscriptions = useSelector((state) => getSubscriptions(state));


    const handleShowToast = (
        message: string,
        type: ToastType = ToastType.success
    ) => {
        dispatch(
            addToast({
                id: new Date().getTime(),
                message: message,
                type: type,
            })
        );
    };


    const handleMenu = (event: any, id: string) => {
        setMenuAnchorEl(event.currentTarget);
        setShowMenu(!showMenu);
        setSelectedItemId(id);
    };

    const handleMenuClose = () => {
        setShowMenu(false);
        setMenuAnchorEl(null);
        setSelectedItemId(null);
    };

    const handleDelete = async (id: any) => {
        try {
            setDeleteLoading(true);
            await deleteContact(id);
            const updatedDate = contactInfo?.filter(
                (contact: any) => contact.id != id
            );

            const updatedPropertyDetails = {
                ...propertyDetails,
                phones: updatedDate,
            };

            dispatch(setPropertyDetails(updatedPropertyDetails));
            handleShowToast("Contact deleted successfully!", ToastType.success);
            setDeleteLoading(false);
            setShowDeleteModal(false);
            setShowMenu(false);
        } catch (error) {
            console.log(error);
            setDeleteLoading(false);
        }
    };

    const handleDeleteEmail = async (id: any) => {
        try {
            setDeleteLoading(true);
            await deleteEmail(id);
            const updatedEmail = emails?.filter((email: any) => email.id != id);
            const updatedPropertyDetails = {
                ...propertyDetails,
                emails: updatedEmail,
            };
            dispatch(setPropertyDetails(updatedPropertyDetails));
            handleShowToast("Email deleted successfully!", ToastType.success);
            setDeleteLoading(false);
            setShowDeleteModal(false);
            setShowMenu(false);
        } catch (error) {
            console.log(error);
            setDeleteLoading(false);
        }
    };




    return (
        <>

            <div className={` ${styles.contactInfo} ${contactInfo?.length > 0 || emails?.length > 0 ? ``: styles.nodata}`}>
                <div className="wrapper">
                    <div className={` ${styles.contactInfo__headerRight} flex justifyEnd`}>

                        {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ?
                            <Tooltip
                                title={ROLE_PERMISSION_STATUS.NoAccess}
                                placement="top-start"
                            >
                                <div>
                                    <Button
                                        prefix={<TargetIcon />}
                                        className="white borderGradient"
                                        label="Skip Trace"
                                        disabled
                                    />
                                </div>
                            </Tooltip> :

                            subscriptions?.creditSubData?.isPaused ? (
                                <Tooltip
                                    title="You can't access this because your subscription is paused."
                                    placement="top-start"
                                >
                                    <div>
                                        <Button
                                            prefix={<TargetIcon />}
                                            className="white borderGradient"
                                            label="Skip Trace"
                                            action={handleClickSkipTrace}
                                            disabled={subscriptions?.creditSubData?.isPaused}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Button
                                    prefix={<TargetIcon />}
                                    className="white borderGradient"
                                    label="Skip Trace"
                                    action={handleClickSkipTrace}
                                    disabled={
                                        propertyDetails?.jsonData &&
                                        Object.keys(propertyDetails.jsonData).length === 0
                                    }
                                />
                            )}
                        {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ?
                            <Tooltip
                                title={ROLE_PERMISSION_STATUS.NoAccess}
                                placement="top-start"
                            >
                                <div>
                                    <Button
                                        prefix={<Plus />}
                                        className="white borderGradient"
                                        disabled
                                    />
                                </div>
                            </Tooltip> :
                            <Button
                                prefix={<Plus />}
                                className="white borderGradient"
                                action={handleClickAddContact}
                            />}
                    </div>
                    <div className={` ${styles.contactInfo__list}`}>
                        <h2>Contact Information</h2>
                        {contactInfo?.length > 0 || emails?.length > 0 ? (
                            <>
                                <div className={` ${styles.contactInfo__listMain} dflex alignStart`}>
                                    {contactInfo?.length > 0 && <div className={` ${styles.contactInfo__phoneList} dflex`}>
                                        {contactInfo?.map((item: any, count) => (
                                            <div
                                                className={`flex alignCenter ${styles.contactInfo__box}`}
                                                key={item.id}
                                            >
                                                <figure>
                                                    <PhoneV2 /> <label>Phone {count + 1}</label>
                                                </figure>
                                                <figcaption>
                                                    <div
                                                        className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                                                    >
                                                        <a href={`tel:${item.number}`}>
                                                            {formatPhoneNumber(item.number)}
                                                        </a>
                                                        <Tooltip title={"Copy Phone Number"}>
                                                            <span
                                                                role="link"
                                                                className="copy-icon"
                                                                onClick={(e: any) => {
                                                                    e.stopPropagation();
                                                                    copyToClipboard(item.number);
                                                                }}
                                                            >
                                                                <Copy />
                                                            </span>
                                                        </Tooltip>

                                                        {(item?.phoneAttempts?.length > 0 ||
                                                            item?.createdBy) && (
                                                                <span className={` ${styles.contactInfo__blueTick}`}>
                                                                    <BlueTick />
                                                                </span>
                                                            )}
                                                        <Button
                                                            className="transparent"
                                                            prefix={<DotVertical />}
                                                            disabled={item?.phoneAttempts?.length > 0}
                                                            // action={(event: any) => {
                                                            //   handleMenu();
                                                            //   setMenuAnchorEl(event.currentTarget);
                                                            // }}
                                                            action={(event: any) => handleMenu(event, item.id)}
                                                        />
                                                        {showMenu && selectedItemId === item.id && (
                                                            <FilterDropdown
                                                                handleClose={handleMenuClose}
                                                                anchorEl={menuAnchorEl}
                                                                sizeList
                                                            >
                                                                <div className="dflex filterInfo">
                                                                    <ul className="filterList">
                                                                        <MenuItem
                                                                            className="verified-list-main"
                                                                            disableRipple
                                                                        >
                                                                            <div
                                                                                onClick={() => {
                                                                                    setShowDeleteModal(true);
                                                                                    setMenuAnchorEl(null);
                                                                                }}
                                                                                className={`verified-list-delete ${item?.phoneAttempts?.length > 0 || profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                                                                                    ? "disabled"
                                                                                    : ""
                                                                                    }`}
                                                                                role="link"
                                                                                key={item.id}
                                                                            >
                                                                                <Trash />
                                                                                <span>Delete</span>
                                                                            </div>

                                                                            {item?.phoneAttempts?.length > 0 && (
                                                                                <div className="verified-list">
                                                                                    <p>
                                                                                        <BlueTick /> Verified AI Lead
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                            {item?.createdBy && (
                                                                                <div className="verified-list">
                                                                                    <p>
                                                                                        <BlueTick /> Added by {profileInfo?.id===item?.createdBy?'you':'a user'}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </MenuItem>
                                                                    </ul>
                                                                </div>
                                                            </FilterDropdown>
                                                        )}
                                                    </div>
                                                    {/* <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p> */}
                                                </figcaption>
                                                {showDeleteModal && selectedItemId === item.id && (
                                                    <ConfirmationModal
                                                        isOpen={showDeleteModal}
                                                        setIsOpen={(value: any) => {
                                                            setShowDeleteModal(value);
                                                            setShowMenu(!showMenu);
                                                        }}
                                                        buttonComponent={
                                                            <Button
                                                                label="Yes, Delete"
                                                                className="red xl full "
                                                                action={() => handleDelete(item.id)}
                                                                disabled={deleteLoading}
                                                                clicked={deleteLoading}
                                                            />
                                                        }
                                                    >
                                                        <h2>Delete Contact</h2>
                                                        <p>Are you sure you want to delete this contact?</p>
                                                    </ConfirmationModal>
                                                )}
                                            </div>
                                        ))}
                                    </div>}
                                    <div className={` ${styles.contactInfo__emailList} dflex`}>
                                        {emails.map((item: any) => (
                                            <div
                                                className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}
                                                key={item.id}
                                            >
                                                <figure>
                                                    <Message /> <label>Email</label>
                                                </figure>
                                                <figcaption>
                                                    <div
                                                        className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                                                    >
                                                        <a
                                                            className={`flex alignCenter ${styles.contactInfo__email}`}
                                                            href={`mailto:${item.email}`}
                                                        >
                                                            {item.email}
                                                        </a>
                                                        <Tooltip title={"Copy Email Address"}>
                                                            <span
                                                                role="link"
                                                                className="copy-icon"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    copyToClipboard(item.email);
                                                                }}
                                                            >
                                                                <Copy />
                                                            </span>
                                                        </Tooltip>

                                                        {item?.createdBy && (
                                                            <span className={` ${styles.contactInfo__blueTick}`}>
                                                                <BlueTick />
                                                            </span>
                                                        )}
                                                        <Button
                                                            className="transparent"
                                                            prefix={<DotVertical />}
                                                            action={(event: any) => handleMenu(event, item.id)}
                                                        />
                                                        {showMenu && selectedItemId === item.id && (
                                                            <FilterDropdown
                                                                handleClose={handleMenuClose}
                                                                anchorEl={menuAnchorEl}
                                                                sizeList
                                                            >
                                                                <div className="dflex filterInfo">
                                                                    <ul className="filterList">
                                                                        <MenuItem
                                                                            className="verified-list-main"
                                                                            disableRipple
                                                                        >
                                                                            <div
                                                                                onClick={() => {
                                                                                    setShowDeleteModal(true);
                                                                                    setMenuAnchorEl(null);
                                                                                }}
                                                                                className={`verified-list-delete`}
                                                                                role="link"
                                                                                key={item.id}
                                                                            >
                                                                                <Trash />
                                                                                <span>Delete</span>
                                                                            </div>
                                                                            {item?.createdBy && (
                                                                                <div className="verified-list">
                                                                                    <p>
                                                                                        <BlueTick /> Added by a user
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </MenuItem>
                                                                    </ul>
                                                                </div>
                                                            </FilterDropdown>
                                                        )}
                                                    </div>
                                                    {/* <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p> */}
                                                </figcaption>
                                                {showDeleteModal && selectedItemId === item.id && (
                                                    <ConfirmationModal
                                                        isOpen={showDeleteModal}
                                                        setIsOpen={(value) => {
                                                            setShowDeleteModal(value);
                                                        }}
                                                        buttonComponent={
                                                            <Button
                                                                label="Yes, Delete"
                                                                className="red xl full "
                                                                action={() => handleDeleteEmail(item.id)}
                                                                disabled={deleteLoading}
                                                                clicked={deleteLoading}
                                                            />
                                                        }
                                                    >
                                                        <h2>Delete email</h2>
                                                        <p>Are you sure you want to delete this email?</p>
                                                    </ConfirmationModal>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            <div className={`${styles.contactInfo__blared}`}>
                                <div className={` ${styles.contactInfo__listMain} dflex alignStart`}>
                                    <div className={` ${styles.contactInfo__phoneList} dflex`}>
                                        <div className={`flex alignCenter ${styles.contactInfo__box}`} >
                                            <figure>
                                                <PhoneV2 /> <label>Phone 1</label>
                                            </figure>
                                            <figcaption>-</figcaption>
                                        </div>
                                        <div className={`flex alignCenter ${styles.contactInfo__box}`} >
                                            <figure>
                                                <PhoneV2 /> <label>Phone 2</label>
                                            </figure>
                                            <figcaption>-</figcaption>
                                        </div>
                                        <div className={`flex alignCenter ${styles.contactInfo__box}`} >
                                            <figure>
                                                <PhoneV2 /> <label>Phone 3</label>
                                            </figure>
                                            <figcaption>-</figcaption>
                                        </div>
                                        <div className={`flex alignCenter ${styles.contactInfo__box}`} >
                                            <figure>
                                                <PhoneV2 /> <label>Phone 4</label>
                                            </figure>
                                            <figcaption>-</figcaption>
                                        </div>
                                        <div className={`flex alignCenter ${styles.contactInfo__box}`} >
                                            <figure>
                                                <PhoneV2 /> <label>Phone 5</label>
                                            </figure>
                                            <figcaption>-</figcaption>
                                        </div>
                                    </div>
                                    <div className={` ${styles.contactInfo__emailList} dflex`}>
                                    <div
                                        className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}

                                    >
                                        <figure>
                                            <Message /> <label>Email</label>
                                        </figure>
                                        <figcaption></figcaption>
                                    </div>
                                    <div
                                        className={`flex alignCenter ${styles.contactInfo__box} ${styles.emailBox}`}

                                    >
                                        <figure>
                                            <Message /> <label>Email</label>
                                        </figure>
                                        <figcaption></figcaption>
                                    </div>
                               
                                    </div>
                                </div>
                                </div>
                                <EmptyPlaceholder
                                    icon={<CallGradient />}
                                    title={"It’s a ghost town here"}
                                    description="Unlock the details—skip-trace this property to reveal contact info!"
                                    smallSize={true}
                                    bgGray={true}
                                    extraStyles={{ maxWidth: "100%" }}
                                    blared

                                />
                            </>
                        )}


                    </div>
                </div>









                {/* NOTE: I removed the `skipButton` param from the above <ShadowBox /> as we're temporarily hiding this button until it is implemented. */}
                {/* <div className={`dflex ${styles.contactInfo}`}>
                        {contactInfo?.length > 0 || emails?.length > 0 ? (
                            <>
                                {contactInfo?.map((item: any) => (
                                    <div
                                        className={`flex alignCenter ${styles.contactInfo__box}`}
                                        key={item.id}
                                    >
                                        <figure>
                                            <Phone />
                                        </figure>
                                        <figcaption>
                                            <div
                                                className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                                            >
                                                <a href={`tel:${item.number}`}>
                                                    {formatPhoneNumber(item.number)}
                                                </a>
                                                <Tooltip title={"Copy Phone Number"}>
                                                    <span
                                                        role="link"
                                                        className="copy-icon"
                                                        onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(item.number);
                                                        }}
                                                    >
                                                        <Copy />
                                                    </span>
                                                </Tooltip>

                                                {(item?.phoneAttempts?.length > 0 ||
                                                    item?.createdBy) && (
                                                        <span className={` ${styles.contactInfo__blueTick}`}>
                                                            <BlueTick />
                                                        </span>
                                                    )}
                                                <Button
                                                    className="transparent"
                                                    prefix={<DotVertical />}
                                                    disabled={item?.phoneAttempts?.length > 0}
                                                    // action={(event: any) => {
                                                    //   handleMenu();
                                                    //   setMenuAnchorEl(event.currentTarget);
                                                    // }}
                                                    action={(event: any) => handleMenu(event, item.id)}
                                                />
                                                {showMenu && selectedItemId === item.id && (
                                                    <FilterDropdown
                                                        handleClose={handleMenuClose}
                                                        anchorEl={menuAnchorEl}
                                                        sizeList
                                                    >
                                                        <div className="dflex filterInfo">
                                                            <ul className="filterList">
                                                                <MenuItem
                                                                    className="verified-list-main"
                                                                    disableRipple
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            setShowDeleteModal(true);
                                                                            setMenuAnchorEl(null);
                                                                        }}
                                                                        className={`verified-list-delete ${item?.phoneAttempts?.length > 0 || profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                                                                            ? "disabled"
                                                                            : ""
                                                                            }`}
                                                                        role="link"
                                                                        key={item.id}
                                                                    >
                                                                        <Trash />
                                                                        <span>Delete</span>
                                                                    </div>

                                                                    {item?.phoneAttempts?.length > 0 && (
                                                                        <div className="verified-list">
                                                                            <p>
                                                                                <BlueTick /> Verified AI Lead
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {item?.createdBy && (
                                                                        <div className="verified-list">
                                                                            <p>
                                                                                <BlueTick /> Added by a user
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </MenuItem>
                                                            </ul>
                                                        </div>
                                                    </FilterDropdown>
                                                )}
                                            </div>
                                            <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p>
                                        </figcaption>
                                        {showDeleteModal && selectedItemId === item.id && (
                                            <ConfirmationModal
                                                isOpen={showDeleteModal}
                                                setIsOpen={(value: any) => {
                                                    setShowDeleteModal(value);
                                                    setShowMenu(!showMenu);
                                                }}
                                                buttonComponent={
                                                    <Button
                                                        label="Yes, Delete"
                                                        className="red xl full "
                                                        action={() => handleDelete(item.id)}
                                                        disabled={deleteLoading}
                                                        clicked={deleteLoading}
                                                    />
                                                }
                                            >
                                                <h2>Delete Contact</h2>
                                                <p>Are you sure you want to delete this contact?</p>
                                            </ConfirmationModal>
                                        )}
                                    </div>
                                ))}
                                {emails.map((item: any) => (
                                    <div
                                        className={`flex alignCenter ${styles.contactInfo__box}`}
                                        key={item.id}
                                    >
                                        <figure>
                                            <Message />
                                        </figure>
                                        <figcaption>
                                            <div
                                                className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                                            >
                                                <a
                                                    className={`flex alignCenter ${styles.contactInfo__email}`}
                                                    href={`mailto:${item.email}`}
                                                >
                                                    {item.email}
                                                </a>
                                                <Tooltip title={"Copy Email Address"}>
                                                    <span
                                                        role="link"
                                                        className="copy-icon"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(item.email);
                                                        }}
                                                    >
                                                        <Copy />
                                                    </span>
                                                </Tooltip>

                                                {item?.createdBy && (
                                                    <span className={` ${styles.contactInfo__blueTick}`}>
                                                        <BlueTick />
                                                    </span>
                                                )}
                                                <Button
                                                    className="transparent"
                                                    prefix={<DotVertical />}
                                                    action={(event: any) => handleMenu(event, item.id)}
                                                />
                                                {showMenu && selectedItemId === item.id && (
                                                    <FilterDropdown
                                                        handleClose={handleMenuClose}
                                                        anchorEl={menuAnchorEl}
                                                        sizeList
                                                    >
                                                        <div className="dflex filterInfo">
                                                            <ul className="filterList">
                                                                <MenuItem
                                                                    className="verified-list-main"
                                                                    disableRipple
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            setShowDeleteModal(true);
                                                                            setMenuAnchorEl(null);
                                                                        }}
                                                                        className={`verified-list-delete`}
                                                                        role="link"
                                                                        key={item.id}
                                                                    >
                                                                        <Trash />
                                                                        <span>Delete</span>
                                                                    </div>
                                                                    {item?.createdBy && (
                                                                        <div className="verified-list">
                                                                            <p>
                                                                                <BlueTick /> Added by a user
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </MenuItem>
                                                            </ul>
                                                        </div>
                                                    </FilterDropdown>
                                                )}
                                            </div>
                                            <p>
                                                Added{" "}
                                                {getDateDifferenceInWords(new Date(item.createdAt))}
                                            </p>
                                        </figcaption>
                                        {showDeleteModal && selectedItemId === item.id && (
                                            <ConfirmationModal
                                                isOpen={showDeleteModal}
                                                setIsOpen={(value) => {
                                                    setShowDeleteModal(value);
                                                }}
                                                buttonComponent={
                                                    <Button
                                                        label="Yes, Delete"
                                                        className="red xl full "
                                                        action={() => handleDeleteEmail(item.id)}
                                                        disabled={deleteLoading}
                                                        clicked={deleteLoading}
                                                    />
                                                }
                                            >
                                                <h2>Delete email</h2>
                                                <p>Are you sure you want to delete this email?</p>
                                            </ConfirmationModal>
                                        )}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <EmptyPlaceholder
                                icon={<SearchReflation />}
                                title={"It’s a ghost town here"}
                                description="Unlock the details—skip-trace this property to reveal contact info!"
                                smallSize={true}
                                bgGray={true}
                                extraStyles={{ width: "100%" }}
                            />
                        )}
                    </div> */}
            </div>

        </>
    );
};
export default OverviewTabs;
