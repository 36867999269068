
const PhoneV2 = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M12 18.2392H12.01M8.2 22.6758H15.8C16.9201 22.6758 17.4802 22.6758 17.908 22.4609C18.2843 22.2719 18.5903 21.9702 18.782 21.5992C19 21.1774 19 20.6252 19 19.5209V6.11245C19 5.00812 19 4.45596 18.782 4.03416C18.5903 3.66313 18.2843 3.36148 17.908 3.17244C17.4802 2.95752 16.9201 2.95752 15.8 2.95752H8.2C7.0799 2.95752 6.51984 2.95752 6.09202 3.17244C5.71569 3.36148 5.40973 3.66313 5.21799 4.03416C5 4.45596 5 5.00812 5 6.11245V19.5209C5 20.6252 5 21.1774 5.21799 21.5992C5.40973 21.9702 5.71569 22.2719 6.09202 22.4609C6.51984 22.6758 7.07989 22.6758 8.2 22.6758ZM12.5 18.2392C12.5 18.5115 12.2761 18.7322 12 18.7322C11.7239 18.7322 11.5 18.5115 11.5 18.2392C11.5 17.967 11.7239 17.7463 12 17.7463C12.2761 17.7463 12.5 17.967 12.5 18.2392Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default PhoneV2;
