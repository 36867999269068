import { Dialog } from "@mui/material";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Logo from "../../components/Logo";
import Close from "../../assets/icons/cross";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resendLinkFormSchema } from "./validation";
import { resendEmailLink } from "../auth/authServices";
import Message from "../../assets/icons/message";
import { toast } from "react-toastify";

interface ResendLinkProps {
  open: boolean;
  setOpen: (a: boolean) => void;
  email: string;
  setLinkSent: (a:boolean)=>void;
  linkSent : boolean;
}

const ResendLink = ({ open, setOpen, email, linkSent, setLinkSent }: ResendLinkProps) => {
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resendLinkFormSchema),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const data = {
      email: email,
    };
    try {
      setSubmitClicked(true);
      await resendEmailLink(data);
      setLinkSent(true);
    } catch (error) {
      console.log("Email Not sent");
      toast.error("Link not sent");
      setLinkSent(false);
    } finally {
      setSubmitClicked(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={`stepsDialog loginRegister otpDialog`}
    >
      {!linkSent ? (
        <div className="dialogWrapper authStyle">
          <span
            role="link"
            className="closeDialog"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </span>
          <div className="authStyle__logo flex alignCenter justifyCenter">
            <Logo />
          </div>
          <div className="authStyle__header title-xl restLink">
            <p>Please click on "Resend Link" to verify your email address.</p>
          </div>
          <form>
            <Input
              type="email"
              label="Email"
              register={register}
              name="email"
              readOnly={true}
              placeholder="Enter your email"
              errors={errors}
              defaultValue={email}
            ></Input>
            <div className="authStyle__footer">
              <Button
                type="submit"
                label="Resend Link"
                className="primary full xl"
                disabled={submitClicked}
                action={handleSubmit(onSubmit)}
                clicked={submitClicked}
              ></Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="dialogWrapper authStyle">
          <div className={`authStyle__Icon`}>
            <span className={`flag`}>
              <Message />
            </span>
          </div>
          <div className="authStyle__header">
            <h2>Please check your email.</h2>
            <p>
              We've resent a link to <strong>{email}</strong>
            </p>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ResendLink;
