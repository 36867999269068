import { initialState } from "./reducer";

const getStateData = (state: any) => state["myTeam"] || initialState;

export const getUserRoleList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.userRoleList;
};

export const getPendingUserList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserList;
}

export const getPendingListLoading=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserListLoading;
}

export const getActiveUserList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.activeUserList;
}

export const getActiveListLoading=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.activeUserListLoading;
}
