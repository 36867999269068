import * as yup from "yup";

export const skipTraceDefaultSchema = {
  addToExistingList: yup.boolean(),
  listName: yup.string().required("List is required"),
  targetList: yup.string(),
  searchResultCount: yup.number().typeError("Search result count must be a number").nullable(),
  redoSkip: yup.boolean(),
  skipPropertyCountMaxLimit: yup.number(),
  skipPeriod: yup.string()
};

export const skipTraceSchema = yup
  .object()
  .shape({ ...skipTraceDefaultSchema })
  .when((value: any, schema: any) => {
    if (value[0].addToExistingList) {
      return schema.shape({
        ...skipTraceDefaultSchema,
        listName: yup.string(),
        targetList: yup.string().required("Target List is required"),
      });
    } else {
      return schema.shape({
        ...skipTraceDefaultSchema,
      });
    }
  });
