const TwitterCircle = () => {
    return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.08"/>
  <circle cx="16" cy="16" r="15.75" stroke="url(#paint0_linear_17191_43962)" strokeOpacity="0.2" strokeWidth="0.5"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M13.774 21C18.3022 21 20.779 17.1522 20.779 13.8158C20.779 13.7062 20.779 13.5975 20.7718 13.4892C21.2536 13.1323 21.6694 12.6892 22 12.1821C21.5512 12.3864 21.0742 12.5204 20.5864 12.5795C21.1 12.2638 21.4846 11.7679 21.6688 11.1827C21.1852 11.4768 20.6566 11.6844 20.1052 11.7958C19.1728 10.7792 17.6134 10.73 16.6216 11.6862C15.9826 12.3028 15.7108 13.2221 15.9094 14.099C13.93 13.9968 12.0856 13.0381 10.8352 11.461C10.1818 12.6148 10.516 14.0902 11.5978 14.8311C11.206 14.8194 10.8226 14.7112 10.48 14.5155V14.5476C10.4806 15.7494 11.3068 16.7842 12.4552 17.0223C12.0928 17.1238 11.7124 17.1385 11.344 17.0653C11.6662 18.0942 12.5908 18.7989 13.6438 18.8192C12.772 19.5219 11.695 19.9035 10.5862 19.9023C10.3906 19.9016 10.195 19.8899 10 19.866C11.1262 20.6068 12.436 21 13.774 20.9982" fill="white"/>
  <defs>
    <linearGradient id="paint0_linear_17191_43962" x1="-54.1111" y1="118.588" x2="37.0568" y2="111.258" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.2"/>
    </linearGradient>
  </defs>
</svg>
        </>
    );
};

export default TwitterCircle;


