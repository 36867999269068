import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../molecules/toaster/toastSlice";
import { notificationDetails } from "./selectors";
import { ToastType } from "../../molecules/toaster/enum";

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => notificationDetails(state));

  const handleShowToast = (message: string, type: ToastType =ToastType.success) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  useEffect(() => {
    handleShowToast(
      notification?.payload?.message,
      notification?.payload?.type
    );
  }, [dispatch, notification]);

  return <></>;
};

export default Notification;
