import { createSlice } from "@reduxjs/toolkit";
import HungUp from "./leadStatusTabs/hungUp/HungUp";

export const initialState = {
  id: null,
  isLoading: false,
  open: false,
  attemptId: "",
  propertyCountByCampaign:{} as any,
  campaignData:[],
  topSectionData:[],
  leadsData:[] as any[],
  activityData:[] as any[],
  leadsLoading:false,
  topMatrixData:[],
  graphData:[],
  isAttemptSelected:false,
  attemptDetails:[],
  total:0,
  activityTotal:0,
  hungUpData:[],
  hungUpDataLoading:false
};

const campaignDetailSlice = createSlice({
  name: "campaignDetail",
  initialState,
  reducers: {
    cancelCampaignRes: (state, { payload: { data } }) => {
      return {
        ...state,
      };
    },
    editCampaignRes: (state, { payload: { data } }) => {
      return {
        ...state,
      };
    },
    setCampaignData: (state, { payload }) => {
      return {
        ...state,
        campaignData: payload,
      };
    },
    setIsLoading: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
    setIsLoadingEdit: (state, { payload }) => {
      return {
        ...state,
        isLoadingEdit: payload,
      };
    },
    setOpen: (state, { payload }) => {
      return {
        ...state,
        open: payload,
      };
    },
    setTopSectionData: (state, { payload }) => {
      return {
        ...state,
        topSectionData: payload,
      };
    },
    setLeadsData: (state, { payload }) => {
      return {
        ...state,
        leadsData: payload.data,
      };
    },
    setActivityData: (state, { payload }) => {
      return {
        ...state,
        activityData: payload.data,
      };
    },
    setActivityLoading: (state, { payload }) => {
      return {
        ...state,
        activityLoading: payload,
      };
    },
    setLeadsLoading: (state, { payload }) => {
      return {
        ...state,
        leadsLoading: payload,
      };
    },
    setTopMatrixData: (state, { payload }) => {
      return {
        ...state,
        topMatrixData: payload,
      };
    },
    setGraphData: (state, { payload }) => {
      return {
        ...state,
        graphData: payload,
      };
    },
    setCampaignDetail: (state, { payload }) => {
      return {
        ...state,
        campaignDetail: payload,
      };
    },
    setAttemptId : (state, { payload }) => {
      return {
        ...state,
        attemptId: payload,
      };
    },
    setIsAttemptSelected: (state, { payload }) => {
      return {
        ...state,
        isAttemptSelected: payload,
      };
    },
    setPropertyCountByCampaign: (state, { payload }) => {
      return {
        ...state,
        propertyCountByCampaign: payload,
      };
    },
    setAttemptDetails:  (state, { payload }) => {
      return {
        ...state,
        attemptDetails: payload,
      };
    },
    appendLeadsList: (state, { payload }) => {
      return {
        ...state,
        leadsData:[...state?.leadsData, ...payload.data]
      };
    },
    settotal:  (state, { payload }) => {
      return {
        ...state,
        total: payload,
      };
    },
    setActivitytotal:  (state, { payload }) => {
      return {
        ...state,
        activityTotal: payload,
      };
    },
    appendActivityData: (state, { payload }) => {
      return {
        ...state,
        activityData: [...state?.activityData,...payload.data],
      };
    },
    setHungUpData: (state, { payload }) => {
      return {
        ...state,
        hungUpData: payload,
      };
    },
    setHungUpDataLoading: (state, { payload }) => {
      return {
        ...state,
        hungUpDataLoading: payload,
      };
    },
    resetCampaignDetailState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  cancelCampaignRes,
  editCampaignRes,
  setCampaignData,
  setIsLoading,
  setIsLoadingEdit,
  setOpen,
  setTopSectionData,
  setLeadsData,
  setActivityData,
  setActivityLoading,
  setLeadsLoading,
  setTopMatrixData,
  setGraphData,
  setCampaignDetail,
  setAttemptId,
  setIsAttemptSelected,
  setPropertyCountByCampaign,
  setAttemptDetails,
  appendLeadsList,
  settotal,
  setActivitytotal,
  appendActivityData,
  setHungUpData,
  setHungUpDataLoading,
  resetCampaignDetailState,
} = campaignDetailSlice.actions;

export default campaignDetailSlice.reducer;



