const FileHeartFill = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1631 12.3589C13.4966 11.6014 12.3853 11.3976 11.5503 12.0912C10.7152 12.7849 10.5977 13.9446 11.2534 14.765C11.9092 15.5853 14.1631 17.5 14.1631 17.5C14.1631 17.5 16.417 15.5853 17.0727 14.765C17.7285 13.9446 17.6253 12.7776 16.7759 12.0912C15.9265 11.4049 14.8295 11.6014 14.1631 12.3589Z"
          fill="#D0E3FF"
        />
        <path
          d="M16.6654 8.33335V5.66669C16.6654 4.26656 16.6654 3.56649 16.3929 3.03171C16.1532 2.56131 15.7707 2.17885 15.3003 1.93917C14.7656 1.66669 14.0655 1.66669 12.6654 1.66669H7.33203C5.9319 1.66669 5.23183 1.66669 4.69706 1.93917C4.22665 2.17885 3.8442 2.56131 3.60451 3.03171C3.33203 3.56649 3.33203 4.26656 3.33203 5.66669V14.3334C3.33203 15.7335 3.33203 16.4336 3.60451 16.9683C3.8442 17.4387 4.22665 17.8212 4.69706 18.0609C5.23183 18.3334 5.9319 18.3334 7.33203 18.3334H9.9987M10.4154 9.16669H6.66536M7.4987 12.5H6.66536M13.332 5.83335H6.66536M14.1631 12.3589C13.4966 11.6014 12.3853 11.3976 11.5503 12.0912C10.7152 12.7849 10.5977 13.9446 11.2534 14.765C11.9092 15.5853 14.1631 17.5 14.1631 17.5C14.1631 17.5 16.417 15.5853 17.0727 14.765C17.7285 13.9446 17.6253 12.7776 16.7759 12.0912C15.9265 11.4049 14.8295 11.6014 14.1631 12.3589Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default FileHeartFill;
