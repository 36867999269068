import React from "react";
import { CounterProps } from "../HomeInterface";

const Counter = React.memo(({ value, label,lastDigit } : CounterProps) => (
    <li>
      <h2>{value}{lastDigit}</h2>
      <p>{label}</p>
    </li>
  ));

  export default Counter;