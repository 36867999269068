import { useState, useEffect } from "react";
import Button from "../../../atoms/Button";
import Dialog from "@mui/material/Dialog";
import styles from "./HomeHeroSection.module.scss";
import SocialShare from "../../../organisms/earlyAccess/SocialShare";
import Close from "../../../assets/icons/cross";
import EarlyAccessSignup from "../../../organisms/earlyAccess/EarlyAccessSignup";
import Login from "../../login/Login";
import Register from "../../../organisms/auth/Register";
import ForgotPassword from "../../../organisms/forgotPassword/ForgotPassword";
import ResetPassword from "../../../organisms/resetPassword/ResetPassword";
import { HomeHeroSectionProps } from "../HomeInterface";
import activeUser from "../../../assets/images/active-user.png";
import VideoModal from "../videoModal/VideoModal";
import TickRounded from "../../../assets/icons/tickRounded";
import PlayIcon from "../../../assets/icons/playIcon";
import AngelDown from "../../../assets/icons/angleDown";
import { Helmet } from "react-helmet-async";
import NewUserResetPassword from "../../../organisms/newUserResetPassword/NewUserResetPassword";

const HomeHeroSection = ({
  resetPassword,
  setResetPassword,
  forgotPassword,
  setForgotpassword,
  openShareModal,
  setOpenShareModal,
  openEarlyAccessModal,
  setOpenEarlyAccessModal,
  openLoginModal,
  setOpenLoginModal,
  email,
  setEmail,
  setOpenVerifyOtp,
  openForgotPasswordPopup,
  openRegisterModal,
  setOpenRegisterModal,
  scrollToMeetOtto,
  openResetPasswordModal,
  setOpenResetPasswordModal
}: HomeHeroSectionProps) => {
  const [openHomeVideoModal, setOpenHomeVideoModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenShareModal(false);
  };
  const handleVideoModal = () => {
    setOpenHomeVideoModal(true);
  };

  return (
    <>
     <Helmet>
        <title>OttoLeads: AI-Powered Real Estate at Your Fingertips</title>
      </Helmet>
      <div className={` ${styles.heroSection}`}>
        <div className={` ${styles.heroSection__inner}`}>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="200"
            data-aos-anchor-placement="bottom-bottom"
            className={` ${styles.activeUser} dflex justifyCenter`}
          >
            <figure>
              <img src={activeUser} alt="active user" />
            </figure>
            <figcaption>
              <h5>
                1.5<span>K+</span>
              </h5>
              <p>Beta Signups</p>
            </figcaption>
          </div>
          <h1
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-anchor-placement="bottom-bottom"
          >
            Your real estate AI that works <span>like magic</span>
          </h1>
          <ul
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-anchor-placement="bottom-bottom"
            className="dflex justifyCenter"
          >
            <li>
              <TickRounded /> AI lead generation
            </li>
            <li>
              <TickRounded /> Precision skip tracing
            </li>
            <li>
              <TickRounded /> Top tier property data
            </li>
          </ul>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          className={`dflex justifyCenter ${styles.heroSection__btn}`}
        >
          <Button
            label="Start your Free Trial"
            className="gradient"
            action={() => setOpenRegisterModal(true)}
          />
          <Button
            label="Watch the film"
            className="videoBtn"
            prefix={<PlayIcon />}
            action={handleVideoModal}
          />
        </div>
        <div>
          <div
            role="link"
            className={` ${styles.heroSection__discover}`}
            onClick={scrollToMeetOtto}
          >
            Discover more
            <AngelDown />
          </div>
        </div>
        {/* <div
          className={`flex-wrap justifyCenter ${styles.heroSection__mobileBtn}`}
        >
          <Button
            label="Sign up for early access"
            className="primary animated"
            action={() => setOpenEarlyAccessModal!(true)}
          />
          <Button
            label="Login"
            className="outline"
            action={() => setOpenLoginModal!(true)}
          />
        </div> */}
      </div>

      {openHomeVideoModal ? (
        <VideoModal
          setOpenShareModal={setOpenShareModal}
          setOpen={setOpenHomeVideoModal}
          open={openHomeVideoModal}
        />
      ) : (
        ""
      )}

      {openShareModal ? (
        <>
          <Dialog
            onClose={handleClose}
            open={true}
            className={`stepsDialog loginRegister  left-align`}
          >
            <div className="dialogWrapper authStyle">
              <span role="link" className="closeDialog" onClick={handleClose}>
                <Close />
              </span>
              <SocialShare></SocialShare>
            </div>
          </Dialog>
        </>
      ) : (
        ""
      )}

      {openEarlyAccessModal ? (
        <EarlyAccessSignup
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
        ></EarlyAccessSignup>
      ) : (
        ""
      )}
      {openLoginModal ? (
        <Login
          open={openLoginModal}
          setOpen={setOpenLoginModal}
          setEmail={setEmail}
          email={email}
          openVerifyOtp={setOpenVerifyOtp}
          forgotpassword={openForgotPasswordPopup}
        ></Login>
      ) : (
        ""
      )}
      {openRegisterModal ? (
        <Register
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
      ) : (
        ""
      )}
      {forgotPassword ? (
        <ForgotPassword
          open={forgotPassword}
          setOpen={setForgotpassword}
          setEmail={setEmail}
          openResetPassword={setResetPassword}
          email={email}
        ></ForgotPassword>
      ) : (
        ""
      )}
      {resetPassword ? (
        <ResetPassword
          open={resetPassword}
          setOpen={setResetPassword}
          email={email}
        ></ResetPassword>
      ) : (
        ""
      )}

{openResetPasswordModal ? (
        <NewUserResetPassword 
        open={openResetPasswordModal}
        setOpen={setOpenResetPasswordModal}
      />
      ) : (
        ""
      )}
      {/* {openVerifyOtp ? (
    <VerifyOtp
      open={openVerifyOtp}
      setOpen={setOpenVerifyOtp}
      onSubmit={verifyAccount}
      userDetails={{ email: email }}
      otpVerificationLoading={otpVerificationLoading}
    ></VerifyOtp>
  ) : (
    ""
  )} */}
    </>
  );
};

export default HomeHeroSection;
