import * as yup from "yup";
export const billingSchema = yup.object({
  annualPricing: yup.boolean(),
  nameOnCard: yup.string().required("Name is required"),
  // zipCode: yup
  //   .string()
  //   .required("Zip code is required")
  //   .min(5, "Enter a valid zip code")
  //   .max(6, "Enter a valid zip code"),
});
