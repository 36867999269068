export enum CardElementType {
  CardCVV = "cardCvc",
  CardExpiry = "cardExpiry",
  CardNumber = "cardNumber",
}

export enum ProductPriceIntervalType {
  YEAR = "year",
  MONTH = "month",
  ONETIME = "one_time"
}

export enum CardBrands {
  Amex = "amex",
  Discover = "discover",
  Mastercard = "mastercard",
  Visa = "visa",
  JCB = "jcb",
  Unionpay = "unionpay",
  EftposAustralia = "eftposaustralia",
}
export enum ProductType {
  CREDIT_ACCESS = "credit_access",
  GENERAL_ACCESS = "general_access",
}

export const BlackFridayOffer:any = {
  '1397': 1047.75,
  '1795': 1346.25,
  '2595': 1946.25,
  '3299': 2474.25,
  '4949': 3711.75
} as const;

export const BlackFridayOfferGeneralAccess:any = {
  '98': 49,
  '1176': 474,
} as const;