import { createAction } from "@reduxjs/toolkit";

export const fetchGeneralAccessProduct = createAction(
  "FETCH_GENERAL_ACCESS_PRODUCT",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCreditAccessProduct = createAction(
  "FETCH_CREDIT_ACCESS_PRODUCT",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCardList = createAction(
  "FETCH_CARDS_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchInvoices = createAction(
  "FETCH_INVOICES",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchSubscriptions = createAction(
  "FETCH_SUBSCRIPTIONS",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCreditUsages = createAction(
  "FETCH_CREDIT_USAGES",
  function prepare(data) {
    return { payload: data };
  }
);
