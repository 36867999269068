
const Tick = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18" height="13"
                viewBox="0 0 18 13"
                fill="none">
                <path d="M17 1L6 12L1 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Tick;
