import Button from "../../../atoms/Button";
import styles from "./SmarterLeadSec.module.scss";
import property from '../../../assets/images/property.svg';
import propertyMobile from '../../../assets/images/propertyMobile.svg';
import skip from '../../../assets/images/skip.svg';
import crm from '../../../assets/images/crm.svg';
import crmMobile from '../../../assets/images/crm-mobile.svg';
import detail from '../../../assets/images/detail.svg';
import detailMobile from '../../../assets/images/detailMobile.svg';
import scalability from "../../../assets/images/scalability.svg";
import { useState } from "react";
import EarlyAccessSignup from "../../../organisms/earlyAccess/EarlyAccessSignup";
import Register from "../../../organisms/auth/Register";

const SmarterLeadSec = () => {
    const [openEarlyAccessModal, setOpenEarlyAccessModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    return (
        <div className={`${styles.smartLeads}`}>
            <div className={`${styles.smartLeads__inner}`}>
                <div className={`${styles.header}`}
                data-aos="fade-up" 
                data-aos-delay="50" 
                data-aos-duration="1000"
                data-aos-anchor-placement="bottom-bottom"
                >
                    <h2>Your one-stop shop.</h2>
                    <p>Buy data, skip trace, launch a campaign, and nurture your leads all in one place.</p>
                    <div className="flex justifyCenter">
                        <Button className="gradient gradientBlack" label="Start your Free Trial"
                           action={() => setOpenEarlyAccessModal(true)}
                        />
                    </div>
                </div>
                <div className={`dflex ${styles.leadsCards}`}>
                    <div className={`${styles.property}`}
                    data-aos="fade-right"
                    data-aos-delay="50" 
                    data-aos-duration="1000"
                    >
                        <h2>Property Search</h2>
                        <div className={`dflex ${styles.propertyInfo}`}>
                            <div className={`${styles.propertyInfo__text}`}>
                                <p>With more than 50 expertly-crafted filters, you can search your target market with ease and precision.</p>
                            </div>
                            <div className={`${styles.propertyInfo__info}`}>
                                <img className={`${styles.desktop}`} src={property} alt="icon" />
                                <img className={`${styles.mobile}`} src={propertyMobile} alt="icon" />

                            </div>
                        </div>

                    </div>
                    <div className={`${styles.skip}`}
                    data-aos="fade-left"
                    data-aos-delay="50" 
                    data-aos-duration="1000"
                    >
                        <h2>Skip Trace</h2>
                        <p>Obtain multiple contact phone numbers for each of the properties on your list with industry standard accuracy.</p>
                        <div className={`${styles.skip__img}`}>
                            <img src={skip} alt="icon" />
                        </div>
                    </div>
                    <div className={`dflex ${styles.crm}`}
                    data-aos="fade-up"
                    data-aos-delay="50" 
                    data-aos-duration="1000"
                    >
                        <div className={`${styles.crm__info}`}>
                            <h2>Integrated CRM</h2>
                            <p>Manage your leads using our built-in CRM to schedule follow up dates, take notes, set lead statuses, track conversations, set reminders, and much more.</p>
                        </div>
                        <div className={`${styles.crm__img}`}>
                            <img className={`${styles.desktop}`} src={crm} alt="icon" />
                            <img className={`${styles.mobile}`} src={crmMobile} alt="icon" />

                        </div>
                    </div>
                    <div className={`${styles.detail}`}
                    data-aos="fade-right"
                    data-aos-delay="50" 
                    data-aos-duration="1000"
                    >
                        <h2>Detailed Property Info</h2>
                        <p>Access 100+ crucial data points for each property such as mortgage balances, MLS history, property features, FMR rates, and more.</p>
                        <div className={`${styles.detail__img}`}>
                            <img className={`${styles.desktop}`} src={detail} alt="icon" />
                            <img className={`${styles.mobile}`} src={detailMobile} alt="icon" />

                        </div>
                    </div>
                    <div className={`dflex ${styles.infinite}`}
                    data-aos="fade-left"
                    data-aos-delay="50" 
                    data-aos-duration="1000"
                    >
                        <div className={`${styles.infinite__left}`}>
                            <h2>Boundless Scaling</h2>
                            <p>Grow your business without the bottlenecks involved with human employees.</p>
                            <br />
                            <ul>
                                <li>Extract leads from your data in <u>3 days</u>, not months.</li>
                                <li>No more hiring, training, or managing employees.</li>
                            </ul>
                        </div>
                        <div className={`dflex ${styles.infinite__right}`}>
                            <div className={` ${styles.infiniteImg} flex alignCenter justifyCenter`}  >
                                <img src={scalability} alt="icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openEarlyAccessModal ? (
        // <EarlyAccessSignup open={openEarlyAccessModal} setOpen={setOpenEarlyAccessModal}></EarlyAccessSignup>
        <Register
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
    ) : (
        ""
      )}
        </div>
    )
}

export default SmarterLeadSec;