import { jwt } from "../../utils/utils";

export const initialState = {
  auth: !!jwt,
};

const authGuardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_AUTH_TOKEN":
      return {
        ...state,
        auth: action.auth,
      };
    case "CLEAR_AUTH_TOKEN":
      return {
        ...state,
        auth: false,
      };
    default:
      return state;
  }
};
export default authGuardReducer;
