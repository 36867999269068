

const Hot = ({ color }: { color?: any }) => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M17.5013 2.50008L12.5013 2.50008M17.5013 5.83342L12.5013 5.83341M17.5013 9.16675L12.5013 9.16675M4.58464 11.4649V3.75008C4.58464 2.59949 5.51738 1.66675 6.66797 1.66675C7.81856 1.66675 8.7513 2.59949 8.7513 3.75008V11.4649C9.75629 12.1376 10.418 13.2832 10.418 14.5834C10.418 16.6545 8.73904 18.3334 6.66797 18.3334C4.5969 18.3334 2.91797 16.6545 2.91797 14.5834C2.91797 13.2832 3.57965 12.1376 4.58464 11.4649ZM7.5013 14.5834C7.5013 15.0437 7.12821 15.4167 6.66797 15.4167C6.20773 15.4167 5.83464 15.0437 5.83464 14.5834C5.83464 14.1232 6.20773 13.7501 6.66797 13.7501C7.12821 13.7501 7.5013 14.1232 7.5013 14.5834Z" 
 stroke={color ? color : 'currentColor'} 
strokeWidth="1.66667" 
strokeLinecap="round" 
strokeLinejoin="round"
/>
</svg>
        </>
    );
};

export default Hot;