import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch } from "react-redux";
import { pauseSubscription } from "./billingService";
import { PAUSE_SUBSCRIPTION_DAYS_OPTIONS } from "./constant";
import { CustomError } from "../addLeads/interface";
import DropDown from "../../atoms/DropDown";
import { setSubscriptions } from "./reducer";
import InfoOutlineSolid from "../../assets/icons/infoOutlineSolid";
import { addDaysToDate } from "../../utils/utils";
import CheckCircle from "../../assets/icons/checkCircle";
import Refresh from "../../assets/icons/refresh";
import Zap from "../../assets/icons/zap";
import Check from "../../assets/icons/check";
import styles from "./PauseSubscription.module.scss";

const PauseSubscription = ({
  handleClose,
  open,
  setPauseModal,
  subscriptions,
  setConfirmPauseModal,
  confirmPauseModal,
  creditUsage
}: any) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      pauseDays: PAUSE_SUBSCRIPTION_DAYS_OPTIONS[0]?.value,
    },
  });
const pauseDay= watch("pauseDays")
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const handlePause = async () => {
    try {
      setLoading(true);
      await pauseSubscription({
        stripeSubscriptionId: subscriptions?.creditSubData?.stripeSubscriptionId,
        daysCount: watch("pauseDays"),
      });
      setLoading(false);
      handleShowToast("Subscription paused successfully!", ToastType.success);
      dispatch(setSubscriptions({
        ...subscriptions,
        creditSubData: {
          ...subscriptions?.creditSubData,
          isPaused: true
        }
      }));
      handleClose()
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      
    }
  };

  useEffect(() => {
    if (!open) {
      setValue("pauseDays", PAUSE_SUBSCRIPTION_DAYS_OPTIONS[0]?.value);
    }
  }, [open]);

  return (
    <>
      <Dialog
        className={`stepsDialog cancelSubscription ${confirmPauseModal ? `` : `upgradeModal showPurchaseModal`}`}
        onClose={handleClose}
        open={open}
      >
      { confirmPauseModal ? 
        <div className="dialogWrapper">
        <span role="link" onClick={handleClose} className="closeDialog">
          <Close />
        </span>
        <div className="dialogWrapper__header">
        <InfoOutlineSolid />
        <h2>Are you sure? You won't be able to create new campaigns!</h2>
        </div>
        <div className="dialogWrapper__info">
        <p>
          Pausing will freeze your billing cycle days and access to launching new campaigns. Credits will remain intact.
          </p>
         <h3>How it works</h3> 
         <ul>
         <li>For example, if you have 5 days left in your billing cycle and pause for 7 days, you'll regain those 5 days and any remaining credits balance once the pause ends.</li>
         <li> Credits reset at your next billing cycle.</li>
         </ul>
         
         <h3> Important</h3>
         <ul>
         <li> Only one pause per billing cycle.</li>
         <li> No consecutive pauses without renewal.</li>
         <li> Your pause is effective immediately. </li>
          </ul>
        
       <p>Have questions? Call/text (877)343-7844</p>
        <DropDown
          options={PAUSE_SUBSCRIPTION_DAYS_OPTIONS}
          label=""
          control={control}
          name="pauseDays"
          errors={errors}
          handleOnFocus={(e: any) => handleOnFocus(e)}
        ></DropDown>
        </div>
     
        <div className="mainCol actions">
          <div className="mainCol__half">
            <Button
              label="Keep My Plan"
              className=" primary full md"
              disabled={loading}
              action={handleClose}
            />
          </div>
          <div className="mainCol__half">
            <Button
              label="Pause Now"
              className="full animated outline  md"
              action={() => {
                setConfirmPauseModal(false)
              }}
              clicked={loading}
              disabled={loading}
            />
          </div>
        </div>
      </div> : <div className="dialogWrapper">
          <span role="link" onClick={handleClose} className="closeDialog">
            <Close />
          </span>
          <div className={`${styles.confirm}`}>
          <div className={`${styles.headerIcon}`}>
          <span className={`${styles.flagBorder}`}>  <Check /></span>
            </div>
          <div className={`${styles.confirm__header}`}>
          <h2>Let's confirm your pause request</h2>
          <p>The following are important details to review.</p>
          </div>
          <div className={`${styles.confirm__info}`}>
            <ul>
                <li>
                    <figure>
                        <Zap />
                    </figure>
                    <figcaption>
                    <p>
              
                    Your plan will pause for <strong>{watch("pauseDays")} days</strong>  and reactivate on <strong>{addDaysToDate(pauseDay)}</strong>
            </p>
           
                    </figcaption>
                </li>
                <li>
                    <figure>
                        <Refresh />
                    </figure>
                    <figcaption>
                    <p>Your existing credit balance of <strong>{creditUsage?.availableCredits}</strong> will remain and be available for use on <strong>{addDaysToDate(pauseDay)}</strong> before refreshing on your next billing cycle.</p>
                    </figcaption>
                </li>
                <li>
                    <figure>
                        <CheckCircle />
                    </figure>
                    <figcaption>
                    <p>Your next billing date is <strong>{addDaysToDate(pauseDay ,creditUsage?.expiresAt)}</strong></p>
                    </figcaption>
                </li>
            </ul>
         </div>
         <div className="mainCol actions">
            <div className="mainCol__half">
              <Button
                label="Go Back"
                className=" primary full md"
                action={() => {
                  setConfirmPauseModal(true)
                }}
                disabled={loading}

              />
            </div>
            <div className="mainCol__half">
              <Button
                label="Confirm Pause"
                className="full animated outline  md"
                action={() => {
                  handlePause();
                }}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
          </div>


        </div>
        
        }

      </Dialog>
    </>
  );
};

export default PauseSubscription;
