import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import * as sagaActions from "./sagaActions";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchGeneralAccessProduct({ payload }: any) {
  try {
    yield put(slice.setGeneralAccessProductsIsLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/payment-gateway/general-access-product`,
      headers: {},
    });
    yield all([
      put(slice.setGeneralAccessProducts(response.data)),
      put(slice.setGeneralAccessProductsIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setGeneralAccessProductsIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchCreditAccessProduct({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/payment-gateway/credit-access-product`,
      headers: {},
    });
    yield all([put(slice.setCreditAccessProducts(response.data))]);
  } catch (error: any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchCardList({ payload }: any) {
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/payment-gateway/card-list`,
      headers: {},
    });
    yield all([put(slice.setCardList(response.data.data))]);
  } catch (error: any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchInvoices({ payload }: any) {
  try {
    if (!payload.last_Index) {
      yield put(slice.setInvoicesIsLoading(true));
    }
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/payment-gateway/get-invoices`,
      headers: {},
      data:payload,
    });
    yield all([
      put(
        payload.last_Index
          ? slice.appendInvoices(response.data)
          : slice.setInvoices(response.data)
      ),
      put(slice.setInvoicesIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setInvoicesIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}
function* fetchSubscriptions({ payload }: any) {
  try {
    yield put(slice.setSubscriptionsIsLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/payment-gateway/subscriptions`,
      headers: {},
      data: payload,
    });
    yield all([
      put(slice.setSubscriptions(response.data)),
      put(slice.setSubscriptionsIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setSubscriptionsIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
};

function* fetchCreditUsages({ payload }: any) {
  try {
    yield put(slice.setCreditUsageIsLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/payment-gateway/credit-usages`,
      headers: {},
      data: payload,
    });
    yield all([
      put(slice.setCreditUsage(response.data)),
      put(slice.setCreditUsageIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setCreditUsageIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
};

function* billingSaga() {
  yield takeLatest(
    sagaActions.fetchGeneralAccessProduct,
    fetchGeneralAccessProduct
  );
  yield takeLatest(
    sagaActions.fetchCreditAccessProduct,
    fetchCreditAccessProduct
  );
  yield takeLatest(sagaActions.fetchCardList, fetchCardList);
  yield takeLatest(sagaActions.fetchInvoices, fetchInvoices);
  yield takeLatest(sagaActions.fetchSubscriptions, fetchSubscriptions);
  yield takeLatest(sagaActions.fetchCreditUsages, fetchCreditUsages);
}

export default billingSaga;
