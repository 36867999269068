import { useRef } from "react";

export const useDbounce = (delay: number, callBack: Function) => {
  const timerRef = useRef<NodeJS.Timeout>();

  return (data: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      callBack(data);
    }, delay);
  };
};
