import { useEffect, useState } from "react";
import styles from "./Usage.module.scss";
import CircularProgressbar from "../../atoms/circularProgressbar";
import Button from "../../atoms/Button";
import Zap from "../../assets/icons/zap";
import InfoCircle from "../../assets/icons/infoCircle";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditUsages } from "../billing/sagaActions";
import {
  getCreditUsage,
  getCreditUsageIsLoading,
  getSubscriptions,
} from "../billing/selectors";
import { formartNumberAsLocalString } from "../../utils/utils";
import Loader from "../../atoms/Loader";
import UpgradeModal from "../upgradeModal/UpgradeModal";
import { UpgradeModalParentType } from "../upgradeModal/enum";
import { Tooltip } from "@mui/material";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { PermissionListEnum } from "../auth/enum";

export const Usage = () => {
  const dispatch = useDispatch();
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState(null);
  const [resetDuration, setResetDuration] = useState(0);
  const creditUsageIsLoading = useSelector((state) =>
    getCreditUsageIsLoading(state)
  );
  const creditUsage = useSelector((state) => getCreditUsage(state));
  const subscriptions = useSelector((state) => getSubscriptions(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  useEffect(() => {
    dispatch(fetchCreditUsages({}));
  }, [subscriptions]);

  useEffect(() => {
    if (creditUsage) {
      const usage = creditUsage?.totalCredits
        ? (+(creditUsage?.usedCredits ?? 0) /
            +(creditUsage?.totalCredits ?? 0)) *
          100
        : 0;
      const diffTime = creditUsage?.expiresAt
        ? new Date(creditUsage?.expiresAt).getTime() - new Date().getTime()
        : 0;
      const diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      setUsagePercentage(+usage.toFixed(1));
      setResetDuration(diffDay > 0 ? diffDay : 0);
    }
  }, [creditUsage]);

  return (
    <>
      <div className={`${styles.usages}`}>
        <div className={`${styles.usages__header}`}>
          <h3>Account Usage</h3>
          <p>See usage details for your current plan.</p>
        </div>
        {subscriptions?.generalSubData &&
          ((creditUsage?.totalCredits &&
            creditUsage?.usedCredits === creditUsage?.totalCredits) ||
            !subscriptions?.creditSubData) && (
            <div
              className={`dflex justifySpaceBetween alignCenter ${styles.usages__usages}`}
            >
              <p>
                <span className={`animated-svg `}>
                  <InfoCircle />
                </span>

                {subscriptions?.creditSubData ? (
                  <>
                    <strong>You’ve reached your monthly usage quota.</strong>
                    Upgrade now to increase your monthly allowance.
                  </>
                ) : (
                  <>
                    <strong>AI Calling Subscription</strong>
                    No Active Subscription
                  </>
                )}
              </p>
              <Tooltip
                title={
                  profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
                    ?ROLE_PERMISSION_STATUS.NoAccess
                    : ""
                }
              >
                <div>
                { profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? 
                <Button
                label={subscriptions?.creditSubData ? "Upgrade now" : "Buy now"}
                prefix={<Zap />}
                className="primary animated"
                disabled={ profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER}
              ></Button>:
              <Button
                label={subscriptions?.creditSubData ? "Upgrade now" : "Buy now"}
                prefix={<Zap />}
                className="primary animated"
                action={() => {
                  setOpenPlanUpgradeModal(true);
                  setUpgradSubscriptionProduct(subscriptions?.creditSubData);
                }}
              />
               }
                </div>
                </Tooltip>
            </div>
          )}
        {creditUsageIsLoading ? (
          <>
            <div className={`loader-main`}>
              <Loader></Loader>
            </div>
          </>
        ) : (
          <>
            <div className={`dflex alignCenter ${styles.usages__credits}`}>
              <div className={`${styles.credits}`}>
                <CircularProgressbar
                  value={usagePercentage}
                  children={
                    <>
                      <div className={`${styles.credits__info}`}>
                        <p>Credits used</p>
                        <h3>{usagePercentage}%</h3>
                      </div>
                    </>
                  }
                ></CircularProgressbar>
              </div>
              <div className={`${styles.creditsText}`}>
                <div className={`${styles.creditsText__top}`}>
                  <p>Subscribed to:</p>
                  <h4>
                    {subscriptions?.creditSubData?.creditCount
                      ? `OttoLeads ${subscriptions?.creditSubData?.creditCount}`
                      : "-"}
                  </h4>
                </div>
                <div className={`${styles.creditsText__info}`}>
                  <p>Credits used:</p>
                  <h5>
                    {formartNumberAsLocalString(creditUsage?.usedCredits ?? 0)}{" "}
                    /{" "}
                    {formartNumberAsLocalString(creditUsage?.totalCredits ?? 0)}
                  </h5>
                </div>
                <div className={`${styles.creditsText__info}`}>
                  <p>Next credit reset in:</p>
                  <h5>
                    {subscriptions?.creditSubData?.isPaused ? "Paused" :
                    `${resetDuration} ${resetDuration > 1 ? "days" : "day"}`}
                    
                  </h5>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <div className={`flex alignCenter ${styles.usages__switch}`}>
                <CustomSwitch
                    control={control}
                    name="optimizeCampaign"
                    defaultChecked={true}
                />
                <div>
                    <h5>Flexible usage</h5>
                    <p>
                        If you run out of credit in your current plan, your campaigns will
                        continue as scheduled, and you'll be billed for the extra credits
                        you use at pay-per-use pricing until the end of your billing
                        cycle.
                    </p>
                </div>

            </div> */}
      </div>
      <UpgradeModal
        handleClose={() => {
          setOpenPlanUpgradeModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openPlanUpgradeModal}
        product={upgradSubscriptionProduct}
        parentType={UpgradeModalParentType.Usage}
      ></UpgradeModal>
    </>
  );
};
