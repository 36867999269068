import { initialState } from "./reducer";

const getStateData = (state: any) => state["campaign"] || initialState;

export const campaignsData = (state: any) => {
  const reducer = getStateData(state);
  return reducer;
};

export const getCampaignListIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignListIsLoading;
};

export const getCampaignStats = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignStats;
};

export const getCampaignsListMore = (state: any) => {
  const reducer = getStateData(state);
  return reducer.isFetchingMore;
};