import React, { useState } from "react";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import styles from "./Thankyou.module.scss";
import Trophy from "../../assets/icons/trophy";
import Register from "../../organisms/auth/Register";

const Thankyou = () => {
  const navigate = useNavigate();
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  return (
    <div className={` flex alignCenter justifyCenter ${styles.thankyou}`}>
      <div className="wrapper bottom-zero">
        <figure>
          <Trophy />
        </figure>
        <h1>Thank You!</h1>
        <p>We've received your information and are excited to get connected with you!</p>
        <p>We will be reaching out to your email and phone.</p>
        <div className={` flex alignCenter justifyCenter ${styles.thankyou__button}`}>
          <Button className="gradient" label={"Start your free trial"} action={() => setOpenRegisterModal(true)} />

          <Button className="gradient gradientBlack" label={"Go back home"} action={() => navigate("/")} />
        </div>
      </div>
      {openRegisterModal ? <Register open={openRegisterModal} setOpen={setOpenRegisterModal}></Register> : ""}
    </div>
  );
};

export default Thankyou;
