import { postAPI, putAPI } from "../../apis/apis"

export const createSubscription = async (payload:any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/create-subscription`,
    headers: {},
    data: payload,
  });
};
export const fetchSetupIntent=async ()=>{
    return postAPI({
      url: process.env.REACT_APP_BASE_URL + `/payment-gateway/setup_intent`,
      headers: {},
    });
}

export const skipCreditPayment = async (payload:any)=>{
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/users/update-profile`,
    headers: {},
    data: payload,
  });
}

export const cancelSubscription = async (payload: any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/cancel-subscription`,
    headers: {},
    data: payload,
  });
};

export const detachPaymentMethod = async (payload: any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/detach-payment-method`,
    headers: {},
    data: payload,
  });
};

export const updatePaymentMethod = async (payload: any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/update-payment-method`,
    headers: {},
    data: payload,
  });
};

export const updatePlan = (payload:any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/payment-gateway/update-plan`,
    headers: {},
    data: payload,
  });
};

export const pauseSubscription = async (payload: any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/pause-subscription`,
    headers: {},
    data: payload,
  });
};

export const resumeSubscription = async (id:any) => {
  return postAPI({
    url:
      process.env.REACT_APP_BASE_URL + `/payment-gateway/resume-subscription/${id}`,
    headers: {},
  });
};