import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Close from "../../assets/icons/cross";
import { SAVED_FILTER_DISPLAY_COUNT } from "./constants";
import { FilterValueType } from "./enum";
import { SelctedFilterListItem } from "./interface";
import styles from "./PropertySearch.module.scss";
import { createSelectedFilterList } from "./utils";

const SelectedFilterList = ({
  isAllFilterOpen,
  dispatchSearchProperty,
}: {
  isAllFilterOpen: boolean;
  dispatchSearchProperty: Function;
}) => {
  const { watch, setValue } = useFormContext();
  const [filterList, setFilterList] = useState<{
    [key: string]: SelctedFilterListItem;
  }>({});
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    let formValue = watch();
    updateFilterList(formValue);
    const sub = watch((formValue: any) => {
      updateFilterList(formValue);
    });
    return () => sub?.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (Object.keys(filterList).length < 9 && showMore) {
      setShowMore(false);
    }
  }, [filterList]);

  const updateFilterList = (formValue: any) => {
    const list = createSelectedFilterList(formValue);
    setFilterList(list);
  };

  const resetToDefaultValue = useCallback(
    (filterdata: SelctedFilterListItem, key: string) => {
      if (filterdata.type === FilterValueType.QUICK_FILTER) {
        const quickFilter = key.replace("quickFilters_", "");
        const selectedFilters: string[] = watch("quickFilters");
        const index = selectedFilters.findIndex((el) => el === quickFilter);
        selectedFilters.splice(index, 1);
        setValue("quickFilters", [...selectedFilters]);
      } else {
        filterdata.keys.forEach((key) =>
          setValue(key, filterdata.defaultValue)
        );
        // setValue("savedFilter", "");
      }
      if (!isAllFilterOpen) {
        dispatchSearchProperty(watch(), 1, false);
      }
    },
    [setValue, isAllFilterOpen]
  );

  if (Object.keys(filterList).length === 0) {
    return <></>;
  }
  return (
    <>
      <div className={` ${styles.searchFilterList} dflex`}>
        {Object.keys(filterList)
          .splice(
            0,
            showMore
              ? Object.keys(filterList).length
              : SAVED_FILTER_DISPLAY_COUNT
          )
          .map((el) => (
            <span key={el}>
              {filterList[el]?.label}{" "}
              <Close onClick={() => resetToDefaultValue(filterList[el], el)} />
            </span>
          ))}
        {Object.keys(filterList).length - SAVED_FILTER_DISPLAY_COUNT > 0 &&
          !showMore && (
            <span onClick={() => setShowMore(true)}>
              More({Object.keys(filterList).length - SAVED_FILTER_DISPLAY_COUNT}
              +)
            </span>
          )}
      </div>
    </>
  );
};
export default SelectedFilterList;
