export const DEFAULT_CREATE_LEAD_DATA = {
  nameFirst: "",
  nameLast: "",
  addressStreet: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  phones: "",
  address:""
};

export const DB_COLUMNS = [
  "nameFirst",
  "nameLast",
  "addressStreet",
  "addressCity",
  "addressState",
  "addressZip",
  "phones",
];
