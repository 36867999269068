import React, { useEffect } from "react";
import Loader from "../../atoms/Loader";
import { GetSession } from "../../services/cognitoService";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { checkUserInDb, createUser } from "../../apis/user";
import { setAuthGuardToken } from "../../organisms/authroute/action";
import { decodetoken } from "../../utils/utils";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { LOGIN_TYPE } from "../../organisms/auth/enum";

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/", { replace: true });
  };
  
  const extractDetailsFromToken = async () => {
    try {
      const session = await GetSession();

      const email = session?.getIdToken()?.payload?.email;
      const name = session?.getIdToken()?.payload?.name;
      const cognitoId = session?.getIdToken()?.payload?.sub;
      const res = await checkUserInDb(email);

      const payload = {
        name,
        email,
        cognitoId,
      };
      if (res?.data === false) {
        await createUser(payload);
      }
      const accessToken = session?.getAccessToken().getJwtToken();
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("loginType", LOGIN_TYPE.GOOGLE);
      dispatch(setAuthGuardToken(decodetoken(accessToken)));
      dispatch(fetchProfileInfo({}));
      navigate("/campaigns");
    } catch (error) {
      handleNavigate();
      console.log(error);
    }
  };

  useEffect(() => {
    extractDetailsFromToken();
  }, []);
  return <Loader />;
};

export default Verification;
