import { postAPI, putAPI } from "./apis";

export interface ISIGNED_URL_PAYLOAD {
  key: string;
  method: string;
  content: string;
}
export interface ICOL_MAPPING {
  fileColumnName: "string";
  dbColumnName: "string";
}

export interface ICREATE_CAMPAIGN_PAYLOAD {
  name: "string";
  startDate: Date;
  dpn: number;
  optimizeEnabled: boolean;
  attemptFrequency: number;
  leadFileKey: "string";
  voiceId: "string";
  columnMapping: ICOL_MAPPING[];
}
export const generateSignedUrl = async (payload: ISIGNED_URL_PAYLOAD) => {
  return new Promise(async (resolve) => {
    await postAPI({
      url: process.env.REACT_APP_BASE_URL + `/signed-url`,
      data: payload,
      headers: {},
    })
      .then((response) => {
        resolve(response.data.result.url);
      })
      .catch((err) => {
        console.log(err);
        resolve({ success: false, message: err.response.data.message });
      });
  });
};

export async function uploadFile(file: File, url: string) {
  const payload = {
    url: url,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  };
  return putAPI(payload);
}

