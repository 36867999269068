
const FileCheck = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M11.6654 1.89111V5.33323C11.6654 5.79994 11.6654 6.0333 11.7562 6.21156C11.8361 6.36836 11.9636 6.49584 12.1204 6.57574C12.2986 6.66656 12.532 6.66656 12.9987 6.66656H16.4408M7.4987 13.3332L9.16536 14.9998L12.9154 11.2498M11.6654 1.6665H7.33203C5.9319 1.6665 5.23183 1.6665 4.69705 1.93899C4.22665 2.17867 3.8442 2.56112 3.60451 3.03153C3.33203 3.56631 3.33203 4.26637 3.33203 5.6665V14.3332C3.33203 15.7333 3.33203 16.4334 3.60451 16.9681C3.8442 17.4386 4.22665 17.821 4.69705 18.0607C5.23183 18.3332 5.9319 18.3332 7.33203 18.3332H12.6654C14.0655 18.3332 14.7656 18.3332 15.3003 18.0607C15.7707 17.821 16.1532 17.4386 16.3929 16.9681C16.6654 16.4334 16.6654 15.7333 16.6654 14.3332V6.6665L11.6654 1.6665Z" 
stroke="currentColor" 
strokeWidth="1.66667" 
strokeLinecap="round" 
strokeLinejoin="round"
/>
</svg>

        </>
    );
};

export default FileCheck;


