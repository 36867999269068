import { initialState } from "./reducer";

const getStateData = (state: any) => state["createLead"] || initialState;

export const listsData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.myLists;
};

export const getOpenCreateLeadModal = (state: any) => {
  const reducer = getStateData(state);
  return reducer.openLeadModal;
};

export const getLeadButtonLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadButtonLoading;
};

export const getNewLeadCreatedFlag = (state: any) => {
  const reducer = getStateData(state);
  return reducer.newLeadCreated;
};

export const getsearchList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.searchList;
};
export const getSearchListLoading=(state:any)=>{
  const reducer= getStateData(state);;
  return reducer.searchLoading
}