import FallbackPropertyImage from "../../assets/images/no-property-image-found.png";
import ImageWithLoading from "../../atoms/ImageWithLoading";
import styles from "./PropertyCard.module.scss";
const PropertyCardLoader = () => {
  return (
    
      <div className={` ${styles.propertyCard__card}`}>
        <figure>
          <div className={` ${styles.propertyCard__relative}`}>
            <ImageWithLoading
              src={FallbackPropertyImage}
              alt="property"
              loading={true}
            ></ImageWithLoading>
          </div>
        </figure>
        <figcaption role="link">
 
          <ul className="flex justifySpaceBetween">
            <li>
              <h6> {"-"}</h6>
              <p>Beds</p>
            </li>
            <li>
              <h6> {"-"}</h6>
              <p>Baths</p>
            </li>
            <li>
              <h6> {"-"}</h6>
              <p>Sq Ft</p>
            </li>
            <li>
              <h6>{"N/A"}</h6>
              <p>Est. Value</p>
            </li>
            <li>
              <h6>{"-"}</h6>
              <p>Built</p>
            </li>
          </ul>
          <div
            className={` ${styles.propertyCard__cardFooter} flex justifySpaceBetween`}
          >
            <p>Last Sale: {"N/A"}</p>
            <p>Sold Price: {"N/A"}</p>
          </div>
        </figcaption>
      </div>
   
  );
};

export default PropertyCardLoader;
