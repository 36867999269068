const CheckCircleFill = () => {
    return (
        <>
            <svg
                width='56'
                height='56'
                viewBox='0 0 56 56'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='4'
                    y='4'
                    width='48'
                    height='48'
                    rx='24'
                    fill='#F2F4F7'
                />
                <rect
                    x='4'
                    y='4'
                    width='48'
                    height='48'
                    rx='24'
                    stroke='#F9FAFB'
                    strokeWidth='8'
                />
                <path
                    d='M36 22L25 33L20 28'
                    stroke='black'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
};

export default CheckCircleFill;
