import { initialState } from "./reducer";

const getStateData = (state: any) => state["uploadList"] || initialState;

export const getIsFileUploading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.isFileUploading;
};

export const getListUploading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listUploading;
};

export const getOpenImportFileModal = (state: any) => {
  const reducer = getStateData(state);
  return reducer.openImportFileModal;
};
export const getLeadListCSV = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadListCSV;
};

export const getNewListUploaded = (state: any) => {
  const reducer = getStateData(state);
  return reducer.newListUploaded;
};