import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  searchTerm: "",
  addressPredictions: [] as any[],
  propertiesList: [] as any,
  propertiesListLoading: false,
  resultsCount: -1,
  potentialCount: 0,
  clearPropertySearch: false,
  addressPredictionLoading: false,
  searchProperty: {},
  requestId: "",
};

const propertySearchSlice = createSlice({
  initialState: initialState,
  name: "propertySearch",
  reducers: {
    setSearchTerm: (state, { payload }) => {
      return {
        ...state,
        searchTerm: payload,
      };
    },
    setAddressPredictions: (state, { payload: { data } }) => {
      return {
        ...state,
        addressPredictions: data?.results ?? [],
      };
    },
    setSearchedProperties: (state, { payload: { data } }) => {
      return {
        ...state,
        propertiesList: data?.results ?? [],
        resultsCount: data?.count ?? 0,
        potentialCount: data?.count ?? 0,
        requestId: data?.requestId ?? "",
        searchTerm: data?.searchTerm ?? state.searchTerm,
      };
    },
    appendSearchedProperties: (state, { payload: { data } }) => {
      return {
        ...state,
        propertiesList: [
          ...state?.propertiesList,
          ...(data?.results ? data?.results : []),
        ],
        resultsCount: data?.count,
        requestId: data?.requestId,
        potentialCount: data?.count ?? 0,
      };
    },
    setPropertiesListLoading: (state, { payload }) => {
      return {
        ...state,
        propertiesListLoading: payload,
      };
    },
    setClearPropertySearch: (state, { payload }) => {
      return {
        ...state,
        clearPropertySearch: payload,
      };
    },
    setAddressPredictionLoading: (state, { payload }) => {
      return {
        ...state,
        addressPredictionLoading: payload,
      };
    },
    resetPropertySearchState: (state, { payload }) => {
      return initialState;
    },
    setSearchTermSelected: (state, { payload }) => {
      return {
        ...state,
        searchProperty: payload,
      };
    },
  },
});

export const {
  setAddressPredictions,
  setSearchedProperties,
  setPropertiesListLoading,
  appendSearchedProperties,
  setClearPropertySearch,
  setAddressPredictionLoading,
  setSearchTerm,
  resetPropertySearchState,
  setSearchTermSelected,
} = propertySearchSlice.actions;
export default propertySearchSlice.reducer;
