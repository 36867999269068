export const LeadStatus: any = {
  NEW_LEAD: "New Lead",
  FOLLOW_UP: "Follow Up",
  CONTRACT_SENT: "Contract Sent",
  HOT: "Make Offer",
  UNDER_CONTRACT: "In Contract",
  CLOSED_DEAL: "Closed Deal",
  PROSPECTING: "Prospecting",
  WARM: "Warm",
  COLD: "Dead Deal",
  APPOINTMENT_SET: "Appointment set",
} as const;

type LeadStatusKeys = keyof typeof LeadStatus;

export const LeadStatusMap = {
  "New Lead": "NEW_LEAD",
  Prospecting: "PROSPECTING",
  Hot: "HOT",
  Warm: "WARM",
  Cold: "COLD",
  "Follow Up": "FOLLOW_UP",
  "Appointment Set": "APPOINTMENT_SET",
  "Contract Sent": "CONTRACT_SENT",
  "Under Contract": "UNDER_CONTRACT",
  "Closed Deal": "CLOSED_DEAL",
} as const;

export const LeadStatusValues: any = {
 'New Lead': "NEW_LEAD" ,
 'Follow Up' : "FOLLOW_UP" ,
  'Contract Sent':"CONTRACT_SENT" ,
  'Make Offer' :"MAKE_OFFER" ,
  'In Contract':"IN_CONTRACT" ,
 'Closed Deal': "CLOSED_DEAL"  ,
 'Dead Deal': "DEAD_DEAL"  ,
 'Warm': "WARM" ,
 'Appointment set': "APPOINTMENT_SET" ,
 'Prospecting': "PROSPECTING" 
} as const;

export type { LeadStatusKeys };
