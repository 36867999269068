import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Logo from "../../components/Logo";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordFormSchema } from "./validation";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  loginUserFn,
  sendEmailForgotPass,
  setNewPassword,
} from "../auth/authServices";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const NewPassword = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const resendCode = async () => {
    setMsg("");
    const data = {
      email: searchParams.get("email"),
    };
    try {
      setSubmitClicked(true);
      await sendEmailForgotPass(data);
      setMsg(`We've resent the link to your email : ${searchParams.get("email")}`);
    } catch (error) {
      console.log("Email Not sent");
    } finally {
      setSubmitClicked(false);
    }
  };

  const onSubmit = async (values: any) => {
    setSubmitClicked(true);
    const payload = {
      email: searchParams.get("email"),
      code: searchParams.get("code"),
      newPassword: values?.password,
    };

    if (executeRecaptcha) {
      const Token = await executeRecaptcha("Login");
      const loginPayload = {
        email: searchParams.get("email"),
        password: values?.password,
        captchaToken: Token,
      };
      try {
        await setNewPassword(payload);
        toast.success("Password Updated Successfully!");
        const loginUserData = await loginUserFn(loginPayload);
        localStorage.setItem("accessToken", loginUserData?.data?.access_token);
        localStorage.setItem("refreshToken", loginUserData?.data?.refresh_token);
        window.location.href = `${process.env.REACT_APP_FE_URL}`;
        navigate("/property-search");
      } catch (err) {
        console.error(err);
        toast.error("Link seems to be expired. Please resend the link!");
      } finally {
        setSubmitClicked(false);
      }

    } else{
      console.error('executeRecaptcha is undefined');
    }
  };

  useEffect(() => {
    if (watch("password") === watch("confirmPassword")) {
      clearErrors("confirmPassword");
    }
  }, [watch("password"), watch("confirmPassword")]);

  return (
    <div className="verifyEmail newPassword">
      <form className="verifyEmail__info ">
        <div className="authStyle__logo flex alignCenter justifyCenter">
          <Logo />
        </div>

        <div className="authStyle__header">
          <h2>Reset password</h2>
          <p>Please enter your password below.</p>
        </div>
        <Input
          type="password"
          label="Password*"
          register={register}
          name="password"
          placeholder="Enter password"
          errors={errors}
        ></Input>
        <Input
          type="password"
          label="Confirm Password*"
          register={register}
          name="confirmPassword"
          placeholder="Re-enter password"
          errors={errors}
        ></Input>
        <div className="authStyle__footer">
          <Button
            label="Reset password"
            className="primary full xl"
            action={handleSubmit(onSubmit)}
            clicked={submitClicked}
            disabled={submitClicked}
          ></Button>
        </div>

        <p className="footerInfo">
          Something wrong with the link?{" "}
          <span role="link" onClick={resendCode}>
            Click to resend
          </span>
          <p>{msg}</p>
          <span
            role="link"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_FE_URL}`)
            }
          >
            Go to Login
          </span>
        </p>
      </form>
    </div>
  );
};

export default NewPassword;
