import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyUserFn } from "./authServices";
import Button from "../../atoms/Button";
import { useDispatch } from "react-redux";
import { setAuthGuardToken } from "../authroute/action";
import { decodetoken } from "../../utils/utils";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import EmailOpen from "../../assets/icons/emailOpen";
import AlertTriangle from "../../assets/icons/alertTriangle";
import Logo from "../../components/Logo";
import ResendLink from "../resendLink/ResendLink";
import { defaultLogout } from "../../services/auth";
import Loader from "../../atoms/Loader";
interface CustomError {
  response?: {
    data?: {
      message?: string;
      isEmailVerified?: boolean;
    };
  };
}

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const code = searchParams.get("code");
  const [verifiedUser, setVerifiedUser] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [showResendModal, setShowResendModal] = useState<boolean>(false);
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const payload = {
    email: email,
    code: code,
  };

  const verifyEmail = async () => {
    setErrorFlag(false);
    if (!email || !code) {
      setErrorFlag(true);
      return;
    }
    try {
      // defaultLogout();
      setLoading(true);
      const verifyEmailData = await verifyUserFn(payload);
      console.log("verifyEmailData", verifyEmailData);
      setVerifiedUser(verifyEmailData?.data?.emailVerified);
      localStorage.setItem("accessToken", verifyEmailData?.data?.access_token);
      localStorage.setItem(
        "refreshToken",
        verifyEmailData?.data?.refresh_token
      );
      dispatch(
        setAuthGuardToken(decodetoken(verifyEmailData?.data?.access_token))
      );
      dispatch(fetchProfileInfo({}));
    } catch (error) {
      console.log("Email not Verified");
      setVerifiedUser(false);
      if ((error as CustomError)?.response?.data?.isEmailVerified) {
        setIsEmailVerified(true);
      } else {
        setVerifiedUser(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendModal = () => {
    setShowResendModal(true);
    if (linkSent) {
      setLinkSent(false);
    }
  };

  const handleLogin = () => {
    defaultLogout();
    sessionStorage.setItem('LoginOpen', 'true');
    window.open(process.env.REACT_APP_FE_URL,"_self");
  };

  const handleOnboarding = () => {
    navigate("/onboarding");
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
      if (verifiedUser) {
        navigate("/onboarding");
      }
    // }, 5000);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [verifiedUser]);

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <>
      <div className="verifyEmailHeader">
        <Logo />
      </div>

      {loading ? (
        <Loader></Loader>
      ) : (
        <div className="verifyEmail">
          {/* {verifiedUser ? (
            <div className="verifyEmail__info">
              <figure>
                <EmailOpen />
              </figure>
              <h2>Email confirmed.</h2>
              <p>{email}</p>
              <p>Your email is confirmed. Let's get you onboarded!</p>
              <div className="dflex justifyCenter confirmButton">
                <Button
                  className="primary"
                  label="Go to Onboarding"
                  action={handleOnboarding}
                />
              </div>
            </div>
          ) : */}
          { isEmailVerified ? (
            <div className="verifyEmail__info">
              <figure>
                <EmailOpen />
              </figure>
              <h2>Email already confirmed.</h2>
              <p>{email}</p>
              <p>Your email is already confirmed.</p>
              <div className="dflex justifyCenter confirmButton">
                <Button
                  className="primary"
                  label="Go to Log In"
                  action={handleLogin}
                />
              </div>
            </div>
          ) : errorFlag ? (
            <div className="verifyEmail__info">
              <figure className="notConfirmed">
                <AlertTriangle />
              </figure>
              <h2>Invalid Link</h2>
              <p>Please click below to Sign Up or Login</p>{" "}
              <div className="dflex justifyCenter confirmButton">
                <Button
                  className="primary"
                  label="LogIn/SignUp"
                  action={handleLogin}
                />
              </div>
            </div>
          ) : (
            <div className="verifyEmail__info">
              <figure className="notConfirmed">
                <AlertTriangle />
              </figure>
              <h2>Email not confirmed</h2>
              <p>{email}</p>
              <p>Please click below to resend the link.</p>{" "}
              <div className="dflex justifyCenter confirmButton">
                <Button
                  className="primary"
                  label="Resend verification link"
                  action={handleResendModal}
                />
              </div>
            </div>
          )}

          {showResendModal && (
            <>
              <ResendLink
                open={showResendModal}
                setOpen={setShowResendModal}
                email={email ?? ""}
                linkSent={linkSent}
                setLinkSent={setLinkSent}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
