
import styles from "./PriceCard.module.scss";

const PriceCardInfo = ({children}:React.PropsWithChildren<{}>  ) => {
    return (
        <>
        <div className={`priceCardInfo dflex justifySpaceBetween alignCenter ${styles.priceCardInfo}`}>
                    <>
                    {children}
                    </> 
                </div>


        </>

    );
};

export default PriceCardInfo;

