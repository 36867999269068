import * as React from "react";
import Menu from "@mui/material/Menu";
import styles from "./FilterDropdown.module.scss";
import { FilterDropdownProp } from "./interface";

export default function FilterDropdown({
  handleClose,
  anchorEl,
  children,
  sizeLarge,
  sizeList,
  dataTestId
}: React.PropsWithChildren<FilterDropdownProp>) {
  const open = Boolean(anchorEl);
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={`${styles.filterMenu} ${sizeList ? styles.filterList : ``} ${
          sizeLarge ? styles.filterLarge : ``
        }`}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        data-testid={dataTestId}
      >
        {children}
      </Menu>
    </>
  );
}