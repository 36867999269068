
const PauseIcon = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
<path d="M9.83333 22.1702C11.1167 22.1702 12.1667 21.1202 12.1667 19.8369V8.17025C12.1667 6.88691 11.1167 5.83691 9.83333 5.83691C8.55 5.83691 7.5 6.88691 7.5 8.17025V19.8369C7.5 21.1202 8.55 22.1702 9.83333 22.1702ZM16.8333 8.17025V19.8369C16.8333 21.1202 17.8833 22.1702 19.1667 22.1702C20.45 22.1702 21.5 21.1202 21.5 19.8369V8.17025C21.5 6.88691 20.45 5.83691 19.1667 5.83691C17.8833 5.83691 16.8333 6.88691 16.8333 8.17025Z" 
fill="currentColor"
/>
</svg>

        </>
    );
};

export default PauseIcon;
