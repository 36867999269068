import * as yup from "yup";

export const signupFormSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required("Please enter a valid email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Please enter a valid email"),
  name: yup
    .string()
    .required("Please enter name")
    .trim()
    .test(
      "no-blank-spaces",
      "Input should not be blank or contain only spaces",
      (value) => !/^\s*$/.test(value)
    ).matches(/^[^\s].*[^\s]$/
    , "Invalid Name"),
  phone: yup
    .string()
    .transform((value) => {
      return value.replaceAll("-", "");
    })
    .required("Please enter valid phone no.")
    .matches(
      new RegExp("[0-9]{10}"),
      "Phone number should have atleast 10 digits."
    ),
  confirmation: yup.boolean().oneOf([true], "Please confirm policy first."),
});
