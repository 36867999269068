
const ToggleIcon = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
<path d="M15.6667 16.9974V19.3307H2.83333V16.9974H15.6667ZM21.5 8.83073V11.1641H0.5V8.83073H21.5ZM19.1667 0.664062V2.9974H6.33333V0.664062H19.1667Z" fill="black"/>
</svg>
        </>
    );
};

export default ToggleIcon;
