import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { cancelCampaignAction } from "./sagaAction";
import { isLoading } from "./selector";
import Trash from "../../assets/icons/trash";
import styles from "./CampaignDetail.module.scss";
import { useNavigate } from "react-router-dom";

interface CancelCampaignProps {
  id: any;
  open: boolean;
  setOpen: (e: boolean) => void;
  date: any;
}

const CancelCampaign = ({ open, setOpen, id, date }: CancelCampaignProps) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const loading = useSelector((state) => isLoading(state));
  
  const closeModal = () => {
    setOpen(false);
  };

  const handleCancelBtn = () => {
    dispatch(cancelCampaignAction(id));
    closeModal();
    navigate(`/campaigns`)
  };

  return (
    <Dialog
      className="stepsDialog successDialog"
      onClose={() => {
        closeModal();
      }}
      open={open}
    >
      <div className="dialogWrapper">
        <div className={` ${styles.cancelDialog} `}>
          <div className="dflex justifySpaceBetween alignCenter">
            <span className={` ${styles.cancelDialog__icon}`}>
              <Trash />
            </span>

            <span
              role="link"
              onClick={() => {
                closeModal();
                // clearErrors();
              }}
              className="closeDialog"
            >
              <Close />
            </span>
          </div>
          <div className={` ${styles.cancelDialog__info}`}>
            <h2>Cancel your campaign</h2>
            <p>
              Are you sure you want to cancel this campaign? <br />
              This action cannot be undone.
            </p>
          </div>
          <div className={` ${styles.cancelDialog__footer} flex`}>
            <Button
              label="Nevermind"
              className="outline xl full"
              action={closeModal}
            ></Button>
            <Button
              label="Yes, Cancel"
              className="red xl full "
              action={handleCancelBtn}
              disabled={loading}
              clicked={loading}
            ></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default CancelCampaign;
