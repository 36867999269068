import Dialog from "@mui/material/Dialog";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import LogoIcon from "../../components/LogoIcon";
import { EarlyAccessSteps } from "./enum";
import React, { useState } from "react";
import Signup from "./Signup";
import { CustomError, EarlyAccessFormModel, EarlyAccessSignupProps } from "./interface";
import { DEFAULT_STEPS_VALUES } from "./constants";
import SocialShareIcon from "../../assets/icons/socialShareIcon";
import { useDispatch, useSelector } from "react-redux";
import { currentStep } from "./selectors";
import SocialShare from "./SocialShare";
import ReactGA from "react-ga4";
import { registeration, setIsLoading, updateCurrentStepState } from "./reducer";
import { earlyAccessSignUp } from "./earlyAccessServices";
import { triggerNotification } from "../notifications/sagaActions";

const EarlyAccessSignup = ({ open, setOpen }: EarlyAccessSignupProps) => {
  const dispatch = useDispatch();
  const step: any = useSelector((state) => currentStep(state));
  const [stepData, setStepData] =
    useState<EarlyAccessFormModel>(DEFAULT_STEPS_VALUES);

  const handleClose = () => {
    setOpen!(false);
    dispatch(updateCurrentStepState(EarlyAccessSteps.Signup));
  };

  const onSubmit = async (data: any) => {
    switch (step) {
      case EarlyAccessSteps.Signup:
        try {
          const payload = { ...data };
          dispatch(setIsLoading(true));
          const response = await earlyAccessSignUp(payload);
          dispatch(registeration(response?.data));
          dispatch(updateCurrentStepState(EarlyAccessSteps.SignedUp));
        } catch (err) {
          console.log(err);
          dispatch(
            triggerNotification({
              message: (err as CustomError)?.response?.data?.message,
              type: "error",
            })
          );
        } finally {
          dispatch(setIsLoading(false));
        }
        break;
      case EarlyAccessSteps.SignedUp:
        dispatch(updateCurrentStepState(EarlyAccessSteps.SocialShare));
        break;
      case EarlyAccessSteps.SocialShare:
        handleClose();
        break;
    }
  };

  const getCurrentStep = (
    step: any,
    onSubmit: Function,
    stepData: EarlyAccessFormModel
  ) => {
    switch (step) {
      case EarlyAccessSteps.Signup:
        return (
          <>
            <Signup
              onSubmit={onSubmit}
              stepData={stepData[EarlyAccessSteps.Signup]}
            ></Signup>
          </>
        );
      case EarlyAccessSteps.SignedUp:
        return (
          <>
            <div className="authStyle__logo logo-left flex justifyStart">
              <LogoIcon />
            </div>
            <div className="authStyle__header">
              <h2>You’re signed up!</h2>
              <p>
                Keep an eye out for an email and text from us in the coming
                weeks for your beta invitation.
              </p>
            </div>
            <div className="authStyle__footer">
              <Button
                prefix={<SocialShareIcon />}
                label="Share on social media"
                className="primary full xl animated"
                action={onSubmit}
              ></Button>
            </div>
          </>
        );
      case EarlyAccessSteps.SocialShare:
        return (
          <>
            <SocialShare></SocialShare>
          </>
        );
      // return (
      //   <>
      //     <div className="authStyle__logo logo-left flex justifyStart">
      //       <LogoIcon />
      //     </div>
      //     <div className="authStyle__header">
      //       <h2>Share OttoLeads!</h2>
      //     </div>
      //     <ul className="dflex alignCenter authStyle__social">
      //       <li>
      //         <a
      //           href="https://twitter.com/intent/tweet?url=www.ottoleads.ai"
      //           target="_blank"
      //           rel="noreferrer"
      //         >
      //           <XTwitter />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={
      //             "https://www.linkedin.com/shareArticle?url=www.ottoleads.ai"
      //           }
      //           target="_blank"
      //         >
      //           <LinkedIn />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={
      //             "https://www.facebook.com/sharer.php?u=www.ottoleads.ai"
      //           }
      //           target="_blank"
      //         >
      //           <Facebook />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={"https://reddit.com/submit?url=www.ottoleads.ai"}
      //           target="_blank"
      //         >
      //           <Reddit />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={
      //             "https://www.instagram.com/sharer.php?u=www.ottoleads.ai"
      //           }
      //           target="_blank"
      //         >
      //           <Instagram />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={
      //             "https://www.pinterest.com/pin/create/button?url=www.ottoleads.ai"
      //           }
      //           target="_blank"
      //         >
      //           <PinTerEst />
      //         </a>
      //       </li>
      //       <li>
      //         <a
      //           href={
      //             "mailto:%7Bemail_address%7D?subject=&body=www.ottoleads.ai%20"
      //           }
      //           target="_blank"
      //           rel="nofollow noopener"
      //         >
      //           <EmailOpen />
      //         </a>
      //       </li>
      //     </ul>
      //     <div className="authStyle__copy">
      //       <input type="text" disabled value="www.ottoleads.ai" placeholder="www.ottoleads.ai" />
      //       <span role="link" onClick={copyToClipboard}>
      //         <Copy /> Copy{" "}
      //       </span>
      //     </div>
      //   </>
      // );
    }
  };

  // Trigger GA4 on successful form submit
  React.useEffect(() => {
    if (step === EarlyAccessSteps.SignedUp) {
      // Trigger GA4 event
      ReactGA.event("beta_signup");
    }
  }, [step]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={true}
        className={`stepsDialog loginRegister  left-align`}
      >
        <div className="dialogWrapper authStyle">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>
          {getCurrentStep(step, onSubmit, stepData)}
        </div>
      </Dialog>
    </>
  );
};

export default EarlyAccessSignup;
