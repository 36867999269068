import { initialState } from "./reducer";

const getStateData = (state: any) => state["createCampaign"] || initialState;

export const createCampaignResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer;
};

export const campaignsVoiceList = (state: any) => {
  const reducer = getStateData(state);

  return reducer.voiceList;
};
export const getCampaignLoadingState = (state: any) => {
  const reducer = getStateData(state);

  return reducer.campaignLoadingState;
};

export const getCampaignCreated = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignCreated;
};

export const getCampaignLeadList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.campaignLeadList;
}

export const getLoadingVoiceList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingVoiceList;
};

export const getLoadingCampaignLeadList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingCampaignLeadList;
};

export const getCreateCampaignData = (state :any)=>{
  const reducer = getStateData(state);
  return reducer.createCampaignData;
}