import { createContext, useContext, useMemo, useState } from "react";
import WelcomeModal from "../organisms/welcomeModal/WelcomeModal";
import Button from "../atoms/Button";
import { ModalCloseReason } from "../organisms/welcomeModal/interface";

interface HelpModalContextType {
  showWelcomeModal?: boolean;
  setShowWelcomeModal?: any;
  isWelcomeModalOpen: boolean;
  openWelcomeModal: Function;
  closeWelcomeModal: Function;
  modalContent: any;
  setModalContent: Function;
}

export const HelpModalContext = createContext<HelpModalContextType>({
  showWelcomeModal: false,
  setShowWelcomeModal: () => {},
  isWelcomeModalOpen: false,
  openWelcomeModal: () => {},
  closeWelcomeModal: () => {},
  modalContent: {},
  setModalContent: () => {},
});

function HelpModalContextProvider({ children }: any) {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    subtitle: "",
    video: "",
    path: "",
    pagename: "",
  });

  const helpModalDetails = useMemo(
    () => ({
      showWelcomeModal,
      setShowWelcomeModal,
      modalContent,
      setModalContent,
      isWelcomeModalOpen: showWelcomeModal,
      openWelcomeModal: ({
        title,
        subtitle,
        video,
        path,
        pagename,
      }: {
        title: string;
        subtitle: string;
        video: string;
        path: string;
        pagename: string;
      }) => {
        setModalContent({
          ...modalContent,
          title: title,
          subtitle: subtitle,
          video: video,
          path: path,
          pagename: pagename,
        });
        setShowWelcomeModal(true);
      },
      closeWelcomeModal: (event : React.SyntheticEvent<Element, Event>,reason : ModalCloseReason) => {
        if(reason === "backdropClick" || reason === "escapeKeyDown") return;
        setShowWelcomeModal(false);
      },
      title: modalContent.title,
      subtitle: modalContent.subtitle,
      video: modalContent.video,
      path: modalContent.path,
      pagename: modalContent.pagename,
      updateCustomModalContent:
        () =>
        (
          title: string,
          subtitle: string,
          video: string,
          path: string,
          pagename: string
        ) => {
          setModalContent({
            ...modalContent,
            title: title,
            subtitle: subtitle,
            video: video,
            path: path,
            pagename: pagename,
          });
        },
    }),
    [showWelcomeModal, setShowWelcomeModal, modalContent, setModalContent]
  );

  return (
    <HelpModalContext.Provider value={helpModalDetails}>
      {children}
      <WelcomeModal
        handleClose={helpModalDetails.closeWelcomeModal}
        open={helpModalDetails.isWelcomeModalOpen}
        title={helpModalDetails.title}
        subtitle={helpModalDetails.subtitle}
        video={helpModalDetails.video}
        button={
          <Button
            action={() => setShowWelcomeModal(false)}
            className="primary xl full"
            label="Got it"
          />
        }
        termsOfUseFlag={helpModalDetails.pagename === "propertySearch"}
      />
    </HelpModalContext.Provider>
  );
}
const useHelpModalContext = () => useContext(HelpModalContext);
export { useHelpModalContext };
export default HelpModalContextProvider;