const ArrowLeft = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20" height="20" viewBox="0 0 20 20"
                fill="none">
                <path
                    d="M15.8327 10.0001H4.16602M4.16602 10.0001L9.99935 15.8334M4.16602 10.0001L9.99935 4.16675"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default ArrowLeft;


