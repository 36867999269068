import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import Chat from "../../../../organisms/chat/Chat";
import { getSelectedLeadId } from "../../../../organisms/leadsTable/selectors";
import { putPropertyComment } from "../../../../organisms/propertySearchFilter/PropertyService";
import { getPropertyDetails } from "../../property.selector";
import { createNote, fetchNotes } from "../../property.service";

const NotesTab = ({ setShowNoteCount }: any) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [addNotesLoading, setAddNotesLoading] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const handleAddNote = async (htmlContent: string) => {
    try {
      setAddNotesLoading(true);
      await createNote(propertyDetails?.leadId || selectedLeadId, {
        body: htmlContent,
      });
      await getNotes(propertyDetails?.leadId || selectedLeadId);
      // handleShowToast("Note added successfully");
      setShowNoteCount((prev: any) => prev + 1);
      setAddNotesLoading(false);
    } catch (error: any) {
      setAddNotesLoading(false);
      handleShowToast(
        error.message || "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleEditNote = async (id: string, htmlContent: string) => {
    try {
      setAddNotesLoading(true);
      await putPropertyComment(id, {
        body: htmlContent,
      });
      await getNotes(propertyDetails?.leadId || selectedLeadId);
      // handleShowToast("Note updated successfully");
      setAddNotesLoading(false);
    } catch (error: any) {
      setAddNotesLoading(false);
      handleShowToast(
        error.message || "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const getNotes = useCallback(
    async (id: string, pageNumber: number = 1) => {
      try {
        if (pageNumber === 1) {
          setIsLoading(true);
        }
        const res = await fetchNotes(id, pageNumber);
        if (res?.data?.totalCount || res?.data?.count) {
          setNotes((messages) => [
            ...(pageNumber === 1 ? [] : messages),
            ...(res?.data?.result || []),
          ]);
          setTotalCount(+(res?.data?.totalCount || res?.data?.count || 0));
        } else {
          setNotes(res.data);
        }
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        handleShowToast(
          error.message || "Something went wrong!",
          ToastType.error
        );
      }
    },
    [setNotes, setIsLoading]
  );
  useEffect(() => {
    if (propertyDetails?.leadId || selectedLeadId) {
      getNotes(propertyDetails?.leadId || selectedLeadId, pageNumber);
    }
  }, [propertyDetails?.leadId, getNotes, selectedLeadId, pageNumber]);

  return (
    <Chat
      chatLoading={isLoading}
      sendLoading={addNotesLoading}
      messages={notes}
      onSend={handleAddNote}
      onEdit={handleEditNote}
      title="My Notes"
      totalCount={totalCount}
      handleNextPage={() => setPageNumber((prev) => prev + 1)}
    />
  );
};
export default NotesTab;
