import { Column } from "../../models/table.interface";

export const INVOICE_COLUMN: Column[] = [
  {
    id: "invoice",
    label: "Invoice",
    minWidth: 570,
    type: "",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 70,
    type: "",
  },
  { id: "createdAt", label: "Date", minWidth: 70, type: "" },
  { id: "status", label: "Status", minWidth: 70, type: "status" },
  { id: "downloadInvoice", label: "", minWidth: 70, type: "icon" },
];

export const INVOICE_CSV_HEADER = [
  { label: "Invoice", key: "invoice" },
  { label: "Amount", key: "amount" },
  { label: "Date", key: "createdAt" },
  { label: "Status", key: "status" },
];


export const PAUSE_SUBSCRIPTION_DAYS_OPTIONS = [
  { value: 7, label: "7 Days" },
  { value: 15, label: "15 Days" },
  { value: 30, label: "30 Days" },
];