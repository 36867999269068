import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAPI, putAPI } from "../../apis/apis";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchProfileInfo({ payload }: any) {
  try {
    yield put(slice.setProfileInfoLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/users/profile`,
      headers: {},
    });
    yield all([
      put(slice.setProfileInfoLoading(false)),
      put(slice.setSessionLoading(false)),
      put(slice.setProfileInfo(response?.data)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  } finally {
    yield all([
      put(slice.setProfileInfoLoading(false)),
      put(slice.setSessionLoading(false)),
    ]);
  }
}

function* updateProfileInfo({ payload }: any) {
  try {
    yield putAPI({
      url: `${process.env.REACT_APP_BASE_URL}/users/update-profile`,
      data: {
        userWalkthrough: payload,
      },
      headers: {},
    });
    yield call(fetchProfileInfo, {});
  } catch (error: any) {
    yield put(
      addToast({
        id: new Date().getTime(),
        message:
          error?.error?.message || error?.message || "Something went wrong!",
        type: ToastType.error,
      })
    );
    console.log("error =", error);
  }
}

function* profileInfoSaga() {
  yield takeLatest(sagaActions.fetchProfileInfo, fetchProfileInfo);
  yield takeLatest(sagaActions.updateProfileAction, updateProfileInfo);
}

export default profileInfoSaga;
