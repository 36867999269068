import { initialState } from "./reducer";

const getStateData = (state: any) => state["notification"] || initialState;

export const notificationDetails = (state: any) => {
  const reducer = getStateData(state);
  return reducer.payload;
};

export const notificationDataResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer.notificationData;
};
