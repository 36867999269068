import Icon from '../assets/images/icon.png';
import IconWhiteImage from '../assets/images/icon-white.png';
import { Link } from "react-router-dom";

const LogoIcon = ({logoWhite}:{logoWhite?:any}) => { 
    return (
        <Link to="/" className='inlineFlex logo-icon'>
        {logoWhite ? <><img src={IconWhiteImage} alt="logo" /></> :<><img src={Icon} alt="logo" /></>} 
        </Link>
       
  );
};

export default LogoIcon;