

const Save = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5.83333 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36359 17.5 7.77124V14.1667M10.4167 8.33333H7.16667C6.69996 8.33333 6.4666 8.33333 6.28834 8.24251C6.13154 8.16261 6.00406 8.03513 5.92416 7.87833C5.83333 7.70007 5.83333 7.46671 5.83333 7V5.41667M11.25 17.5V13.8333C11.25 13.3666 11.25 13.1333 11.1592 12.955C11.0793 12.7982 10.9518 12.6707 10.795 12.5908C10.6167 12.5 10.3834 12.5 9.91667 12.5H7.16667C6.69996 12.5 6.4666 12.5 6.28834 12.5908C6.13154 12.6707 6.00406 12.7982 5.92416 12.955C5.83333 13.1333 5.83333 13.3666 5.83333 13.8333V17.5M14.5833 8.46895V14.8333C14.5833 15.7668 14.5833 16.2335 14.4017 16.59C14.2419 16.9036 13.9869 17.1586 13.6733 17.3183C13.3168 17.5 12.8501 17.5 11.9167 17.5H5.16667C4.23325 17.5 3.76654 17.5 3.41002 17.3183C3.09641 17.1586 2.84144 16.9036 2.68166 16.59C2.5 16.2335 2.5 15.7668 2.5 14.8333V8.08333C2.5 7.14991 2.5 6.6832 2.68166 6.32668C2.84144 6.01308 3.09641 5.75811 3.41002 5.59832C3.76654 5.41667 4.23325 5.41667 5.16667 5.41667H11.531C11.7349 5.41667 11.8368 5.41667 11.9327 5.43969C12.0177 5.46011 12.099 5.49378 12.1736 5.53947C12.2577 5.591 12.3297 5.66306 12.4739 5.80719L14.1928 7.52614C14.3369 7.67027 14.409 7.74233 14.4605 7.82643C14.5062 7.90099 14.5399 7.98228 14.5603 8.06731C14.5833 8.16321 14.5833 8.26513 14.5833 8.46895Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Save;
