import * as yup from "yup";
export const amortizationCalculatorSchema = yup.object({
  afterRepairValue: yup.number().nullable().transform((value, originalValue) => {
    return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
  }),
  costOfRepairs: yup.number().nullable().transform((value, originalValue) => {
    return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
  }),
  assignmentFee: yup.number().nullable().transform((value, originalValue) => {
    return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
  }),
  loanAmount: yup.number().nullable().transform((value, originalValue) => {
    return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
  }),
  profitMargin: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === "" || isNaN(originalValue) ? 0.7 : Number(originalValue);
    })
    .required("Profit Margin is required."),
  loanTerm: yup
    .string()
    .test("min-max", "Loan Term cannot be greater than 60", function (value) {
      return +(value ?? 0) <= 60;
    }),
  interestRate: yup
    .string()
    .test(
      "min-max",
      "Interest Rate cannot be greater than 100",
      function (value) {
        return +(value??0) <= 100;
      }
    ).test(
      "min-max",
      "Interest Rate cannot be equal and lower than 0",
      function (value) {
        return +(value??0) > 0;
      }
    ),
});
