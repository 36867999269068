import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  openCreateListModal: false,
  skipPropertyCount: {} as any,
  skipPropertyCountLoading:false,
};

const createListSlice = createSlice({
  name: "createList",
  initialState,
  reducers: {
    setSkipPropertyCount: (state, { payload }) => {
      return { ...state, skipPropertyCount: payload };
    },
    toggleSkipPropertyCountLoading: (state, { payload }) => {
      return { ...state, skipPropertyCountLoading: payload };
    },
    toggleCreateListModal: (state, { payload }) => {
      return { ...state, openCreateListModal: payload };
    },
    setPropertyCardsLoading: (state, { payload }) => {
      return { ...state, propertyCardsLoading: payload };
    },
    resetCreateListState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  toggleCreateListModal,
  resetCreateListState,
  setSkipPropertyCount,
  toggleSkipPropertyCountLoading,
  setPropertyCardsLoading
} = createListSlice.actions;

export default createListSlice.reducer;