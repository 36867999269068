export enum PropertyDetailsParentIdType {
  LEAD_ID = "leadId",
  REFERENCE_ID = "referenceId",
}

export enum PropertyDetailsDisplay {
  PROPERTY_SEARCH = "Property Search"
}

export enum tabTitle {
  AISUMMARY = "AI Smart Summary"
}


