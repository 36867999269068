import logo from "../assets/images/logo.png";
import logoWhiteImage from "../assets/images/logo-white.png";
import { Link } from "react-router-dom";

const Logo = ({ logoWhite }: { logoWhite?: any }) => {
  return (
    <>
      {window.location.pathname === "/verify-email" ||
      window.location.pathname === "/forgot-password" ? (
       <div className="inlineFlex logo-main">
          {logoWhite ? (
            <>
              <img src={logoWhiteImage} alt="logo" />
            </>
          ) : (
            <>
              <img src={logo} alt="logo" />
            </>
          )}
       </div>
      ) : (
        <Link to="/property-search" className="inlineFlex logo-main">
          {logoWhite ? (
            <>
              <img src={logoWhiteImage} alt="logo" />
            </>
          ) : (
            <>
              <img src={logo} alt="logo" />
            </>
          )}
        </Link>
      )}
    </>
  );
};

export default Logo;
