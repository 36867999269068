const Percent = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M16.5026 4.16699L4.83594 15.8337M8.16927 5.83366C8.16927 6.75413 7.42308 7.50033 6.5026 7.50033C5.58213 7.50033 4.83594 6.75413 4.83594 5.83366C4.83594 4.91318 5.58213 4.16699 6.5026 4.16699C7.42308 4.16699 8.16927 4.91318 8.16927 5.83366ZM16.5026 14.167C16.5026 15.0875 15.7564 15.8337 14.8359 15.8337C13.9155 15.8337 13.1693 15.0875 13.1693 14.167C13.1693 13.2465 13.9155 12.5003 14.8359 12.5003C15.7564 12.5003 16.5026 13.2465 16.5026 14.167Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>


        </>
    );
};

export default Percent;
